// import '../style.scss'

import bgDesktop from "src/assets/imgs/block-5-background.png"
import bgMd from "src/assets/imgs/block-5-background-md.png"
import bgXs from "src/assets/imgs/block-5-background-xs.png"
import useWindowSize from "src/util/hook/useWindowSize"

interface Block5Props {}
const Block5Kr: React.FC<Block5Props> = () => {
  const { width }: any = useWindowSize()
  return (
    <div
      className="lg:h-[650px] md:h-[350px] lpSmm:h-[230px] h-[160px] bg-block5Bg md:bg-1024 lpSm:bg-541 lg:bg-cover flex items-center justify-center text-center w-full"
      style={{
        backgroundImage: `url(${
          width <= 375 ? bgXs : width <= 540 ? bgMd : bgDesktop
        })`,
        // zoom: width < 768 ? undefined : width < 1280 ? '0.7' : undefined,
      }}
    >
      <div data-aos="fade-up">
        <h4 className="lg:text-[35px] md:text-[24px] lpSmm:text-[18px] text-[15px] md:leading-[45px] leading-normal0 uppercase font-montserrat400 text-[#fff] ld:text-[35px] text-[10px] uppercase lg:mb-[31px] lpSmm:mb-[10px] mb-[5px]">
          PREMIUM MEMBERSHIP
        </h4>
        <h3 className="lg:text-[55px] md:text-[40px] lpSmm:text-[28px] text-[22px] md:leading-[45px] leading-normal uppercase font-montserrat400 text-[#fff] font-spoqa500">
          한정된 VIP만을 위한 하이엔드 라이프
        </h3>
      </div>
    </div>
  )
}

export default Block5Kr
