import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const niceApi = {
  createVerify(
    requestType: "REGISTER" | "UPDATE_INFO",
  ): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.CREATE_VERIFY,
      method: "POST",
      tokenRequired: true,
      data: {
        requestType: requestType,
      },
    })
  },
}
