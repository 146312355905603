import { message } from "antd"
import dayjs from "dayjs"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { orderApi } from "src/api/orderApi"
import Button from "src/components/Button"
import CommonModal from "src/components/Modal"
import { UrlInternal } from "src/constant/endpointRoute"
import NavBarMb from "src/layout/Header/NavBarMb"
import { convertStatus } from "src/util/convertStatusPayment"
import { formatPrice } from "src/util/formatPrice"

const TIME_NUMBER: any = { "1M": 1, "3M": 3, "6M": 6, "1Y": 12 }

function DetailHistoryOrderMembership() {
  const { id } = useParams()
  const { t } = useTranslation()
  const [orderDetail, setOrderDetail] = useState<any>({})
  const [isModalCancelOrder, setIsModalCancelOrder] = useState(false)
  const [queryTrans, setQueryTrans] = useState<{
    page: number
    size: number
    timeFilter: string
    brandId: string
  }>({
    page: 1,
    size: 10,
    timeFilter: "",
    brandId: "",
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const type = searchParams.get("type")
  const navigate = useNavigate()

  const getDetailOrderHistory = async () => {
    try {
      const { data } =
        type === "order"
          ? await orderApi.getOrderDetail(Number(id))
          : await orderApi.getOrderDetailNew(Number(id))

      if (data) {
        setOrderDetail(data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getDetailOrderHistory()
  }, [])

  const handleCancelOrder = async () => {
    try {
      const resp: any = await orderApi.cancelOrder(Number(id))

      setIsModalCancelOrder(false)

      if (resp.result) {
        getDetailOrderHistory()
      } else {
        message.error(resp.error.message)
      }
    } catch (error) {}
  }
  return (
    <>
      <CommonModal
        onCancel={() => {
          setIsModalCancelOrder(false)
        }}
        // isOpen
        isOpen={isModalCancelOrder}
        className="modal-request-refund text-[#000] xs:max-w-[380px] max-w-[340px]"
      >
        <div className="text-[20px] font-pretendard500">환불 요청</div>
        <div className="font-pretendard500 text-[16px] pt-[20px] pb-[9px]">
          환불 정책
        </div>
        <div className="text-[#757575]">
          <div>결제 후 14일 이내 브랜드가 결제 취소 가능</div>
          <div>결제 당일에 취소하면 월렛으로 적립금 즉시 환불</div>
          <div>결제일+1일부터 취소하는 건에 대해서는 적립금이 결</div>
          <div>제취소일+1일 오전 9시에 환불됨</div>
        </div>
        <div className="font-pretendard500 text-[15px] pt-[40px] pb-[10px] text-[#2D2D2D] text-center">
          환불을 요청하시겠어요?
        </div>
        <div className="flex gap-[10px]">
          <div
            className="w-[50%] bg-[#fff] text-[#000] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer border border-solid border-[#000]"
            onClick={() => setIsModalCancelOrder(false)}
          >
            취소
          </div>
          <div
            className="w-[50%] bg-[#000] text-[#fff] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
            onClick={handleCancelOrder}
          >
            확인
          </div>
        </div>
      </CommonModal>

      <NavBarMb
        pageName="주문상세조회"
        csGoback={() =>
          navigate(UrlInternal.HISTORY_ORDER_MEMBERSHIP, {
            state: {
              type: type,
            },
          })
        }
      />
      <div className="px-[24px]">
        <div className="py-[29px] text-[18px] font-pretendard700">
          주문 상품 정보
        </div>
        <div className="flex gap-[20px] pb-[30px] border-b border-b-[#D4D4D4]">
          <img
            src={
              type === "order"
                ? orderDetail?.voucherImage
                : orderDetail?.logoImage
            }
            alt="img"
            className="w-[80px] h-[80px] border border-[#D4D4D4]"
          />
          <div className="flex-1 leading-[100%]">
            <div className="flex justify-between">
              <div className="text-[#666] text-[12px]">
                {orderDetail?.brandName}
              </div>
              <div
                className={`font-pretendard700 text-[11px] text-center text-[#fff] h-[20px] leading-[20px] px-[5.5px]  ${
                  orderDetail?.status === "PAID" ||
                  orderDetail?.status === "OWNED"
                    ? "bg-[#539165]"
                    : "bg-[#C70039]"
                }`}
              >
                {t(convertStatus(orderDetail?.status) ?? "")}
              </div>
            </div>
            <div className="pt-[10px] pb-[16px] text-[16px]">
              {type === "order"
                ? orderDetail?.voucherName
                : orderDetail?.membershipName}
            </div>
            <div className="pb-[8px] font-pretendard700 text-[14px]">
              {formatPrice(
                type === "order"
                  ? orderDetail?.totalValue
                  : orderDetail?.sellingPrice,
              )}
              원
            </div>
            <div className="text-[14px]">
              수량 {type === "order" ? orderDetail?.quantity : "1"}개
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[17px] pt-[33px] pb-[38px] text-[14px]">
          <div className="flex justify-between">
            <div className="text-[#484848]">멤버십 구분</div>
            <div className="font-pretendard700">
              {type === "order" ? "프리미엄" : "일반"}
            </div>
            {/* <div className="font-pretendard700">{orderDetail?.type}</div> */}
          </div>
          <div className="flex justify-between">
            <div className="text-[#484848]">멤버십 No.</div>
            <div className="font-pretendard700">
              {orderDetail?.membershipNo?.length > 1 ? (
                <>
                  {orderDetail?.membershipNo[0]}~
                  {
                    orderDetail?.membershipNo[
                      orderDetail?.membershipNo.length - 1
                    ]
                  }
                </>
              ) : (
                orderDetail?.membershipNo?.[0]
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-[#484848]">유효 기간</div>
            <div className="font-pretendard700">
              {dayjs()
                .subtract(TIME_NUMBER[queryTrans.timeFilter] || 12, "month")
                .format("YYYY.MM.DD")}{" "}
              ~ {dayjs().format("YYYY.MM.DD")}
            </div>
          </div>
        </div>

        <div className="w-[100vw] h-[16px] bg-[#F5F5F5] mx-[-24px]"></div>

        <div className="pt-[46px] pb-[40px] text-[14px]">
          <div className="pb-[18px] text-[18px] font-pretendard700">
            구매자 정보
          </div>
          <div className="flex flex-col gap-[13px]">
            <div className="flex justify-between">
              <div className="text-[#484848]">주문자</div>
              <div className="font-pretendard700">
                {orderDetail?.displayName}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-[#484848]">이메일주소</div>
              <div className="font-pretendard700">{orderDetail?.email}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-[#484848]">휴대전화번호</div>
              <div className="font-pretendard700">
                {orderDetail?.phoneNumber}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100vw] h-[16px] bg-[#F5F5F5] mx-[-24px]"></div>

        <div className="pt-[40px] pb-[58px] text-[14px]">
          <div className="pb-[20px] text-[18px] font-pretendard700">
            결제 정보
          </div>
          <div className="flex flex-col gap-[13px]">
            <div className="flex justify-between">
              <div className="text-[#484848]">총 상품 금액</div>
              <div className="font-pretendard700">
                {formatPrice(
                  type === "order"
                    ? orderDetail?.usageValue
                    : orderDetail?.rootPrice,
                )}
                원
              </div>
            </div>
            {type === "order" ? (
              <>
                {orderDetail?.discountPercentage && (
                  <div className="flex justify-between">
                    <div className="text-[#484848]">할인 금액</div>
                    <div className="font-pretendard700">
                      {formatPrice(
                        orderDetail?.discountPercentage * orderDetail?.quantity,
                      )}
                      원
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {orderDetail?.sellingPrice && (
                  <div className="flex justify-between">
                    <div className="text-[#484848]">할인 금액</div>
                    <div className="font-pretendard700">
                      {formatPrice(
                        Number(orderDetail?.rootPrice) -
                          Number(orderDetail?.sellingPrice),
                      )}
                      원
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="flex justify-between">
              <div className="text-[16px] font-pretendard500">총 결제 금액</div>
              <div className="font-pretendard700 text-[#C70039] text-[24px]">
                {formatPrice(
                  type === "order"
                    ? orderDetail?.totalValue
                    : orderDetail?.sellingPrice,
                )}
                원
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-[#484848]">결제 방법</div>
              <div className="font-pretendard700">
                {t(orderDetail?.paymentMethod)}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-[#484848]">결제 일시</div>
              <div className="font-pretendard700">
                {moment(orderDetail?.createdTime).format("YYYY.MM.DD HH:mm")}
              </div>
            </div>
          </div>
        </div>
        <Button
          theme="main"
          variant="outline"
          height={44}
          // width={155}
          onClick={() => {
            setIsModalCancelOrder(true)
          }}
          disabled={
            orderDetail?.status === "REQUESTED" ||
            orderDetail?.status === "REFUNDED" ||
            orderDetail?.status === "CANCEL"
          }
        >
          {t("환불 요청")}
        </Button>

        <div className="mt-[15px]">
          <div className="flex gap-[8px]">
            <div className="w-[3px] h-[3px] aspect-square bg-[#666666] rounded mt-[8px]"></div>
            <div className="text-[#666666] text-[12px]">
              주문 취소 및 환불 신청은 결제 후 7일 이내에만 가능합니다.
            </div>
          </div>
          <div className="flex gap-[8px]">
            <div className="w-[3px] h-[3px] aspect-square bg-[#666666] rounded mt-[8px]"></div>
            <div className="text-[#666666] text-[12px]">
              주문 취소 접수 후 결제 카드 승인취소까지 영업일 기준 약 3~7일
              소요됩니다.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DetailHistoryOrderMembership
