import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  deleteEContent,
  getDetailEContent,
  readEContent,
} from "src/api/myEContentApi"
import ArrowDown from "@assets/images/my_e_content/arrow-down.png"
import ArrowUp from "@assets/images/my_e_content/arrow-up.png"
import { UrlInternal } from "src/constant/endpointRoute"
import PlayAudio from "@assets/images/my_e_content/play-audio.png"
import PauseAudio from "@assets/images/my_e_content/pause-audio.png"
import VideoJS from "src/components/VideoJS"
import CommonModal from "src/components/Modal"
import Button from "src/components/Button"
import ApiConfig from "src/configs/apiConfig"
const apiConfig = ApiConfig()
function MyEContentDetail() {
  const params = useParams()
  const { id } = params
  const navigate = useNavigate()
  const [audioStatus, changeAudioStatus] = useState(false)
  const myRef: any = useRef()
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [data, setData] = useState<any>({})
  const [dataTab, setDataTab] = useState({
    isShow: false,
    mimeType: "",
  })

  const [modalDownloadFile, setModalDownloadFile] = useState({
    isOpen: false,
    urlFile: "",
  })

  const startAudio = () => {
    myRef.current.play()

    changeAudioStatus(true)
  }

  const pauseAudio = () => {
    console.log("here")
    myRef.current.pause()
    changeAudioStatus(false)
  }

  // Update current time and duration when audio plays or time updates
  useEffect(() => {
    const audio = myRef.current
    if (audio && dataTab.mimeType) {
      const updateCurrentTime = () => setCurrentTime(audio.currentTime)
      const updateDuration = () => setDuration(audio.duration)

      audio.addEventListener("timeupdate", updateCurrentTime)
      audio.addEventListener("loadedmetadata", updateDuration)

      // Clean up event listeners on component unmount
      return () => {
        audio.removeEventListener("timeupdate", updateCurrentTime)
        audio.removeEventListener("loadedmetadata", updateDuration)
      }
    }
  }, [dataTab.mimeType])

  // Helper function to format time in mm:ss
  const formatTime = (time: number) => {
    console.log("time", time)

    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
  }

  const getDetail = async () => {
    try {
      const resp: any = await getDetailEContent({
        id: Number(id),
      })
      if (resp.result) {
        setData(resp.data)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getDetail()
  }, [])

  const handleDelete = async () => {
    try {
      const resp: any = await deleteEContent({
        id: Number(id),
      })
      if (resp.result) {
        navigate(UrlInternal.MY_E_CONTENT)
      }
    } catch (error) {}
  }

  const handleReadFile = async () => {
    try {
      const resp: any = await readEContent({
        id: Number(id),
      })
    } catch (error) {}
  }

  return (
    <>
      <CommonModal
        isOpen={modalDownloadFile.isOpen}
        handleClose={() =>
          setModalDownloadFile({
            isOpen: false,
            urlFile: "",
          })
        }
      >
        <div className="font-pretendard400 text-[16px]">
          해당 파일은 브랜드가 업로드한 파일이기에 모아프렌즈에서는 해당 파일의
          안정성을 보장하지 않습니다. 다운로드 하시겠습니까?
        </div>

        <div className="flex gap-[10px] mt-[30px] justify-between">
          <Button
            theme="main"
            variant="outline"
            onClick={() =>
              setModalDownloadFile({
                isOpen: false,
                urlFile: "",
              })
            }
          >
            취소
          </Button>
          <a
            // href={modalDownloadFile.urlFile}
            href={`${apiConfig.BASE_URL}/upload/download-from-s3?url=${modalDownloadFile.urlFile}`}
            // target="_blank"
            rel="noopener noreferrer"
            download
            className="block w-full"
          >
            <Button
              theme="main"
              variant="primary"
              // onClick={handleDownloadFile}
            >
              다운로드
            </Button>
          </a>
        </div>
      </CommonModal>

      <div className="font-pretendard700 text-[24px] pb-[21px] border-b border-[#000000] md:block hidden">
        My e-content
      </div>

      <div className="flex justify-between items-center md:mt-[49px] md:mb-[54px] mt-[4px] mb-[27px]">
        <div className="md:text-[35px] text-[18px] font-pretendard600">
          {data?.voucherName}
        </div>
        <div
          className="text-center bg-[#E9E9E9] h-[40px] w-[100px] leading-[40px] cursor-pointer md:block hidden"
          onClick={handleDelete}
        >
          Delete
        </div>
      </div>

      {data?.file &&
        data.file.length > 0 &&
        data.file.map((item: any, i: number) => (
          <div key={i}>
            <div
              className="flex justify-between cursor-pointer py-[30px] border-y border-dashed border-[#AAAAAA]"
              onClick={() => {
                if (audioStatus) {
                  pauseAudio()
                }

                if (!data?.read) {
                  handleReadFile()
                }

                if (
                  item?.mimeType.split("/")[0] !== "video" &&
                  item?.mimeType.split("/")[0] !== "image" &&
                  item?.mimeType.split("/")[0] !== "audio" &&
                  item?.mimeType !== "application/pdf"
                ) {
                  setModalDownloadFile({
                    isOpen: true,
                    urlFile: item?.imageUrl,
                  })
                } else {
                  if (dataTab.mimeType !== item?.mimeType) {
                    setDataTab({
                      isShow: true,
                      mimeType: item?.mimeType,
                    })
                  } else {
                    setDataTab({
                      isShow: !dataTab.isShow,
                      mimeType: item?.mimeType,
                    })
                  }
                }
              }}
            >
              <div>{item?.name}</div>
              <div className="flex justify-center items-center ">
                {dataTab.mimeType === item?.mimeType && dataTab.isShow ? (
                  <img src={ArrowUp} alt="up" />
                ) : (
                  <img src={ArrowDown} alt="down" />
                )}
              </div>
            </div>

            {dataTab.mimeType === item?.mimeType && dataTab.isShow && (
              <div className="mt-[-1px]">
                {item?.mimeType.split("/")[0] === "audio" && (
                  <div className="max-w-807px mx-auto bg-[#131313] px-[40px] py-[12px] flex justify-between items-center">
                    <div className="flex gap-[22px] items-center">
                      <div
                        className="border-[3px] border-[#FFFFFF26] min-w-[65px] h-[65px] rounded-full flex justify-center items-center cursor-pointer"
                        onClick={() => {
                          if (audioStatus) {
                            pauseAudio()
                          } else {
                            startAudio()
                          }
                        }}
                      >
                        <audio ref={myRef} src={item?.imageUrl} />
                        {audioStatus ? (
                          <img src={PauseAudio} alt="play img" />
                        ) : (
                          <img src={PlayAudio} alt="play img" />
                        )}
                      </div>
                      <div>
                        <div className="text-[#fff]">{item?.name}</div>
                        <div className="text-[#ACACAC] font-pretendard500 md:hidden">
                          {formatTime(currentTime)} / {formatTime(duration)}
                        </div>
                      </div>
                    </div>

                    <div className="text-[#ACACAC] font-pretendard500 md:block hidden">
                      {formatTime(currentTime)} / {formatTime(duration)}
                    </div>
                  </div>
                )}

                {item?.mimeType === "application/pdf" && (
                  <iframe
                    src={item?.imageUrl}
                    className="min-h-[600px] w-full"
                  ></iframe>
                )}

                {item?.mimeType.split("/")[0] === "video" && (
                  <VideoJS url={item?.imageUrl} />
                )}
                {item?.mimeType.split("/")[0] === "image" && (
                  <img alt="img" src={item?.imageUrl} />
                )}
              </div>
            )}
          </div>
        ))}

      <div
        className="text-center bg-[#E9E9E9] h-[40px] w-[100px] leading-[40px] cursor-pointer md:hidden mt-[28px] ml-auto"
        onClick={handleDelete}
      >
        Delete
      </div>
    </>
  )
}

export default MyEContentDetail
