import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "src/app/store"

export interface LoadingState {
  isLoading: boolean
}

const initialState: LoadingState = {
  isLoading: false,
}

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    openLoading: (state) => {
      state.isLoading = true
    },
    closeLoading: (state) => {
      state.isLoading = false
    },
  },
})

export const { openLoading, closeLoading } = loadingSlice.actions
export const loadingSelect = (state: RootState) => state.loading.isLoading

export default loadingSlice.reducer
