import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Button from "src/components/Button"
import useIsMobile from "src/customHook/useIsMobile"
import { orderApi } from "src/api/orderApi"
import React, { useEffect, useState } from "react"
import { MoneyConvert } from "src/util/format"
import { Col, Row, message } from "antd"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import CancelOrder from "src/components/Modal/CancelOrder"
import { convertStatus, convertStatusBg } from "src/util/convertStatusPayment"
import { formatPrice } from "src/util/formatPrice"
import moment from "moment"

function OrderEcontentDetail() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isMobile } = useIsMobile(1023)
  const params = useParams()
  const [orderDetail, setOrderDetail] = useState<any>()
  const [isCanceled, setIsCanceled] = useState<boolean>(false)
  const [isOpenCancel, setIsOpenCancel] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 })
  const [searchParams, setSearchParams] = useSearchParams()

  const orderId = params?.id as string
  const getOrderDetail = async () => {
    try {
      const { data, result }: any = await orderApi.getOrderDetail(orderId)
      if (result) {
        setOrderDetail(data)
      } else {
        // navigate(-1)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getOrderDetail()
  }, [orderId])

  const convertPayment = (paymentMethod: string) => {
    switch (paymentMethod) {
      case "CREDIT":
        return "신용/체크카드"
      case "KAKAO":
        return "카카오페이"
      case "NAVER":
        return "네이버페이"
      default:
        return paymentMethod
    }
  }

  const handleCancelOrder = async () => {
    const { result, data, error }: any = await orderApi.cancelOrder(orderId)

    if (error) {
      messageApi.open({
        type: "error",
        content: error?.message,
      })
    }

    setIsOpenCancel(false)

    if (result) {
      getOrderDetail()

      // setOrderDetail((prev: any) => ({
      //   ...prev,
      //   ...data,
      //   orderHistory: prev?.orderHistory
      //     ? [
      //         ...prev.orderHistory,
      //         {
      //           status: data.status,
      //           createdTime: data.updated_time,
      //         },
      //       ]
      //     : prev?.orderHistory,
      // }))
      setIsCanceled(true)
    } else {
      throw data
    }
  }

  const handleRequestCancelRefund = async () => {
    const { result, data, error }: any =
      orderDetail?.status === "REQUESTED"
        ? await orderApi.cancelRequestRefundOrder(orderId)
        : await orderApi.requestCancelRefundOrder(orderId)
    if (result) {
      getOrderDetail()
    }
  }

  return (
    <div className="">
      <div className="relative z-10">
        <div className="lg:gap-[20px] gap-[16px]">
          <div className="hidden lg:flex justify-between font-pretendard700 text-[24px] leading-[100%] pb-[24px] border-b-[1px] border-b-primary mb-[32px]">
            <span className="leading-[100%]">{t("주문 상세 조회")}</span>
          </div>

          <Row gutter={[59, 40]}>
            <Col flex={1}>
              <p className="text-[18px] font-pretendard700 text-primary mb-[32px] leading-none">
                {t("주문 상품 정보")}
              </p>
              <div className="mb-[30px] md:mb-[32px] border-b-solid border-b md:border-b border-gray5 flex md:flex-row md:justify-normal">
                <div className="mr-[18px] mb-[36px]">
                  <div className="border border-solid w-[80px] h-[80px] md:w-[120px] md:h-[120px] flex justify-center items-center text-primaryGreen font-pretendard700">
                    <img
                      className="w-full h-full object-cover aspect-square cursor-pointer"
                      src={orderDetail?.voucherImage}
                      alt="img"
                      onClick={() => navigate(`/${orderDetail?.brandId}`)}
                    />
                  </div>
                </div>
                <div className="block flex-1">
                  <div className="flex">
                    <p className="text-[12px] md:text-[14px] font-pretendard400 leading-none mb-[8px] md:mb-[16px]">
                      {orderDetail?.brandName}
                    </p>
                    <div className="flex justify-end flex-1 ml-[20px]">
                      <div className="bg-black  h-[24px]">
                        <Button
                          onClick={() => {}}
                          className={`flex justify-center ${convertStatusBg(
                            orderDetail?.status,
                          )} text-white text-[10px] w-full font-pretendard700 h-full px-[5px] disabled:text-gray2 cursor-default`}
                        >
                          {t(orderDetail?.status)}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <p className="text-[16px] md:text-[18px] font-pretendard700 text-primary mb-[10px] leading-none">
                    {orderDetail?.voucherName}
                  </p>

                  {orderDetail?.membershipType !== "NONE" && (
                    <div className="line-through text-[#767676] text-[13px] pb-[10px]">
                      {formatPrice(
                        Number(orderDetail?.voucherPrice) *
                          orderDetail?.quantity,
                      )}
                      원
                    </div>
                  )}
                  {/* <div className="line-through text-[#767676] text-[13px] pb-[10px]">
                    {formatPrice(
                      Number(orderDetail?.voucherPrice) * orderDetail?.quantity,
                    )}
                    원
                  </div> */}

                  <span className="text-[12px] md:text-[14px] font-pretendard400 leading-none">
                    <span className="font-pretendard700">
                      {MoneyConvert(
                        Number(orderDetail?.totalPayment) /
                          orderDetail?.quantity,
                      ) || 0}
                      {t("원")}{" "}
                    </span>
                    / 수량 {orderDetail?.quantity}개
                  </span>
                </div>
              </div>
              <div className="border-b-solid border-b-[16px] md:border-b-[16px] border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0">
                <div className="flex justify-between items-baseline mb-[16px] md:mb-[15px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("멤버십 구분")}
                  </p>
                  <div className="font-pretendard700">{"프리미엄"}</div>
                </div>
              </div>
              <div className="pt-[32px] pb-[32px] md:pb-0 border-b-solid border-b-[16px] md:border-none border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0">
                <div className="flex mb-[32px]">
                  <p className="text-[18px] font-pretendard700 text-primary leading-none">
                    {t("구매자 정보")}
                  </p>
                </div>
                <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("주문자")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                    {orderDetail?.displayName}
                  </p>
                </div>
                <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("이메일주소")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                    {orderDetail?.email}
                  </p>
                </div>
                <div className="flex justify-between items-baseline">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("휴대전화번호")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                    {orderDetail?.phoneNumber}
                  </p>
                </div>
              </div>
            </Col>
            <Col flex={isMobile ? 1 : "530px"}>
              <div className="w-full lg:bg-gray3 lg:p-[40px] lg:pt-[32px] mb-[20px] bg-[#fff] px-0 pt-[40px] border-t-[16px] border-[#F5F5F5]">
                <div className="flex items-center justify-between pb-[12px] md:pb-[0px] lg:mb-[60px]">
                  <p className="text-[18px] font-pretendard700 text-primary leading-none">
                    {t("결제 정보")}
                  </p>
                </div>

                <div className="flex items-center mb-[24px] justify-between mt-[28px] md:mt-[40px]">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("Cost price")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {MoneyConvert(
                      Number(orderDetail?.voucherPrice) * orderDetail?.quantity,
                    ) || 0}
                    원
                  </p>
                </div>

                <div className="flex items-center pb-[24px] justify-between">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("Discount")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {`${formatPrice(
                      100 -
                        ((Number(orderDetail?.totalPayment) -
                          Number(orderDetail?.shippingFee || 0) -
                          Number(orderDetail?.costsForJeju || 0)) /
                          orderDetail?.quantity /
                          Number(orderDetail?.voucherPrice)) *
                          100,
                    )}${t("%")}`}
                    {/* {`${formatPrice(
                      100 -
                        (Number(orderDetail?.totalValue) /
                          orderDetail?.quantity /
                          Number(orderDetail?.voucherPrice)) *
                          100,
                    )}${t("%")}`} */}
                  </p>
                </div>

                <div className="flex items-center justify-between md:mt-[0] pb-[24px]">
                  <p className="text-[16px] font-pretendard700 text-primary leading-none">
                    {t("Total payment")}
                  </p>
                  <p className="text-[16px] md:text-[24px] font-pretendard700 text-[#C70039] leading-none">
                    {MoneyConvert(Number(orderDetail?.totalPayment)) || 0}
                    {t("원")}
                  </p>
                </div>

                <div className="flex items-center pb-[24px] justify-between">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("Payment Method")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {convertPayment(orderDetail?.paymentMethod)}
                  </p>
                </div>

                <div className="flex items-center pb-[24px] justify-between">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("Payment date and time")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {dayjs(orderDetail?.createdTime).format("YYYY.MM.DD HH:mm")}
                  </p>
                </div>

                <div className="flex font-pretendard500 gap-x-[30px] pt-[12px] w-full">
                  {orderDetail?.status === "REFUNDED" ? (
                    <Button
                      onClick={() => {
                        setIsOpenCancel(true)
                      }}
                      disabled={!orderDetail?.canCancel}
                      className="flex justify-center border border-solid border-primary text-primary text-[16px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
                    >
                      환불 완료{" "}
                      {moment(orderDetail?.updatedTime).format(
                        "YYYY년 MM월 DD월",
                      )}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        if (orderDetail?.status === "REQUESTED") {
                          handleRequestCancelRefund()
                        } else {
                          setIsOpenCancel(true)
                        }
                      }}
                      disabled={
                        !orderDetail?.canCancel &&
                        orderDetail?.status !== "REQUESTED"
                      }
                      className="flex justify-center border border-solid border-primary text-primary text-[16px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
                    >
                      {orderDetail?.status === "REQUESTED"
                        ? t("환불 요청 취소하기")
                        : t("환불 요청")}
                    </Button>
                  )}
                </div>
                <ul className="mt-[26px] list-disc pl-4">
                  <li className="text-[12px] font-pretendard400 text-gray1 leading-[170%]">
                    {t(
                      "주문 취소 및 환불 신청은 결제 후 7일 이내에만 가능합니다.",
                    )}
                  </li>
                  <li className="text-[12px] font-pretendard400 text-gray1 leading-[170%]">
                    {t(
                      "주문 취소 접수 후 신용카드 취소 승인까지 XX일 예상 됩니다.",
                    )}
                  </li>
                </ul>
              </div>
              {orderDetail?.canViewHistory && (
                <Button
                  theme="main"
                  height={48}
                  onClick={() =>
                    navigate(`/my-membership/${orderDetail?.brandId}`)
                  }
                >
                  {t("멤버십 이용 내역 보기")}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>

      <CancelOrder
        open={isOpenCancel}
        handleClose={() => setIsOpenCancel(false)}
        handleConfirm={handleCancelOrder}
        data={orderDetail}
      />
      {contextHolder}
    </div>
  )
}

export default OrderEcontentDetail
