import Button from "src/components/Button"
import CSInput from "src/components/Input/Index"
import CommonModal, { CommonModalProps } from "src/components/Modal"
import { Form } from "antd"
import { message } from "antd"
import { changePassword } from "src/services/Auth"
import { useAppDispatch } from "src/app/hooks"
import { logout } from "src/features/user/userSlice"
import { useTranslation } from "react-i18next"

type UpdatePasswordProps = {
  handleClose: () => void
  userName: string
} & CommonModalProps

const UpdatePassword: React.FC<UpdatePasswordProps> = ({
  handleClose,
  userName,
  ...props
}) => {
  const dispath = useAppDispatch()
  const { t } = useTranslation()

  const onFinish = async (values: any) => {
    try {
      const resp: any = await changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.confirmPassword,
      })
      if (resp?.result) {
        message.success("Update password success!", 5)
        // dispath(logout())
      } else {
        if (resp?.errorCode === "INVALID_PASS") {
          message.error(
            "Incorrect password. Please input correct password to reset new one",
            5,
          )
        }

        if (resp?.errorCode === "REUSE_OLD_PASS") {
          message.error(
            "New password cannot be the same as the old password. Please choose a different password.",
            5,
          )
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CommonModal handleClose={() => handleClose()} {...props}>
      <p className="font-pretendard500 text-[20px] mb-[28px]">비밀번호 변경</p>
      <div className="">
        <Form layout="vertical" requiredMark={false} onFinish={onFinish}>
          <CSInput
            name="oldPassword"
            placeholder="현재 비밀번호를 입력하세요."
            inputType="password"
            inputHeight={48}
            className="!mb-[12px]"
            rules={[
              {
                required: true,
              },
              {
                min: 8,
                message: "Passwords should be at least 8 characters long.",
              },
              {
                pattern:
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
                message:
                  "Passwords must include a mix of uppercase and lowercase letters, numbers, and special characters.",
              },
            ]}
          />
          <CSInput
            name="newPassword"
            placeholder="새 비밀번호를 입력하세요."
            inputType="password"
            inputHeight={48}
            className="!mb-[12px]"
            rules={[
              {
                required: true,
              },
              {
                min: 8,
                message: "Passwords should be at least 8 characters long.",
              },
              {
                pattern:
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
                message:
                  "Passwords must include a mix of uppercase and lowercase letters, numbers, and special characters.",
              },
            ]}
          />
          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword"]}
            className="!mb-[8px]"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      t(
                        "Password and Confirmation Password do not match. Please ensure both fields contain the same value",
                      ),
                    ),
                  )
                },
              }),
            ]}
          >
            <CSInput
              name="confirmPassword"
              placeholder="새 비밀번호를 한 번 더 확인하세요."
              rules={[{ required: true }]}
              inputType="password"
              inputHeight={48}
              className="!mb-[12px]"
            />
          </Form.Item>
          <div className="flex items-center gap-[10px] mb-[10px]">
            <button
              className="w-full border-[1px] border-primary h-[48px]"
              onClick={handleClose}
              type="button"
            >
              {t("번경 취소")}
            </button>
            <button className="bg-primary w-full h-[48px] font-pretendard700 text-primaryWhite">
              {t("확인")}
            </button>
          </div>
        </Form>
      </div>
    </CommonModal>
  )
}

export default UpdatePassword
