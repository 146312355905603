import React from "react"
import PreviousIcon from "@assets/icons/table/PaginationPrev.svg"
import NextIcon from "@assets/icons/table/PaginationNext.svg"
import {
  Pagination as AntdPagination,
  ConfigProvider,
  PaginationProps,
} from "antd"
import "./style.scss"

type Props = {}

function Pagination({ ...props }: Props & PaginationProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Pretendard-400",
          colorBorder: "transparent",
          // colorPrimary: "#000000",
          colorText: "#D4D4D4",
          fontSize: 16,
        },
      }}
    >
      <AntdPagination
        rootClassName="flex justify-center custom-pagination"
        prevIcon={
          <div className="flex items-center h-full justify-end">
            <PreviousIcon />
          </div>
        }
        nextIcon={
          <div className="flex items-center h-full justify-start">
            <NextIcon />
          </div>
        }
        {...props}
      />
    </ConfigProvider>
  )
}

export default Pagination
