import imgDeals from "@assets/images/image175.png"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getDataListShop } from "src/api/shopApi"
import { useAppSelector } from "src/app/hooks"
import Button from "src/components/Button"
import ShopCard from "src/components/Card/ShopCard"
import NoData from "src/components/NoData"
import Pagination from "src/components/Pagination"
import SelectCustom from "src/components/SelectCustom"
import { brandDataDetailSelect } from "src/features/brandData/brandDataSlice"
import { TypeVoucher } from "src/pages/GroupBuying/VoucherList"
import SearchIcon from "@assets/images/shop/search_icon.png"
import useIsMobile from "src/customHook/useIsMobile"

type TQUERY = {
  page: number
  size: number
  querySearch: string
  progress: string
  productType: string
  brandId: number
}

const handleDataTab = (data: string) => {
  switch (data) {
    case "in-progress":
      return "IN_PROGRESS"
    case "up-coming":
      return "INCOMING"
    case "finished":
      return "FINISHED"

    default:
      return ""
  }
}

function BrandShop() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [dataSource, setDataSource] = useState<any[]>([])
  const [dataTab, setDataTab] = useState<any>({})
  const [pageable, setPageable] = useState<any>({})
  const { isMobile } = useIsMobile(768)

  const dataBrandDetail = useAppSelector(brandDataDetailSelect)
  const [querySearchChange, setQuerySearchChange] = useState<string>("")
  const query: TQUERY = {
    page: Number(searchParams.get("page")) || 1,
    size: 8,
    querySearch: searchParams.get("querySearch") || "",
    brandId: dataBrandDetail?.id,
    progress: handleDataTab(searchParams.get("type") || ""),
    productType: searchParams.get("productType") || "",
  }

  const getAllData = async () => {
    try {
      const resp: any = await getDataListShop(query)
      if (resp?.result) {
        setDataSource(resp.data.datas)
        setDataTab(resp.data.dataAdd)
        setPageable(resp.data.pageable)
      } else {
        setPageable({})
        setDataTab({})
        setDataSource([])
      }
    } catch (error) {
      setPageable({})
      setDataTab({})
      setDataSource([])
    }
  }

  useEffect(() => {
    if (dataBrandDetail?.id) {
      getAllData()
    }
  }, [searchParams, dataBrandDetail?.id])

  const routes = [
    {
      label: `${t("ALL")} (${dataTab?.allCount || 0})`,
      path: "all",
    },
    {
      label: `${t("In progress")} (${dataTab?.inProgressCount || 0})`,
      path: "in-progress",
    },
    {
      label: `${t("Upcoming")} (${dataTab?.incomingCount || 0})`,
      path: "up-coming",
    },
    {
      label: `${t("Finished")} (${dataTab?.finishedCount || 0})`,
      path: "finished",
    },
  ]

  const handleShowNonData = () => {
    switch (searchParams.get("type")) {
      case "in-progress":
        return "현재 판매되고 있는 상품이 없습니다."
      case "up-coming":
        return "현재 대기중인 상품이 없습니다."
      case "finished":
        return "현재 판매완료된 상품이 없습니다."

      default:
        return
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex lg:justify-between items-start lg:flex-row flex-col gap-[18px] lg:gap-0">
        <div className="flex items-center gap-[16px] relative z-20 justify-center lg:justify-start mt-[24px] lg:mt-0">
          {routes.map((item, index) => {
            return (
              <Button
                key={index}
                className={`lg:text-[18px] text-[15px] text-primary capitalize ${
                  item.path === searchParams.get("type") ||
                  (index === 0 && !searchParams.get("type"))
                    ? "font-pretendard700"
                    : ""
                }`}
                onClick={() => {
                  searchParams.set("type", item.path)
                  setSearchParams(searchParams)
                }}
              >
                {item.label}
              </Button>
            )
          })}
        </div>

        <div className="flex lg:gap-[18.5px] gap-[15px] lg:items-center lg:flex-row flex-col lg:w-auto w-full">
          <div className="flex gap-[14px] items-center">
            <div className="text-[14px]">Filter</div>
            <SelectCustom
              placeholder={t("All")}
              value={query.productType}
              onChange={(e) => {
                searchParams.set("productType", e)
                setSearchParams(searchParams)
              }}
              options={[
                { value: "", label: t("All") },
                { value: "VOUCHER", label: t("VOUCHER") },
                { value: "E_CONTENT", label: t("E-CONTENT") },
                { value: "COUPON", label: t("COUPON") },
                { value: "PRODUCT", label: t("PRODUCT") },
              ]}
              height={40}
              width={isMobile ? null : 155}
            />
          </div>
          <div className="border border-[#D9D9D9] flex items-center h-[40px] px-[15px] w-full">
            <input
              type="text"
              className={`md:w-[152px] w-full pr-[10px] focus-visible:outline-none bg-transparent text-[#000000] text-[14px] flex-1 placeholder:text-[#666666]`}
              onChange={(e) => {
                setQuerySearchChange(e.target.value)
              }}
              placeholder={t("Search...")}
              value={querySearchChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchParams.set("querySearch", querySearchChange)
                  setSearchParams(searchParams)
                }
              }}
            />
            <div
              className="cursor-pointer max-w-[16px]"
              onClick={() => {
                searchParams.set("querySearch", querySearchChange)
                setSearchParams(searchParams)
              }}
            >
              <img src={SearchIcon} alt="search" />
            </div>
          </div>
        </div>
      </div>

      <div className="md:mt-[30px] mt-[27px]">
        {dataSource?.length > 0 ? (
          <>
            <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-x-[18px] md:gap-y-[20px] gap-y-[30px] lg:mt-0 mt-[20px]">
              {dataSource &&
                dataSource.map((item, index) => {
                  return <ShopCard key={index} data={item} />
                })}
            </div>
            <div className="mt-[80px]">
              {pageable?.totalElement > 8 && (
                <Pagination
                  total={pageable?.totalElement}
                  current={query.page}
                  pageSize={8}
                  showSizeChanger={false}
                  onChange={(page) => {
                    searchParams.set("page", page.toString())
                    setSearchParams(searchParams)
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div className="mt-[200px] text-center text-[18px] font-pretendard500">
            {handleShowNonData()}
          </div>
        )}
      </div>
    </div>
  )
}

export default BrandShop
