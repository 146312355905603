import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export type CsType =
  | "MEMBERSHIP_USAGE"
  | "ORDER_PAYMENT"
  | "CANCELATION_REFUND"
  | "OTHER"

export const customerApi = {
  getAnnouncementList(params: {
    page: string | number
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.ANNOUNCEMENT_LIST,
      method: "GET",
      params: { ...params, size: 10 },
    })
  },
  getFAQList(params: {
    page: string | number
    type: CsType
    querySearch: string
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.FAQ_LIST,
      method: "GET",
      params: { ...params, size: 10 },
    })
  },
  getFAQTypes(): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.FAQ_TYPES,
      method: "GET",
    })
  },
  getAnnouncementTypes(): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.ANNOUNCEMENT_TYPE,
      method: "GET",
    })
  },
  getInquiryList(params: {
    page: string | number
    type?: CsType
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.INQUIRY_LIST,
      method: "GET",
      tokenRequired: true,
      params: { ...params, size: 10 },
    })
  },
  getInquiryReplyList(params: {
    page: string | number
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.INQUIRY_REPLY_LIST,
      method: "GET",
      tokenRequired: true,
      params: { ...params, size: 10 },
    })
  },
  getInquiryUnreplyList(params: {
    page: string | number
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.INQUIRY_UNREPLY_LIST,
      method: "GET",
      tokenRequired: true,
      params: { ...params, size: 10 },
    })
  },
  createInquiry(data: {
    subject: string
    description: string
    type: CsType
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.INQUIRY_CREATE,
      method: "POST",
      data: data,
      tokenRequired: true,
    })
  },
  deleteInquiry(data: { id: string | number }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.INQUIRY_DELETE,
      method: "POST",
      data: data,
      tokenRequired: true,
    })
  },
  getTotalData(): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.CS_TOTAL,
      method: "GET",
    })
  },
  getTotalInquiry(): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.INQUIRY_TOTAL,
      method: "GET",
    })
  },
}
