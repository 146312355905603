import { Form, message } from "antd"
import jwt_decode from "jwt-decode"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import Button from "src/components/Button"
import CSInput from "src/components/Input/Index"
import CommonModal from "src/components/Modal"
import ResetPasswordSuccess from "src/components/Modal/auth/ResetPasswordSuccess"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import { resetPassword } from "src/services/Auth"

type Props = {}

function ResetPassword({}: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { isMobile } = useIsMobile(1023)
  const [searchParam] = useSearchParams()
  const [userToken, setUserToken] = useState<string>("")
  const [userName, setUserName] = useState<string>("")
  const [fieldForm, setFieldForm] = useState<any>({})
  const [isOpenSuccess, setIsOpenSuccess] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    const token = searchParam.get("access_token") || ""
    const name = searchParam.get("user_name") || ""
    setUserToken(token)
    setUserName(name)
    if (token) {
      const decoded: any = jwt_decode(token)
      // console.log("params", decoded)
    }
  }, [searchParam])

  const onFinish = async (data: any) => {
    // console.log(data)
    try {
      const resp: any = await resetPassword({
        password: data.confirmPass,
        accessToken: userToken,
      })
      if (resp?.result) {
        setIsOpenSuccess(true)
      } else {
        message.error(resp?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeValue = (changedValues: any, allValues: any) => {
    setFieldForm(allValues)
  }

  return (
    <>
      {isMobile && <NavBarMb pageName={t("RESET_PASSWORD")} />}

      <ResetPasswordSuccess
        isOpen={isOpenSuccess}
        handleClose={() => {
          setIsOpenSuccess(false)
          navigate("/auth")
        }}
      />
      <div className="flex-1 flex justify-center items-center">
        <div className="w-full max-w-[527px] m-auto pt-[66px] lg:pt-[120px] pb-[105px] lg:pb-[257px]">
          {/* <div>
            <img src={bgTop} alt="" />
          </div> */}
          <div className="px-[20px] sm:px-[50px]">
            <div>
              <p className="text-[24px] font-pretendard500 text-black leading-normal text-center mb-[8px]">
                {t("Reset password")}
              </p>
              <p className="text-gray1 text-[16px] font-pretendard400 leading-[100%] text-center mb-[48px]">
                {/* Set up new password for {userName && `[${userName}]`} */}
                {t(`Set up new password for [userName]`, { userName })}
              </p>
              <div className="mt-6">
                <Form
                  form={form}
                  layout="vertical"
                  requiredMark={false}
                  onFinish={onFinish}
                  onValuesChange={handleChangeValue}
                >
                  <CSInput
                    name="password"
                    placeholder={t("Password")}
                    // label="Password"
                    inputType="password"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        min: 8,
                        message:
                          "Passwords should be at least 8 characters long.",
                      },
                      {
                        pattern:
                          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/,
                        message:
                          "Passwords must include a mix of uppercase and lowercase letters, numbers, and special characters.",
                      },
                    ]}
                  />
                  <Form.Item
                    name="confirmPass"
                    dependencies={["password"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error(
                              t(
                                "Password and Confirmation Password do not match. Please ensure both fields contain the same value",
                              ),
                            ),
                          )
                        },
                      }),
                    ]}
                  >
                    <CSInput
                      placeholder={t("Confirm password")}
                      className="has-show-password"
                      name="confirmPass"
                      inputType="password"
                      // label={t("CONFIRM_PASSWORD")}
                      rules={[
                        {
                          required: true,
                          message: "Please enter confirm password",
                        },
                      ]}
                    />
                  </Form.Item>
                  <div className="mt-[16px]">
                    <Button
                      type="submit"
                      disabled={!fieldForm?.password || !fieldForm?.confirmPass}
                      theme="main"
                    >
                      {t("Reset password")}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
