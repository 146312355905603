import { createSlice } from "@reduxjs/toolkit"
import { AppThunk, RootState } from "src/app/store"
import { getMyInfo } from "src/services/User"

export interface UserDataState {
  data: any | null
}

const initialState: UserDataState = {
  data: null,
}

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setUserData } = userDataSlice.actions

export const getUserInfo = (): AppThunk => async (dispatch) => {
  try {
    const resp: any = await getMyInfo()
    console.log("resp", resp)

    if (resp?.result) {
      dispatch(setUserData(resp?.data))
    } else {
      dispatch(setUserData(null))
    }
  } catch (error) {
    console.log("error", error)
    dispatch(setUserData(null))
  }
}

export const userDataSelect = (state: RootState) => state.userData.data

export default userDataSlice.reducer
