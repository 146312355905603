import { UrlInternal } from "src/constant/endpointRoute"

export const menuList = [
  {
    title: "프리미엄 멤버십",
    link: "/group-buying",
  },
  {
    title: "브랜드",
    link: "/brand",
  },
  {
    title: "보유 멤버십",
    link: "/my-membership",
  },
  {
    title: "MY 페이지",
    link: "/my-page",
  },
  {
    title: "MY voucher",
    link: UrlInternal.MY_VOUCHERS,
  },
]

export const menuListPc = [
  {
    title: "프리미엄 멤버십",
    link: "/group-buying",
  },
  {
    title: "브랜드",
    link: "/brand",
  },
  {
    title: "보유 멤버십",
    link: "/my-membership",
  },
  {
    title: "MY voucher",
    link: UrlInternal.MY_VOUCHERS,
  },
]
