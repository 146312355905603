export function copyLink(customLink?: string): void {
  const linkToCopy: string = customLink || window.location.href

  const input: HTMLInputElement = document.createElement("input")
  input.value = linkToCopy
  document.body.appendChild(input)

  input.select()

  document.execCommand("copy")

  document.body.removeChild(input)
}
