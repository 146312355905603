import ChatText from "@assets/images/brand_detail/ChatText.png"
import more_vert_opacity from "@assets/images/brand_detail/more_vert_opacity.png"
import shapeBt from "@assets/images/brand_detail/shadow-bt.png"
import shapeRight from "@assets/images/brand_detail/shape-right.png"
import new_release from "@assets/images/brand_detail/new_releases.png"
import heart_check from "@assets/images/brand_detail/heart_check.png"

import { useRef, useState } from "react"
import LikeFeed from "src/components/LikeFeed"
import useWindowSize from "src/customHook/useWindowSize"

import { BottomSheet } from "react-spring-bottom-sheet"
import { json, useNavigate, useParams } from "react-router-dom"
import { UrlInternal } from "src/constant/endpointRoute"
import GalleryImage from "./GalleryImage"
import { handleDateCommunity, handleDateNewPost } from "src/util/handleDate"
import { formatPrice } from "src/util/formatPrice"
import ShowMoreText from "react-show-more-text"
import { useTranslation } from "react-i18next"
import { useAuth } from "src/customHook/useAuth"
import { useOutsideAlerter } from "src/util/hook/useOutSide"
import moment from "moment"

interface Props {
  setIsEditPost: (data: any) => void
  onSubmitDeletePost: (id: number) => void
  setIsModalComment: (data: any) => void
  data: any
}

function PostMe({
  setIsEditPost,
  onSubmitDeletePost,
  setIsModalComment,
  data,
}: Props) {
  const [isModal, setIsModal] = useState(false)
  const [isModalReportMobile, setIsModalReportMobile] = useState<boolean>(false)
  const { width }: any = useWindowSize()
  const isMobile = width <= 475
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { brandName } = useParams()
  const { isLogined } = useAuth()
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => setIsModal(false))
  const { i18n } = useTranslation()

  return (
    <>
      {/**report me  mobile*/}
      {isModalReportMobile && (
        <div
          className="bg-bottomSheetOverlay fixed w-[100vw] h-[100vh] left-0 top-0 z-10"
          onClick={() => setIsModalReportMobile(false)}
        ></div>
      )}
      <BottomSheet
        open={isModalReportMobile}
        onDismiss={() => setIsModalReportMobile(false)}
        snapPoints={({ minHeight }) => minHeight}
        className="bottom-sheet"
        blocking={false}
      >
        <div
          className="pt-[15px] pb-[13px] px-[24px] text-[14px]"
          onClick={() =>
            navigate(
              `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_POST}`,
              {
                state: data,
              },
            )
          }
        >
          수정하기
        </div>
        <div
          className="pt-[15px] pb-[29px] px-[24px] text-[14px]"
          onClick={() => {
            onSubmitDeletePost?.(data?.postId)
            setIsModalReportMobile(false)
          }}
        >
          삭제하기
        </div>
      </BottomSheet>

      <div className="sm:max-w-[1009px] max-w-[calc(100%_-_15px)] w-full bg-[#fff] shadow-custom2 ml-auto text-right sm:mr-[12px] mr-[15px] sm:p-[24px] px-[20px] py-[16px] relative">
        <img
          src={shapeRight}
          alt="shape"
          className="absolute right-[-12px] top-[16px]"
        />
        <div className="font-pretendard700 flex items-center justify-end">
          {data?.creatorName}{" "}
          {data?.creatorBrandId && (
            <img
              src={new_release}
              alt="logo"
              className="inline-block pl-[8px]"
            />
          )}
          {isLogined && data?.creatorIsMembershipOfTargetBrand && (
            <div className="bg-[#FF6E6E] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600 ml-[8px]">
              멤버십
            </div>
          )}
        </div>
        <div className="text-[#757575] text-[12px] pt-[10px]">
          {/* {handleDateCommunity(data?.updatedAt ?? data?.createdAt)} */}
          {/* {moment().diff(moment(data?.updatedAt ?? data?.createdAt), "second") <
          60
            ? i18n.language === "kor"
              ? "방금전"
              : "just now"
            : handleDateCommunity(data?.updatedAt ?? data?.createdAt)} */}
          {handleDateNewPost(data?.updatedAt ?? data?.createdAt, i18n.language)}
        </div>
        {data?.media && JSON.parse(data?.media).length > 0 && (
          <div className="mb-[15px] mt-[15px]">
            <GalleryImage
              className="gallery-postme"
              data={data?.media ? JSON.parse(data?.media) : []}
            />
          </div>
        )}
        <div className="pb-[15px] pt-[5px] border-0 border-b border-solid border-b-[#D6D6D6]">
          {data?.onlyMemberShip && (
            <div className="bg-[#FF6E6E] mr-[5px] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600">
              멤버십
            </div>
          )}
          {data?.content && (
            <ShowMoreText
              lines={data?.media && JSON.parse(data?.media).length > 0 ? 5 : 10}
              more={<span style={{ color: "#757575" }}>{t("더 보기")}</span>}
              className="text-[16px] break-words pointer-events-none"
              onClick={() => {}}
              expanded={false}
              truncatedEndingComponent={" ..."}
              keepNewLines
            >
              {data?.content}
            </ShowMoreText>
          )}
        </div>
        <div className="pt-[15px] flex gap-[20px] justify-end">
          <LikeFeed
            classLabel="sm:text-[16px] text-[14px] !font-pretendard400"
            classIcon="sm:max-h-[16px] max-h-[13.75px]"
            count={data?.cntLike}
            active={data?.doLike}
            id={data?.postId}
            isCommunity
          />
          <div
            className="flex sm:items-center gap-[5px] cursor-pointer"
            onClick={() => setIsModalComment(data)}
          >
            <img
              src={ChatText}
              alt="ChatText"
              className="sm:max-h-[26px] max-h-[21px]"
            />
            <div className="sm:text-[16px] text-[14px]">
              {formatPrice(Number(data?.cntComment))}
            </div>
          </div>
          <div className="cursor-pointer relative">
            <img
              src={more_vert_opacity}
              alt="icon"
              onClick={() =>
                isMobile
                  ? setIsModalReportMobile(true)
                  : setIsModal((prev) => !prev)
              }
            />

            {isModal && (
              <div
                className="absolute top-[170%] right-[-7px] shadow-custom3 bg-[#fff] w-[135px] p-[20px] text-left z-10"
                ref={wrapperRef}
              >
                <img
                  src={shapeBt}
                  alt="icon"
                  className="absolute right-[7px] top-[-16px]"
                />
                <div className="absolute right-[12px] top-[-1px] bg-[#fff] w-[12px] h-[12px]"></div>
                <div
                  className="hover:font-pretendard600 hover:underline"
                  onClick={() => {
                    setIsEditPost(data)
                    setIsModal(false)
                  }}
                >
                  수정하기
                </div>
                <div
                  className="mt-[28px] hover:font-pretendard600 hover:underline"
                  onClick={() => {
                    onSubmitDeletePost(data?.postId)
                    setIsModal(false)
                  }}
                >
                  삭제하기
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PostMe
