import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import locationBannerDefault from "@assets/images/brand_detail/location_banner_default.png"
import { useEffect, useState } from "react"
import { TDATE, sortObjectsByConditions } from "src/util/dayInWeekFormat"

export type TLOCATION_CARD = {
  id: number
  branchName: string
  address: string
  phoneNumber: string
  openAt: string
  closeAt: string
  openingDay: TDATE[]
  branchImage: string
  locationLink: string
  data: any
}

const LocationCard: React.FC<Omit<TLOCATION_CARD, "id">> = ({
  branchName,
  address,
  phoneNumber,
  openAt,
  closeAt,
  openingDay,
  branchImage,
  locationLink,
  data,
}) => {
  const { t } = useTranslation()
  const [imgUrl, setImgUrl] = useState<string>()

  const renderDayInWeek = (arr: TDATE[]) => {
    // const dateList = sortObjectsByConditions(arr)
    // console.log("dateList", dateList)
    return arr.map((item: TDATE, index: number) => (
      <span key={index}>
        {item?.name === "Mon" && t("Mon")}
        {item?.name === "Tue" && t("Tue")}
        {item?.name === "Wed" && t("Wed")}
        {item?.name === "Thu" && t("Thu")}
        {item?.name === "Fri" && t("Fri")}
        {item?.name === "Sat" && t("Sat")}
        {item?.name === "Sun" && t("Sun")}
        {item?.name === "All day" && t("All day")}
        {index === arr.length - 1 ? "" : "-"}
      </span>
    ))
  }

  useEffect(() => {
    if (branchImage) {
      setImgUrl(branchImage)
    } else {
      setImgUrl(locationBannerDefault)
    }
  }, [branchImage])

  return (
    <div className="mb-[23px]">
      <div className="mb-[20px]">
        <img
          className="object-cover w-full h-imgRatio_16_9"
          onError={() => setImgUrl(locationBannerDefault)}
          src={imgUrl}
          alt=""
        />
      </div>
      <div className="px-[24px] md:px-0">
        <p className="font-pretendard600 text-[16px] mb-[13px]">{branchName}</p>
        {data?.comment && (
          <div className="text-[14px] text-[#757575]">{data?.comment}</div>
        )}
        <div
          className={`py-[24px]`}
          // className={address && data?.homepageUrl && phoneNumber && `py-[24px]`}
        >
          <p className="text-[14px] leading-[23.8px]">{address}</p>
          <p className="text-[14px] leading-[23.8px]">{data?.homepageUrl}</p>
          <p className="text-[14px] leading-[23.8px]">
            {phoneNumber?.replaceAll("-", "")}
          </p>
        </div>
        <p className="text-[14px] leading-[23.8px]">
          {openingDay?.length > 0 && (
            <>
              <span>영업 시간</span> {renderDayInWeek(openingDay)}
            </>
          )}{" "}
          {openAt && dayjs(openAt).format("h:mma")}
          {closeAt && `- ${dayjs(closeAt).format("h:mma")}`}{" "}
          {data?.startBreakTime && data?.endBreakTime && (
            <span className="text-[14px] text-[#757575]">
              (브레이크: {dayjs(data?.startBreakTime).format("h:mma")}-
              {dayjs(data?.endBreakTime).format("h:mma")})
            </span>
          )}
        </p>
        {data?.haveParking && (
          <p className="text-[14px] leading-[23.8px]">
            주차 가능 {data?.placeCount} 대
          </p>
        )}
        {locationLink && (
          <a
            href={locationLink}
            target="_blank"
            className="underline mt-[16px] text-[14px] inline-block"
            rel="noreferrer"
          >
            지도보기
          </a>
        )}
      </div>
    </div>
  )
}

export default LocationCard
