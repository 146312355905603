import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"
import { AxiosRequestConfig } from "axios"

export const homeAPi = {
  getDataHomePage() {
    return api.request({
      url: ApiUrl.HOMEPAGE_DATA,
      method: "GET",
      tokenRequired: true,
    })
  },
  checkGb(
    id: number | string,
    pageType: "HOME" | "GB_DETAIL",
    quantity: number,
  ): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.CHECK_GB,
      method: "GET",
      params: { id, pageType, quantity },
      tokenRequired: true,
    })
  },
}

export const getProduct = async (params: {
  pageSize: string | number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.GET_PRODUCT,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const getCategoryBrand = async (): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.GET_CATEGORY_BRAND,
    method: "GET",
    tokenRequired: true,
  })
}

export const getBrand = async (payload: {
  filter: string
  page: number
  size: number
  querySearch?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.GET_BRAND,
    method: "POST",
    tokenRequired: true,
    data: payload,
  })
}

export const getMyMembership = async (): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.GET_MY_MEMBERSHIP,
    method: "GET",
    tokenRequired: true,
  })
}

export const getMyFollower = async (): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.GET_MY_FOLLOWER,
    method: "GET",
    tokenRequired: true,
  })
}
