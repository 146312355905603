import { useTranslation } from "react-i18next"
import Button from "src/components/Button"
import CommonModal from "src/components/Modal"

interface Props {
  isOpen?: boolean
  handleClose?: () => void
}

function MaxQtyModal({ isOpen, handleClose }: Props) {
  const { t } = useTranslation()

  return (
    <div>
      <CommonModal isOpen={isOpen} handleClose={handleClose}>
        <p className="font-pretendard600 text-[20px] text-primary leading-[20px]">
          {t("Oops! Quantity changed")}
        </p>
        <div className="mt-[10px]">
          <p className="text-gray1 text-[14px] leading-[20px] font-pretendard400 capitalize">
            {t("Oops! Quantity changed sub")}
          </p>
          <div className="mt-[30px]">
            <Button onClick={handleClose} theme="main">
              {t("Review order")}
            </Button>
          </div>
        </div>
      </CommonModal>
    </div>
  )
}

export default MaxQtyModal
