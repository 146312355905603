// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/grid"
import "swiper/css/pagination"

import "./index.scss"
// import required modules
import { Grid, Pagination } from "swiper/modules"
import ContentCard from "./ContentCard"
import ShopCard from "src/components/Card/ShopCard"

interface Props {
  data: any
}

function CardMobile({ data }: Props) {
  return (
    <Swiper
      slidesPerGroup={2}
      slidesPerView={2}
      spaceBetween={7}
      pagination={{
        clickable: true,
      }}
      modules={[Grid, Pagination]}
      className="mySwiper"
      grid={{
        rows: 2,
        fill: "row",
      }}
    >
      {data
        .map((dt: any, index: number) => (
          <SwiperSlide key={index}>
            <ShopCard data={dt} className="h-full" isHome />
            {/* <ContentCard dt={dt} /> */}
          </SwiperSlide>
        ))
        .concat(
          Array(data.length % 4 === 0 ? 0 : 4 - (data.length % 4)).fill(
            <SwiperSlide></SwiperSlide>,
          ),
        )}
    </Swiper>
  )
}

export default CardMobile
