export const MoneyConvert = (value: any, toFixed?: boolean) => {
  if (value === undefined || value === null || value === "") return 0
  if (toFixed) {
    return Number(value)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  if (Number.isInteger(Number(value)))
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export const LikeCountConvert = (value: any, toFixed?: boolean) => {
  if (value === undefined || value === null || value === "") return 0
  if (toFixed) {
    return Number(value)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
  }
  if (Number.isInteger(Number(value)))
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
}
