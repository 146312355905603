import dayjs from "dayjs"
import { useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { groupBuyAPi } from "src/api/groupBuyApi"
import { useAuth } from "src/customHook/useAuth"
import { useCountTime } from "src/customHook/useCountTime"
import { TypeVoucher } from "src/pages/GroupBuying/VoucherList"
import { secondToHours } from "src/util"
import { MoneyConvert } from "src/util/format"

export type DealProps = {
  id: number | string
  logo?: string
  title: string
  desc: string
  price: string | number
  available: number | string
  voucherImage: string
  logoImage: string
  tag?: string
  type: TypeVoucher
  startTime: string
  endTime: string
  totalValue?: string
  sold: number
  isSoldOut?: boolean
  isRemind?: boolean
  remindSuccess?: (id: any) => void
  onRefresh?: () => void
  discountPercentage?: number | string
  progress?: "IN_PROGRESS" | "FINISHED" | "INCOMING"
  quantity?: number
  totalSold?: number
}

function GroupBuyingCard({
  id,
  title,
  desc,
  price,
  available,
  voucherImage,
  logoImage,
  startTime,
  endTime,
  tag,
  type,
  totalValue,
  sold,
  isSoldOut,
  isRemind = false,
  onRefresh = () => {},
  remindSuccess = () => {},
  discountPercentage,
  progress,
}: DealProps) {
  const { t } = useTranslation()
  const divRef = useRef<any>(null)
  const { countTime, handleSetCountTime } = useCountTime(-1)
  const { isLogined } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const handleRemindVoucher = async () => {
    try {
      const res: any = await groupBuyAPi.remindVoucher(id)
      if (res?.result) {
        remindSuccess(id)
      } else {
        throw res
      }
    } catch (error) {}
  }
  useEffect(() => {
    if (type === "in-progress") {
      const seconds = dayjs(endTime).diff(dayjs(new Date()), "seconds")
      handleSetCountTime(seconds)
    } else if (type === "up-coming") {
      const seconds = dayjs(startTime).diff(dayjs(new Date()), "seconds")
      handleSetCountTime(seconds)
    }
  }, [endTime, handleSetCountTime, startTime, type])

  useEffect(() => {
    if (countTime == 0) {
      // window.location.reload()
      onRefresh()
    }
    // console.log("countTime", countTime)
  }, [countTime])

  const timeTag = useMemo(() => {
    return secondToHours(countTime, " :  ")
  }, [countTime])

  const isFinished: boolean = useMemo(() => {
    return dayjs(endTime).isBefore(dayjs()) || type === "finished"
  }, [endTime, type])
  // console.log('location', location);

  return (
    <div
      ref={divRef}
      className="flex-1 flex flex-col xs:border-[1px] border-gray12"
    >
      <div className="relative overflow-hidden">
        <img
          src={voucherImage}
          alt=""
          className="object-cover w-full h-full aspect-square cursor-pointer"
          onClick={() => navigate(`/group-buying/detail/${id}`)}
        />
        {(isFinished || isSoldOut) && (
          <div className="flex absolute bottom-[0px] bg-lightColor text-lightColor">
            <div className="px-[15px] py-[1px] bg-primaryRed">
              <span className="font-pretendard400 text-primaryWhite text-[12px] leading-none">
                {t("SOLD OUT")}
              </span>
            </div>
          </div>
        )}
        {progress === "INCOMING" && (
          <div className="flex absolute bottom-[0px] bg-lightColor text-lightColor">
            <div className="px-[15px] py-[1px] bg-primaryGreen">
              <span className="font-pretendard400 text-primaryWhite text-[12px] leading-none">
                {t("UPCOMING")}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="relative flex flex-col flex-1 border border-solid	border-t-0 border-primaryWhite xs:px-[12px] xs:py-[14px] p-[9px]">
        <div className="flex gap-x-[14px] items-center">
          <div className="flex items-center gap-[15px]">
            <p
              className="font-pretendard700 xs:text-[16px] text-[12px] leading-[100%] text-primary xs:mb-[8px] mb-[13px] line-clamp-2"
              onClick={() => navigate(`/group-buying/detail/${id}`)}
            >
              {title}
            </p>
          </div>
        </div>
        {desc && (
          <p className="font-pretendard400 xs:text-[16px] text-[14px] text-primary line-clamp-2 xs:mb-4 mb-2">
            {desc}
          </p>
        )}

        <div>
          <div className="flex items-center">
            <p className="font-pretendard700 xs:text-[16px] text-[14px] text-primary">
              {discountPercentage ? discountPercentage + "%" : ""}{" "}
              {MoneyConvert(price) || 0}원
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GroupBuyingCard
