import bnTopMd from "src/assets/imgs/bn-top-md.png"
import bnTopXs from "src/assets/imgs/bn-top-xs.png"
import bnTop from "src/assets/imgs/bn-top.png"
import smallLogo from "src/assets/imgs/moafriends_logo_small.png"
import useWindowSize from "src/util/hook/useWindowSize"
import Header from "../Header"

// import '../style.scss'

interface Block1Props {}
const Block1Kr: React.FC<Block1Props> = () => {
  const { width }: any = useWindowSize()

  const zoomStyle =
    width <= 540
      ? undefined
      : width <= 767
      ? "0.4"
      : width <= 992
      ? "0.5"
      : width <= 1024
      ? "0.6"
      : width < 1536
      ? "0.7"
      : undefined

  return (
    <div>
      {width <= 540 && <Header />}
      <div
        className={`lpSm:h-[1027px] lpXs:h-[399px] h-[420px] max-w-[1920px] m-auto bg-cover bg-center`}
        style={{
          backgroundImage: `url(${
            width <= 375 ? bnTopXs : width <= 540 ? bnTopMd : bnTop
          })`,
          zoom: zoomStyle,
        }}
      >
        {width > 540 && <Header />}
        <div className="lpSm:pt-[61px] pt-[42px] text-center">
          <div className="flex justify-center items-center lpSm:gap-[15px] gap-[7.39px] lpSm:mb-[38px] mb-[24px]">
            <img
              src={smallLogo}
              alt="logo"
              className="lpSm:max-w-none max-w-[108.696px]"
            />
            <p className="lpSm:text-[24px] text-[16px] font-spoqa500 text-[#333333]">
              모아프렌즈
            </p>
          </div>
          <h1
            data-aos="fade-up"
            className="main-text-top lpSm:mb-[29px] mb-[13px] lpSm:text-[58px] text-[32px]"
          >
            나의 브랜드를 위한 <br />
            진짜 프렌즈를 모아보세요
          </h1>
          <a
            href="https://dev-brand.moafriends.com/auth#register"
            target="_blank"
            data-aos="fade-up"
            data-aos-duration="1000"
            className="lpSm:h-[60px] inline-block h-[45px] px-[15px] lpSm:leading-[60px] leading-[45px] rounded-[50px] bg-[#15A0A1] lpSm:text-[20px] text-[16px] border-0 lpSm:min-w-[200px] min-w-[160px] text-[#fff] font-spoqa500"
          >
            가입신청
          </a>
        </div>
      </div>
    </div>
  )
}

export default Block1Kr
