import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store"; //

export interface SearchState {
  isOpen: boolean
}

const initialState: SearchState = {
  isOpen: false,
}

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    onOpen: (state) => {
      state.isOpen = true;
    },
    onClose: (state) => {
      state.isOpen = false;
    },
  },
})

export const { onOpen, onClose } = searchSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const isOpenSearchSelect = (state: RootState) => state.search.isOpen


export default searchSlice.reducer
