import Button from "src/components/Button"
import CSInput from "src/components/Input/Index"
import CommonModal, { CommonModalProps } from "src/components/Modal"
import { Form } from "antd"
import { message } from "antd"
import { forgotPassword } from "src/services/Auth"
import { useMemo, useState } from "react"
import { useCountTime } from "src/customHook/useCountTime"
import { secondToMinute } from "src/util"
import { Trans, useTranslation } from "react-i18next"
import ForgotPasswordSuccess from "./ResetPasswordSuccess"
const TIME_TO_SENDEMAIL = 60
const EMAIL_SEND_KEY = "email_send"

type ForgotPasswordProps = {
  handleClose: () => void
} & CommonModalProps

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  handleClose,
  ...props
}) => {
  const { t } = useTranslation()
  const { countTime, handleSetCountTime } = useCountTime(0, EMAIL_SEND_KEY)
  const [form] = Form.useForm()
  const [email, setEmail] = useState("")
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const isReadySend = useMemo(() => {
    return countTime <= 0
  }, [countTime > 0])

  const handleChangeValue = (changedValues: any) => {
    setEmail(changedValues.email)
  }

  const onFinish = async (values: any) => {
    try {
      const resp: any = await forgotPassword({
        email: values.email,
      })
      // console.log(resp)
      if (resp?.result) {
        // setOpenSuccess(true)
        message.success("이메일로 비밀번호 재설정 링크를 발송해 드렸습니다.")
      } else {
        message.error(resp?.error?.message)
      }
    } catch (error) {
      console.log(error)
    }
    if (isReadySend) {
      handleClose()
      form.resetFields()
      handleSetCountTime(TIME_TO_SENDEMAIL)
    }
  }

  const onClose = () => {
    handleClose()
    form.resetFields()
  }

  return (
    <>
      <CommonModal handleClose={onClose} {...props}>
        <div className="">
          <p className="text-[20px] text-primary font-pretendard500 leading-normal mb-[8px]">
            {t("Forgot password?")}
          </p>
          <p className="text-gray1 font-pretendard400 text-[14px] leading-[20px]">
            {t(
              "Please input your email below. We will send recover link to your email",
            )}
          </p>
          <div className="mt-[30px] relative">
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              onValuesChange={handleChangeValue}
              requiredMark={false}
            >
              <CSInput
                name="email"
                // label={t("YOUR_EMAIL")}
                inputMode="email"
                placeholder={t("Your email")}
                rules={[
                  {
                    type: "email",
                    message:
                      "Please enter a valid email address. Ex: abc@xyz.kr",
                  },
                  { required: true },
                ]}
              />
              <Button
                theme="main"
                type="submit"
                disabled={!isReadySend || !email}
              >
                {t("Send recover link")}
              </Button>
              {!isReadySend && (
                <div className="w-full flex justify-center">
                  <p className="text-primary font-pretendard500 text-[13px] mt-[7px]">
                    <Trans i18nKey="Didn’t receive? Resend after s">
                      {t("Didn’t receive? Resend after")}
                      <span className="text-errorColor text-[#EA980C] font-pretendard700 ml-1">
                        {secondToMinute(countTime)}
                      </span>
                    </Trans>
                  </p>
                </div>
              )}
            </Form>
          </div>
        </div>
      </CommonModal>
      <ForgotPasswordSuccess />
    </>
  )
}

export default ForgotPassword
