import { useTranslation } from "react-i18next"

interface Props {
  status: string
}

function ConvertStatusPaymentHtml({ status }: Props) {
  const { t } = useTranslation()
  const convertStatusPayment = (status: string) => {
    switch (status) {
      case "WAITING_FOR_CONFIRMATION":
        return (
          <div className="px-[10px] py-[3px] bg-[#FFF8F6] text-[#FC8644]">
            {t("Wait for confirm")}
          </div>
        )
      case "WAITING_FOR_DELIVERY":
        return (
          <div className="px-[10px] py-[3px] bg-[#FAFFFA] text-[#09C17F]">
            {t("Wait for delivery")}
          </div>
        )
      case "DELIVERING":
        return (
          <div className="px-[10px] py-[3px] bg-[background: #ECF8FF] text-[#0098EE]">
            {t("Delivering")}
          </div>
        )
      case "DELIVERED":
        return (
          <div className="px-[10px] py-[3px]  text-[#000]">
            {t("Delivered")}
          </div>
        )
      case "REFUND_REQUEST":
        return (
          <div className="px-[10px] py-[3px] bg-[#FFF6FE] text-[#FF31A0]">
            {t("Refund request")}
          </div>
        )
      case "REFUND_APPROVED":
        return (
          <div className="px-[10px] py-[3px] bg-[#FFF6FE] text-[#FF31A0]">
            {t("Refund approved")}
          </div>
        )
      case "REFUND_REJECTED":
        return (
          <div className="px-[10px] py-[3px] bg-[#FFF6FE] text-[#FF31A0]">
            {t("Refund Rejected")}
          </div>
        )
      case "CANCEL_ORDER":
      case "CANCEL_ORDER_AFTER_CONFIRM":
      case "CANCEL_ORDER_AFTER_DELIVERING":
        return (
          <div className="px-[10px] py-[3px] bg-[#FFF3EF] text-[#EE3900]">
            {t("Cancel order")}
          </div>
        )
      case "SUCCESS":
        return (
          <div className="px-[10px] py-[3px] bg-[#E4FFFF] text-[#15A0A1]">
            {t("Success")}
          </div>
        )
      default:
        return
    }
  }

  return convertStatusPayment(status)
}

export default ConvertStatusPaymentHtml
