import logoBrandDefault from "src/assets/images/brand_detail/logo-brand-default.png"
import dayjs from "dayjs"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import Button from "src/components/Button"

import CommonModal from "src/components/Modal"
import { completeTransaction } from "src/features/confirmTransaction/confirmTransactionSlice"
import { MoneyConvert } from "src/util/format"
import { useTranslation } from "react-i18next"
import { UrlInternal } from "src/constant/endpointRoute"

function SuccessTransaction() {
  const { status, data }: any = useAppSelector((state) => state.confirmModal)
  const { detailData } = useAppSelector((state) => state.brandData)
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleClose = () => {
    searchParams.set("typeQr", "CLOSE_QR")
    setSearchParams(searchParams)

    dispatch(completeTransaction())
  }

  console.log("15315", data)

  return (
    <CommonModal
      isOpen={status === "SUCCESS_VOUCHER_COUPON"}
      // isOpen={true}
      handleClose={handleClose}
      imageTop={
        <img
          className="object-cover"
          src={detailData?.logoImage || logoBrandDefault}
          alt="brand-logo"
        />
      }
    >
      <p className="font-pretendard700 text-[19px] leading-normal text-primary">
        {t("Successful transaction")}
      </p>
      <div className="md:mt-[28px] mt-[16px]">
        <div className="flex justify-between md:items-center md:flex-row flex-col gap-[4px]">
          <p className="text-[18px] font-pretendard700 leading-normal">
            {data?.voucherCode}
          </p>
          <p className="text-[18px] font-pretendard700 text-[#C70039] leading-normal">
            {MoneyConvert(data?.amount)} {t("Won")}
          </p>
        </div>
        <div className="flex justify-between mt-2">
          <p className="text-[14px] leading-[24px] text-start text-black font-pretendard400">
            {t("Payment time")}
          </p>
          <p className="text-end text-[14px] leading-[24px] font-pretendard700">
            {dayjs(data?.time).format("HH:mm - YYYY/MM/DD")}
          </p>
        </div>
        <div className="flex justify-between mt-2">
          <p className="text-[14px] leading-[24px] text-start text-black font-pretendard400">
            {t("Order ID")}
          </p>
          <p className="text-end text-[14px] leading-[24px] font-pretendard700">
            {data?.trxUID}
          </p>
        </div>

        <div className="bg-[#F5F5F5] p-[15px] flex flex-col gap-[7px] mt-[15px] font-pretendard400">
          <div className="flex justify-between">
            <div className="text-[#757575] text-[12px]">{t("User")}</div>
            <div className="text-[#757575] text-[12px] font-pretendard700">
              {data?.customerInfo?.name}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-[#757575] text-[12px]">{t("Brand")}</div>
            <div className="text-[#757575] text-[12px] font-pretendard700 ">
              {data?.branchInformation?.brandName}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-[#757575] text-[12px]">{t("Branch")}</div>
            <div className="text-[#757575] text-[12px] font-pretendard700 max-w-[190px] ml-auto text-right">
              {data?.branchInfo?.isOnline || !data?.branchInfo?.address
                ? "온라인"
                : data?.branchInfo?.address}
            </div>
          </div>
          {/* {data?.voucherType === "VOUCHER_NEW" && (
            <div className="flex justify-between">
              <div className="text-[#757575] text-[12px]">{t("Note")}</div>
              <div className="text-[#757575] text-[12px] font-pretendard700 max-w-[190px] ml-auto text-right">
                {data?.branchNote}
              </div>
            </div>
          )} */}
        </div>

        <div className="mt-[12px]">
          <Button
            theme="main"
            labelSize={14}
            onClick={() => {
              handleClose()
              navigate(
                `${UrlInternal.VOUCHER_USAGE_HISTORY}/${data?.brandId}?transactionType=${data?.voucherType}`,
              )
            }}
          >
            {t("View history")}
          </Button>
          <div className="mt-2 flex justify-between gap-2">
            <Button
              theme="main"
              labelSize={14}
              variant="outline"
              onClick={() => {
                handleClose()
                navigate("/")
              }}
            >
              {t("To Moa Friends Main")}
            </Button>
          </div>
        </div>
      </div>
    </CommonModal>
  )
}

export default SuccessTransaction
