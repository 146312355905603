import RightArrow from "@assets/icons/RightArrow14.svg"
import { ColumnsType } from "antd/es/table"
import logoBrandDefault from "src/assets/images/brand_detail/logo-brand-default.png"
import dayjs from "dayjs"
import { useNavigate, useSearchParams } from "react-router-dom"
import Button from "src/components/Button"
import CsTable from "src/components/Table"
import useIsMobile from "src/customHook/useIsMobile"
import { Order } from "src/models/order"
import { MoneyConvert } from "src/util/format"
import { useTranslation } from "react-i18next"

interface Props {
  data: Order[]
  onSortChange?: (sort: Record<string, string>) => void
}

const OrderHistoryTable: React.FC<Props> = ({
  data,
  onSortChange = () => {},
}) => {
  const { t } = useTranslation()

  const { isMobile } = useIsMobile(1023)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  // const setTextSearch = (value: string) => {
  //   console.log(value)
  // }
  const columns: ColumnsType<any> = [
    {
      title: t("주문일"),
      dataIndex: "createdTime",
      key: "createdTime",
      render: (_, record) => {
        return (
          <p className="text-[14px] text-primary font-pretendard400">
            {dayjs(record?.createdTime).format("YYYY/MM/DD")}
          </p>
        )
      },
    },
    {
      title: () => (
        <p className="flex gap-[12px] font-pretendard700">{t("주문번호")}</p>
      ),
      dataIndex: "id",
      key: "id",
      render: (id, record) => {
        return (
          <div className="grid gap-[2px]">
            <p className="text-[16px] cursor-pointer text-primary font-pretendard700">
              {id}
            </p>
          </div>
        )
      },
    },
    {
      title: () => (
        <p className="flex gap-[12px] font-pretendard700">
          {t("브랜드/상품정보")}
        </p>
      ),
      dataIndex: "brand",
      key: "brand",
      render: (_, record: Order) => (
        <div className="flex gap-[16px] items-center">
          <img
            className="max-w-[64px] aspect-square"
            src={record.logoImage}
            alt={record.logoImage}
          />
          <div className="flex-1">
            <h4 className="mb-[8px] line-clamp-1 text-[16px] text-primary font-pretendard400 cursor-pointer leading-none">
              {record.brandName}
            </h4>
            <p className="text-[14px] line-clamp-1 text-gray1 font-pretendard400 leading-none">
              {record.voucherName}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: t("수량"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("결제금액"),
      dataIndex: "totalPayment",
      align: "center",
      render: (_, record: Order) => (
        <div className="text-[18px] text-primary font-pretendard700 leading-[28px]">
          {MoneyConvert(Number(record.totalPayment))} W
        </div>
      ),
      // sorter: (): any => null,
      // defaultSortOrder: (searchParams.get("usageValueSort")
      //   ? searchParams.get("usageValueSort") + "end"
      //   : null) as SortOrder,
    },
    {
      title: t("주문상태"),
      dataIndex: "status",
      key: "status",
      align: "right",
      render: (_, record: Order) => (
        <div className="flex justify-end text-gray9">
          <Button
            className={`text-[16px] font-pretendard700 capitalize ${convertStatusColor(
              record.status,
            )}`}
          >
            {convertStatusText(record.status)}
          </Button>
        </div>
      ),
    },
  ]

  const columnsMb: ColumnsType<any> = [
    {
      title: "구매일",
      dataIndex: "createdTime",
      key: "createdTime",
      render: (_, record) => {
        return (
          <p className="text-[14px] text-primary font-pretendard400">
            {dayjs(record?.updatedTime).format("YYYY/MM/DD")}
          </p>
        )
      },
    },
    {
      title: () => (
        <p className="flex gap-[12px] font-pretendard700">{t("Order ID")}</p>
      ),
      dataIndex: "orderId",
      key: "orderId",
      render: (_, order: Order) => (
        <div className="grid gap-[2px]">
          <p className="text-[14px] text-primary font-pretendard400">
            {order.id}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="flex gap-[12px] font-pretendard700">
          {t("Order Product")}
        </p>
      ),
      dataIndex: "orderProduct",
      key: "orderProduct",
      render: (_, orderProduct: Order) => (
        <div className="max-w-[127px]">
          <div className="flex items-center gap-[12px] mb-[10px]">
            <img
              className="w-[24px]"
              src={orderProduct.logoImage || logoBrandDefault}
              alt={orderProduct.voucherName}
            />
            <span className="flex-1 line-clamp-1 underline text-[14px] text-textColor6 font-pretendard500">
              {orderProduct.brandName}
            </span>
          </div>
          <h4 className="mb-[8px] line-clamp-1 max-w-[280px] text-[14px] text-secondary5 font-pretendard700">
            {orderProduct.voucherName}
          </h4>
        </div>
      ),
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (status, record: Order) => (
        <div className="flex items-center justify-between">
          <div>
            <Button className="capitalize">{record.status}</Button>
            <p className="mt-[8px] text-textColor6 text-[13px] font-pretendard400 leading-[18px]">
              {record.updatedTime}
            </p>
          </div>
          <div className="w-[16px]">
            <RightArrow />
          </div>
        </div>
      ),
    },
  ]

  const convertStatusText = (status?: string) => {
    switch (status) {
      case "PAID":
        return t("Paid")
      case "OWNED":
        return t("Owned")
      case "REQUESTED":
        return t("Requested")
      case "REFUNDED":
        return t("Refunded")
      default:
        break
    }
  }

  const convertStatusColor = (status?: string) => {
    switch (status) {
      case "PAID":
        return "text-primaryGreen"
      case "OWNED":
        return "text-gray9"
      case "REQUESTED":
        return "text-rose"
      case "REFUNDED":
        return "text-gray9"
      default:
        break
    }
  }

  return data ? (
    <CsTable
      columns={isMobile ? columnsMb : columns}
      dataSource={data}
      showSorterTooltip={false}
      onRow={(record) => {
        return {
          onClick: (event) => {
            navigate(`/my-page/order-detail/${record.id}`)
          },
        }
      }}
      onChange={(pagination, filter, sorter: any) => {
        const field = sorter.field
        const order = sorter.order?.replaceAll("end", "")
        if (order) {
          onSortChange && onSortChange({ [field + "Sort"]: order })
        } else {
          searchParams.delete(field + "Sort")
          setSearchParams(searchParams)
        }
      }}
    />
  ) : (
    <></>
  )
}

export default OrderHistoryTable
