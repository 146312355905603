export enum UrlInternal {
  BRAND = "brand",
  BRAND_DETAIL = "/brand-detail",
  FEED_DETAIL = "feed-detail",
  TOSS_MEMBERSHIP = "/tosspay-membership",
  BRAND_DETAIL_COMMUNITY_REPORT = "community-report",
  BRAND_DETAIL_COMMUNITY_POST = "community-post",
  BRAND_DETAIL_COMMUNITY_COMMENT = "community-comment",

  MY_MEMBERSHIP = "/my-membership",
  HISTORY_USING_MEMBERSHIP = "/my-membership/history-using-membership",
  COMMUNITY_MEMBERSHIP = "/my-membership/community-membership",
  HISTORY_ORDER_MEMBERSHIP = "/my-page/history-order-membership",
  DETAIL_HISTORY_ORDER_MEMBERSHIP = "/my-membership/detail-history-order-membership",
  CANCEL_MEMBER_SHIP = "/my-membership/membership-benefit",
  HISTORY_RECEIVED_GIFT = "/my-page/history-received-gift",
  NOTIFICATION = "/my-page/notification",

  MY_PAGE_ORDER_DETAIL = "my-page/order-detail",
  RECEIVED_GIFT = "/received-gift",

  MYPAGE_ORDER_DETAIL = "/my-page/order-detail",

  SEARCH_BRAND = "/search-brand",

  MY_VOUCHERS = "/my-vouchers",

  //shop
  BRAND_SHOP = "/brand-shop",
  BRAND_SHOP_DETAIL = "/brand-shop/detail",
  BRAND_SHOP_ORDER = "/brand-shop/order",

  MY_ORDER_HISTORY = "/my-page/my-order-history",
  MY_E_CONTENT = "/my-page/my-e-content",

  ORDER_VOUCHER_DETAIL = "/my-page/order-voucher-detail",
  ORDER_ECONTENT_DETAIL = "/my-page/order-econtent-detail",
  ORDER_PRODUCT_DETAIL = "/my-page/order-product-detail",
  VOUCHER_USAGE_HISTORY = "/my-page/voucher-usage-history",

  TOSS_PRODUCT = "/payment/tosspay-product",
}
