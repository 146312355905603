import SearchIcon from "@assets/images/home/icon_search1.png"
import CloseIcon from "@assets/images/home/close.png"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { UrlInternal } from "src/constant/endpointRoute"

interface Props {
  openSearch: boolean
  setOpenSearch: () => void
}

function SearchBrand({ openSearch, setOpenSearch }: Props) {
  const [querySearchChange, setQuerySearchChange] = useState<string>("")
  const navigate = useNavigate()

  return (
    openSearch && (
      <div className="fixed inset-0 bg-[#000000E5] z-[10001]">
        <div className="max-w-[1200px] mx-auto mt-[22vh] relative">
          <div className="flex justify-between items-end border-b border-b-[#FFFFFF33] pb-[13px]">
            <input
              type="text"
              placeholder={"Search for your brand..."}
              className={`w-[226px] placeholder:text-[#828282] focus-visible:outline-none bg-transparent text-[#EDEDED] text-[32px] flex-1`}
              onChange={(e) => setQuerySearchChange(e.target.value)}
            />
            <div
              className="cursor-pointer flex items-center max-w-[38px] max-h-[38px]"
              onClick={() => {
                navigate(
                  `${UrlInternal.SEARCH_BRAND}?searchValue=${querySearchChange}`,
                )

                setOpenSearch()
              }}
            >
              <img src={SearchIcon} alt="search" />
            </div>
          </div>

          <div
            className={`cursor-pointer absolute top-[10px] right-[-140px]`}
            onClick={setOpenSearch}
          >
            <img src={CloseIcon} alt="close icon" />
          </div>
        </div>
      </div>
    )
  )
}

export default SearchBrand
