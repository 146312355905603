import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "src/app/store"

export interface dataBrandMappingState {
  data: object
}

const initialState: dataBrandMappingState = {
  data: {},
}

export const dataBrandMappingSlice = createSlice({
  name: "loading",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDataBrandMapping: (state, action) => {
      state.data = action.payload
    },
    deleteDataBrandMapping: (state) => {
      state.data = {}
    },
  },
})

export const { setDataBrandMapping, deleteDataBrandMapping } =
  dataBrandMappingSlice.actions
export const dataBrandMappingSelect = (state: RootState) =>
  state.brandMappingData.data

export default dataBrandMappingSlice.reducer
