import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"
type Params_Base = {
  page: number
  limit?: number
}
export const groupBuyAPi = {
  getInProgress(params: Params_Base): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.GET_GROUPBUY_INPROGRESS,
      method: "GET",
      params,
      tokenRequired: true,
    })
  },
  getInComing(params: Params_Base): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.GET_GROUPBUY_INCOMING,
      method: "GET",
      params,
      tokenRequired: true,
    })
  },
  getFinished(params: Params_Base): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.GET_GROUPBUY_FINISHED,
      method: "GET",
      params,
      tokenRequired: true,
    })
  },
  getAll(params: Params_Base): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.GET_GROUPBUY_ALL,
      method: "GET",
      params,
      tokenRequired: true,
    })
  },
  getVoucherDetail(id: number | string): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.GET_VOUCHER_DETAIL,
      method: "GET",
      params: { id },
      tokenRequired: true,
    })
  },
  getTotalVoucher(): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.GET_VOUCHER_TOTAL,
      method: "GET",
      tokenRequired: true,
    })
  },
  remindVoucher(id: number | string): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.REMIND_VOUCHER,
      method: "POST",
      data: { id },
      tokenRequired: true,
    })
  },
  paymentRequest(data: FormData): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.PAYMENT_REQUEST,
      method: "POST",
      data,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    })
  },
  paymentInicisApprove(data: {
    merchant_uid?: string
    buyer_email?: string
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.INICIS_APPROVE,
      method: "POST",
      data,
    })
  },

  paymentBankingApi(data: {
    id?: number
    bankName?: string
    bankAccountName?: string
    bankAccountNumber?: string
    quantity?: number
    amount?: number
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.BANKING_PAYMENT,
      method: "POST",
      data,
      tokenRequired: true,
    })
  },
  paymentFreeMoneyApi(data: {
    gpId?: number
    quantity?: number
    amount?: number
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.FREE_MONEY_PAYMENT,
      method: "POST",
      data,
      tokenRequired: true,
    })
  },
}
