import dayjs from "dayjs"
type VOUCHER_PROGRESS = "FINISHED" | "IN_PROGRESS" | "INCOMING"
export enum VOUCHER_TRANS_TYPE {
  USING_VOUCHER = "USING_VOUCHER", // mua voucher,
  EXPIRED_VOUCHER = "EXPIRED_VOUCHER", // hết hạn
  GP_BUYING = "GP_BUYING",
  CANCEL_ORDER = "CANCEL_ORDER",
}
export class Voucher {
  id: number
  brandName?: string
  slogan?: string
  logoImage?: string
  progress?: VOUCHER_PROGRESS
  quantity?: number | null
  isHighlight?: boolean
  endTime?: string | null
  expiredTime?: string | null
  startTime?: string | null
  voucherImage?: string
  description?: string
  voucherName?: string
  voucherPrice?: string | number
  totalSold?: number | null
  maxPurchaseQuantity?: number
  maxPurchaseFrequency?: number
  isLimited?: boolean
  totalGroupBuyingValue?: number
  discountPercentage?: number | string
  rootPrice?: number | string
  voucherDetail?: any
  isSoldOut?: boolean
  productType?: string
  image?: string
  price?: number
  shippingCost?: number
  priceForMembership?: number
  userPhoneNumber?: string
  userName?: string
  address?: string
  costsForJeju?: any
  isBanking?: string
  addressDescription?: string
  dateOfUse?: string
  canBuy?: boolean
  totalBought?: number
  hasCustomerMessage?: string
  hasMembershipMessage?: string
  hasPremierMessage?: string
  priceForCustomers?: string
  priceForPremier?: string
  onlyMembershipMessage?: string
  onlyPremierMessage?: string
  hasSlot?: boolean
  constructor(props: Voucher) {
    this.id = props.id
    for (const key in this) {
      if (props.hasOwnProperty(key)) {
        ;(this as any)[key] = (props as any)[key]
      }
    }
  }
  getCountTime = (): string | number => {
    return dayjs(
      this.progress === "INCOMING" ? this.startTime : this.endTime,
    ).format()
  }
  get status() {
    return this.progress
  }
  // API
}
