import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const uploadSingleFile = async (file: FormData) => {
  return api.request({
    url: ApiUrl.UPLOAD_SINGLE_FILE,
    method: "POST",
    data: file,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
}