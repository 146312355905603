// import '../style.scss'

import bigLogo from "src/assets/imgs/moafriends_logo_big.png"
import bigLogoMb from "src/assets/imgs/moafriends_logo_big_mb.png"
import imgBrandOn1 from "src/assets/imgs/brand-on-1.png"
import imgBrandOn2 from "src/assets/imgs/brand-on-2.png"
import imgBrandOn3 from "src/assets/imgs/brand-on-3.png"
import imgBrandOn4 from "src/assets/imgs/brand-on-4.png"
import line from "src/assets/imgs/line.png"
import lineXs from "src/assets/imgs/line-xs.png"
import line2Md from "src/assets/imgs/line-2md.png"
import lineMd from "src/assets/imgs/line-md.png"
import useWindowSize from "src/util/hook/useWindowSize"

interface Block2Props {}
const Block2Kr: React.FC<Block2Props> = () => {
  const { width }: any = useWindowSize()

  return (
    <div className="pt-[75px] lpSmm:pt-[96px] lpSm:pt-[120px] xl:pt-[194px]">
      <div
        className="flex items-center justify-center lpSm:gap-[17px] gap-[6.27px]"
        data-aos="fade-up"
        style={{
          zoom:
            width <= 540
              ? undefined
              : width < 768
              ? "0.5"
              : width < 1024
              ? "0.7"
              : width < 1280
              ? "0.8"
              : undefined,
        }}
      >
        <h3 className="lpSm:text-[60px] text-[24px] font-montserrat500 uppercase text-[#111]">
          Brands on
        </h3>
        <img src={width <= 540 ? bigLogoMb : bigLogo} alt="logo" />
      </div>
      <div className="pt-[24px] lpSm:pt-[54px] lpSmm:pb-[90px] lpSm:pb-[127px] pb-[47px] bg-bgCustom1">
        <div className={width < 992 ? "flex justify-center" : ""}>
          <div className="relative md:pt-[121px] pt-[32px] pb-[76px] lpSmm:pb-[0] lpSmm:pl-[38px] pl-[17px] 2md:pl-0">
            {/* text fixed top */}
            <div className="absolute top-0 left-[17px] lpSmm:left-[38px] 2md:left-1/2 2md:transform 2md:-translate-x-1/2">
              <img
                src={
                  width < 540
                    ? lineXs
                    : width < 768
                    ? lineMd
                    : width < 992
                    ? line2Md
                    : line
                }
                alt="line"
              />
            </div>
            {/* end text fixed top */}

            <div className="w-full md:mb-[75px] mb-[66px] lpSmm:mb-[58px] 2md:h-[510px] grid 2md:block relative">
              <div className="2md:absolute top-1/2 2md:left-[calc(50%-237.955px)] xl:left-[calc(50%-289px)] 2md:transform 2md:translate-x-[-50%] 2md:translate-y-[-50%] 2md:mt-[-40px]">
                <div
                  className="2md:mt-0 md:mt-[-50px] lpSmm:mt-[-32px] mt-[-15px] pl-[30px] md:pl-[50px] 2md:pl-0 2md:w-max 2md:float-right 2md:text-right 2md:pr-[50px] xl:pr-[68px]"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  style={{
                    zoom: width < 1280 && width > 991 ? "0.85" : undefined,
                  }}
                >
                  <h4 className="md:text-[40px] text-[29px] font-montserrat500 leading-normal text-[#111] uppercase md:mb-[14px] mb-[12px]">
                    MEMBERSHIP
                  </h4>
                  <p className="md:text-[22px] text-[14px] md:leading-[32px] leading-[18.5px] text-[#111] uppercase font-spoqa500">
                    위기와 시련이 언제 닥쳐올지 모르지만 <br />
                    나의 프렌즈와 함께라면 이겨낼 수 있어♡
                  </p>
                  <p className="md:text-[22px] text-[14px] md:leading-[32px] leading-[18.5px] text-[#7B7B7B] uppercase font-spoqa400">
                    나의 브랜드를 지켜내는 힘! 브랜드 성장의 발판!
                    <br />
                    <br />
                    모아프렌즈를 통한 나만의 브랜드 멤버십 구축 서비스
                    <br />
                    브랜드 경험 기회 제공 및 신규 고객 유입 확대
                    <br />
                    충성고객 이탈 방지 및 재구매 유도로 기업 안전 매출{" "}
                    {width < 375 && <br />}확보
                  </p>
                </div>
              </div>
              <div className="order-[-1] 2md:order-0 2md:absolute top-1/2 2md:left-[calc(50%+382.5px)] 2md:transform 2md:translate-x-[-50%] 2md:translate-y-[-50%] lpSmm:ml-0 ml-[-2px]">
                <img
                  className="md:max-w-max lpSmm:max-w-[500px] max-w-[360px]"
                  src={imgBrandOn1}
                  alt="img"
                  data-aos="fade-up"
                />
              </div>
            </div>

            <div className="w-full 2md:mb-[55px] md:mb-[75px] lpSmm:mb-[50px] mb-[65px] 2md:h-[519px] relative">
              <div className="2md:absolute top-1/2 2md:left-[calc(50%-371px)] 2md:transform 2md:translate-x-[-50%] 2md:translate-y-[-50%] 2md:text-right">
                <img
                  className="md:max-w-max lpSmm:max-w-[500px] max-w-[360px] 2md:ml-0 md:ml-[-72px] lpSmm:ml-[-45px] ml-[-30px]"
                  src={imgBrandOn2}
                  alt="img"
                  data-aos="fade-up"
                />
              </div>
              <div className="2md:absolute top-1/2 xl:left-[calc(50%+290px)] 2md:left-[calc(50%+220.615px)] 2md:transform 2md:translate-x-[-50%] 2md:translate-y-[-50%] 2md:mt-[-40px]">
                <div
                  className="w-max text-left xl:pl-[110px] pl-[30px] md:pl-[50px] md:mt-[-20px] mt-[-5px] 2md:mt-0"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  style={{
                    zoom: width < 1280 && width > 991 ? "0.85" : undefined,
                  }}
                >
                  <h4 className="md:text-[40px] text-[29px] font-montserrat500 text-[#111] uppercase md:mb-[14px] mb-[12px]">
                    COMMUNICATION
                  </h4>
                  <p className="md:text-[22px] text-[14px] md:leading-[32px] leading-[18.5px] text-[#111] uppercase font-spoqa500">
                    너와 만나는 시간은 항상 즐거워!
                  </p>
                  <p className="md:text-[22px] text-[14px] md:leading-[32px] leading-[18.5px] text-[#7B7B7B] uppercase font-spoqa400">
                    나의 프렌즈는 나를 어떻게 생각할까? <br />
                    보다 자주 얘기하며 너의 속마음을 알고 싶어
                    <br />
                    <br />
                    소상공인 & 스몰브랜드 최적화 실시간 소통채널 제공
                    <br />
                    편리한 단골관리와 고객 경험 바이럴 체크
                    <br />
                    고객 니즈 분석을 통한 서비스 개선 및 확대
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:mb-[36px] 2md:mb-[75px] lpSmm:mb-[47px] mb-[67px] 2md:h-[519px] grid 2md:block relative">
              <div className="2md:absolute top-1/2 xl:left-[calc(50%-308.335px)] 2md:left-[calc(50%-255.28px)] 2md:transform 2md:translate-x-[-50%] 2md:translate-y-[-50%] 2md:mt-[-60px]">
                <div
                  className="2md:w-max 2md:float-right 2md:text-right xl:pr-[66px] 2md:pr-[50px] md:mt-[-70px] lpSmm:mt-[-50px] mt-[-30px] 2md:mt-0 pl-[30px] md:pl-[50px] 2md:pl-0"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  style={{
                    zoom: width < 1280 && width > 991 ? "0.85" : undefined,
                  }}
                >
                  <h4 className="md:text-[40px] text-[29px] font-montserrat500 text-[#111] uppercase md:mb-[14px] mb-[12px]">
                    TARGETING
                  </h4>
                  <p className="md:text-[22px] text-[14px] md:leading-[32px] leading-[18.5px] text-[#111] uppercase font-spoqa500">
                    사장님도 잘 알쥬? 매출 80%는 상위고객 20%에
                    {width <= 540 && <br />}서 나오는거!
                  </p>
                  <p className="md:text-[22px] text-[14px] md:leading-[32px] leading-[18.5px] text-[#7B7B7B] uppercase font-spoqa400">
                    잘 되려면 이 20% 단골을 무조건 잡아야 된다니까!
                    <br />
                    신규 및 기존 우수고객을 위한 맞춤형 프로모션 제공
                    <br />
                    <br />
                    불필요한 마케팅 리소스 절감 및 리스크 최소화
                    <br />
                    한정된 자원 활용 효율성 최대화
                    <br />
                    우수 잠재고객의 충성고객 전환을 통한 수익성 향상
                  </p>
                </div>
              </div>
              <div className="2md:absolute order-[-1] 2md:order-0 top-1/2 2md:left-[calc(50%+395px)] 2md:transform 2md:translate-x-[-50%] 2md:translate-y-[-50%] md:w-max lpSmm:ml-0 ml-[-2px]">
                <img
                  className="md:w-max lpSmm:max-w-[500px] max-w-[360px] md:max-w-max"
                  src={imgBrandOn3}
                  alt="img"
                  data-aos="fade-up"
                />
              </div>
            </div>

            <div className="w-full 2md:h-[535px] relative">
              <div className="2md:absolute top-1/2 2md:left-[calc(50%-361.5px)] 2md:transform 2md:translate-x-[-50%] 2md:translate-y-[-50%] 2md:text-right">
                <img
                  className="md:w-max lpSmm:max-w-[500px] max-w-[360px] md:max-w-max md:ml-[-72px] lpSmm:ml-[-47px] ml-[-32px] 2md:ml-0"
                  src={imgBrandOn4}
                  alt="img"
                  data-aos="fade-up"
                />
              </div>
              <div className="2md:absolute top-1/2 xl:left-[calc(50%+249px)] 2md:left-[calc(50%+186.565px)] 2md:transform 2md:translate-x-[-50%] 2md:translate-y-[-50%] 2md:mt-[-50px]">
                <div
                  className="text-left xl:pl-[109px] pl-[30px] md:pl-[50px] 2md:w-max mt-[-10px] md:mt-0"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  style={{
                    zoom: width < 1280 && width > 991 ? "0.85" : undefined,
                  }}
                >
                  <h4 className="md:text-[40px] text-[29px] font-montserrat500 text-[#111] uppercase md:mb-[14px] mb-[12px]">
                    GROWTH
                  </h4>
                  <p className="md:text-[22px] text-[14px] md:leading-[32px] leading-[18.5px] text-[#111] uppercase font-spoqa500">
                    보여줄게, 완전히 달라진 나의 브랜드
                  </p>
                  <p className="md:text-[22px] text-[14px] md:leading-[32px] leading-[18.5px] text-[#7B7B7B] uppercase font-spoqa400">
                    완벽하게 준비된 최고의 브랜드 경험으로
                    <br />
                    이젠 우리도 TOP 브랜드 고고!
                    <br />
                    <br />
                    정교한 타겟팅에 기반한 브랜드 전략
                    <br />
                    안정된 매출 및 장기적 수익 예측
                    <br />
                    업계 최고를 향한 지속적이고 점진적인 성장
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Block2Kr
