import { ConfigProvider, Form, Input, InputProps } from "antd"
import { RuleObject } from "antd/es/form"
import { ReactElement } from "react"
import HidePass from "@assets/icons/eyes-hidden.svg"
import ShowPass from "@assets/icons/eyes-show.svg"
import useIsMobile from "src/customHook/useIsMobile"
import "./style.scss"

type InputType = "text" | "password"
type Props = {
  initialValue?: string
  name: string
  label?: string
  whiteSpaceCheck?: { message: string } | boolean | undefined
  inputClassName?: string
  rules?: RuleObject[]
  inputType?: InputType
  textFont?: string
  inputHeight?: number
  borderColor?: string
  textSize?: string
}

function CSInput({
  initialValue,
  name,
  label,
  rules = [],
  className,
  inputClassName,
  inputType = "text",
  textFont,
  value,
  whiteSpaceCheck,
  inputHeight = 56,
  disabled,
  borderColor = "#000000",
  textSize,
  ...props
}: Props & InputProps) {
  const { isMobile } = useIsMobile(1023)
  const dynamicInputHeight = isMobile ? 48 : inputHeight
  const isRequired = !!rules?.some((item) => !!item?.required)
  const inputMode: Record<InputType, ReactElement> = {
    text: (
      <Input
        disabled={disabled}
        style={{
          height: `${dynamicInputHeight}px`,
        }}
        className={inputClassName}
        value={value}
        {...props}
      />
    ),
    password: (
      <Input.Password
        disabled={disabled}
        style={{
          height: `${dynamicInputHeight}px`,
        }}
        className={inputClassName}
        iconRender={(visible) => {
          return visible ? <ShowPass /> : <HidePass />
        }}
        {...props}
      />
    ),
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorBorder: `${disabled ? "#F5F5F5" : borderColor}`,
          borderRadius: 0,
          // borderRadiusLG: 50,
          // borderRadiusSM: 50,
          colorError: "#FF5851",
          fontFamily: textFont || "Pretendard-400",
          fontSize: textSize || isMobile ? 14 : 16,
          // fontSizeSM: 30,
          // fontSizeXL: 20,
          // fontSizeLG: 30,
          colorText: "#000",
          controlPaddingHorizontal: 20,
          colorPrimaryHover: "#000",
          // colorPrimary: "#000",
          colorBgContainerDisabled: "#F5F5F5",
          // Alias Token
        },
        components: {
          Input: {
            colorPrimaryActive: "none",
          },
        },
      }}
    >
      <Form.Item
        initialValue={initialValue}
        validateFirst
        name={name}
        label={
          !!label ? (
            <p className="font-pretendard700 text-black text-[16px] leading-[16px]">
              {label}{" "}
              {isRequired && (
                <span className="text-errorColor text-[#ff4d4f]">*</span>
              )}
            </p>
          ) : (
            false
          )
        }
        className={className}
        rules={[
          ...rules,
          whiteSpaceCheck
            ? {
                validator: (rules, value, error) => {
                  console.log(rules)

                  if (value && value.includes(" ")) {
                    return typeof whiteSpaceCheck !== "boolean"
                      ? error(whiteSpaceCheck?.message)
                      : error(
                          `${label} cannot contain spaces. Please enter a valid ${label?.toLowerCase()}!`,
                        )
                  }
                  return error()
                },
              }
            : {},
        ]}
      >
        {inputMode[inputType]}
      </Form.Item>
    </ConfigProvider>
  )
}

export default CSInput
