
  import { useEffect, useLayoutEffect } from 'react';

const Close = () => {
    window.location.reload()
    useEffect(() => {
        window.location.replace('/group-buying/in-progress')
    }, []);
    return(
        <div></div>
    )
};
export default Close;