import { Checkbox } from "antd"
import type { CheckboxProps } from "antd"

import "./style.scss"

interface Props {
  label?: React.ReactNode
  onChangeValue?: (e: boolean) => void
}

function CheckboxPost({ label, onChangeValue }: Props) {
  const onChange: CheckboxProps["onChange"] = (e) => {
    onChangeValue?.(e.target.checked)
  }

  return <Checkbox onChange={onChange}>{label}</Checkbox>
}

export default CheckboxPost
