import React, { useEffect, useRef, useState } from "react"
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk"
import { nanoid } from "nanoid"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"
import { closeLoading, openLoading } from "src/features/loading/loadingSlice"
import { loadTossPayments } from "@tosspayments/payment-sdk"
import { getCustomerIdToss } from "src/api/brandApi"
import { UrlInternal } from "src/constant/endpointRoute"
import CommonModal from ".."

// 구매자의 고유 아이디를 불러와서 customerKey로 설정하세요.
// 이메일・전화번호와 같이 유추가 가능한 값은 안전하지 않습니다.
const clientKey = import.meta.env.VITE_KEY_MEMBERSHIP
// const clientKey = "test_ck_yL0qZ4G1VOY15BQlwvMw8oWb2MQY"
const customerKey = nanoid()
// const paymentWidget = PaymentWidget(clientKey, PaymentWidget.ANONYMOUS) // 비회원 결제

function TossPayMembership() {
  const [paymentWidget, setPaymentWidget] = useState<any>(null)
  const paymentMethodsWidgetRef = useRef(null)
  // const [price, setPrice] = useState(50_000)
  const { state } = useLocation()
  const dataUser = useAppSelector(userDataSelect)
  const dispatch = useAppDispatch()
  const params = useParams()
  console.log("useParams()", state)
  const { id } = params
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get("code")

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  useEffect(() => {
    const fetchPaymentWidget = async () => {
      dispatch(openLoading())
      try {
        const loadedWidget: any = await loadPaymentWidget(
          clientKey,
          customerKey,
        )
        if (loadedWidget) {
          setPaymentWidget(loadedWidget)
        }
      } catch (error) {
        console.error("Error fetching payment widget:", error)
      }
    }

    fetchPaymentWidget()
  }, [])

  useEffect(() => {
    if (paymentWidget == null) {
      return
    }

    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      "#payment-widget",
      { value: Number(state?.rootPrice) },
      { variantKey: "DEFAULT" },
    )

    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" })

    console.log(
      "paymentMethodsWidgetRef.current",
      paymentMethodsWidgetRef.current,
    )

    paymentMethodsWidgetRef.current = paymentMethodsWidget

    if (paymentMethodsWidgetRef.current) {
      setTimeout(() => {
        dispatch(closeLoading())
      }, 2500)
    }
  }, [paymentWidget, state?.rootPrice])

  useEffect(() => {
    const paymentMethodsWidget: any = paymentMethodsWidgetRef.current

    if (paymentMethodsWidget == null) {
      return
    }

    paymentMethodsWidget.updateAmount(Number(state?.rootPrice))
  }, [state?.rootPrice])

  useEffect(() => {
    if (id && dataUser?.id && state?.id) {
      handlePaymentRequest()
    }
  }, [id, dataUser?.id, state?.id])

  const handlePaymentRequest = async () => {
    // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
    try {
      const { data } = await getCustomerIdToss({ id: Number(id) })

      if (data) {
        loadTossPayments(clientKey)
          .then((tossPayments) => {
            // ------ 카드 등록창 호출 ------
            tossPayments
              .requestBillingAuth("카드", {
                // orderName: "Toss T-shirts and 2 other items",
                customerEmail: dataUser?.email,
                customerName: dataUser?.displayName,
                // customerMobilePhone: "01012341234"
                customerKey: data,
                successUrl: `https://dev-api.moafriends.com/api/v1/payment/buy-membership-by-toss?userId=${dataUser?.id}&membershipId=${state?.id}`,
                failUrl: `${window.location.origin}/${UrlInternal.TOSS_MEMBERSHIP}/${id}`,
                // _skipAuth: "FORCE_SUCCESS",
              })
              // ------ 결제창을 띄울 수 없는 에러 처리 ------
              // 메서드 실행에 실패해서 reject 된 에러를 처리하는 블록입니다.
              // 결제창에서 발생할 수 있는 에러를 확인하세요.
              // https://docs.tosspayments.com/reference/error-codes#결제창공통-sdk-에러
              .catch(function (error) {
                console.log("error", error)

                if (error.code === "USER_CANCEL") {
                  navigate("/" + state?.brandId)
                  // 결제 고객이 결제창을 닫았을 때 에러 처리
                }
              })
          })
          .catch(function (error) {
            console.log("error113", error)
          })
      }
      // await paymentWidget?.requestBillingAuth({
      //   customerKey: Date.now(),
      //   // orderName: "토스 티셔츠 외 2건",
      //   // customerName: "김토스",
      //   // customerEmail: "customer123@gmail.com",
      //   // customerMobilePhone: "01012341234",
      //   // successUrl: `${
      //   //   import.meta.env.VITE_API_URL
      //   // }/payment/approve-toss-membership?userId=${dataUser?.id}&brandId=${
      //   //   state?.id
      //   // }`,
      //   successUrl: `http://dev.moafriends.com/api/v1/payment/buy-membership-by-toss?userId=${dataUser?.id}&membershipId=1`,
      //   failUrl: `${window.location.origin}/fail`,
      //   _skipAuth: "FORCE_SUCCESS",
      // })
    } catch (error) {
      console.error("Error requesting payment:", error)
    }
  }

  return (
    <>
      <CommonModal
        isOpen={code === "INVALID_CARD_NUMBER"}
        handleClose={() => navigate(`${UrlInternal.TOSS_MEMBERSHIP}/${id}`)}
        width={375}
        className="text-[#000000]"
      >
        <div>정보를 다시 확인해주세요</div>
      </CommonModal>

      <div id="payment-widget" />
      <div id="agreement" className="inline-block" />
      <div className="inset-0 bg-[#fff] z-[9999998] fixed"></div>
      {/* <div className="container my-[50px]">

        <div id="payment-widget" />
        <div id="agreement" className="inline-block" />
        <button
          onClick={handlePaymentRequest}
          className="block mx-[30px] px-[50px] py-[10px] text-[#fff] bg-[#000] rounded-[5px]"
        >
          결제하기
        </button>
      </div> */}
    </>
  )
}

export default TossPayMembership
