import React from "react"
import Header from "./Header"
import { Outlet, useLocation } from "react-router-dom"
import Footer from "./Footer"
import useIsMobile from "src/customHook/useIsMobile"
import NavigationMobile from "src/components/NavigationMobile"
import useWindowSize from "src/customHook/useWindowSize"

function MainLayout() {
  // const { isMobile } = useIsMobile(1023)
  const { width }: any = useWindowSize()
  const isMobileHeader = width <= 1023
  const isNavigation = width <= 768
  const { pathname } = useLocation()
  const isHiddenNavigation =
    pathname.includes("group-buying/detail") ||
    pathname.includes("group-buying/order") ||
    pathname.includes("brand-shop/detail") ||
    pathname.includes("brand-shop/order")

  return (
    <div className="overflow-x-hidden min-h-screen flex flex-col">
      {!isMobileHeader && <Header />}
      <div className="flex-1">
        <Outlet />
      </div>
      <Footer />

      {isNavigation && !isHiddenNavigation && <NavigationMobile />}
    </div>
  )
}

export default MainLayout
