import ImgReset from "@assets/images/brand_page/reset.png"
import { Spin } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { useNavigate } from "react-router-dom"
import { getBrand } from "src/api/homeApi"
import { UrlInternal } from "src/constant/endpointRoute"

interface Props {
  dataFilter: any
  setIsFilterAllBrand: (e: boolean) => void
}

function FilterAllBrand({ dataFilter, setIsFilterAllBrand }: Props) {
  const [dataBrand, setDataBrand] = useState<any[]>([])
  const [pageable, setPageable] = useState<any>({})
  const navigate = useNavigate()
  const { t } = useTranslation()

  const getDataBrand = async (isLoadmore?: boolean, page?: number) => {
    try {
      const res: any = await getBrand({
        page: page ?? 1,
        size: 12,
        filter: dataFilter.selected ?? [],
        querySearch: dataFilter.querySearch ?? "",
      })

      if (res.result) {
        setDataBrand(res.data.datas)
        setPageable(res.data.pageable)

        if (isLoadmore) {
          setDataBrand([...dataBrand, ...res.data.datas])
        }
      } else {
        setDataBrand([])
        setPageable({})
      }
    } catch (error) {
      setDataBrand([])
      setPageable({})
    }
  }

  useEffect(() => {
    if (dataFilter) {
      getDataBrand()
    }
  }, [dataFilter])

  const handleLoadMore = () => {
    setTimeout(() => {
      getDataBrand(true, pageable.page + 1)
    }, 1500)
  }

  return (
    <div className="container">
      <div className="flex justify-between pb-[20px] border-b border-b-[#000] mb-[40px]">
        <div className="text-[24px] font-pretendard700">
          {t("검색 결과")}: {dataFilter.querySearch}
        </div>
        <div
          className="flex items-center gap-[5px] cursor-pointer"
          onClick={() => setIsFilterAllBrand(false)}
        >
          <img src={ImgReset} alt="ImgReset" />
          <div className="text-[14px] underline">{t("Reset All")}</div>
        </div>
      </div>

      <div className="pb-[15px] text-[#757575] text-[14px]">
        {t("Showing all")} {pageable?.totalElement ?? 0} {t("results")}
      </div>

      {dataBrand && dataBrand?.length > 0 && (
        <InfiniteScroll
          dataLength={dataBrand?.length}
          next={handleLoadMore}
          hasMore={pageable?.totalElement > dataBrand?.length}
          loader={
            <div className="flex justify-center items-center py-[30px] overflow-hidden">
              <Spin tip="Loading" size="small" />
            </div>
          }
        >
          <div className="md:grid-cols-4 xs:grid-cols-3 grid grid-cols-2 gap-[21px]">
            {dataBrand?.map((item: any, index) => (
              <div
                className="cursor-pointer"
                key={index}
                onClick={() => navigate(`/${item.id}`)}
              >
                <div className="border border-[#D4D4D4] aspect-square">
                  <img
                    src={item?.logoImage}
                    alt="img"
                    className="w-[100%] h-[100%] object-contain max-w-[100%]"
                  />
                </div>
                <div className="mt-[16px] font-pretendard700">
                  {item?.brandName}
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  )
}

export default FilterAllBrand
