import SearchIcon from "@assets/images/my_page/search.png"
import { ColumnsType } from "antd/es/table"
import CsTable from "src/components/Table"
import useIsMobile from "src/customHook/useIsMobile"

import NotiIcon from "@assets/images/my_page/noti.png"
import { useEffect, useState } from "react"
import { getListNotification } from "src/api/notificationApi"
import Pagination from "src/components/Pagination"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { UrlInternal } from "src/constant/endpointRoute"

function Notification() {
  const { isMobile } = useIsMobile(768)
  const [queryTrans, setQueryTrans] = useState<{
    page: number
    size: number
    querySearch: string
  }>({
    page: 1,
    size: 10,
    querySearch: "",
  })
  const [transactionHistory, setTransactionHistory] = useState<any>([])
  const [pageable, setPageable] = useState<any>({})
  const [valueSearch, setValueSearch] = useState("")
  const navigate = useNavigate()

  const handleGetTransactionHistory = async (
    isLoadmore?: boolean,
    page?: number,
  ) => {
    try {
      let params = {
        ...queryTrans,
        page: page ?? queryTrans.page,
      }
      const { data }: any = await getListNotification(params)
      if (data) {
        let dataResp = data.datas
        if (isLoadmore) {
          setTransactionHistory([...transactionHistory, ...dataResp])
        } else {
          setTransactionHistory(dataResp)
        }
        setPageable(data.pageable)
      }
    } catch (error) {
      setTransactionHistory({})
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetTransactionHistory()
  }, [queryTrans])

  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "number",
      key: "number",
      align: "center",
      render: (_: any, __: any, index: number) =>
        Number(
          pageable?.totalElement -
            ((queryTrans?.page - 1) * queryTrans?.size + index),
        ),
    },
    {
      title: "제목",
      dataIndex: "title",
      key: "title",
      align: "center",
      render: (text, record) => {
        return <div>{text}</div>
      },
    },
    {
      title: "작성일",
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (text, record) => {
        return <div>{moment(text).format("YYYY.MM.DD")}</div>
      },
    },
  ]

  const getDetailOrder = (id: number) => {
    navigate(UrlInternal.NOTIFICATION + `/${id}`)
  }

  const handleSearch = () => {
    setQueryTrans({
      ...queryTrans,
      querySearch: valueSearch,
    })
  }

  return !isMobile ? (
    <>
      <div className="flex justify-between items-end">
        <div className="text-[14px]">총 {transactionHistory?.length}건</div>
        <div className="ml-auto w-[220px] border border-[#D4D4D4] flex items-center justify-between">
          <input
            type="text"
            placeholder="Search..."
            className="outline-none px-[18px] max-w-[180px]"
            onChange={(e) => setValueSearch(e.target.value)}
          />
          <div
            className="p-[11px] border-l border-[#D4D4D4] cursor-pointer"
            onClick={handleSearch}
          >
            <img src={SearchIcon} alt="search" />
          </div>
        </div>
      </div>

      <div className="border-t border-t-solid border-t-primary lg:mx-0 mx-[-24px] mt-[14px]">
        <CsTable
          className="mb-[34px] table-history-order"
          columns={columns}
          dataSource={transactionHistory}
          // showHeader={false}
          onRow={(record, rowIndex) => {
            console.log("record", record)
            return {
              onClick: () => {
                getDetailOrder(record?.id)
              },
            }
          }}
        />
        {transactionHistory && transactionHistory?.length > 0 && (
          <Pagination
            showSizeChanger={false}
            rootClassName="flex justify-center"
            className="custom-pagination"
            total={pageable?.totalElement}
            pageSize={queryTrans?.size}
            current={queryTrans?.page}
            onChange={(page) => {
              setQueryTrans((prev) => ({ ...prev, page }))
            }}
          />
        )}
      </div>
    </>
  ) : (
    <>
      <div className="border border-[#D4D4D4] flex items-center justify-between max-h-[40px]">
        <input
          type="text"
          placeholder="Search..."
          className="outline-none px-[18px]"
          onChange={(e) => setValueSearch(e.target.value)}
        />
        <div
          className="p-[11px] border-l border-[#D4D4D4] cursor-pointer"
          onClick={handleSearch}
        >
          <img src={SearchIcon} alt="search" />
        </div>
      </div>

      <div className="pt-[27px] pb-[10px]  border-b-[2px] border-[#000000]">
        <div className="min-h-[20px] text-end text-[#444] text-[13px]">
          총 {transactionHistory?.length}건
        </div>
      </div>

      {transactionHistory &&
        transactionHistory?.length > 0 &&
        transactionHistory?.map((item: any, i: number) => (
          <div
            className="py-[20px] flex gap-[20px] items-center border-b border-[#D4D4D4]"
            key={i}
          >
            <div>
              <img src={NotiIcon} alt="noti" />
            </div>
            <div>
              <div className="text-[14px] font-pretendard500">
                {item?.title}
              </div>
              <div className="text-[#757575] text-[12px]">
                {moment(item?.createdTime).format("YYYY/MM/DD")}
              </div>
            </div>
          </div>
        ))}

      {transactionHistory?.length >= 10 && pageable?.hasNext && (
        <div className="w-full bg-[#fff] mt-[40px]">
          <div
            className="border border-[#000] h-[40px] leading-[40px] text-center bg-[#fff]"
            onClick={() => {
              if (pageable?.hasNext) {
                handleGetTransactionHistory(true, pageable.page + 1)
              }
            }}
          >
            더 보기
          </div>
        </div>
      )}
    </>
  )
}

export default Notification
