import { Form } from "antd"
import { useEffect } from "react"
import Button from "src/components/Button"
import CSInput from "src/components/Input/Index"
import CommonModal from "src/components/Modal"
import { Postcode } from "src/components/PostCode"

interface Props {
  isOpen: boolean
  handleClose: () => void
  getDataModalChangeAddress: (e: any) => void
  data: any
}

function ModalChangeAddress({
  isOpen,
  handleClose,
  getDataModalChangeAddress,
  data,
}: Props) {
  const [form] = Form.useForm()

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        userName: data?.userName,
        phone: data?.userPhoneNumber,
        address: data?.address,
        addressDescription: data?.addressDescription,
      })
    }
  }, [data])

  const onFinish = (values: any) => {
    console.log("values", values)
    getDataModalChangeAddress(values)

    handleClose()
  }

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} isCloseOutside>
      <div className="leading-[100%]">
        <div className="text-[20px] font-pretendard500 mb-[23px]">
          배송지 정보 등록
        </div>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          form={form}
        >
          <div className="text-[16px] pb-[12px]">
            받는 사람 <span className="text-[#C70039]">*</span>
          </div>
          <CSInput name="userName" placeholder="입력해 주세요" required />
          <div className="text-[16px] pb-[12px]">
            연락처 <span className="text-[#C70039]">*</span>
          </div>
          <CSInput
            name="phone"
            required
            placeholder="숫자와 - (하이픈)만 입력 가능"
          />
          <div className="text-[16px] pb-[12px]">
            배송지 주소 <span className="text-[#C70039]">*</span>
          </div>

          <div className="flex gap-[8px]">
            <CSInput
              name="address"
              required
              readOnly
              disabled
              inputClassName="disabled:bg-[#E2E2E2]"
              placeholder="우편번호 검색 눌러주세요"
              className="!mb-[5px]"
            />
            <Postcode
              type="button"
              btnClassName="w-[111px] h-[56px] border-[1px] border-primary font-pretendard400 text-[#fff] bg-[#000] text-[16px]"
              wrapClassName="!mb-0"
              name="addressInfo"
              label={"우편번호 검색"}
              placeholder={"우편번호 검색"}
              onCompleteAddress={(value: any) => {
                form.setFieldValue("sidoEnglish", value?.sidoEnglish)
                form.setFieldValue("address", value?.address)
              }}
            />
          </div>

          <CSInput
            name="addressDescription"
            placeholder="상세주소를 입력해 주세요"
          />

          <div className="hidden">
            <CSInput name="sidoEnglish" />
          </div>

          <Button
            theme="main"
            mainStyle={{
              marginTop: 30,
            }}
          >
            저장
          </Button>
        </Form>
      </div>
    </CommonModal>
  )
}

export default ModalChangeAddress
