import { useTranslation } from "react-i18next"
import Button from "src/components/Button"
import CommonModal from "src/components/Modal"
type Props = {
  isOpen?: boolean
  handleClose?: () => void
  isShop?: boolean
}

function EvenEnd({ isOpen, handleClose, isShop }: Props) {
  const { t } = useTranslation()

  return (
    <div>
      <CommonModal isOpen={isOpen} handleClose={handleClose}>
        <p className="font-pretendard600 text-[20px] text-primary leading-[20px]">
          {t("Oops! Event Ended!")}
        </p>
        <div className="mt-[10px]">
          <p className="text-gray1 text-[14px] leading-[20px] font-pretendard400 capitalize">
            {isShop
              ? "아쉽게도 판매 기간이 끝나 더이상 구매가 불가능합니다."
              : t(
                  "The current event has ended. Explore more exciting membership events now!",
                )}
          </p>
          <div className="mt-[30px]">
            <Button onClick={handleClose} theme="main">
              {t("Explore now")}
            </Button>
          </div>
        </div>
      </CommonModal>
    </div>
  )
}

export default EvenEnd
