import img1 from "src/assets/imgs/block4-img-1.png"
import img2 from "src/assets/imgs/block4-img-2.png"
import img1Md from "src/assets/imgs/block4-540-slide-1.png"
import img2Md from "src/assets/imgs/block4-540-slide-2.png"
import img1Xs from "src/assets/imgs/block4-375-slide-1.png"
import img2Xs from "src/assets/imgs/block4-375-slide-2.png"
import { useState } from "react"
import SwiperCore from "swiper"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import ArrowLeftIcon from "src/assets/icons/arrow-left.svg"
import ArrowRightIcon from "src/assets/icons/arrow-right.svg"
import ArrowLeftDisableIcon from "src/assets/icons/arrow-left-disable.svg"
import ArrowRightDisableIcon from "src/assets/icons/arrow-right-disable.svg"
import useWindowSize from "src/util/hook/useWindowSize"

interface Block4Props {}

const Block4Kr: React.FC<Block4Props> = () => {
  const { width }: any = useWindowSize()
  const [isEnd, setIsEnd] = useState<Boolean>(false)
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)
  // console.log('isEnd', isEnd)

  const goNext = () => {
    if (swiper) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper) {
      swiper.slidePrev()
    }
  }

  const renderNavigation = () => {
    return (
      <div
        className="flex md:gap-[15px] gap-[9px] absolute lg:top-[60px] md:top-[46px] top-[31px] lg:right-[55px] md:right-[46px] right-[20px]"
        style={{
          zoom: width < 768 ? undefined : width < 992 ? "0.7" : undefined,
        }}
      >
        <div
          className={`${
            isEnd ? "cursor-pointer border-black" : "border-[#00000026]"
          } m-0 static swiper-button-prev md:w-[50px] w-[35px] md:h-[50px] h-[35px] rounded-[10px] border border-solid flex items-center justify-center`}
          onClick={goPrev}
        >
          <div className="custom-prev-arrow-icon">
            {isEnd ? <ArrowLeftIcon /> : <ArrowLeftDisableIcon />}
          </div>
        </div>
        <div
          className={`${
            !isEnd ? "cursor-pointer border-black" : "border-[#00000026]"
          } m-0 static swiper-button-next md:w-[50px] w-[35px] md:h-[50px] h-[35px] rounded-[10px] border border-solid flex items-center justify-center`}
          onClick={goNext}
        >
          <div className="custom-next-arrow-icon">
            {!isEnd ? <ArrowRightIcon /> : <ArrowRightDisableIcon />}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full flex justify-center lg:pb-[148px] lpSmm:pb-[85px] pb-[54px]">
      <div className="max-w-[1920px] w-full m-auto ">
        <div
          style={{
            float: width <= 1024 ? undefined : isEnd ? "left" : "right",
          }}
          className="brand-slide-comp w-full max-w-[1698px] lpSmm:px-[30px] lg:px-0"
          data-aos="fade-up"
        >
          <Swiper
            pagination={{
              clickable: true,
            }}
            // modules={[Pagination]}
            // loop
            className="mySwiper lg:overflow-visible"
            onReachEnd={() => {
              setIsEnd(true)
            }}
            onReachBeginning={() => {
              setIsEnd(false)
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            onSwiper={(swiper) => setSwiper(swiper)}
            slidesPerView={1}
            autoHeight
            // slidesPerView="auto"
            // spaceBetween = {70}
            breakpoints={{
              1024: {
                slidesPerView: "auto",
                spaceBetween: 50,
                autoHeight: false,
              },
              1280: {
                slidesPerView: "auto",
                spaceBetween: 70,
                autoHeight: false,
              },
            }}
            style={{
              boxShadow:
                width < 1024
                  ? "0px 0px 15px 15px rgba(0, 0, 0, 0.08)"
                  : undefined,
            }}
          >
            <SwiperSlide
              style={{
                maxWidth:
                  width < 1024
                    ? undefined
                    : width < 1280
                    ? 900
                    : width < 1536
                    ? 1000
                    : 1283,
                height:
                  width < 1024
                    ? undefined
                    : width < 1280
                    ? 750
                    : width < 1536
                    ? 800
                    : 850,
                boxShadow:
                  width >= 1024
                    ? "0px 0px 15px 15px rgba(0, 0, 0, 0.08)"
                    : undefined,
              }}
            >
              <div className="w-full h-full relative lg:pt-[65px] lg:pb-0 md:py-[50px] lpSmm:pt-[27px] lpSmm:pb-[48px] pt-[71px] pb-[48px]">
                {!isEnd && renderNavigation()}
                <div className="text-center px-[20px]">
                  <div
                    style={{
                      zoom:
                        width < 768
                          ? undefined
                          : width < 992
                          ? "0.7"
                          : undefined,
                    }}
                  >
                    <h3 className="md:text-[45px] text-[24px] font-montserrat600 leading-[45px] uppercase text-[#111] md:mb-[69px] mb-[18.04px]">
                      RECOMMENDATION
                    </h3>
                    <p className="md:text-[28px] text-[24px] font-spoqa500 leading-normal uppercase text-[#111] md:mb-[21px] mb-[9.02px]">
                      강력한 브랜드 파워
                      <br />
                      모아프렌즈 멤버십이 만듭니다.
                    </p>
                    <p className="md:text-[18px] text-[14px] font-spoqa500 leading-normal uppercase text-[#838383] mb-[65px]">
                      *모아프렌즈 PoC Data 기반 대상 모아프렌즈 BX 제공{" "}
                      {width < 540 && <br />}서비스 참여업체
                    </p>
                  </div>
                  <img
                    className="2xl:px-0 lg:px-[50px] px-0 m-auto"
                    src={width < 540 ? img1Xs : width < 768 ? img1Md : img1}
                    alt="img"
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide
              style={{
                maxWidth:
                  width < 1024
                    ? undefined
                    : width < 1280
                    ? 900
                    : width < 1536
                    ? 1000
                    : 1283,
                height:
                  width < 1024
                    ? undefined
                    : width < 1280
                    ? 750
                    : width < 1536
                    ? 800
                    : 850,
                boxShadow:
                  width >= 1024
                    ? "0px 0px 15px 15px rgba(0, 0, 0, 0.08)"
                    : undefined,
              }}
            >
              <div className="w-full h-full relative lg:pt-[65px] lg:pb-0 md:py-[50px] lpSmm:pt-[27px] lpSmm:pb-[48px] pt-[71px] pb-[48px]">
                {isEnd && renderNavigation()}
                <div className="text-center px-[20px]">
                  <div
                    style={{
                      zoom:
                        width < 768
                          ? undefined
                          : width < 992
                          ? "0.7"
                          : undefined,
                    }}
                  >
                    <h3 className="md:text-[45px] text-[24px] font-montserrat600 leading-[45px] uppercase text-[#111] md:mb-[69px] mb-[18.04px]">
                      RECOMMENDATION
                    </h3>
                    <p className="md:text-[28px] text-[24px] font-spoqa500 leading-normal uppercase text-[#111] md:mb-[21px] mb-[9.02px]">
                      매출 향상 & 비용 절감
                      <br />
                      모아프렌즈 멤버십은 가능합니다.
                    </p>
                    <p className="md:text-[18px] text-[14px] font-spoqa500 leading-normal uppercase text-[#838383] mb-[65px]">
                      *모아프렌즈 PoC Data 기반 대상 모아프렌즈 BX 제공{" "}
                      {width < 540 && <br />}서비스 참여업체
                    </p>
                  </div>
                  <img
                    className="2xl:px-0 lg:px-[50px] px-0 m-auto"
                    src={width < 540 ? img2Xs : width < 768 ? img2Md : img2}
                    alt="img"
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Block4Kr
