import upload from "@assets/images/brand_detail/upload.png"
import Slider from "./components/Slider"

import Post from "./components/Post"
import PostMe from "./components/PostMe"

import { useEffect, useState } from "react"
import ModalBase from "src/components/ModalBase"
import useWindowSize from "src/customHook/useWindowSize"
import ModalPost from "./components/ModalPost"
import ModalReport from "./components/ModalReport"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { UrlInternal } from "src/constant/endpointRoute"
import ModalComment from "./components/ModalComment"
import {
  commentList,
  createPost,
  deletePost,
  editPost,
  getDataPost,
  reportPost,
} from "src/api/communityApi"
import { uploadSingleFile } from "src/api/uploadSingleFile"
import { useAuth } from "src/customHook/useAuth"
import InfiniteScroll from "react-infinite-scroll-component"
import { Spin } from "antd"
import { useAppSelector } from "src/app/hooks"
import { brandDataDetailSelect } from "src/features/brandData/brandDataSlice"
import floating_btn from "@assets/images/brand_detail/floating_fixed.png"
import { dataBrandMappingSelect } from "src/features/dataBrandMapping/dataBrandMappingSlice"
import PostOnlyMembershipView from "./components/PostOnlyMembershipView"

function Community() {
  const [isModalReport, setIsModalReport] = useState<boolean>(false)
  const [isStatusModalReport, setIsStatusModalReport] = useState<boolean>(false)
  const [isModalPost, setIsModalPost] = useState<boolean>(false)
  const [isModalDeletePost, setIsModalDeletePost] = useState<boolean>(false)
  const [idDeletePost, setIdDeletePost] = useState<number>()
  const [isModalComment, setIsModalComment] = useState({
    isVisible: false,
    data: {},
  })
  const { width }: any = useWindowSize()
  const isMobile = width <= 475
  const navigate = useNavigate()
  // const params = useParams()
  // const { id, brandName } = params
  const { isLogined, user } = useAuth()
  const { pathname } = useLocation()
  const [dataSliderPost, setDataSliderPost] = useState([])
  const [dataPost, setDataPost] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageable, setPageable] = useState<any>({})
  const [dataItemReport, setDataItemReport] = useState<any>({})
  const [dataItemEditPost, setDataItemEditPost] = useState<any>({})

  const [searchParams, setSearchParams] = useSearchParams()
  const commentID = searchParams.get("commentID")
  const postID = searchParams.get("postID")
  const widthBrandSite =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  const dataBrandDetail = useAppSelector(brandDataDetailSelect)
  const dataBrandMapping: any = useAppSelector(dataBrandMappingSelect)
  const brandName = dataBrandMapping?.userName
  const id = dataBrandMapping?.brandId
  const checkTypeLock =
    dataBrandDetail?.onlyMembershipView &&
    !dataBrandDetail?.isMine &&
    !dataBrandDetail?.subscriberId

  console.log("dataBrandDetail", dataBrandDetail)

  /**data brand site -> user site */
  const getDetailPostBrandSite = async (postID: number) => {
    try {
      const resp: any = await getDataPost({
        page: 1,
        size: 1,
        id: postID,
      })
      if (resp.result) {
        setIsModalComment({
          isVisible: true,
          data: { ...resp.data.datas[0], commentIdBrandSite: commentID },
        })
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (commentID || postID) {
      if (widthBrandSite <= 475) {
        // if (commentID) {
        //   navigate(
        //     `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_COMMENT}/${postID}?commentID=${commentID}`,
        //   )
        // } else {
        //   navigate(
        //     `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_COMMENT}/${postID}`,
        //   )
        // }
      } else {
        getDetailPostBrandSite(Number(postID))
      }
    }
  }, [commentID, postID])

  const getDataSliderCommunity = async () => {
    try {
      const resp: any = await getDataPost({
        brandId: Number(id),
        page: 1,
        size: 12,
        postOfBrand: 1,
      })
      if (resp.result) {
        setDataSliderPost(resp.data.datas)
      } else {
        setDataSliderPost([])
      }
    } catch (error) {
      setDataSliderPost([])
    }
  }

  const getDataCommunity = async (isLoadmore?: boolean, page?: number) => {
    try {
      const resp: any = await getDataPost({
        brandId: Number(id),
        page: page ?? 1,
        size: 12,
      })
      if (resp.result) {
        setDataPost(resp.data.datas)
        setPageable(resp.data.pageable)

        if (isLoadmore) {
          setDataPost([...dataPost, ...resp.data.datas])
        }
      } else {
        console.log("resp error else", resp)

        setDataPost([])
        setPageable({})
      }
    } catch (error) {
      console.log("error catch", error)

      setDataPost([])
      setPageable({})
    }
  }

  useEffect(() => {
    getDataSliderCommunity()
    getDataCommunity()
  }, [])

  const onSubmitPost = async (data: any) => {
    setIsLoading(true)
    try {
      const dataMedias = []
      if (data?.medias?.length > 0) {
        for (let i = 0; i < data.medias.length; i++) {
          if (data?.medias[i]?.idPreview) {
            dataMedias.push({
              mediaType: "IMAGE",
              url: data.medias[i].src,
            })
          } else {
            const formData = new FormData()
            formData.append("file", data.medias[i].originFileObj)
            const respUpload: any = await uploadSingleFile(formData)
            if (respUpload) {
              dataMedias.push({
                mediaType: "IMAGE",
                url: respUpload.imageUrl,
              })
            }
          }
        }
      }

      const resp: any = data?.isEdit
        ? await editPost({
            content: data.content,
            onlyMembership: data.onlyMembership ?? false,
            id: data.postId,
            medias: dataMedias,
          })
        : await createPost({
            content: data.content,
            onlyMembership: data.onlyMembership,
            targetBrandId: Number(id),
            medias: dataMedias,
          })
      if (resp.result) {
        setIsLoading(false)
        setIsModalPost(false)

        getDataSliderCommunity()
        getDataCommunity()
      }
    } catch (error) {
      setIsLoading(false)
      setIsModalPost(false)
    }
  }

  const handleSubmitReport = async (data: number) => {
    try {
      const resp: any = await reportPost({
        refId: dataItemReport?.commentId ?? dataItemReport?.postId,
        content: `CONTENT_${data}`,
        type: dataItemReport?.commentId ? "COMMENT" : "C_POST",
      })
      if (resp.result) {
        setIsStatusModalReport(true)
        setIsModalReport(false)

        getDataCommunity()
      } else {
        setIsModalReport(false)
      }
    } catch (error) {
      setIsModalReport(false)
    }
  }

  const handleDeletePost = async () => {
    try {
      const resp: any = await deletePost({
        id: idDeletePost ?? 0,
      })
      if (resp.result) {
        setIsModalDeletePost(false)

        getDataSliderCommunity()
        getDataCommunity()
      }
    } catch (error) {
      setIsModalDeletePost(false)
    }
  }

  const handleLoadMore = () => {
    setTimeout(() => {
      getDataCommunity(true, pageable.page + 1)
    }, 1500)
  }

  const handleCardShowComment = async (data: any) => {
    if (isMobile) {
      navigate(
        `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_COMMENT}/${data?.postId}`,
      )
    } else {
      const resp: any = await getDataPost({
        page: 1,
        size: 1,
        id: data?.postId,
        // brandId: data?.targetBrandId,
      })

      if (resp.result) {
        setIsModalComment({
          isVisible: true,
          data: resp.data.datas[0],
        })
      }
    }
  }

  const handleCancelModalComment = async () => {
    setDataPost([])
    setPageable({})
    await getDataCommunity()
    setIsModalComment({
      isVisible: false,
      data: {},
    })
  }

  return (
    <>
      {/**modal comment */}
      <ModalComment
        isVisible={isModalComment.isVisible}
        setCancel={() => {
          handleCancelModalComment()
        }}
        data={isModalComment.data}
        onClickReportCommentID={(data) => {
          setIsModalComment({
            isVisible: false,
            data: {},
          })

          setIsModalReport(true)
          setDataItemReport(data)
        }}
      />

      {/**modal report */}
      <ModalReport
        isVisible={isModalReport}
        setCancel={() => setIsModalReport(false)}
        onSubmit={(e) => {
          handleSubmitReport(e)
        }}
      />

      {/* modal success report */}
      <ModalBase
        isVisible={isStatusModalReport}
        setCancel={() => setIsStatusModalReport(false)}
        className="sm:max-w-[380px] max-w-[100%]"
      >
        <div className="text-center mb-[60px]">
          <div>신고 접수가 완료되었습니다. 운영팀에서</div>
          <div>확인 후 처리하겠습니다.</div>
        </div>

        <div
          className="absolute bottom-0 w-full bg-[#000] text-[#fff] mx-[-16px] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
          onClick={() => setIsStatusModalReport(false)}
        >
          확인
        </div>
      </ModalBase>

      {/* modal post */}
      <ModalPost
        isVisible={isModalPost}
        setCancel={() => setIsModalPost(false)}
        onSubmit={(e) => onSubmitPost(e)}
        isLoading={isLoading}
        dataEdit={dataItemEditPost}
      />

      {/**delete post me */}
      <ModalBase
        isVisible={isModalDeletePost}
        setCancel={() => setIsModalDeletePost(false)}
        className="sm:max-w-[380px] max-w-[100%]"
      >
        <div className="text-center mb-[30px]">삭제하시겠습니까?</div>

        <div className="flex gap-[10px]">
          <div
            className="w-[50%] bg-[#fff] text-[#000] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer border border-solid border-[#000]"
            onClick={() => setIsModalDeletePost(false)}
          >
            취소
          </div>
          <div
            className="w-[50%] bg-[#000] text-[#fff] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
            onClick={handleDeletePost}
          >
            확인
          </div>
        </div>
      </ModalBase>

      {dataBrandDetail?.enableCommunity != 0 && (
        <div className={`pt-[10px] ${dataSliderPost.length <= 0 && "pt-0"} `}>
          <Slider
            data={dataSliderPost}
            setIsModalComment={(data) => {
              handleCardShowComment(data)
            }}
          />

          {isLogined && !checkTypeLock ? (
            <div
              className={`border border-dashed border-[#D3D3D3] sm:p-[20px] sm:py-[16px] py-[8px] px-[16px] sm:mt-[75px] mt-[40px] sm:mb-[42px] mb-[30px] flex justify-between items-center cursor-pointer ${
                dataSliderPost.length <= 0 && "!mt-0"
              }`}
              onClick={() =>
                !isLogined
                  ? navigate({
                      pathname: "/auth",
                      search: `backUrl=${pathname}?tab=community`,
                    })
                  : isMobile
                  ? navigate(
                      `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_POST}`,
                      {
                        state: {
                          id,
                        },
                      },
                    )
                  : setIsModalPost(true)
              }
            >
              <div className="flex items-center gap-[20px]">
                <img
                  src={dataBrandDetail?.avatar}
                  alt="logo"
                  className="w-[56px] h-[56px] aspect-square rounded-[50%] sm:inline hidden"
                />
                <div className="text-[#ACACAC] text-[18px] font-pretendard600">
                  {isMobile
                    ? "자유롭게 포스트를 남겨보세요."
                    : "커뮤니티에 자유롭게 포스트를 남겨보세요."}
                </div>
              </div>
              <div>
                <img
                  src={upload}
                  alt="icon"
                  className="sm:max-w-[100%] max-w-[32px]"
                />
              </div>
            </div>
          ) : (
            <div className="h-[50px]" />
          )}
          <InfiniteScroll
            dataLength={dataPost?.length}
            next={handleLoadMore}
            hasMore={pageable?.totalElement > dataPost?.length}
            loader={
              <div className="flex justify-center items-center py-[30px] overflow-hidden">
                <Spin tip="Loading" size="small" />
              </div>
            }
            style={{ overflow: "unset" }}
          >
            <div className="flex flex-col sm:gap-[64px] gap-[35px]">
              {dataPost && dataPost?.length > 0 ? (
                dataPost?.map((dt: any, index) => (
                  <div key={index}>
                    {checkTypeLock ? (
                      <PostOnlyMembershipView />
                    ) : dt?.creatorId === user?.id ? (
                      <PostMe
                        setIsEditPost={(data) => {
                          setIsModalPost(true)
                          setDataItemEditPost(data)
                        }}
                        onSubmitDeletePost={(id: number) => {
                          setIsModalDeletePost(true)
                          setIdDeletePost(id)
                        }}
                        setIsModalComment={(data) =>
                          handleCardShowComment(data)
                        }
                        data={dt}
                      />
                    ) : (
                      <Post
                        setIsModalReport={(data) => {
                          setIsModalReport((prev) => !prev)
                          setDataItemReport(data)
                        }}
                        setIsModalComment={(data) =>
                          handleCardShowComment(data)
                        }
                        data={dt}
                        onSubmitDeletePost={(id: number) => {
                          setIsModalDeletePost(true)
                          setIdDeletePost(id)
                        }}
                      />
                    )}
                  </div>
                ))
              ) : (
                <div className="mt-[200px] text-center text-[18px] font-pretendard500">
                  {/* 멤버십 전용 커뮤니티 게시판입니다. 이용하시려면 멤버십에
                  가입해 주세요. */}
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      )}
      {isMobile && isLogined && (
        <div
          className="fixed bottom-[134px] right-[16px] bg-[#000] w-[52px] h-[52px] rounded-[50%] flex justify-center items-center z-50"
          onClick={() =>
            navigate(
              `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_POST}`,
              {
                state: {
                  id,
                },
              },
            )
          }
        >
          <img src={floating_btn} alt="floating_btn" />
        </div>
      )}
    </>
  )
}

export default Community
