import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { orderApi } from "src/api/orderApi"
import Pagination from "src/components/Pagination"
import SelectCustom from "src/components/SelectCustom"
import useIsMobile from "src/customHook/useIsMobile"
import { Order } from "src/models/order"
import OrderHistoryTable from "./OrderHistoryTable"
import "./style.scss"
import { useTranslation } from "react-i18next"
import Button from "src/components/Button"
import dayjs from "dayjs"
import { MoneyConvert } from "src/util/format"
import NoData from "src/components/NoData"

function OrderHistory() {
  const { isMobile } = useIsMobile(768)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [searchParam, setSearchParams] = useSearchParams()

  const allTime = [
    // { value: "", label: t("All") },
    { value: "1M", label: "1개월" },
    { value: "3M", label: "3개월" },
    { value: "6M", label: "6개월" },
    { value: "1Y", label: "1년" },
  ]

  const [orderList, setOrderList] = useState<Order[]>([])
  const [totalOrder, setTotalOrder] = useState<number>(0)
  const query = {
    page: searchParam.get("page") || 1,
    querySearch: searchParam.get("querySearch") || "",
    status: searchParam.get("status") || "",
    timeFilter: searchParam.get("timeFilter") || "1Y",
    totalValueSort: searchParam.get("totalValueSort") || "",
    usageValueSort: searchParam.get("usageValueSort") || "",
  }
  const getListOrder = async () => {
    try {
      const { data, result }: any = await orderApi.getListOrder(query)
      if (!!result) {
        setOrderList(data.datas)
        setTotalOrder(Number(data.pageable.totalElement))
      } else {
        setOrderList([])
      }
    } catch (error) {
      setOrderList([])
      setTotalOrder(0)
    }
  }

  const convertStatus = (status?: string) => {
    switch (status) {
      case "PAID":
        return t("Paid")
      case "OWNED":
        return t("Owned")
      case "REQUESTED":
        return t("Requested")
      case "REFUNDED":
        return t("Refunded")
      default:
        break
    }
  }

  useEffect(() => {
    getListOrder()
  }, [searchParam])

  return (
    <div className="">
      <div className="relative z-10">
        <div className="lg:gap-[20px] gap-[16px]">
          <div className="flex justify-between items-center font-pretendard700 text-[24px] leading-[100%] pb-[20px] md:border-b border-b-primary">
            <span className="hidden md:block">{t("주문 내역")}</span>
            <div className="flex">
              <SelectCustom
                placeholder={t("All status")}
                className="lg:w-full max-w-[124px] mr-[16px]"
                value={query.status || ""}
                options={[
                  { value: "", label: t("All status") },
                  { value: "PAID", label: t("Paid") },
                  { value: "OWNED", label: t("Owned") },
                  { value: "REQUESTED", label: t("Requested") },
                  { value: "REFUNDED", label: t("Refunded") },
                ]}
                onChange={(e) => {
                  searchParam.set("status", e)
                  searchParam.set("page", "1")
                  setSearchParams(searchParam)
                }}
              />
              <SelectCustom
                className="lg:w-full w-[124px] min-w-[96px]"
                placeholder="Category"
                value={query.timeFilter}
                options={allTime}
                onChange={(e) => {
                  searchParam.set("timeFilter", e)
                  searchParam.set("page", "1")
                  setSearchParams(searchParam)
                }}
              />
            </div>
          </div>

          {!isMobile && (
            <OrderHistoryTable
              data={orderList}
              onSortChange={(e) => {
                setSearchParams((prev) => ({ ...prev, ...e }))
              }}
            />
          )}
          {isMobile &&
            (orderList.length > 0 ? (
              orderList?.map((item, index) => (
                <div key={index}>
                  <div className="bg-gray3 py-[8px] px-[16px] mx-[-16px]">
                    <span className="font-pretendard700 text-[14px] leading-[14px] text-primary">
                      {dayjs(item?.createdTime).format("YYYY.MM.DD")}
                    </span>
                    <span className="font-pretendard400 text-[14px] leading-[14px] text-primary mx-[8px]">
                      |
                    </span>
                    <span className="font-pretendard700 text-[14px] leading-[14px] text-primary">
                      {item?.id}
                    </span>
                  </div>
                  <div
                    className="my-[40px] flex justify-between items-start"
                    onClick={() => navigate(`/my-page/order-detail/${item.id}`)}
                  >
                    <div className="flex md:flex-row md:justify-normal">
                      <div className="mr-[13px]">
                        <div className="border border-solid w-[64px] h-[64px] flex justify-center items-center text-primaryGreen font-pretendard700">
                          <img
                            className="w-[64px] h-[64px] object-cover aspect-square"
                            src={item?.voucherImage}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="block">
                        <p className="text-[14px] line-clamp-1 font-pretendard700 leading-none mb-[12px]">
                          {item?.brandName}
                        </p>
                        <p className="text-[12px] line-clamp-1 md:text-[18px] font-pretendard400 text-primary mb-[8px] leading-none">
                          {item?.voucherName}
                        </p>
                        <span className="text-[14px] font-pretendard700 leading-none">
                          {MoneyConvert(Number(item?.totalPayment || 0))}원{" "}
                          <span className="font-pretendard400">
                            / {MoneyConvert(Number(item?.quantity || 0))}개
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-end text-gray9">
                      <Button
                        className={`text-[14px] font-pretendard700 capitalize min-w-[50px] ${
                          item.status === "PAID" ? "text-primaryGreen" : ""
                        } ${
                          item.status === "REQUESTED" ? "text-[#C70039]" : ""
                        }`}
                      >
                        {convertStatus(item.status)}
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="pt-[30px]">
                <NoData />
              </div>
            ))}

          {orderList?.length > 0 && (
            <div className="mt-[32px]">
              <Pagination
                showSizeChanger={false}
                total={totalOrder}
                pageSize={10}
                current={Number(query.page) || 1}
                onChange={(data) => {
                  searchParam.set("page", data.toString())
                  setSearchParams(searchParam)
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderHistory
