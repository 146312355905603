import img1 from "src/assets/imgs/block3-img-1.png"
import img2 from "src/assets/imgs/block3-img-2.png"
import img3 from "src/assets/imgs/block3-img-3.png"
import logo from "src/assets/imgs/moafriends_logo_medium.png"

import React, { useEffect, useRef } from "react"
import "./style.scss"
import useWindowSize from "src/util/hook/useWindowSize"

interface Block3Props {}
const Block3Kr: React.FC<Block3Props> = () => {
  const { width }: any = useWindowSize()
  // console.log('height: ' + height)

  const wrapperTopRef = useRef<any>()
  const textTopRef = useRef<any>()

  useEffect(
    function () {
      if (width >= 992) {
        const wrapperTopElement = wrapperTopRef.current
        const textTopRefEl = textTopRef.current

        const text1El: any = document.getElementById("step-1")
        const text2El: any = document.getElementById("step-2")
        const text3El: any = document.getElementById("step-3")

        document.addEventListener("aos:in", ({ detail }) => {
          if (detail.id === "img-1") {
            text2El?.classList.remove("active")
            text3El?.classList.remove("active")
            text1El?.classList?.add("active")
          } else if (detail.id === "img-2") {
            text1El?.classList.remove("active")
            text3El?.classList.remove("active")
            text2El?.classList?.add("active")
          } else if (detail.id === "img-3") {
            text1El?.classList.remove("active")
            text2El?.classList.remove("active")
            text3El?.classList?.add("active")
          }
          // detail.classList.add('active')
          // const
        })

        document.addEventListener("aos:out", ({ detail }) => {
          // console.log('animated out', detail.id)
          if (detail.id === "img-3") {
            text1El?.classList.remove("active")
            text3El?.classList.remove("active")
            text2El?.classList.add("active")
          } else if (detail.id === "img-2") {
            text2El?.classList.remove("active")
            text3El?.classList.remove("active")
            text1El?.classList.add("active")
          } else if (detail.id === "img-1") {
            text2El?.classList.remove("active")
            text3El?.classList.remove("active")
            text1El?.classList.remove("active")
          }
        })

        const handleScroll = () => {
          const topWrapPoisition =
            wrapperTopElement.getBoundingClientRect().top + window.scrollY
          const bottomWrapPosition =
            wrapperTopElement.getBoundingClientRect().bottom + window.scrollY
          const positionHidden = bottomWrapPosition - 700

          if (wrapperTopElement && textTopRefEl) {
            if (
              window.scrollY >= topWrapPoisition &&
              window.scrollY < positionHidden
            ) {
              textTopRefEl.style.position = "fixed"
            } else {
              textTopRefEl.style.position = "absolute"
            }
          }
        }

        window.addEventListener("scroll", handleScroll)
        return () => {
          window.removeEventListener("scroll", handleScroll)
        }
      }
    },
    [width],
  )

  return (
    // <div className="absolute" ref={wrapperTopRef}>
    <div
      className="w-full max-w-[1480px] m-auto md:px-[24px] lpSmm:pl-[26px] lpSmm:pr-[12px] relative"
      ref={wrapperTopRef}
    >
      {/* <div className="relative"> */}
      {width >= 992 && (
        <div className="wrap-image 2xl:max-w-[910px] xl:max-w-[660px] max-w-[560px]">
          <div
            id="img-1"
            // ref={step1Ref}
            data-aos="fade-up"
            data-aos-offset="400"
            className="flex flex-col items-center justify-center h-[100vh] max-h-[960px] flex items-center justify-center pt-[131px]"
          >
            <img src={img1} alt="img" />
          </div>
          <div
            id="img-2"
            // ref={step2Ref}
            data-aos="fade-up"
            data-aos-offset="400"
            className="flex flex-col items-center justify-center h-[100vh] max-h-[960px] flex items-center justify-center"
          >
            <img src={img2} alt="img" />
          </div>
          <div
            id="img-3"
            // ref={step3Ref}
            data-aos="fade-up"
            data-aos-offset="400"
            className="flex flex-col items-center justify-center h-[100vh] max-h-[960px] flex items-center justify-center pb-[156px]"
          >
            <img src={img3} alt="img" />
          </div>
        </div>
      )}
      <div
        ref={textTopRef}
        className={`${
          width < 768 ? "flex justify-center" : ""
        } wrap-text 2md:absolute w-full 2md:max-w-[1480px] 2md:h-[100vh] 2md:top-0 2md:left-0 2md:right-0 2md:m-auto 2md:px-[24px] 2md:z-[1000]`}
      >
        <div
          className="2md:absolute 2md:pl-[64px] 2md:top-1/2 2md:transform 2md:translate-y-[-50%] 2md:right-[24px] 2xl:w-[calc(100%-958px)] xl:w-[calc(100%-708px)] 2md:w-[calc(100%-754px)]"
          style={{
            zoom:
              width < 768
                ? undefined
                : width < 992
                ? "0.7"
                : width < 1280
                ? "0.8"
                : undefined,
          }}
        >
          {width >= 992 && (
            <div data-aos="fade-up">
              <img src={logo} alt="logo" />
              <p className="text-[26px] leading-[46px] text-[#111] font-montserrat400">
                Details of our services
              </p>
            </div>
          )}
          <div
            id="step-1"
            className={`text-step xl:mt-[100px] 2md:mt-[50px] items-center md:py-[75px] lpSmm:pt-[50px] lpSmm:pb-[32.55px] pt-[75px] pb-[64px] 2md:py-0`}
            style={{
              display:
                width < 540 ? undefined : width < 992 ? "flex" : undefined,
            }}
            data-aos="fade-up"
          >
            {width < 992 && (
              <div className="md:w-[55%] lpSmm:w-[300px] w-full pr-[16px] lpSmm:pr-0">
                <img className="w-[360px] lpSmm:w-auto" src={img1} alt="img" />
              </div>
            )}
            <div className="md:w-[45%] lpSmm:w-[calc(100%-300px)] w-full 2md:w-auto md:pl-[50px] lpSmm:pl-[20px] 2md:pl-0 pl-[50px] mt-0 lpSmm:mt-[-13px] md:mt-0">
              {width < 992 && (
                <div className="lpSmm:mb-[40px] mb-[18.74px]">
                  <img
                    className="md:max-w-none max-w-[130.12px]"
                    src={logo}
                    alt="logo"
                  />
                  <p className="md:text-[26px] text-[12px] md:leading-[46px] leading-normal text-[#111] font-montserrat400">
                    Details of our services
                  </p>
                </div>
              )}
              <div className="relative md:mb-[24px] mb-[10px]">
                <h3 className="md:text-[60px] lpSmm:text-[22px] text-[34px] relative z-[1] leading-normal text-[#111] font-montserrat600 uppercase">
                  MARKETING
                </h3>
                <div className="md:w-[165px] w-[67px] md:h-[24px] h-[12px] block bg-[#DFFFF9] absolute md:bottom-[18px] bottom-[4px] md:left-[-20px] left-[-3px]" />
              </div>
              <p className="md:text-[26px] lpSmm:text-[14px] text-[22px] md:leading-[40px] lpSmm:leading-[20px] leading-[41.45px] text-[#7B7B7B]">
                단골은 최고의 바이럴 마케터
                <br />
                브랜드를 사랑하는 충성고객
                <br />
                먼저 찾는 리더 브랜드로 도약
              </p>
            </div>
          </div>
          <div
            id="step-2"
            className={`text-step xl:mt-[100px] 2md:mt-[50px] items-center md:pb-[75px] lpSmm:pb-0 pb-[69px] 2md:pb-0`}
            style={{
              display:
                width < 540 ? undefined : width < 992 ? "flex" : undefined,
            }}
            data-aos="fade-up"
          >
            {width < 992 && (
              <div className="md:w-[55%] lpSmm:w-[300px] w-full pr-[16px] lpSmm:pr-0">
                <img className="w-[360px] lpSmm:w-auto" src={img2} alt="img" />
              </div>
            )}
            <div className="md:w-[45%] lpSmm:w-[calc(100%-300px)] w-full 2md:w-auto md:pl-[50px] lpSmm:pl-[20px] 2md:pl-0 pl-[50px] mt-0 lpSmm:mt-[-32px] md:mt-0">
              {width < 992 && (
                <div className="lpSmm:mb-[40px] mb-[18.74px]">
                  <img
                    className="md:max-w-none max-w-[130.12px]"
                    src={logo}
                    alt="logo"
                  />
                  <p className="md:text-[26px] text-[12px] md:leading-[46px] leading-normal text-[#111] font-montserrat400">
                    Details of our services
                  </p>
                </div>
              )}
              <div className="relative md:mb-[24px] mb-[10px]">
                <h3 className="md:text-[60px] lpSmm:text-[22px] text-[34px] relative z-[1] leading-normal text-[#111] font-montserrat600 uppercase">
                  Opportunity
                </h3>
                <div className="md:w-[165px] w-[67px] md:h-[24px] h-[12px] block bg-[#DFFFF9] absolute md:bottom-[18px] bottom-[4px] md:left-[-20px] left-[-3px]" />
              </div>
              <p className="md:text-[26px] lpSmm:text-[14px] text-[22px] md:leading-[40px] lpSmm:leading-[20px] leading-[41.45px] text-[#7B7B7B]">
                우리가 어떤 친구입니까
                <br />
                브랜드 성장의 기회
                <br />
                프렌즈 멤버십
              </p>
            </div>
          </div>
          <div
            id="step-3"
            className={`text-step xl:mt-[100px] 2md:mt-[50px] items-center md:pb-[75px] lpSmm:pb-[66px] pb-[74px] 2md:pb-0 md:pl-0 lpSmm:pl-[11px]`}
            style={{
              display:
                width < 540 ? undefined : width < 992 ? "flex" : undefined,
            }}
            data-aos="fade-up"
          >
            {width < 992 && (
              <div className="md:w-[55%] lpSmm:w-[300px] w-full pr-[5px] lpSmm:pr-0">
                <img
                  className="w-[360px] lpSmm:w-auto pl-[15px] lpSmm:pl-0"
                  src={img3}
                  alt="img"
                />
              </div>
            )}
            <div className="md:w-[45%] lpSmm:w-[calc(100%-300px)] w-full 2md:w-auto md:pl-[50px] lpSmm:pl-[6px] 2md:pl-0 pl-[50px]">
              {width < 992 && (
                <div className="lpSmm:mb-[40px] mb-[18.74px]">
                  <img
                    className="md:max-w-none max-w-[130.12px]"
                    src={logo}
                    alt="logo"
                  />
                  <p className="md:text-[26px] text-[12px] md:leading-[46px] leading-normal text-[#111] font-montserrat400">
                    Details of our services
                  </p>
                </div>
              )}
              <div className="relative md:mb-[24px] mb-[10px]">
                <h3 className="md:text-[60px] lpSmm:text-[22px] text-[34px] relative z-[1] leading-normal text-[#111] font-montserrat600 uppercase">
                  ADVENTURE
                </h3>
                <div className="md:w-[165px] w-[67px] md:h-[24px] h-[12px] block bg-[#DFFFF9] absolute md:bottom-[18px] bottom-[4px] md:left-[-20px] left-[-3px]" />
              </div>
              <p className="md:text-[26px] lpSmm:text-[14px] text-[22px] md:leading-[40px] lpSmm:leading-[20px] leading-[41.45px] text-[#7B7B7B]">
                모아프렌즈도, 고객도, 브랜드도 {width < 540 && <br />}승선완료!
                <br />
                모두 프렌즈가 되어 함께 떠나는
                <br />
                흥미진진한 브랜드 성장 여정
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
    // </div>
  )
}

export default Block3Kr
