import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "src/app/store"
type TRANSACTION_STATUS =
  | "VERIFY"
  | "SUCCESS"
  | "NOT_ENOUGH"
  | "EXPIRED"
  | "REQUEST_USING_VOUCHER_COUPON"
  | "SUCCESS_VOUCHER_COUPON"
  | null
export interface ModalState {
  status: TRANSACTION_STATUS
  data: {
    trxUID?: string
    branchNote?: string
    expiredAt?: string
    amount?: number
    brandId?: number
    branchId?: number
    time?: string
    sender?: string
    receiver?: string
    message?: string
    brandName?: string
    name?: string
    branchInformation?: {
      lat: string
      long: string
      address: string
      name: string
      brandName: string
    }
  } | null
}

const initialState: ModalState = {
  status: null,
  data: {},
}

export const confirmModalSlice = createSlice({
  name: "confirmModal",
  initialState,
  reducers: {
    openConfirmTransaction: (state, action) => {
      state.status = "VERIFY"
      state.data = action.payload
    },
    openVerifyOrder: (state, action) => {
      state.status = "REQUEST_USING_VOUCHER_COUPON"
      state.data = action.payload
    },
    openNotEnough: (state, action) => {
      state.status = "NOT_ENOUGH"
      state.data = action.payload
    },
    successTransaction: (state, action) => {
      state.status = "SUCCESS"
      // console.log(action.payload)

      state.data = { ...state.data, ...action.payload }
    },
    successTransactionVoucherCoupon: (state, action) => {
      state.status = "SUCCESS_VOUCHER_COUPON"
      // console.log(action.payload)

      state.data = { ...state.data, ...action.payload }
    },
    completeTransaction: (state) => {
      state.status = null
    },
    expiredTransaction: (state) => {
      // console.log('state', state);

      state.status = "EXPIRED"
    },
  },
})

export const {
  openConfirmTransaction,
  openNotEnough,
  successTransaction,
  completeTransaction,
  expiredTransaction,
  openVerifyOrder,
  successTransactionVoucherCoupon,
} = confirmModalSlice.actions

export const statusSelect = (state: RootState) => state.confirmModal.status

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export default confirmModalSlice.reducer
