import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import ImgHeart from "@assets/images/mymembership/heart_check.png"
import { UrlInternal } from "src/constant/endpointRoute"
import moment from "moment"

export type DealProps = {
  key?: number
  item: any
  activeTab?: number
}

function MyPageCard({ key, item, activeTab }: DealProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  console.log("activeTab", activeTab)

  const convertTime = (time?: any) => {
    return dayjs(time).format("YYYY-MM-DD")
  }

  const handleType = (type: string) => {
    switch (type) {
      case "MEMBERSHIP":
        return (
          <>
            <div className="font-pretendard400 text-[12px] xs:text-[14px] text-[#539165] p-[4px] border border-solid border-[#539165] leading-none mr-[6px] hidden items-center gap-[4px] sm:flex">
              {t("맴버십")}
              <img
                src={ImgHeart}
                alt="membership"
                className="hidden sm:inline"
              />
            </div>
            <img src={ImgHeart} alt="membership" className="sm:hidden" />
          </>
        )
      case "VIP":
        return (
          <div className="font-pretendard400 text-[12px] xs:text-[14px] text-[#c70039] p-[4px] border border-solid border-[#c70039] leading-none mr-[6px]">
            {t("VIP")}
          </div>
        )
      case "BOTH":
        return (
          <>
            <div className="font-pretendard400 text-[12px] xs:text-[14px] text-[#c70039] p-[4px] border border-solid border-[#c70039] leading-none mr-[6px]">
              {t("VIP")}
            </div>
            <>
              <div className="font-pretendard400 text-[12px] xs:text-[14px] text-[#539165] p-[4px] border border-solid border-[#539165] leading-none mr-[6px] hidden items-center gap-[4px] sm:flex">
                {t("맴버십")}
                <img
                  src={ImgHeart}
                  alt="membership"
                  className="hidden sm:inline"
                />
              </div>
              <img src={ImgHeart} alt="membership" className="sm:hidden" />
            </>
          </>
        )
      default:
        return ""
    }
  }

  return (
    <div key={key} className="flex-1 flex flex-col pb-[32px]">
      <div className="relative overflow-hidden border border-solid aspect-square flex justify-center items-center text-primary font-pretendard700 text-[18px] md:text-[24px]">
        {item?.logoImage ? (
          <img
            src={item?.logoImage}
            alt=""
            className="object-cover w-full h-full aspect-square"
            onClick={() =>
              navigate(
                `${UrlInternal.MY_MEMBERSHIP}/${
                  activeTab === 1 ? item?.id : item?.brandId
                }`,
                {
                  state: { activeTab },
                },
              )
            }
          />
        ) : (
          item?.brandName
        )}
      </div>
      <div className="mt-[28px]">
        <div className="flex items-center gap-[15px]">
          {/* {isSoldOut && <SoldOut />} */}
          <p
            className="font-pretendard700 text-[14px] xs:text-[16px] text-primary line-clamp-2 leading-none"
            onClick={() =>
              navigate(
                `${UrlInternal.MY_MEMBERSHIP}/${item?.brandId ?? item?.id}`,
                {
                  state: { activeTab },
                },
              )
            }
          >
            {item?.brandName}
          </p>
        </div>

        <div className="flex justify-between items-center mt-[16px] xs:mt-[12px]">
          {item?.type && <div>{handleType(item?.type)}</div>}
          {item?.expiredTime && (
            <div className="flex items-center">
              <p className="font-pretendard400 text-[14px] xs:text-[16px] text-primary leading-none">
                {moment(item?.expiredTime)
                  .subtract(1, "minutes")
                  .diff(moment(), "days") > 0
                  ? `D-${moment(item?.expiredTime)
                      .subtract(1, "minutes")
                      .diff(moment(), "days")}`
                  : "오늘까지"}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MyPageCard
