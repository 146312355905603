import { useTranslation } from "react-i18next"
import { Outlet, useNavigate } from "react-router-dom"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"

function BrandShop() {
  const navigate = useNavigate()
  const { isMobile } = useIsMobile(1023)
  const { t } = useTranslation()

  return (
    <div className="pb-[100px] lg:pt-[60px]">
      {isMobile && <NavBarMb pageName={"MOAFRIENDS"} />}
      <div className="z-10">
        <div className="container md:px-[16px]">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default BrandShop
