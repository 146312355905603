import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const getAllCategoryRequest = async (): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.CATEGORIES,
    method: "GET",
  })
}
