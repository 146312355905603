import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { transactionApi } from "src/api/myBalance"
import Pagination from "src/components/Pagination"
import SelectCustom from "src/components/SelectCustom"
import CsTable from "src/components/Table"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import {
  filterBrand,
  getBrandFilter,
  getBrandHistoryMembership,
} from "src/services/Brand"
import { MoneyConvert } from "src/util/format"
import { formatPrice } from "src/util/formatPrice"
import SearchIcon from "@assets/images/home/icon_search.png"
import { UrlInternal } from "src/constant/endpointRoute"
import "./style.scss"
import { getListEContent } from "src/api/myEContentApi"

const TIME_NUMBER: any = { "1M": 1, "3M": 3, "6M": 6, "1Y": 12 }

function MyEContent() {
  const { t } = useTranslation()
  const { state } = useLocation()
  const [queryTrans, setQueryTrans] = useState<{
    page: number
    size: number
    querySearch: string
    brandId: number
  }>({
    page: 1,
    size: 10,
    querySearch: "",
    brandId: state?.brandId ?? "",
  })
  const [pageable, setPageable] = useState<any>({})
  const { isMobile } = useIsMobile(768)
  const [transactionHistory, setTransactionHistory] = useState<any>([])
  const navigate = useNavigate()
  const [dataListBrand, setDataListBrand] = useState<any>([])
  const [querySearchChange, setQuerySearchChange] = useState("")

  const getDataBrand = async () => {
    try {
      const { data }: any = await getBrandFilter({
        querySearch: "",
        filter: [],
        page: 1,
        size: 999999,
      })
      if (data) {
        let dataResp = [
          {
            value: "",
            label: t("All"),
          },
        ]

        for (let i = 0; i < data.datas.length; i++) {
          dataResp.push({
            value: data.datas[i].id,
            label: data.datas[i].brandName,
          })
        }

        setDataListBrand(dataResp)
      } else {
        setDataListBrand([])
      }
    } catch (error) {
      setDataListBrand([])
    }
  }

  useEffect(() => {
    getDataBrand()
  }, [])

  const handleGetTransactionHistory = async (
    isLoadmore?: boolean,
    page?: number,
  ) => {
    try {
      let params = {
        ...queryTrans,
        page: page ?? queryTrans.page,
      }
      const { data }: any = await getListEContent(params)
      if (data) {
        let dataResp = data.datas
        if (isLoadmore) {
          setTransactionHistory([...transactionHistory, ...dataResp])
        } else {
          setTransactionHistory(dataResp)
        }
        setPageable(data.pageable)
      }
    } catch (error) {
      setTransactionHistory({})
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetTransactionHistory()
  }, [queryTrans])

  const columns2: ColumnsType<any> = [
    {
      title: t("Purchase date"),
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (text, record) => {
        return (
          <p className="text-primary font-pretendard400">
            {dayjs(text).format("YYYY.MM.DD")}
          </p>
        )
      },
      width: "18%",
    },
    {
      title: t("Image"),
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (text, record) => {
        return (
          <div className="w-[75px] h-[75px] flex justify-center items-center mx-auto">
            <img src={text} alt="img" className="w-full h-auto object-cover" />
          </div>
        )
      },
      width: "18%",
    },
    {
      title: t("Product’s name"),
      dataIndex: "voucherName",
      key: "voucherName",
      align: "center",
      render: (text, record) => {
        return <p className="text-primary font-pretendard400">{text}</p>
      },
      width: "22%",
    },
    {
      title: t("Brand name"),
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
      render: (text, record) => (
        <p className="text-primary font-pretendard400">{text}</p>
      ),
      width: "22%",
    },
    {
      title: t("Usage history"),
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (text, record) => {
        return (
          <p className="text-primary font-pretendard400">
            {text ? t("Read") : t("Unread")}
          </p>
        )
      },
      width: "18%",
    },
  ]

  const getDetailOrderHistory = async (id: number) => {
    navigate(`${UrlInternal.MY_E_CONTENT}/${id}`)
  }

  return (
    <>
      {!isMobile ? (
        <>
          <div className="md:container mt-[63px]">
            <div className="font-pretendard700 text-[24px] leading-[24px] pb-[24px] border-b-[2px] border-b-primary">
              {t("My e-content")}
            </div>

            <div className="flex justify-between mt-[50px] mb-[17px] items-center">
              <div className="flex gap-[30px]">
                <div className="flex gap-[14px] items-center">
                  <div className="text-[14px]">브랜드 선택</div>
                  <SelectCustom
                    placeholder={t("All")}
                    value={queryTrans.brandId}
                    onChange={(e) => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        brandId: e,
                      }))
                    }}
                    options={dataListBrand}
                    height={40}
                    width={155}
                  />
                </div>
                <div className="border border-[#EAEAEA] flex items-center m-auto h-[42px] pl-[15px]">
                  <input
                    type="text"
                    className={`w-[162px] focus-visible:outline-none bg-transparent text-[#000000] text-[14px] flex-1 placeholder:text-[#666666]`}
                    onChange={(e) => {
                      setQuerySearchChange(e.target.value)
                    }}
                    placeholder="검색"
                    value={querySearchChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setQueryTrans((prev) => ({
                          ...prev,
                          querySearch: querySearchChange,
                        }))
                      }
                    }}
                  />
                  <div
                    className="cursor-pointer  px-[11px] border-[#EAEAEA] border-l h-full leading-[42px] flex items-center"
                    onClick={() => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        querySearch: querySearchChange,
                      }))
                    }}
                  >
                    <img
                      src={SearchIcon}
                      alt="search"
                      className="max-w-[16px]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-[28px]">
                <div className="text-[14px]">
                  {t("총 N건", {
                    number: pageable?.totalElement ?? 0,
                  })}
                </div>
              </div>
            </div>

            <div className="border-t border-t-solid border-t-primary lg:mx-0 mx-[-24px]">
              <CsTable
                className="mb-[34px] table-history-order"
                columns={columns2}
                dataSource={transactionHistory}
                // showHeader={false}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => {
                      getDetailOrderHistory(record?.eId)
                    },
                  }
                }}
              />
              {transactionHistory && transactionHistory?.length > 0 && (
                <Pagination
                  showSizeChanger={false}
                  rootClassName="flex justify-center"
                  className="custom-pagination"
                  total={pageable?.totalElement}
                  pageSize={queryTrans?.size}
                  current={queryTrans?.page}
                  onChange={(page) => {
                    setQueryTrans((prev) => ({ ...prev, page }))
                  }}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <NavBarMb
            pageName="My e-content"
            // csGoback={() => navigate("/my-membership")}
          />

          <div className="px-[24px] mt-[19px] mb-[9px]">
            <div className="flex gap-[14px] flex-col">
              <div className="flex-1">
                <div className="pb-[10px] text-[14px]">브랜드</div>
                <SelectCustom
                  placeholder={t("All")}
                  value={queryTrans.brandId}
                  onChange={(e) => {
                    setQueryTrans((prev) => ({
                      ...prev,
                      brandId: e,
                    }))
                  }}
                  options={dataListBrand}
                  height={40}
                />
              </div>
              <div className="border border-[#EAEAEA] flex items-center h-[42px] pl-[15px]">
                <input
                  type="text"
                  className={`w-full focus-visible:outline-none bg-transparent text-[#000000] text-[14px] flex-1 placeholder:text-[#666666]`}
                  onChange={(e) => {
                    setQuerySearchChange(e.target.value)
                  }}
                  placeholder="Search"
                  value={querySearchChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setQueryTrans((prev) => ({
                        ...prev,
                        querySearch: querySearchChange,
                      }))
                    }
                  }}
                />
                <div
                  className="cursor-pointer  px-[11px] border-[#EAEAEA] border-l h-full leading-[42px] flex items-center"
                  onClick={() => {
                    setQueryTrans((prev) => ({
                      ...prev,
                      querySearch: querySearchChange,
                    }))
                  }}
                >
                  <img src={SearchIcon} alt="search" className="max-w-[16px]" />
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-[39px]">
              <div className="text-[13px] text-[#444444]">
                {t("총 N건", {
                  number: pageable?.totalElement ?? 0,
                })}
              </div>
            </div>
          </div>

          {transactionHistory &&
            transactionHistory?.length > 0 &&
            transactionHistory?.map((item: any, i: number) => (
              <div className="pb-[45px]" key={i}>
                <div
                  className="px-[24px] py-[17px] font-pretendard700 bg-[#F5F5F5] mb-[14px]"
                  onClick={() => getDetailOrderHistory(item?.eId)}
                >
                  {item?.brandName}
                </div>
                <div className="flex gap-[30px] px-[24px]">
                  <div className="border border-[#F1F1F1] w-[75px] h-[75px]">
                    <img
                      src={item?.image}
                      alt="image"
                      className="w-full h-auto aspect-square object-cover"
                    />
                  </div>

                  <div className="flex-col flex gap-[15px] leading-none">
                    <div className="flex gap-[17px]">
                      <div className="text-[#666666] text-[14px] min-w-[49px]">
                        브랜드명
                      </div>
                      <div className="text-[#000] text-[14px] font-pretendard500 min-h-[14px]">
                        {item?.voucherName}
                      </div>
                    </div>
                    <div className="flex gap-[17px]">
                      <div className="text-[#666666] text-[14px] min-w-[49px]">
                        구매일
                      </div>
                      <div className="text-[#000] text-[14px] font-pretendard500 min-h-[14px]">
                        {dayjs(item?.createdTime).format("YYYY.MM.DD")}
                      </div>
                    </div>
                    <div className="flex gap-[17px]">
                      <div className="text-[#666666] text-[14px] min-w-[49px]">
                        이용내역
                      </div>
                      <div className="text-[#000] text-[14px] font-pretendard500 min-h-[14px]">
                        {item?.read ? "Read" : "Unread"}
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex gap-[17px] leading-none">
                    <div className="flex-col flex gap-[15px]">
                      {["브랜드명", "구매일", "이용내역"].map((item, i) => (
                        <div key={i} className="text-[#666666] text-[14px]">
                          {item}
                        </div>
                      ))}
                    </div>
                    <div className="flex-col flex gap-[15px]">
                      {[
                        item?.voucherName,
                        dayjs(item?.createdTime).format("YYYY.MM.DD"),
                        item?.read ? "Read" : "Unread",
                      ].map((item, i) => (
                        <div
                          key={i}
                          className="text-[#000] text-[14px] font-pretendard500 min-h-[14px]"
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>
              </div>
            ))}

          <div className="fixed bottom-[55px] w-full bg-[#fff] py-[20px] z-10">
            <div
              className="border border-[#000] h-[40px] leading-[40px] text-center bg-[#fff] mx-[24px]"
              onClick={() => {
                if (pageable?.hasNext) {
                  handleGetTransactionHistory(true, pageable.page + 1)
                }
              }}
            >
              더 보기
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default MyEContent
