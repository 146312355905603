import React from "react"
import CommonModal, { CommonModalProps } from "."
import Button from "../Button"
import { useTranslation } from "react-i18next"
import { formatPrice } from "src/util/formatPrice"

interface Props extends CommonModalProps {
  open: boolean
  handleClose?: () => void
  handleConfirm?: () => void
  data?: any
}

const CancelOrder: React.FC<Props> = ({
  handleClose,
  handleConfirm,
  data,
  ...props
}) => {
  const { t } = useTranslation()

  console.log("data", data)

  return (
    <>
      <CommonModal
        onCancel={() => {
          handleClose && handleClose()
        }}
        {...props}
      >
        <h3 className="text-[20px] font-pretendard500 leading-normal mb-[20px]">
          {t("주문 취소 및 환불 요청")}
        </h3>
        <p className="text-[16px] font-pretendard500 text-primary leading-normal mb-[9px]">
          {t("환불 약관")}
        </p>
        <p className="text-[14px] text-gray1 leading-[20px]">
          {t("주문 취소 및 환불 신청은 결제 후 7일 이내에만 가능합니다.")}
          <br />
          {t(
            "주문 취소 접수 후 결제 카드 승인취소까지 영업일 기준 약 3~7일 소요됩니다.",
          )}
          <br />
        </p>
        <p className="text-[14px] font-pretendard500 text-primary leading-normal mt-[20px]">
          환불 예상 금액: {formatPrice(data?.refundAmount)}원
        </p>
        <p className="text-[14px] font-pretendard500 text-primary leading-normal mt-[20px]">
          {t("주문 취소 및 환불을 원하시면 확인 버튼으르 눌러주세요.")}
        </p>
        <div className="mt-[10px] flex gap-[10px]">
          <Button
            theme="main"
            variant="outline"
            height={48}
            width={155}
            onClick={handleClose}
          >
            {t("취소")}
          </Button>
          <Button
            theme="main"
            variant="primary"
            height={48}
            width={155}
            onClick={handleConfirm}
          >
            {t("확인")}
          </Button>
        </div>
      </CommonModal>
    </>
  )
}

export default CancelOrder
