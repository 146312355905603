import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const getListNotification = async (params: {
  filter?: string
  page?: number
  size?: number
  querySearch?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.NOTIFICATION_LIST,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const getDetailNotification = async (params: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.DETAIL_NOTIFICATION,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}
