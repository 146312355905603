import { Modal, ModalProps } from "antd"
import Close from "@assets/icons/close-modal.svg"
import CloseIcon from "@assets/icons/close-popup-new.svg"
import { ReactNode } from "react"

type Props = {
  imageTop?: ReactNode
  isOpen?: boolean
  handleClose?: () => void
  onRefresh?: () => void
  width?: number | string
  handleCloseOnlyClickButton?: () => void
  isCloseOutside?: boolean
}

export type CommonModalProps = Props & ModalProps

function CommonModal({
  isOpen,
  children,
  imageTop,
  handleClose = () => {},
  width = 400,
  onRefresh,
  handleCloseOnlyClickButton,
  closeIcon,
  isCloseOutside,
  ...props
}: CommonModalProps) {
  return (
    <Modal
      open={isOpen}
      onCancel={handleCloseOnlyClickButton ? undefined : handleClose}
      footer={false}
      className="relative"
      width={width || 400}
      closeIcon={
        closeIcon ||
        (isCloseOutside ? (
          <div className="absolute lg:right-[-65px] right-[-27px] lg:top-[-27px] top-[-65px]">
            <Close />
          </div>
        ) : (
          <div className="mr-[16px]">
            <CloseIcon />
          </div>
        ))
      }
      {...props}
    >
      <div>
        <div>{children}</div>
      </div>
    </Modal>
  )
}

export default CommonModal
