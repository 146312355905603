import { Spin } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { getDetailFeed, getListFeed } from "src/api/brandApi"
import FeedCard from "./FeedCard"
import ModalDetailBrand from "./ModalDetailBrand"
import useWindowSize from "src/customHook/useWindowSize"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { UrlInternal } from "src/constant/endpointRoute"
import { handleDate, handleDateNewPost } from "src/util/handleDate"

import Masonry from "react-responsive-masonry"
import { useAppSelector } from "src/app/hooks"
import { dataBrandMappingSelect } from "src/features/dataBrandMapping/dataBrandMappingSlice"
import ModalDetailFeed from "../Feed/ModalDetailFeed"
import ModalReport from "../Community/components/ModalReport"
import ModalBase from "src/components/ModalBase"
import { commentListFeed, reportFeed, reportPost } from "src/api/communityApi"

interface Props {
  brandId?: number
}

const Feed = ({ brandId }: Props) => {
  const { t, i18n } = useTranslation()
  const [dataFeed, setDataFeed] = useState<any[]>([])
  const [pageable, setPageable] = useState<any>({})
  const [modalDetail, setModalDetail] = useState({
    isVisible: false,
    dataDetail: {},
  })
  const { width }: any = useWindowSize()
  const isMobile = width < 640
  const navigate = useNavigate()
  const { state } = useLocation()
  // const params = useParams()
  // const { id } = params
  const dataBrandMapping: any = useAppSelector(dataBrandMappingSelect)
  const id = dataBrandMapping?.brandId

  const [isModalReport, setIsModalReport] = useState<boolean>(false)
  const [dataItemReport, setDataItemReport] = useState<any>({})
  const [isStatusModalReport, setIsStatusModalReport] = useState<boolean>(false)
  const brandName = dataBrandMapping?.userName
  const [searchParams, setSearchParams] = useSearchParams()
  const commentID = searchParams.get("commentID")
  const postID = searchParams.get("feedID")
  const widthBrandSite =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth

  const getDetailPostBrandSite = async (postID: number) => {
    try {
      const resp: any = await getDetailFeed({
        id: postID,
      })

      if (resp.result) {
        setModalDetail({
          isVisible: true,
          dataDetail: { ...resp.data, commentIdBrandSite: commentID },
        })
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (commentID || postID) {
      if (widthBrandSite <= 475) {
      } else {
        getDetailPostBrandSite(Number(postID))
      }
    }
  }, [commentID, postID])

  useEffect(() => {
    if (state?.id) {
      handleClickItem(state)
    }
  }, [state])

  const getDataFeed = async (isLoadmore?: boolean, page?: number) => {
    try {
      const res: any = await getListFeed({
        page: page ?? 1,
        size: 12,
        brandId: brandId,
        // brandId: Number(id),
      })

      if (res.result) {
        setDataFeed(res.data.datas)
        setPageable(res.data.pageable)

        if (isLoadmore) {
          setDataFeed([...dataFeed, ...res.data.datas])
        }
      } else {
        setDataFeed([])
        setPageable({})
      }
    } catch (error) {
      setDataFeed([])
      setPageable({})
    }
  }

  useEffect(() => {
    if (brandId) {
      getDataFeed()
    }
  }, [brandId])

  const handleLoadMore = () => {
    setTimeout(() => {
      getDataFeed(true, pageable.page + 1)
    }, 1500)
  }

  async function getDetail(id: number | string) {
    try {
      const res: any = await getDetailFeed({
        id: Number(id),
      })

      if (res.result) {
        return res.data
      } else {
        return {}
      }
    } catch (error) {}
  }

  const handleClickItem = async (item: any) => {
    if (isMobile) {
      navigate(`/${brandName}/${UrlInternal.FEED_DETAIL}/${item.id}`)
      // navigate(`/${UrlInternal.FEED_DETAIL}/${item.id}`)
    } else {
      const data = await getDetail(item.id)

      setModalDetail({
        isVisible: true,
        dataDetail: data,
      })
    }
  }

  const handleCancelModalComment = async () => {
    setDataFeed([])
    setPageable({})
    await getDataFeed()
    setModalDetail({
      isVisible: false,
      dataDetail: {},
    })
  }

  const handleSubmitReport = async (data: number) => {
    try {
      const resp: any = await reportPost({
        refId: dataItemReport?.commentId ?? dataItemReport?.postId,
        content: `CONTENT_${data}`,
        type: "F_COMMENT",
      })
      if (resp.result) {
        setIsStatusModalReport(true)
        setIsModalReport(false)

        getDataFeed()
      } else {
        setIsModalReport(false)
      }
    } catch (error) {
      setIsModalReport(false)
    }
  }

  return (
    <>
      {/**modal comment */}
      <ModalDetailFeed
        isVisible={modalDetail.isVisible}
        setCancel={() => {
          handleCancelModalComment()
        }}
        data={modalDetail.dataDetail}
        onClickReportCommentID={(data) => {
          setModalDetail({
            isVisible: false,
            dataDetail: {},
          })

          setIsModalReport(true)
          setDataItemReport(data)
        }}
      />
      {/**modal report */}
      <ModalReport
        isVisible={isModalReport}
        setCancel={() => setIsModalReport(false)}
        onSubmit={(e) => {
          handleSubmitReport(e)
        }}
      />

      {/* modal success report */}
      <ModalBase
        isVisible={isStatusModalReport}
        setCancel={() => setIsStatusModalReport(false)}
        className="sm:max-w-[380px] max-w-[100%]"
      >
        <div className="text-center mb-[60px]">
          <div>신고 접수가 완료되었습니다. 운영팀에서</div>
          <div>확인 후 처리하겠습니다.</div>
        </div>

        <div
          className="absolute bottom-0 w-full bg-[#000] text-[#fff] mx-[-16px] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
          onClick={() => setIsStatusModalReport(false)}
        >
          확인
        </div>
      </ModalBase>

      {/* <ModalDetailBrand
        isVisible={modalDetail.isVisible}
        setCancel={() => {
          getDataFeed()
          setModalDetail({ isVisible: false, dataDetail: {} })
        }}
        dataDetail={modalDetail.dataDetail}
      /> */}

      <div className="md:mx-0 mx-[-24px]">
        {dataFeed && dataFeed?.length > 0 && (
          <InfiniteScroll
            dataLength={dataFeed?.length}
            next={handleLoadMore}
            hasMore={pageable?.totalElement > dataFeed?.length}
            loader={
              <div className="flex justify-center items-center py-[30px] overflow-hidden">
                <Spin tip="Loading" size="small" />
              </div>
            }
          >
            <Masonry
              columnsCount={isMobile ? 1 : 2}
              gutter={isMobile ? "31px 30px" : "50px 30px"}
            >
              {/* <div className="grid md:grid-cols-2 grid-cols-1 gap-x-[30px] sm:gap-y-[50px] gap-y-[31px]"> */}
              {dataFeed?.map((item, index) => (
                <FeedCard
                  key={index}
                  title={item.title}
                  createTime={handleDateNewPost(
                    item.updatedAt ?? item.createdAt,
                    i18n.language,
                  )}
                  // createTime={handleDate(item.createdAt)}
                  imgUrl={item.thumbnail}
                  // imgUrl={item.thumbnail ?? item.brandThumbnail}
                  description={item.content}
                  like={item.cntLike}
                  isLike={item.doLike}
                  urlIncontentMetadata={
                    item?.urlIncontentMetadata
                      ? JSON.parse(item?.urlIncontentMetadata)
                      : []
                  }
                  id={item.id}
                  onClick={() => handleClickItem(item)}
                  data={item}
                />
              ))}
              {/* </div> */}
            </Masonry>
          </InfiniteScroll>
        )}
      </div>
    </>
  )
}

export default Feed
