import { useEffect, useState } from "react"
import NavBarMb from "src/layout/Header/NavBarMb"
import PostCommunity from "../../Component/PostCommunity"
import Pagination from "src/components/Pagination"
import ListComment from "../../Component/ListComment"
import { useLocation, useNavigate } from "react-router-dom"
import {
  deletePost,
  editPost,
  getDataPost,
  listBrandCommunity,
  reportPost,
} from "src/api/communityApi"
import useIsMobile from "src/customHook/useIsMobile"
import ModalBase from "src/components/ModalBase"
import ModalPost from "src/pages/BrandDetail/Community/components/ModalPost"
import ModalReport from "src/pages/BrandDetail/Community/components/ModalReport"
import ModalComment from "src/pages/BrandDetail/Community/components/ModalComment"
import { uploadSingleFile } from "src/api/uploadSingleFile"
import { UrlInternal } from "src/constant/endpointRoute"
import SelectCustom from "src/components/SelectCustom"
import { useTranslation } from "react-i18next"

const tabType = [
  {
    label: "포스트",
    value: "포스트",
  },
  {
    label: "댓글",
    value: "댓글",
  },
]

function CommunityMembership() {
  const { t } = useTranslation()
  const { state } = useLocation()
  const [queryTrans, setQueryTrans] = useState<{
    page: number
    size: number
    timeFilter: string
    brandId: string
  }>({
    page: 1,
    size: 10,
    timeFilter: "",
    brandId: state?.brandId ?? "",
  })
  const [tabCommunity, setTabCommunity] = useState(tabType[0].value)
  const [dataPost, setDataPost] = useState<any[]>([])
  const [pageable, setPageable] = useState<any>({})
  const [isModalPost, setIsModalPost] = useState<boolean>(false)
  const [dataItemEditPost, setDataItemEditPost] = useState<any>({})
  const [isModalDeletePost, setIsModalDeletePost] = useState<boolean>(false)
  const [idDeletePost, setIdDeletePost] = useState<number>()
  const navigate = useNavigate()
  const { isMobile } = useIsMobile(475)
  const [isModalComment, setIsModalComment] = useState({
    isVisible: false,
    data: {},
  })
  const [isModalReport, setIsModalReport] = useState<boolean>(false)
  const [dataItemReport, setDataItemReport] = useState<any>({})
  const [isStatusModalReport, setIsStatusModalReport] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataListBrand, setDataListBrand] = useState<any>([])

  console.log("state", state)

  useEffect(() => {
    if (state) {
      setQueryTrans((prev) => ({
        ...prev,
        page: 1,
        brandId: state?.targetBrandId,
      }))
    }
  }, [state])

  const getDataBrand = async () => {
    try {
      const { data }: any = await listBrandCommunity()
      if (data) {
        let dataResp = [
          {
            value: "",
            label: t("All"),
          },
        ]

        for (let i = 0; i < data.length; i++) {
          dataResp.push({
            value: data[i].id,
            label: data[i].brand_name,
          })
        }

        setDataListBrand(dataResp)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getDataBrand()
  }, [])

  const getDataCommunity = async () => {
    try {
      const resp: any = await getDataPost({
        brandId: queryTrans.brandId ? Number(queryTrans.brandId) : null,
        page: queryTrans.page ?? 1,
        size: 12,
        ofMine: 1,
      })
      if (resp.result) {
        setDataPost(resp.data.datas)
        setPageable(resp.data.pageable)
      } else {
        setDataPost([])
        setPageable({})
      }
    } catch (error) {
      setDataPost([])
      setPageable({})
    }
  }

  useEffect(() => {
    if (tabCommunity === tabType[0].value) {
      getDataCommunity()
    }
  }, [queryTrans])

  const handleCardShowComment = async (data: any) => {
    if (isMobile) {
      navigate(
        `/${data?.targetBrandId}/${UrlInternal.BRAND_DETAIL_COMMUNITY_COMMENT}/${data?.postId}`,
        {
          state: { postId: data?.postId, brandId: data?.targetBrandId },
        },
      )
    } else {
      const resp: any = await getDataPost({
        page: 1,
        size: 1,
        id: data?.postId,
      })

      if (resp.result) {
        setIsModalComment({
          isVisible: true,
          data: resp.data.datas[0],
        })
      }
    }
  }

  const handleCancelModalComment = async () => {
    setDataPost([])
    setPageable({})
    await getDataCommunity()
    setIsModalComment({
      isVisible: false,
      data: {},
    })
  }

  const handleSubmitReport = async (data: number) => {
    try {
      const resp: any = await reportPost({
        refId: dataItemReport?.commentId ?? dataItemReport?.postId,
        content: `CONTENT_${data}`,
        type: dataItemReport?.commentId ? "COMMENT" : "C_POST",
      })
      if (resp.result) {
        setIsStatusModalReport(true)
        setIsModalReport(false)

        getDataCommunity()
      } else {
        setIsModalReport(false)
      }
    } catch (error) {
      setIsModalReport(false)
    }
  }

  const onSubmitPost = async (data: any) => {
    setIsLoading(true)
    try {
      const dataMedias = []
      if (data?.medias?.length > 0) {
        for (let i = 0; i < data.medias.length; i++) {
          if (data?.medias[i]?.idPreview) {
            dataMedias.push({
              mediaType: "IMAGE",
              url: data.medias[i].src,
            })
          } else {
            const formData = new FormData()
            formData.append("file", data.medias[i].originFileObj)
            const respUpload: any = await uploadSingleFile(formData)
            if (respUpload) {
              dataMedias.push({
                mediaType: "IMAGE",
                url: respUpload.imageUrl,
              })
            }
          }
        }
      }

      const resp: any = await editPost({
        content: data.content,
        onlyMembership: data.onlyMembership ?? false,
        id: data.postId,
        medias: dataMedias,
      })
      if (resp.result) {
        setIsLoading(false)
        setIsModalPost(false)

        getDataCommunity()
      }
    } catch (error) {
      setIsLoading(false)
      setIsModalPost(false)
    }
  }

  const handleDeletePost = async () => {
    try {
      const resp: any = await deletePost({
        id: idDeletePost ?? 0,
      })
      if (resp.result) {
        setIsModalDeletePost(false)

        getDataCommunity()
      }
    } catch (error) {
      setIsModalDeletePost(false)
    }
  }

  return (
    <>
      {/**modal comment */}
      <ModalComment
        isVisible={isModalComment.isVisible}
        setCancel={() => {
          handleCancelModalComment()
        }}
        data={isModalComment.data}
        onClickReportCommentID={(data) => {
          setIsModalComment({
            isVisible: false,
            data: {},
          })

          setIsModalReport(true)
          setDataItemReport(data)
        }}
      />
      {/**modal report */}
      <ModalReport
        isVisible={isModalReport}
        setCancel={() => setIsModalReport(false)}
        onSubmit={(e) => {
          handleSubmitReport(e)
        }}
      />
      {/* modal post */}
      <ModalPost
        isVisible={isModalPost}
        setCancel={() => setIsModalPost(false)}
        onSubmit={(e) => onSubmitPost(e)}
        isLoading={isLoading}
        dataEdit={dataItemEditPost}
      />

      {/* modal success report */}
      <ModalBase
        isVisible={isStatusModalReport}
        setCancel={() => setIsStatusModalReport(false)}
        className="sm:max-w-[380px] max-w-[100%]"
      >
        <div className="text-center mb-[60px]">
          <div>신고 접수가 완료되었습니다. 운영팀에서</div>
          <div>확인 후 처리하겠습니다.</div>
        </div>

        <div
          className="absolute bottom-0 w-full bg-[#000] text-[#fff] mx-[-16px] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
          onClick={() => setIsStatusModalReport(false)}
        >
          확인
        </div>
      </ModalBase>
      {/**delete post me */}
      <ModalBase
        isVisible={isModalDeletePost}
        setCancel={() => setIsModalDeletePost(false)}
        className="sm:max-w-[380px] max-w-[100%]"
      >
        <div className="text-center mb-[30px]">삭제하시겠습니까?</div>

        <div className="flex gap-[10px]">
          <div
            className="w-[50%] bg-[#fff] text-[#000] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer border border-solid border-[#000]"
            onClick={() => setIsModalDeletePost(false)}
          >
            취소
          </div>
          <div
            className="w-[50%] bg-[#000] text-[#fff] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
            onClick={handleDeletePost}
          >
            확인
          </div>
        </div>
      </ModalBase>

      {isMobile ? (
        <>
          <NavBarMb
            pageName="커뮤니티 활동 내역"
            csGoback={() => navigate("/my-page")}
          />
          {!state?.brandId && (
            <div className="mb-[40px] px-[24px] mt-[18px]">
              <div className="text-[14px] pb-[11px]">브랜드</div>
              <SelectCustom
                placeholder={t("All status")}
                value={queryTrans.brandId}
                onChange={(e) => {
                  setQueryTrans((prev) => ({
                    ...prev,
                    page: 1,
                    brandId: e,
                  }))
                }}
                options={dataListBrand}
                height={40}
              />
            </div>
          )}
          <div className="flex mt-[39px]">
            {tabType.map((item, index) => (
              <div
                className={`text-[#000] text-[16px] flex-1 text-center border-b border-b-[#E5E5E5] pb-[12px] ${
                  item.value === tabCommunity &&
                  "font-pretendard700 border-b-[3px] !border-b-[#000000]"
                }`}
                onClick={() => setTabCommunity(item.value)}
                key={index}
              >
                {item.label}
              </div>
            ))}
          </div>
          <div className="py-[20px] px-[24px]">
            {tabCommunity === tabType[0].value ? (
              <>
                <div className="flex flex-col sm:gap-[64px] gap-[35px]">
                  {dataPost &&
                    dataPost.length > 0 &&
                    dataPost.map((dt: any, index) => (
                      <div key={index}>
                        <PostCommunity
                          setIsEditPost={(data) => {
                            setIsModalPost(true)
                            setDataItemEditPost(data)
                          }}
                          onSubmitDeletePost={(id: number) => {
                            setIsModalDeletePost(true)
                            setIdDeletePost(id)
                          }}
                          setIsModalComment={(data) =>
                            handleCardShowComment(data)
                          }
                          data={dt}
                        />
                      </div>
                    ))}
                </div>
                {dataPost && dataPost?.length > 0 && (
                  <Pagination
                    showSizeChanger={false}
                    rootClassName="flex justify-center"
                    className="custom-pagination mt-[30px]"
                    total={pageable?.totalElement}
                    pageSize={pageable?.size}
                    current={pageable?.page}
                    onChange={(page) => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        page: page,
                      }))
                      // setPageable({
                      //   ...pageable,
                      //   page: page,
                      // })
                    }}
                  />
                )}
              </>
            ) : (
              <ListComment
                onClickReportCommentID={() => {}}
                // brandIdPush={state?.brandId}
                brandIdPush={
                  queryTrans.brandId ? Number(queryTrans.brandId) : null
                }
              />
            )}
          </div>
        </>
      ) : (
        <div className="container">
          <div className="mt-[63px] mb-[30px] pb-[21px] border-b border-b-[#000000] text-[24px] font-pretendard700">
            커뮤니티 활동 내역
          </div>

          {!state?.brandId && (
            <div className="flex gap-[14px] items-center mb-[40px]">
              <div className="text-[14px]">브랜드</div>
              <SelectCustom
                placeholder={t("All status")}
                value={queryTrans.brandId}
                onChange={(e) => {
                  setQueryTrans((prev) => ({
                    ...prev,
                    page: 1,
                    brandId: e,
                  }))
                }}
                options={dataListBrand}
                height={40}
                width={250}
              />
            </div>
          )}

          <div className="flex pb-[30px]">
            {tabType.map((item, index) => (
              <div
                className={`text-[18px] cursor-pointer leading-[18px] ${
                  item.value === tabCommunity && "font-pretendard700"
                } ${
                  index === 0
                    ? "pr-[16px] border-r border-r-[#D4D4D4]"
                    : "pl-[16px]"
                }`}
                key={index}
                onClick={() => setTabCommunity(item.value)}
              >
                {item.label}
              </div>
            ))}
          </div>

          <div className="py-[20px] px-[24px]">
            {tabCommunity === tabType[0].value ? (
              <>
                <div className="flex flex-col sm:gap-[64px] gap-[35px]">
                  {dataPost &&
                    dataPost.length > 0 &&
                    dataPost.map((dt: any, index) => (
                      <div key={index}>
                        <PostCommunity
                          setIsEditPost={(data) => {
                            setIsModalPost(true)
                            setDataItemEditPost(data)
                          }}
                          onSubmitDeletePost={(id: number) => {
                            setIsModalDeletePost(true)
                            setIdDeletePost(id)
                          }}
                          setIsModalComment={(data) =>
                            handleCardShowComment(data)
                          }
                          data={dt}
                        />
                      </div>
                    ))}
                </div>
                {dataPost && dataPost?.length > 0 && (
                  <Pagination
                    showSizeChanger={false}
                    rootClassName="flex justify-center"
                    className="custom-pagination mt-[30px]"
                    total={pageable?.totalElement}
                    pageSize={pageable?.size}
                    current={pageable?.page}
                    onChange={(page) => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        page: page,
                      }))
                    }}
                  />
                )}
              </>
            ) : (
              <ListComment
                onClickReportCommentID={() => {}}
                // brandIdPush={state?.brandId}
                brandIdPush={
                  queryTrans.brandId ? Number(queryTrans.brandId) : null
                }
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default CommunityMembership
