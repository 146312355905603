import { Player, Controls } from "@lottiefiles/react-lottie-player"
import loading from "@assets/loading.json"

type Props = {}

function Loading({}: Props) {
  return (
    <div className="fixed top-0 left-0 w-full h-full z-[9999999] bg-[#00000073] flex justify-center items-center">
      {/* <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
      <Player
        autoplay
        loop
        src={loading}
        style={{ height: "180px", width: "180px" }}
      >
        {/* <Controls
          visible={true}
          buttons={["play", "repeat", "frame", "debug"]}
        /> */}
      </Player>
    </div>
  )
}

export default Loading
