import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import LocationCard, { TLOCATION_CARD } from "src/components/Card/LocationCard"
import InfiniteScroll from "react-infinite-scroll-component"
import { Spin } from "antd"

interface BrandLocationProps {
  locationList?: TLOCATION_CARD[]
  defaultBanner: string
}

const BrandLocation: React.FC<BrandLocationProps> = ({
  locationList,
  defaultBanner,
}) => {
  const { t } = useTranslation()
  const [offset, setOffset] = useState(3)
  const [data, setData] = useState<TLOCATION_CARD[]>(locationList ?? [])

  const handleLoadMore = () => {
    // if (locationList) {
    //   setTimeout(() => {
    //     setData([...data, ...locationList.slice(offset, offset + 3)])
    //     setOffset((prev) => prev + 3)
    //   }, 1500)
    // }
  }

  return (
    <div id="cs-infinite-scroll-wrapper" className="sm:mx-0 mx-[-24px]">
      <h4 className="capitalize md:text-[18px] text-[16px] font-pretendard700 md:mb-[32px] mb-[16px] leading-[18px] px-[24px] md:px-0">
        {t("매장 안내")}
      </h4>
      {locationList && locationList?.length > 0 && (
        // <InfiniteScroll
        //   dataLength={data?.length}
        //   next={handleLoadMore}
        //   hasMore={data?.length < locationList?.length}
        //   loader={
        //     <div className="flex justify-center items-center py-[30px] overflow-hidden">
        //       <Spin tip="Loading" size="small" />
        //     </div>
        //   }
        // >
        <div className="grid md:grid-cols-3 grid-cols-1 gap-[27px]">
          {locationList?.map((item) => (
            <LocationCard
              key={item?.id}
              branchName={item?.branchName}
              address={item?.address}
              phoneNumber={item?.phoneNumber}
              openAt={item?.openAt}
              closeAt={item?.closeAt}
              openingDay={item?.openingDay}
              branchImage={item?.branchImage || defaultBanner}
              locationLink={item?.locationLink}
              data={item}
            />
          ))}
        </div>
        // </InfiniteScroll>
      )}
    </div>
  )
}

export default BrandLocation
