import { Col, Row } from "antd"
import moment from "moment"
import { useTranslation } from "react-i18next"

type Props = {
  data?: any
}

function Service({ data }: Props) {
  const { t } = useTranslation()

  return (
    <div>
      <p className="text-[20px] font-pretendard700 text-primary mb-[19px] leading-none">
        {t("판매 정보")}
      </p>
      <div>
        <Row gutter={52}>
          <Col lg={24} sm={24} xs={24}>
            <div className="border border-solid border-[#E5E5E5] mb-[20px]">
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("Brand")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.brandName}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("Available stores")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.availableStores}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("Reservation Conditions")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.reservationConditions}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("Expiry")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {moment(data?.dateOfUse).format("YYYY-MM-DD")} ~{" "}
                    {moment(data?.expiredTime)
                      .subtract(1, "minutes")
                      .format("YYYY-MM-DD")}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("Cancellation Refund Policy")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.cancelRefundPolicy}
                  </span>
                </div>
              </div>

              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("환불 방법")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.refundMethod}
                  </span>
                </div>
              </div>
              <div className="flex ">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span
                    className="text-[13px] font-pretendard400 text-primary leading-[200%]"
                    dangerouslySetInnerHTML={{
                      __html: t("소비자상담 연락처"),
                    }}
                  />
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.consumerConsultationContact}
                  </span>
                </div>
              </div>
              {data?.productType === "PRODUCT" && (
                <div className="flex border-t border-[#E5E5E5]">
                  <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                    <span
                      className="text-[13px] font-pretendard400 text-primary leading-[200%]"
                      dangerouslySetInnerHTML={{
                        __html: t("Shipping fee"),
                      }}
                    />
                  </div>
                  <div className="px-[10px] py-[7px]">
                    {data?.shippingCost && (
                      <div className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                        - {data?.shippingCost}
                      </div>
                    )}
                    {data?.costsForJeju && (
                      <div className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                        - {data?.costsForJeju}
                      </div>
                    )}
                    {data?.returnFee && (
                      <div className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                        - {data?.returnFee}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col lg={24} sm={24} xs={24}>
            <div className="border border-solid border-[#E5E5E5]">
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("판매자")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.brandName}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("대표자")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.representative}
                  </span>
                </div>
              </div>
              <div className="flex border-b border-b-solid border-b-[#E5E5E5]">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("연락처")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.phoneNumber}
                  </span>
                </div>
              </div>
              <div className="flex">
                <div className="w-[219px]  min-w-[219px] bg-[#D7D7D7] px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {t("사업장 소재지")}
                  </span>
                </div>
                <div className="px-[10px] py-[7px]">
                  <span className="text-[13px] font-pretendard400 text-primary leading-[200%]">
                    {data?.address}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Service
