// import { ReactComponent as CameraPlus } from "assets/icons/CameraPlus.svg";
import CameraIcon from "@assets/icons/mypage/camera.svg"
import userImg from "@assets/images/my_page/user.png"
import { Image, Upload } from "antd"
import ImgCrop from "antd-img-crop"
import { useEffect, useRef, useState } from "react"
import "./style.scss"

type Props = {
  initImage?: string
  uploadDone?: boolean
  crop?: {
    type?: "round" | "rect"
    aspect?: number
  }
  onImageChange?: (file: File) => void
  onModalOk?: ((value: any) => void) | undefined
}
function UploadAvatar({
  initImage,
  onModalOk,
  uploadDone,
  crop = {
    type: "round",
    aspect: 1,
  },
  onImageChange = () => {},
}: Props & React.HTMLAttributes<HTMLDivElement>) {
  const ref = useRef<any>(null)
  const [imageSrc, setImageSrc] = useState<string | undefined>()
  useEffect(() => {
    if (initImage) {
      setImageSrc(initImage)
    } else {
      setImageSrc(userImg)
    }
  }, [initImage])

  useEffect(() => {
    return () => {
      imageSrc && URL.revokeObjectURL(imageSrc)
    }
  }, [imageSrc])
  return (
    <div className="image-upload-container">
      <ImgCrop
        onModalOk={onModalOk}
        cropShape={crop.type}
        rotationSlider
        aspect={crop.aspect}
      >
        <Upload
          accept="image/png, image/jpeg"
          customRequest={(e) => {
            const file: any = e.file
            const type = file.type
            if (type.includes("image")) {
              const src = URL.createObjectURL(file)
              setImageSrc(src)
              onImageChange(file)
            } else {
            }
          }}
          showUploadList={false}
          onDownload={() => false}
          className="!hidden"
        >
          <div ref={ref}>Upload</div>
        </Upload>
      </ImgCrop>
      <label className="upload-label">
        <Image
          style={{
            objectFit: "cover",
          }}
          src={uploadDone ? imageSrc : initImage}
        />
      </label>
      <label
        className="upload-btn"
        onClick={(e) => {
          ref.current && ref.current.click()
        }}
      >
        <CameraIcon />
      </label>
    </div>
  )
}

export default UploadAvatar
