import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { groupBuyAPi } from "src/api/groupBuyApi"
import useIsMobile from "src/customHook/useIsMobile"
import GroupBuy from "src/pages/Home/components/GroupBuy"
import { Helmet } from "react-helmet"

type Props = {}

function VoucherDetail({}: Props) {
  const navigate = useNavigate()

  const { isMobile } = useIsMobile(474)

  const [voucherDetail, setVoucherDetail] = useState<any>({})
  const params = useParams()

  const getVoucherDetail = async () => {
    try {
      const res: any = await groupBuyAPi.getVoucherDetail(params.id as string)
      if (res.result && !!res.data) {
        setVoucherDetail(res.data)
      } else {
        throw res
      }
    } catch (error) {}
  }

  useEffect(() => {
    getVoucherDetail()
  }, [params.id])

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=10, minimum-scale=1"
        />
      </Helmet>

      <div className={isMobile ? "mx-[-24px]" : ""}>
        {voucherDetail?.brandId && (
          <GroupBuy
            brandId={voucherDetail?.brandId}
            mainColor="#15A0A1"
            colorButton="#2D2D2D"
            titleEx
            data={voucherDetail}
            onComplete={() => {
              // getVoucherDetail()
              setVoucherDetail(undefined)
              voucherDetail?.progress === "INCOMING" &&
                navigate("/group-buying/up-coming")
            }}
          />
        )}
      </div>
    </>
  )
}

export default VoucherDetail
