import { useTranslation } from "react-i18next"
import { Outlet, useNavigate } from "react-router-dom"
import useCheckMatchPath from "src/customHook/useCheckMatchPath"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"

function GroupBuying() {
  const navigate = useNavigate()
  const { isMobile } = useIsMobile(1023)
  const { t } = useTranslation()
  const isGoupBuyingDetail = useCheckMatchPath("/group-buying/detail/:id")
  const isGoupBuyingOrderDetail = useCheckMatchPath("/group-buying/order/:id")
  const isGoupBuying = useCheckMatchPath("/group-buying/:type")

  console.log("isGoupBuying", isGoupBuying)

  const renderPageTitle = () => {
    if (isGoupBuyingDetail) {
      return "MOAFRIENDS"
    }
    if (isGoupBuyingOrderDetail) {
      return t("주문서 작성")
    }
    return t("프리미엄 멤버십")
  }

  return (
    <div className="pb-[100px] lg:pt-[60px]">
      {isMobile ? (
        <NavBarMb
          pageName={renderPageTitle()}
          csGoback={() => {
            if (isGoupBuying) {
              navigate("/")
            } else {
              navigate("/group-buying")
            }
          }}
        />
      ) : (
        !isGoupBuyingDetail && (
          <div className="container">
            <div className="font-pretendard700 text-[24px] leading-[24px] pb-[24px] border-b-[2px] border-b-primary mb-[40px]">
              {isGoupBuyingOrderDetail
                ? t("주문서 작성")
                : t("프리미엄 멤버십")}
            </div>
          </div>
        )
      )}
      <div className="z-10">
        <div className="container md:px-[16px]">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default GroupBuying
