import { Input } from "antd"
import { useEffect, useState } from "react"
import MinusLarge from "src/assets/icons/MinusLarge.svg"
import PlusLarge from "src/assets/icons/PlusLarge.svg"
import SearchBlack from "src/assets/icons/SearchBlack.svg"
import ArrowBendDownRight from "src/assets/icons/cs-center/ArrowBendDownRight.svg"
import Pagination from "src/components/Pagination"
import CsTable from "src/components/Table"

import { ColumnsType } from "antd/es/table"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CsType, customerApi } from "src/api/customerApi"
import TabsComp from "src/components/Tabs"
import useIsMobile from "src/customHook/useIsMobile"
import { useFaqType } from "src/customHook/useTypeFaq"
import NavBarMb from "src/layout/Header/NavBarMb"
import { Faq } from "src/models/faq"
import "./style.scss"
import { useTranslation } from "react-i18next"
import Button from "src/components/Button"

const FAQ: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [faqList, setFaqList] = useState<Faq[]>([])
  const [totalFaq, setTotalFaq] = useState<number>(0)
  const faqTypes = useFaqType()
  const query = {
    page: searchParams.get("page") || 1,
    querySearch: searchParams.get("querySearch") || "",
    type: (searchParams.get("type") as CsType) || "",
  }
  const { isMobile } = useIsMobile(1023)
  const [expandedKeys, setExpandedKeys] = useState<any>([])

  const getFAQList = async () => {
    const { data, result }: any = await customerApi.getFAQList(query)
    try {
      if (result) {
        setFaqList(data.datas)
        setTotalFaq(data.pageable.totalElement || 0)
      } else {
        throw data
      }
    } catch (error) {
      console.log(error)
      setFaqList([])
      setTotalFaq(0)
    }
  }
  useEffect(() => {
    getFAQList()
  }, [searchParams])

  useEffect(() => {
    console.log("searchParams", searchParams.get("type"))
    if (!searchParams.get("type")) {
      searchParams.set("type", "MEMBERSHIP_USAGE")
      setSearchParams(searchParams)
    }
  }, [])

  const typeMappings: any = {
    MEMBERSHIP_USAGE: t("멤버십이용"),
    ORDER_PAYMENT: t("주문결제"),
    CANCELATION_REFUND: t("취소/환불"),
    OTHER: t("기타"),
  }

  const columns: ColumnsType<any> = [
    {
      title: isMobile ? "" : t("구분"),
      dataIndex: "type",
      key: "type",
      render: (_, record: Faq) => (
        <p className="lg:text-[16px] text-[14px] text-black leading-[100%] font-pretendard400 bg-primary4 rounded-[5px] text-center">
          {typeMappings[record.type] || record.type}
        </p>
      ),
      // width: "200px",
      // align: "center",
    },
    {
      title: isMobile ? "" : t("제목"),
      dataIndex: "question",
      key: "question",
      render: (_, record: Faq) => (
        // <p className="lg:text-[16px] text-[14px] text-textColor5 font-pretendard400 leading-[100%] lg:first-letter:text-[20px] first-letter:text-[160px] first-letter:font-pretendard400 first-letter:text-primary first-letter:leading-[100%] first-letter:mr-[10px]">
        //   Q.{record.question}
        // </p>
        <p className="lg:text-[16px] text-[14px] text-textColor5 font-pretendard400 leading-[100%] text-left">
          Q. {record.question}
        </p>
      ),
    },
  ]

  const items = [
    {
      key: "MEMBERSHIP_USAGE",
      label: t("멤버십이용"),
    },
    {
      key: "ORDER_PAYMENT",
      label: t("주문결제"),
    },
    {
      key: "CANCELATION_REFUND",
      label: t("취소/환불"),
    },
    {
      key: "OTHER",
      label: t("기타"),
    },
  ]

  const handleChangeTabs = (key: string) => {
    searchParams.set("type", key)
    searchParams.set("page", "1")
    searchParams.delete("querySearch")
    setSearchParams(searchParams)
    setExpandedKeys([])
  }

  return (
    <>
      {isMobile && <NavBarMb pageName="FAQ" csGoback={() => navigate("/")} />}
      <div className="xs:container">
        <div className="pt-[27px] lg:pt-[60px] pb-[33px] lg:pb-[29px] lg:mb-[20px] rounded-[26px] bg-primary4 px-[24px] xs:px-0">
          {!isMobile && (
            <>
              <h4 className="uppercase text-[24px] leading-[100%] font-pretendard700 text-black pb-[21px]">
                {t("FAQ")}
              </h4>
              <div className="h-[2px] bg-black w-full block mb-[43px]" />
            </>
          )}
          <div className="">
            <Input
              className="rounded-[0px] border-[#BDBDBD] lg:w-[440px] w-full h-[48px] px-[20px] font-pretendard500 text-[14px]"
              placeholder={t("검색어를 입력하세요")}
              suffix={
                <div className="absolute right-0 top-0 w-[48px] h-[46px] flex items-center justify-center border-l border-solid border-[#BDBDBD]">
                  <SearchBlack />
                </div>
              }
              value={query.querySearch}
              onChange={(e) => {
                const params: any = {
                  querySearch: e.target.value,
                  page: "1",
                }
                if (searchParams.get("typeId")) {
                  params.typeId = Number(searchParams.get("typeId"))
                }
                setSearchParams({
                  ...params,
                })
              }}
            />
          </div>
        </div>
        <TabsComp
          className="tabs-faq"
          defaultActiveKey={searchParams.get("type") || "MEMBERSHIP_USAGE"}
          onChange={handleChangeTabs}
          theme="fence"
          items={items}
        />
        <div className="bg-lightColor shadow-custom3 md:mb-[186px] rounded-[26px]">
          <CsTable
            className="mb-[34px] table-hidden-expand faq-table"
            // columns={isMobile ? columnsMb : columns}
            columns={columns}
            dataSource={faqList}
            showHeader={isMobile ? false : true}
            onRow={({ key }) =>
              expandedKeys.includes(key) && { className: "expand-parent" }
            }
            expandable={{
              onExpand: (expanded, { key }) => {
                const expandedKeysData = expanded ? [key] : []
                setExpandedKeys(expandedKeysData)
              },
              expandRowByClick: true,
              expandedRowKeys: expandedKeys,
              expandedRowRender: (record) => (
                <div className="lg:py-[12px] px-[15px] lg:w-[calc(100%-200px)] lg:float-right text-left">
                  <div className="flex items-start py-[12px] px-[8px] ">
                    <span className="w-[24px] h-[24px] mr-[16px] mt-[-5px]">
                      <ArrowBendDownRight />
                    </span>
                    <div
                      className="font-pretendard500 lg:text-[16px] text-[14px] whitespace-pre-line"
                      dangerouslySetInnerHTML={{
                        __html: record.answer,
                      }}
                    />
                  </div>
                </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <div
                    className="cursor-pointer w-[20px] h-[20px] flex items-center justify-center"
                    onClick={(e) => onExpand(record, e)}
                  >
                    <MinusLarge />
                  </div>
                ) : (
                  <div
                    className="cursor-pointer w-[20px] h-[20px] flex items-center justify-center"
                    onClick={(e) => onExpand(record, e)}
                  >
                    <PlusLarge />
                  </div>
                ),
            }}
          />
          {totalFaq > 10 && (
            <Pagination
              rootClassName="flex justify-center"
              showSizeChanger={false}
              className="custom-pagination"
              total={totalFaq}
              pageSize={10}
              onChange={(page: number) => {
                setSearchParams((prev) => {
                  return { ...prev, page }
                })
              }}
              current={Number(query.page)}
            />
          )}
          {/* <div className="px-[24px] md:hidden mt-[50px] mb-[30px]">
            <Button
              onClick={() => navigate("/my-page/inquiry")}
              theme="main"
              height={48}
              variant="outline"
              mainStyle={{
                borderColor: "#D4D4D4",
                fontFamily: "Pretendard-400",
              }}
            >
              {t("1:1 문의 작성하기")}
            </Button>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default FAQ
