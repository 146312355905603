import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const getListVoucherUsageHistory = async (params: {
  page?: number
  size?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.LIST_VOUCHER_USAGE_HISTORY,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const getDetailVoucherUsageHistory = async (params: {
  page?: number
  size?: number
  brandId?: number
  transactionType?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.DETAIL_VOUCHER_USAGE_HISTORY,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}
