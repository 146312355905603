import { CheckOutlined } from "@ant-design/icons"
import { CSSProperties, ReactNode, useEffect, useState } from "react"

type SELECT = {
  name: string
  defaultChecked?: boolean
  label: ReactNode
  children?: SELECT_OPTION
}
type SELECT_OPTION = SELECT[]
type Props = {
  selectOption: SELECT_OPTION
  getSelectValues?: (data: SELECT_OPTION) => void
  onSelectChange?: (data: Record<string, boolean>) => void
  width?: number
  height?: number
  checkmarkSize?: number
  checkmarkColor?: string
  labelStyle?: CSSProperties
  borderColor?: string
}

function Selection({
  id,
  checked,
  height = 17,
  width = 17,
  checkmarkSize = 11,
  checkmarkColor = "#fff",
  getSelectValues = () => {},
  onSelectChange = () => {},
  selectOption: selectOptions,
  labelStyle,
  borderColor,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & Props) {
  const [selectOption, setSelectOption] = useState<SELECT_OPTION>([])
  useEffect(() => {
    if (!!selectOptions) {
      setSelectOption(selectOptions)
    }
  }, [selectOptions])
  const handleSelectAll = (selectOption: SELECT_OPTION, value: boolean) => {
    selectOption.forEach((item) => {
      item.defaultChecked = value
      if (!!item.children) {
        handleSelectAll(item.children, value)
      }
    })
  }
  const handleSelectCheck = (selectOption: SELECT_OPTION, name: string) => {
    selectOption.forEach((item) => {
      if (item.name === name) {
        item.defaultChecked = !item.defaultChecked
        onSelectChange({ [name]: item.defaultChecked })

        if (!!item.children) {
          handleSelectAll(item.children, !!item.defaultChecked)
        }
      } else {
        if (!!item.children) {
          handleSelectCheck(item.children, name)
        }
      }
    })
  }
  const handleSelectChange = ({ name }: { name: string }) => {
    setSelectOption((prev) => {
      const temp = [...prev]
      handleSelectCheck(temp, name)
      return temp
    })
  }

  const renderSelection = (data: SELECT_OPTION, ml = 0) => {
    const result = data?.map((item) => {
      return (
        <div className={`relative flex items-center flex-wrap`} key={item.name}>
          <input
            id={item.name}
            type="checkbox"
            name={item.name}
            hidden
            {...props}
            checked={item.defaultChecked || false}
            onChange={(e) => {
              handleSelectChange({
                name: e.target.name,
              })
              getSelectValues(selectOption)
            }}
          />
          <label
            htmlFor={item.name}
            style={{
              marginLeft: ml + "px",
              width,
              height,
              borderColor: borderColor,
            }}
            className={`${
              item.defaultChecked ? "bg-primary" : "bg-secondary4"
            } absolute flex justify-center items-center rounded-[2px] overflow-hidden border border-black `}
          >
            {item.defaultChecked && (
              <CheckOutlined
                style={{
                  fontSize: `${checkmarkSize}px`,
                  color: `${checkmarkColor}`,
                }}
              />
            )}
          </label>
          <label
            htmlFor={item.name}
            style={{
              marginLeft: ml + "px",
              paddingLeft: width + 10 + "px",
              ...labelStyle,
            }}
            className={`block mt-[7px] text-[14px] font-pretendard500 ${
              item.defaultChecked ? "text-primary" : "text-gray1"
            } relative top-[-2px]`}
          >
            {item.label}
          </label>
          {item.children ? renderSelection(item.children, ml + 28) : null}
        </div>
      )
    })
    return result
  }
  return <div className="">{renderSelection(selectOption)}</div>
}

export default Selection
