import { memo, useEffect } from "react"
import { createPortal } from "react-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import useWindowSize, { Size } from "src/customHook/useWindowSize"
import SearchDesktop from "./SearchDesktop"
import SearchMobile from "./SearchMobile"
import { isOpenSearchSelect, onClose } from "../searchSlice"
import "../style.scss"

type Props = {}

function SearchPopup({}: Props) {
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(isOpenSearchSelect)
  const { width }: Size = useWindowSize()

  useEffect(() => {
    const handleEscape: any = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        dispatch(onClose())
      }
    }

    if (isOpen) {
      const bodyEl = document.body
      bodyEl.style.overflow = "hidden"
      document.addEventListener("keydown", handleEscape)
    } else {
      const bodyEl = document.body
      bodyEl.style.overflow = ""
      document.removeEventListener("keydown", handleEscape)
    }

    return () => {
      const bodyEl = document.body
      bodyEl.style.overflow = "" // Loại bỏ thiết lập overflow: hidden khi component unmount
      document.removeEventListener("keydown", handleEscape)
      document.removeEventListener("keydown", handleEscape)
    }
  }, [isOpen])

  return createPortal(
    <>
      {width as number >= 1024 ? (
        <div className={`search-modal-desktop wr-search-modal ${isOpen ? "active" : ""}`}>
          <SearchDesktop />
        </div>
      ) : (
        <div className={`search-modal-mobile wr-search-modal ${isOpen ? "active" : ""}`}>
          <SearchMobile />
        </div>
      )}
    </>,
    document.getElementById("search-modal") as HTMLElement,
  )
}

export default memo(SearchPopup)
