import React, { useRef } from "react";


interface Props {
  params: any
  refMobile?: any
}

function CreatePayInicis({ params, refMobile }: Props): React.ReactElement {

  // your requset parameters
  const inicisFormData = params;

  if (!inicisFormData) {
    return <div></div>;
  }

  return (
    <div>
      <form id="SendPayForm_id" name="" method="Post">
        <input type="hidden" name="mid" value={inicisFormData.mid} />
        <input type="hidden" name="price" value={inicisFormData.price} />
        <input type="hidden" name="version" value="1.0" />
        <input type="hidden" name="oid" value={inicisFormData.oid} />
        <input
          type="hidden"
          name="goodname"
          value={inicisFormData.goodsname}
        />
        <input
          type="hidden"
          name="currency"
          value={inicisFormData.currency}
        />
        <input
          type="hidden"
          name="buyername"
          value={inicisFormData.buyername}
        />
        <input
          type="hidden"
          name="buyertel"
          value={inicisFormData.buyertel}
        />
        <input
          type="hidden"
          name="buyeremail"
          value={inicisFormData.buyeremail}
        />
        <input
          type="hidden"
          name="timestamp"
          value={inicisFormData.timestamp}
        />
        <input
          type="hidden"
          name="use_chkfake"
          value={'Y'}
        />
        <input
          type="hidden"
          name="signature"
          value={inicisFormData.signature}
        />
        <input
          type="hidden"
          name="verification"
          value={inicisFormData.verification}
        />
        <input
          type="hidden"
          name="returnUrl"
          value={inicisFormData.returnUrl}
        />
        <input type="hidden" name="mKey" value={inicisFormData.mkey} />
        <input
          type="hidden"
          name="closeUrl"
          value={inicisFormData.closeUrl}
        />
        <input
          type="hidden"
          name="gopaymethod"
          value={inicisFormData.gopaymethod}
        />
        <input type="hidden" name="payViewType" value="overlay" />
      </form>

      <form hidden name="mobileweb" method="post" acceptCharset="euc-kr" ref={refMobile}>
        <input type="text" readOnly name="P_NEXT_URL" value={inicisFormData.returnUrl} />
        <input type="text" readOnly name="P_INI_PAYMENT" value={inicisFormData.gopaymethod} />
        <input type="text" readOnly name="P_MID" value={import.meta.env.DEV ? "INIpayTest" : inicisFormData.mid} />
        <input type="text" readOnly name="P_OID" value={inicisFormData.oid} />
        <input type="text" readOnly name="P_GOODS" value={inicisFormData.goodsname} />
        <input type="text" readOnly name="P_AMT" value={inicisFormData.price} />
        <input type="text" readOnly name="P_UNAME" value={inicisFormData.buyername} />

        {/* 휴대폰결제 필수 [1:컨텐츠, 2:실물] */}
        {/* {payData.payStatus === 2 && <input type="text" readOnly name="P_HPP_METHOD" value={payData.telStatus} />} */}
      </form>
    </div>
  );
}

export default CreatePayInicis;
