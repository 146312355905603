import Button from "src/components/Button"
import CommonModal, { CommonModalProps } from ".."
import "./index.scss"
import NavBarMb from "src/layout/Header/NavBarMb"
import useIsMobile from "src/customHook/useIsMobile"
import { formatPrice } from "src/util/formatPrice"

interface Props extends CommonModalProps {
  open: boolean
  handleClose?: () => void
  handleConfirm?: () => void
  data: any
}

function InfoRegisterMembership({
  handleClose,
  handleConfirm,
  data,
  ...props
}: Props) {
  const { isMobile } = useIsMobile(475)

  return (
    <CommonModal
      width={450}
      onCancel={() => {
        handleClose && handleClose()
      }}
      // closeIcon
      {...props}
      className="modal-membership text-[#000] w-[100vh] h-[100vh] modal-fullpage"
    >
      {isMobile && (
        <>
          <NavBarMb
            pageName={`${data?.membershipName} 멤버십`}
            className="mx-[-24px]"
            csGoback={() => handleClose?.()}
          />

          <div className="fixed left-0 right-0 bottom-0">
            <Button theme="main" onClick={handleConfirm}>
              가입하기
            </Button>
          </div>
        </>
      )}

      <div className="text-[20px] font-pretendard700 text-center sm:block hidden">
        {data?.brandName} 멤버십
      </div>

      <div className="sm:mt-[41px] mt-[24px] sm:mb-[51px]">
        <div className="border border-[#D9D9D9] pt-[20px] px-[20px] pb-[33px]">
          <div className="border border-[#D9D9D9] w-[80px] h-[80px] mb-[16px]">
            <img src={data?.logoImage} alt="img" />
          </div>
          <div className="flex justify-between">
            <div className="text-[#539165] font-pretendard700 text-[16px]">
              {data?.membershipName}
            </div>
            <div className="text-[#539165]">
              월{" "}
              <span className="font-pretendard700 text-[16px]">
                {formatPrice(Number(data?.sellingPrice))}
              </span>
              원
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-[#757575] text-[13px]">월 자동 결제</div>
            <div className="text-[#757575] text-[13px]">
              {formatPrice(
                100 -
                  (Number(data?.sellingPrice) / Number(data?.rootPrice)) * 100,
              )}
              % 할인
            </div>
          </div>

          <div className="mt-[16px] mb-[12px] pt-[20px] border-t border-t-[#D9D9D9] text-[16px] font-pretendard700">
            멤버십 혜택
          </div>
          <div className="flex flex-col gap-[12px]">
            {data?.preferential?.map(
              (item: any, index: number) =>
                item && (
                  <div className="flex gap-[10px]" key={index}>
                    <div className="bg-[#000] h-[3px] w-[3px] rounded-full mt-[6px]"></div>
                    <div className="leading-[100%]">{item}</div>
                  </div>
                ),
            )}
          </div>
        </div>

        <div className="sm:block hidden">
          <Button theme="main" onClick={handleConfirm}>
            멤버십 가입하기
          </Button>
        </div>

        <div className="mt-[51px] mb-[63px]">
          {data?.images?.map((item: any, index: number) => (
            <img src={item} alt="img" key={index} />
          ))}
        </div>

        <div className="mt-[42px]">
          <div className="mb-[16px] text-[#757575] font-pretendard700">
            멤버십 안내
          </div>
          <div className="flex flex-col gap-[5px]">
            <div className="flex gap-[10px]">
              <div className="bg-[#757575] h-[3px] min-w-[3px] rounded-full mt-[8px]"></div>
              <div className="text-[#757575] text-[12px]">
                정기구독형 멤버십은 매월 결제를 통해 브랜드에서 제공하는 혜택을
                이용할 수 있는 상품입니다.
              </div>
            </div>
            <div className="flex gap-[10px]">
              <div className="bg-[#757575] h-[3px] min-w-[3px] rounded-full mt-[8px]"></div>
              <div className="text-[#757575] text-[12px]">
                정기구독의 결제는 매월 구독 신청일과 동일한 날짜에 진행됩니다.
                <br />
                (예. 구독 신청일: 7월 17일, 다음 정기 구독 결제일 8월 17일)
              </div>
            </div>
            <div className="flex gap-[10px]">
              <div className="bg-[#757575] h-[3px] min-w-[3px] rounded-full mt-[8px]"></div>
              <div className="text-[#757575] text-[12px]">
                윤달 혹은 30, 31일에 신청하여 차월 해당 일이 없는 경우, 해당 월
                말일에 결제됩니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  )
}

export default InfoRegisterMembership
