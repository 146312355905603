import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

interface ChangePasswordParams {
  oldPassword: string
  newPassword: string
}

interface ResetPasswordParams {
  password: string
  accessToken: string
}

interface ForgotPasswordParams {
  email: string
}

export const changePassword = async (payload: ChangePasswordParams) => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.CHANGE_PASSWORD,
    method: "POST",
    data: payload,
  })
}

export const resetPassword = async (payload: ResetPasswordParams) => {
  return api.request({
    url: ApiUrl.RESET_PASSWORD,
    method: "POST",
    data: payload,
  })
}

export const forgotPassword = async (payload: ForgotPasswordParams) => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.FORGOT_PASSWORD,
    method: "POST",
    data: payload,
  })
}
