export const isScrolledToBottom = (): boolean => {
  const documentHeight: number = document.documentElement.scrollHeight
  const windowHeight: number =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  const scrollTop: number =
    window.scrollY || window.pageYOffset || document.documentElement.scrollTop
  return scrollTop + windowHeight >= documentHeight
}
