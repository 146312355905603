import { ChangeEvent, FC, InputHTMLAttributes, useState } from "react"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  label?: string
}

const CheckboxComp: FC<InputProps> = ({
  className,
  onChange,
  label,
  ...rest
}) => {
  const [isChecked, setIschecked] = useState<boolean>(true)
  // console.log(isChecked)
  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
      setIschecked(e.target.checked)
    }
  }
  return (
    <label className={`flex items-center gap-[8px] ${className}`}>
      <span
        className={`cursor-pointer checkmark w-[20px] h-[20px] border border-black block rounded-full relative before:absolute before:w-[15px] before:h-[15px] before:top-[50%] before:translate-y-[-50%] before:translate-x-[-50%] before:left-[50%] before:bg-black before:rounded-full ${
          isChecked ? "before:block" : "before:hidden"
        }`}
      ></span>
      <span className="text-[14px] lg:text-[16px] text-black leading-[100%]">
        {label}
      </span>
      <input hidden onChange={handleChecked} type="checkbox" {...rest} />
    </label>
  )
}

export default CheckboxComp
