import { useEffect, useState } from "react"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { useTranslation } from "react-i18next"
import useIsMobile from "src/customHook/useIsMobile"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"
import NavBarMb from "src/layout/Header/NavBarMb"
import { mypageList } from "./config"
import useCheckMatchPath from "src/customHook/useCheckMatchPath"
import { useAuth } from "src/customHook/useAuth"
import { UrlInternal } from "src/constant/endpointRoute"

function MyPage() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const { isMobile } = useIsMobile(1023)
  const navigate = useNavigate()
  const isMypage = useCheckMatchPath("/my-page")
  const isInquiryPage = useCheckMatchPath("/my-page/inquiry")
  const isOrderHistory = useCheckMatchPath("/my-page/order-history")

  const isOrderVoucherDetail = useCheckMatchPath(
    UrlInternal.ORDER_VOUCHER_DETAIL + "/:id",
  )
  const isOrderEcontentDetail = useCheckMatchPath(
    UrlInternal.ORDER_ECONTENT_DETAIL + "/:id",
  )
  const isOrderProductDetail = useCheckMatchPath(
    UrlInternal.ORDER_PRODUCT_DETAIL + "/:id",
  )
  const isOrderMyEContent = useCheckMatchPath(UrlInternal.MY_E_CONTENT + "/:id")

  const isOrderDetail = useCheckMatchPath("/my-page/order-detail/:id")
  const isPageDetail =
    isOrderVoucherDetail ||
    isOrderEcontentDetail ||
    isOrderProductDetail ||
    isOrderMyEContent ||
    isOrderDetail

  const isNotification = useCheckMatchPath(UrlInternal.NOTIFICATION)
  const isNotificationDetail = useCheckMatchPath(
    `${UrlInternal.NOTIFICATION}/:id`,
  )
  const { user }: any = useAuth()

  const dataUser = useAppSelector(userDataSelect)

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  const convertPageName = () => {
    if (isOrderVoucherDetail || isOrderEcontentDetail || isOrderProductDetail) {
      return "주문상세조회"
    }
    if (isOrderMyEContent) {
      return "My e-content"
    }

    if (isInquiryPage) {
      return t("1:1 문의")
    }
    if (isOrderHistory) {
      return t("주문내역")
    }
    if (isOrderDetail) {
      return t("주문상세조회")
    }
    if (isNotification || isNotificationDetail) {
      return t("공지사항")
    }
  }

  const checkHiddenMenu = (item: any) => {
    if (
      user?.userRole === "BRAND" &&
      (item?.link === UrlInternal.VOUCHER_USAGE_HISTORY ||
        item?.link === UrlInternal.HISTORY_ORDER_MEMBERSHIP)
    ) {
      return "hidden"
    }

    if (
      item?.link === UrlInternal.VOUCHER_USAGE_HISTORY &&
      !dataUser?.hasUsageHistory
    ) {
      return "hidden"
    }
    if (
      item?.link === UrlInternal.HISTORY_ORDER_MEMBERSHIP &&
      !dataUser?.hasGbOrder
    ) {
      return "hidden"
    }
    if (
      item?.link === UrlInternal.HISTORY_USING_MEMBERSHIP &&
      !dataUser?.hasTransaction
    ) {
      return "hidden"
    }
    if (
      item?.link === UrlInternal.MY_ORDER_HISTORY &&
      !dataUser?.hasMyVouchers
    ) {
      return "hidden"
    }
    if (
      item?.link === UrlInternal.HISTORY_RECEIVED_GIFT &&
      !dataUser?.hasGift
    ) {
      return "hidden"
    }
    if (item?.link === UrlInternal.MY_E_CONTENT && !dataUser?.hasEcontent) {
      return "hidden"
    }

    return ""
  }

  return (
    <div className="z-10 md:pb-[100px] relative">
      {isMobile ? (
        <div className="relative">
          <NavBarMb
            pageName={convertPageName() || t("MY 페이지")}
            csGoback={() => {
              if (isMypage) {
                navigate("/")
              } else if (isPageDetail) {
                navigate(-1)
              } else {
                navigate("/my-page")
              }
            }}
          />
        </div>
      ) : (
        <div className="container">
          <div className="font-pretendard700 text-[24px] leading-[24px] pb-[24px] border-b-[2px] border-b-primary mt-[60px]">
            {t("MY 페이지")}
          </div>
        </div>
      )}
      <div className="container">
        <div className="flex lg:mt-[37px] mt-[25px]">
          {!isMobile && (
            <div className="flex-none w-[200px]">
              <ul className="flex flex-col gap-[20px]">
                {mypageList.map((item, index) => (
                  <li
                    key={index}
                    // className={`${
                    //   user?.userRole === "BRAND" &&
                    //   (index === 0 || index === 1) &&
                    //   "hidden"
                    // }`}
                    className={checkHiddenMenu(item)}
                  >
                    <Link
                      to={item.link}
                      className={`text-[16px] inline-block ${
                        pathname.includes(item.link) ||
                        pathname.includes(item.subLink)
                          ? "font-pretendard700"
                          : ""
                      }`}
                      // className={`text-[16px] inline-block ${
                      //   pathname === item.link ? "font-pretendard700" : ""
                      // }`}
                    >
                      {t(`${item.title}`)}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyPage
