import useIsMobile from "src/customHook/useIsMobile"

type Props = {
  children?: React.ReactNode
}

const MainTitle: React.FC<Props> = ({ children }) => {
  const { isMobile } = useIsMobile(1023)
  if (!isMobile)
    return (
      <div className="container">
        <h1 className="pt-[60px] pb-[24px] text-[24px] font-pretendard700 leading-[100%]">
          {children}
        </h1>
        <div className="h-[2px] bg-primary block" />
      </div>
    )
}

export default MainTitle
