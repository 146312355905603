import { useEffect } from "react"
import MyPageBanner from "src/components/MyPageBanner"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Button from "src/components/Button"
import { mypageList } from "../config"
import { logout } from "src/features/user/userSlice"
import { useTranslation } from "react-i18next"
import NoData from "@assets/images/my_page/noData.svg"
import { UrlInternal } from "src/constant/endpointRoute"

const MyMembership = () => {
  const { t } = useTranslation()
  const dataUser = useAppSelector(userDataSelect)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  return (
    <div className="">
      <MyPageBanner
        avatar={dataUser?.avatar}
        displayName={dataUser?.displayName}
        memberType="VIP"
        gender={dataUser?.gender}
        totalVip={dataUser?.totalVip}
        dataUser={dataUser}
      />
      <div className="hidden md:block lg:gap-[20px] gap-[16px]">
        {/* <div className="leading-[100%] pb-[11px] border-b-[2px] border-b-primary justify-between flex items-center">
          <div className="font-pretendard700 text-[18px]">{t("MY 멤버십")}</div>
          <div
            className="text-[14px] cursor-pointer"
            onClick={() => navigate(UrlInternal.MY_MEMBERSHIP)}
          >
            + {t("전체보기")}
          </div>
        </div> */}

        {/* {dataUser?.vipMem && dataUser?.vipMem?.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-x-[20px] gap-y-[20px] md:gap-y-[20px] lg:mt-0 mt-[20px] pt-[17px]">
            {dataUser?.vipMem?.slice(0, 10).map((item: any, index: number) => {
              return (
                <div key={index} className="flex-1 flex flex-col pb-[10px]">
                  <div className="relative overflow-hidden border border-solid aspect-square flex justify-center items-center text-primary font-pretendard700 text-[18px]">
                    {item?.logo_image ? (
                      <img
                        src={item?.logo_image}
                        alt=""
                        className="object-cover w-full h-full aspect-square"
                        onClick={() =>
                          navigate(`/my-membership/${item?.brand_id}`)
                        }
                      />
                    ) : (
                      item.brand_name
                    )}
                  </div>
                  <div className="mt-[12px]">
                    <div className="flex items-center gap-[15px]">
                      <p
                        className="font-pretendard400 text-[14px] xs:text-[16px] text-primary line-clamp-2 leading-none"
                        onClick={() =>
                          navigate(`/my-membership/${item?.brand_id}`)
                        }
                      >
                        {item?.brand_name}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full mt-[120px]">
            <NoData />
            <p className="mt-[20px] text-[20px] leading-[32px]">
              현재 맴버십을 보유하지 않습니다.
            </p>
            <Link
              to="/group-buying"
              className="text-[20px] leading-[32px] text-primaryBlue underline"
            >
              맴버십 구입하러 가기
            </Link>
          </div>
        )} */}
      </div>

      <div className="flex-none block md:hidden">
        <ul className="flex flex-col gap-[20px]">
          {mypageList.map((item, index) => (
            <li key={index}>
              <Link
                to={item.link}
                className={`text-[16px] flex justify-between inline-block ${
                  pathname === item.link ? "font-pretendard700" : ""
                }`}
              >
                {t(item.title)}
              </Link>
            </li>
          ))}
        </ul>

        <div className="mt-[100px] w-full">
          <Button
            variant={"outline"}
            theme="main"
            onClick={() => dispatch(logout())}
          >
            {t("로그아웃")}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MyMembership
