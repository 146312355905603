import { Form } from "antd"
import { useEffect } from "react"
import Button from "src/components/Button"
import CSInput from "src/components/Input/Index"
import CommonModal from "src/components/Modal"
import { Postcode } from "src/components/PostCode"

interface Props {
  isOpen: boolean
  handleClose: () => void
  getDataModalChangeInfo?: (e: any) => void
  data: any
}

function ModalChangeInfoTransfer({
  isOpen,
  handleClose,
  getDataModalChangeInfo,
  data,
}: Props) {
  const [form] = Form.useForm()

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        bankAccountName: data?.userBankAccountName,
        // bankName: data?.bankName,
        // bankAccountNumber: data?.bankAccountNumber,
      })
    }
  }, [data])

  const onFinish = (values: any) => {
    console.log("values", values)
    getDataModalChangeInfo?.(values)

    // handleClose()
  }

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} isCloseOutside>
      <div className="leading-[100%] font-pretendard400 text-[#000]">
        <div className="text-[20px] font-pretendard500 mb-[28px]">
          Change transfer information
        </div>
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          form={form}
        >
          <div className="text-[16px] pb-[12px] font-pretendard600">
            입금자명
          </div>
          <CSInput name="bankAccountName" required />
          {/* <div className="text-[16px] pb-[12px] font-pretendard600">은행명</div>
          <CSInput name="bankName" required />
          <div className="text-[16px] pb-[12px] font-pretendard600">
            계좌번호
          </div>
          <CSInput name="bankAccountNumber" required /> */}

          <Button
            theme="main"
            mainStyle={{
              marginTop: 35,
            }}
          >
            저장
          </Button>
        </Form>
      </div>
    </CommonModal>
  )
}

export default ModalChangeInfoTransfer
