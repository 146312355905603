import LogoIcon from "@assets/icons/logo.svg"
import jwt_decode from "jwt-decode"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { useAppDispatch } from "src/app/hooks"
import { useAuth } from "src/customHook/useAuth"
import useIsMobile from "src/customHook/useIsMobile"
import { loginSuccess } from "src/features/user/userSlice"
import NavBarMb from "src/layout/Header/NavBarMb"
import LoginForm from "./LoginForm"
import SNSGroup from "./SNSGroup"
import { message } from "antd"

// dev
// const clientAppleId = "dev-api.moafriends.com"
// const clientGoogleId =
//   "291660065549-g58nho1i1h3fborbhsaeullaucmgso3c.apps.googleusercontent.com"

//main
// const clientAppleId = 'api.moafriends.com';
// const clientGoogleId = '756862647868-lechbgjc9mt5h8h3pbot7bcsfmkjosat.apps.googleusercontent.com';

// const urlKakaoLogin =
//   "https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=64c873359b4ab8fbd6dfeefd9343e2c3&redirect_uri=https://dev-api.moafriends.com/api/v1/auth/callback-kakao"
// const urlNaverLogin =
//   "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=zTGA0mZlrkL_Ozo0tnZI&redirect_uri=https://dev-api.moafriends.com/api/v1/auth/callback-naver"
// const urlGoogleLogin = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&access_type=offline&redirect_uri=${
//   import.meta.env.VITE_API_URL
// }/auth/callback-google&response_type=code&client_id=${clientGoogleId}`
// const urlAppleLogin = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id=${clientAppleId}&redirect_uri=${
//   import.meta.env.VITE_API_URL
// }/auth/callback-apple&state=YOUR_STATE&scope=email%20name&response_mode=form_post`

type Props = {}

function base64UrlDecode(base64Url: string) {
  base64Url = base64Url.replace(/-/g, "+").replace(/_/g, "/")

  while (base64Url.length % 4) {
    base64Url += "="
  }

  return atob(base64Url)
}

function UserAuth({}: Props) {
  const { isLogined } = useAuth()
  const { t } = useTranslation()
  const [searchParam] = useSearchParams()
  const [messageApi, contextHolder] = message.useMessage()
  const dataFromParams = searchParam.get("data")

  const dataParseFromParams = dataFromParams
    ? JSON.parse(base64UrlDecode(dataFromParams))
    : {}

  const backUrl = dataFromParams
    ? `${dataParseFromParams?.redirectUrl}`
    : searchParam.get("backUrl") || "/"

  // const backUrl = searchParam.get("backUrl") || "/"
  const [isRegister, setIsRegister] = useState<boolean>(false)
  const navigate = useNavigate()
  const { hash } = useLocation()
  const { isMobile } = useIsMobile(1023)
  const dispatch = useAppDispatch()

  const errorCodeFromParams = searchParam.get("errorCode")
  const receiverNameFromParams = searchParam.get("receiverName")

  useEffect(() => {
    if (errorCodeFromParams === "INVALID_RECEIVER") {
      messageApi.open({
        type: "error",
        content: `선물을 받은 계정 (${receiverNameFromParams})으로 로그인해 주세요.`,
      })
    }
    if (errorCodeFromParams === "INVALID_GIFT_CODE") {
      messageApi.open({
        type: "error",
        content: `선물을 받은 계정 으로 로그인해 주세요.`,
      })
    }
  }, [errorCodeFromParams])

  // useEffect(() => {
  //   const token = searchParam.get("accessToken")
  //   if (token) {
  //     const decoded: any = jwt_decode(token)
  //     dispatch(loginSuccess({ ...decoded, accessToken: token }))
  //   }
  // }, [dispatch, searchParam])

  useEffect(() => {
    setIsRegister(hash === "#register")
  }, [hash])

  if (isLogined) {
    return <Navigate to={backUrl} />
  }

  return (
    <div>
      {contextHolder}
      {isMobile && <NavBarMb pageName={isRegister ? "Register" : "Login"} />}
      <div className="w-full max-w-[527px] m-auto lg:pt-[71px] pt-[50px] lg:pb-[123px] pb-[60px] px-[16px]">
        {isMobile ? (
          <div className="flex justify-center mb-[28px]">
            <LogoIcon />
          </div>
        ) : (
          <>
            <h3 className="text-center text-[24px] leading-normal text-black font-pretendard400 mb-[8px]">
              {t("로그인")}
            </h3>
            <p className="text-center text-[16px] leading-[100%] text-gray1 font-pretendard400 mb-[48px]">
              {t("모아프렌즈에서 프리미엄 멤버십 서비스와 혜택을 누리세요.")}
            </p>
          </>
        )}
        <LoginForm />
        <SNSGroup />
      </div>
    </div>
  )
}

export default UserAuth
