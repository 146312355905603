// import GoogleIcon from "@assets/icons/social/Google.svg"
import AppleIcon from "@assets/icons/social/Apple.svg"
import KakaoIcon from "@assets/icons/social/Kakao.svg"
import NaverIcon from "@assets/icons/social/Naver.svg"
import GoogleIcon from "@assets/icons/social/Google.svg"
import avatarDefaultMale from "@assets/images/my_page/avt-default-male.png"
import avatarDefaultFemale from "@assets/images/my_page/avt-default-female.png"
import { UrlInternal } from "src/constant/endpointRoute"

export const mypageList = [
  {
    title: "멤버십 주문내역",
    link: UrlInternal.HISTORY_ORDER_MEMBERSHIP,
    subLink: UrlInternal.MY_PAGE_ORDER_DETAIL,
  },
  {
    title: "선물받은 내역",
    link: UrlInternal.HISTORY_RECEIVED_GIFT,
    subLink: UrlInternal.HISTORY_RECEIVED_GIFT,
  },
  {
    title: "MY월렛 이용내역",
    link: UrlInternal.HISTORY_USING_MEMBERSHIP,
    subLink: UrlInternal.HISTORY_USING_MEMBERSHIP,
  },
  {
    title: "공지사항",
    link: UrlInternal.NOTIFICATION,
    subLink: UrlInternal.NOTIFICATION,
  },
  {
    title: "1:1 문의",
    link: "/my-page/inquiry",
    subLink: "/my-page/inquiry",
  },
  {
    title: "회원정보수정",
    link: "/my-page/my-change-info",
    subLink: "/my-page/my-change-info",
  },
  {
    title: "My order history",
    link: UrlInternal.MY_ORDER_HISTORY,
    subLink: UrlInternal.MY_ORDER_HISTORY,
  },
  {
    title: "My e-content",
    link: UrlInternal.MY_E_CONTENT,
    subLink: UrlInternal.MY_E_CONTENT,
  },
  {
    title: "Voucher usage history",
    link: UrlInternal.VOUCHER_USAGE_HISTORY,
    subLink: UrlInternal.VOUCHER_USAGE_HISTORY,
  },
]

// export const mypageList = [
//   {
//     title: "Voucher usage history",
//     link: UrlInternal.VOUCHER_USAGE_HISTORY,
//     subLink: UrlInternal.VOUCHER_USAGE_HISTORY,
//   },
//   {
//     title: "멤버십 구매 내역",
//     link: UrlInternal.HISTORY_ORDER_MEMBERSHIP,
//     subLink: UrlInternal.MY_PAGE_ORDER_DETAIL,
//   },
//   {
//     title: "프리미엄 멤버십 사용 내역",
//     link: UrlInternal.HISTORY_USING_MEMBERSHIP,
//     subLink: UrlInternal.HISTORY_USING_MEMBERSHIP,
//   },
//   {
//     title: "My order history",
//     link: UrlInternal.MY_ORDER_HISTORY,
//     subLink: UrlInternal.MY_ORDER_HISTORY,
//   },
//   {
//     title: "선물 받은 내역",
//     link: UrlInternal.HISTORY_RECEIVED_GIFT,
//     subLink: UrlInternal.HISTORY_RECEIVED_GIFT,
//   },
//   {
//     title: "My e-content",
//     link: UrlInternal.MY_E_CONTENT,
//     subLink: UrlInternal.MY_E_CONTENT,
//   },
//   {
//     title: "공지사항",
//     link: UrlInternal.NOTIFICATION,
//     subLink: UrlInternal.NOTIFICATION,
//   },
//   {
//     title: "1:1 문의",
//     link: "/my-page/inquiry",
//     subLink: "/my-page/inquiry",
//   },
//   {
//     title: "회원정보 수정",
//     link: "/my-page/my-change-info",
//     subLink: "/my-page/my-change-info",
//   },
// ]
// export const mypageList = [
//   {
//     title: "주문 내역",
//     link: "/my-page/order-history",
//   },
//   {
//     title: "쿠폰",
//     link: "",
//   },
//   {
//     title: "1:1 문의",
//     link: "/my-page/inquiry",
//   },
//   {
//     title: "회원정보수정",
//     link: "/my-page/my-change-info",
//   },
// ]

export const socialListIcon = {
  NAVER: <NaverIcon />,
  GG: <GoogleIcon />,
  KAKAO: <KakaoIcon />,
  APPLE: <AppleIcon />,
}

export const listDefaultAvt = {
  MALE: avatarDefaultMale,
  FEMALE: avatarDefaultFemale,
}
