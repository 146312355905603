import { Alert, Space } from "antd"
import { useEffect, useState } from "react"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { confirmCodeGift } from "src/api/confirmCodeGift"
import { useAppDispatch } from "src/app/hooks"
import Button from "src/components/Button"
import CommonModal from "src/components/Modal"
import { UrlInternal } from "src/constant/endpointRoute"
import { logout } from "src/features/user/userSlice"

function ReceivedGift() {
  const params = useParams()
  const code = params.slug
  const navigate = useNavigate()
  const dispath = useAppDispatch()
  const [isTypeError, setIsTypeError] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  const getConfirmCodeGift = async (code: string) => {
    try {
      const resp: any = await confirmCodeGift({
        code,
      })

      if (resp.data?.id) {
        navigate(`${UrlInternal.HISTORY_RECEIVED_GIFT}/${resp.data?.id}`)
      } else {
        if (resp.errorCode === "INVALID_RECEIVER") {
          await dispath(logout())
          await setSearchParams((params) => {
            params.set("errorCode", "INVALID_RECEIVER")
            params.set("receiverName", resp?.data?.receiverName)
            return params
          })
        }
        if (resp.errorCode === "INVALID_GIFT_CODE") {
          await dispath(logout())
          await setSearchParams((params) => {
            params.set("errorCode", "INVALID_GIFT_CODE")
            return params
          })
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (code) {
      getConfirmCodeGift(code)
    }
  }, [code])

  return (
    <>
      {/* {isTypeError ? (
        <div className="fixed inset-0 bg-[#000] z-[100000]">
          <div className="max-w-[450px] mx-auto mt-[20vh]">
            <Alert
              // message="You are logging into an account that is not the recipient of the gift"
              showIcon
              description="You are logging into an account that is not the recipient of the gift"
              type="error"
              action={
                <Button
                  onClick={() => dispath(logout())}
                  className="px-[25px] bg-[#000] text-[#fff] py-[5px] ml-[5px]"
                >
                  Confirm
                </Button>
              }
            />
          </div>
        </div>
      ) : ( */}
      <div className="fixed inset-0 bg-[#000] z-[100000]">
        <div className="flex space-x-2 justify-center items-center bg-white h-screen dark:invert">
          <span className="sr-only">Loading...</span>
          <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-8 w-8 bg-black rounded-full animate-bounce"></div>
        </div>
      </div>
      {/* )} */}
    </>
  )
}

export default ReceivedGift
