import NavBarMb from "src/layout/Header/NavBarMb"
import CommonModal, { CommonModalProps } from ".."
import "./index.scss"
import useIsMobile from "src/customHook/useIsMobile"

interface Props extends CommonModalProps {
  open: boolean
  handleClose?: () => void
  data: any
}

function Modal3rdPartMembership({ data, open, handleClose, ...props }: Props) {
  const { isMobile } = useIsMobile(475)

  return (
    <CommonModal
      width={375}
      onCancel={() => {
        handleClose && handleClose()
      }}
      open={open}
      className="text-[#000] modal-3rd-part-membership modal-fullpage"
      {...props}
    >
      {isMobile && (
        <>
          <NavBarMb
            pageName="개인정보 제3자 제공"
            className="mx-[-24px]"
            csGoback={() => handleClose?.()}
          />
        </>
      )}

      <h2 className="text-[18px] font-pretendard700 leading-normal mt-[-25px] text-center xs:block hidden">
        개인정보 제3자 제공
      </h2>
      <div className="xs:mt-[45px] mt-[24px] mb-[40px] text-[18px] font-pretendard700">
        멤버십 개인정보 제3자 제공
      </div>

      <div className="border-t-[2px] border-b-[2px] border-[#000000]">
        <div className="flex border-b border-b-[#E5E5E5]">
          <div className="w-[98px] text-[#757575] px-[12px] bg-[#F5F5F5] py-[8px]">
            제공받는자
          </div>
          <div className="flex-1 px-[12px] py-[8px]">{data?.brandName}</div>
        </div>
        <div className="flex border-b border-b-[#E5E5E5]">
          <div className="w-[98px] text-[#757575] px-[12px] bg-[#F5F5F5] py-[8px]">
            제공 목적
          </div>
          <div className="flex-1 px-[12px] py-[8px]">
            브랜드의 상품, 서비스, 멤버십 <br /> 운영 등 서비스 제공, 계약의
            이행(이용자 및 이용정보 확인), 민원처리 등 소비자 분쟁 해결
          </div>
        </div>
        <div className="flex border-b border-b-[#E5E5E5]">
          <div className="w-[98px] text-[#757575] px-[12px] bg-[#F5F5F5] py-[8px]">
            제공 항목
          </div>
          <div className="flex-1 px-[12px] py-[8px]">
            멤버십 회원 정보 <br />
            (회원번호, 회원명, 전화번호, <br />
            이메일주소)
          </div>
        </div>
        <div className="flex border-b border-b-[#E5E5E5]">
          <div className="w-[98px] text-[#757575] px-[12px] bg-[#F5F5F5] py-[8px]">
            이용 및 <br /> 보유기간
          </div>
          <div className="flex-1 px-[12px] py-[8px]">
            개인정보 이용목적 달성 시까지 <br />
            단, 관계법령에 의하여 보존할
            <br /> 필요성이 있는 경우 그 시점까지
            <br /> 보존 후 지체없이 파기
          </div>
        </div>
      </div>

      <div className="flex gap-[10px] mt-[10px]">
        <div className="min-w-[3px] h-[3px] bg-[#666666] rounded-full mt-[8px]"></div>
        <div className="text-[13px] text-[#666666]">
          위의 개인정보 제3자 제공에 대한 동의를 거부할 권리가 있습니다. 그러나
          동의를 거부할 경우 서비스 이용이 제한됩니다.
        </div>
      </div>
    </CommonModal>
  )
}

export default Modal3rdPartMembership
