import type { TabsProps } from "antd"
import { Tabs } from "antd"
import React from "react"
import "./style.scss"

interface Props extends TabsProps {
  items: TabsProps["items"]
  theme: "detailTab" | "vipTab" | "fence"
  onChange?: (key: string) => void
}

const TabsComp: React.FC<Props> = ({ items, theme, onChange, ...rest }) => {
  return (
    <div className="custom-tabs">
      <div className={theme}>
        <Tabs items={items} onChange={onChange} {...rest} />
      </div>
    </div>
  )
}

export default TabsComp
