import React from "react"
import { useTranslation } from "react-i18next"
import useIsMobile from "src/customHook/useIsMobile"
import DataEn from "./DataEn"
import DataKr from "./DataKr"

type Props = {}

const TermsOfUse: React.FC<Props> = () => {
  const { i18n } = useTranslation()
  const { isMobile } = useIsMobile(1023)

  const getContent = () => {
    if (i18n.language === "en") {
      return <DataEn />
    } else {
      return <DataKr />
    }
  }

  return (
    <div className="container">
      <div className="mx-auto px-[0] xs:px-[16px] lg:pt-[70px] pb-[40px] xs:pb-[64px]">
        <div
          className={
            isMobile ? "py-[20px] px-[8px] bg-white rounded-[15px]" : ""
          }
        >
          <div
            className={`bg-bgTextTop bg-contain flex items-center ${"border-solid border-b-2 border-b-primary pb-[15px] xs:pb-[24px]"}`}
          >
            <h1
              className={`${
                isMobile ? "text-[18px]" : "text-[24px]"
              } text-primary uppercase font-pretendard700 leading-none`}
            >
              {i18n.language === "en" ? "Terms of Service" : "서비스 이용약관"}
            </h1>
          </div>
          <div
            className={`shadow-custom7 ${"lg:h-[532px] h-[calc(100vh-230px)] pt-[32px] pb-[42px] border border-solid mt-[30px]"}`}
          >
            <div
              className={`scroll-style-1 overflow-y-auto h-full ${
                isMobile ? "pl-[16px] pr-[16px]" : "px-[28px]"
              }`}
            >
              {getContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse
