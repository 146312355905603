import { Form } from "antd"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { userApi } from "src/api/userApi"
import { useAppDispatch } from "src/app/hooks"
import CSInput from "src/components/Input/Index"
import CommonModal, { CommonModalProps } from "src/components/Modal"
import { useCountTime } from "src/customHook/useCountTime"
import { logout } from "src/features/user/userSlice"
import { secondTo } from "src/util"

type DeactiveAccountProps = {
  handleClose: () => void
  handleOpenForgotPassword?: () => void
  timeOtp?: number
} & CommonModalProps

const DeactiveAccountSns: React.FC<DeactiveAccountProps> = ({
  handleClose,
  handleOpenForgotPassword,
  timeOtp,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  // const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { countTime, handleSetCountTime } = useCountTime(0)

  const onFinish = async (values: any) => {
    // console.log(values)

    try {
      const otp: number = +values.otp
      const trxUID = searchParams.get("trxUID")
      const params: { otp: number; trxUID?: string } = { otp }

      if (trxUID) {
        params.trxUID = trxUID
      }

      const resp: any = await userApi.deactiveAccountSns(params)
      // console.log(resp);
      if (resp.errorCode === "INVALID_OTP") {
        form.setFields([
          {
            name: "otp",
            errors: [
              t(
                "OTP is not correct. Please input the correct OTP you received.",
              ),
            ],
          },
        ])
        return
      } else if (resp.errorCode === "EXPIRED_OTP") {
        form.setFields([
          {
            name: "otp",
            errors: [t("OTP has expired. Please request a new OTP.")],
          },
        ])
        return
      }
      dispatch(logout())
    } catch (error) {
      console.log(error)
    }
  }

  const handleResendOtp = async () => {
    try {
      const resp: any = await userApi.getOtp()
      if (resp.result) {
        handleSetCountTime(60)
      }
    } catch (error) {
      console.error(error)
    }
  }

  // useEffect(() => {
  //   if (timeOtp) {
  //     handleSetCountTime(timeOtp)
  //   }
  // }, [timeOtp])

  useEffect(() => {
    if (isOpen === false) {
      form.resetFields()
    }
  }, [isOpen])

  useEffect(() => {
    const sentOtp = async () => {
      try {
        const resp: any = await userApi.getOtp()
        if (resp.result) {
          handleSetCountTime(60)
        }
      } catch (error) {
        console.error(error)
      }
    }
    sentOtp()
  }, [])

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} {...props}>
      <p className="font-pretendard500 text-[20px]">{t("계정 비활성화")}</p>
      <p className="text-gray1 text-[14px]">
        {t("Enter the OTP has been sent to your email")}
      </p>
      <div className="mt-[28px]">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
          form={form}
        >
          <CSInput
            inputType="text"
            name="otp"
            placeholder={t("입력하세요.")}
            inputHeight={48}
            borderColor="#D4D4D4"
            rules={[
              {
                required: true,
              },
            ]}
            onChange={(e) => {
              const inputValue = e.target.value
              const numericValue = inputValue.replace(/\D/g, "")
              form.setFieldValue("otp", numericValue)
            }}
          />
          <div className="flex items-center gap-[10px] mb-[40px]">
            <button
              className="w-full border-[1px] border-primary h-[48px]"
              onClick={handleClose}
              type="button"
            >
              {t("Cancel")}
            </button>
            <button className="bg-primary w-full h-[48px] font-pretendard700 text-primaryWhite">
              {t("Deactivate account")}
            </button>
          </div>
          <p className="mt-[40px] text-center text-[14px] font-pretendard400 text-gray1">
            {t("Did not receive?")}{" "}
            {countTime > 0 ? (
              <>
                {t("Resend after")}{" "}
                <span className="text-[14px] font-pretendard700 text-primary">
                  {secondTo(countTime)}s
                </span>
              </>
            ) : (
              <span
                onClick={handleResendOtp}
                className="text-[14px] text-primaryBlue underline cursor-pointer"
              >
                {t("Resend OTP via mail")}
              </span>
            )}
          </p>
        </Form>
      </div>
    </CommonModal>
  )
}

export default DeactiveAccountSns
