import { useEffect, useState } from "react"
import { matchPath, useLocation } from "react-router-dom"

function useCheckMatchPath(pattern: string) {
  const { pathname } = useLocation()
  const [isMatch, setIsMatch] = useState<boolean>()
  useEffect(() => {
    if (matchPath(pattern, pathname)) {
      setIsMatch(true)
    } else {
      setIsMatch(false)
    }
  }, [pathname])
  return isMatch
}

export default useCheckMatchPath
