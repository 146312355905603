import logo from "@assets/imgs/moafriends_logo.png"
import { Link } from "react-router-dom"

interface HeaderProps {}
const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="max-w-[1432px] px-[16px] m-auto flex gap-[16px] items-center justify-between lpSm:py-[20px] py-[18px] border-b border-solid border-[#E8E8E8]">
      <Link to="/">
        <img
          className="lpSm:w-auto lp2Xs:w-[184.33px] w-[138.25px]"
          src={logo}
          alt="logo"
        />
      </Link>
      <a
        target="_blank"
        href="https://dev-brand.moafriends.com/auth"
        className="bg-[#333333] text-[#fff] lpSmm:h-[45px] lpXs:h-[38px] h-[35px] rounded-[50px] lpSm:text-[16px] text-[14px] lpSmm:leading-[45px] lpXs:leading-[38px] leading-[35px] text-center px-[16px] min-w-[160px]"
      >
        브랜드 로그인
      </a>
    </div>
  )
}

export default Header
