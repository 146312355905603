import { ButtonHTMLAttributes, CSSProperties, FC } from "react"

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  disabled?: boolean
  loading?: boolean
  labelSize?: number
  variant?: "primary" | "outline"
  theme?: "main" | "custom"
  width?: number | "100%"
  height?: number
  paddingX?: number
  onClick?: () => void
  mainStyle?: CSSProperties
}

// Component Button
const Button: FC<ButtonProps> = ({
  variant = "primary",
  children,
  className,
  theme,
  onClick,
  width,
  height,
  disabled = false,
  labelSize,
  paddingX,
  mainStyle,
  ...rest
}) => {
  const mainClasses = `text-[16px] ${
    disabled
      ? "bg-gray3 text-gray2"
      : variant === "primary"
      ? "bg-black text-white"
      : "border border-black"
  } font-pretendard700 h-[48px] lg:h-[56px]`

  const buttonStyle: CSSProperties = {
    fontSize: `${labelSize}px`,
    width: width ? (typeof width === "number" ? `${width}px` : width) : "100%",
    height: `${height}px`,
    padding: `0px ${paddingX}px`,
    ...mainStyle,
  }

  return (
    <button
      className={`${
        theme === "main" ? mainClasses : className
      } flex items-center justify-center`}
      style={theme === "main" ? buttonStyle : {}}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button

{
  /* 
khác theme="main" truyền theme="custom" và styles theo class taiwindcss

<Button  
  theme="main"
>
  main
</Button> 

<Button variant="outline" theme="main">
    main outline
</Button>

<Button  
  theme="main" 
  disabled={true}
>
  main disabled
</Button> 

<Button theme="custom" className="h-[26px] text-[15px] text-white bg-primaryGreen w-fit px-[15px]">
  In progress
</Button>

<Button theme="custom" className="h-[26px] text-[15px] text-white bg-primaryRed w-fit px-[15px]">
  Finish
</Button>

<Button theme="custom" className="h-[26px] text-[15px] text-white bg-primaryYellow w-fit px-[15px]">
  Sold out
</Button>

<Button theme="custom" className="h-[26px] text-[15px] text-white bg-primaryBlue w-fit px-[15px]">
  Upcoming
</Button>


*/
}
