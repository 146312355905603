import Selection from "@components/Selection"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { sucriberKakao } from "src/api/brandApi"
import Button from "src/components/Button"
import { UrlInternal } from "src/constant/endpointRoute"
import useIsMobile from "src/customHook/useIsMobile"
import { formatPrice } from "src/util/formatPrice"
import CommonModal, { CommonModalProps } from ".."
import ArrowRightImg from "@assets/images/mymembership/next.png"
import "./index.scss"
import NavBarMb from "src/layout/Header/NavBarMb"

const options = [
  {
    label: "일시불",
    value: "one",
  },
  {
    label: "2개월",
    value: "2month",
  },
  {
    label: "3개월",
    value: "3month",
  },
  {
    label: "4개월",
    value: "4month",
  },
  {
    label: "5개월",
    value: "5month",
  },
  {
    label: "6개월",
    value: "6month",
  },
]

interface Props extends CommonModalProps {
  open: boolean
  handleClose?: () => void
  handleConfirm?: () => void
  data: any
  handleOpenModal3rdPart?: () => void
}

type TypePayment = "TOSS" | "KAKAO" | "NAVER"

const RegisterMembership: React.FC<Props> = ({
  handleClose,
  handleConfirm,
  data,
  handleOpenModal3rdPart,
  ...props
}) => {
  const { t } = useTranslation()
  const { isMobile } = useIsMobile(475)
  const [checked, setChecked] = useState<Record<string, boolean>>({
    agree: false,
  })
  const [typePayment, seTypePayment] = useState<TypePayment>("TOSS")
  const navigate = useNavigate()
  const refKakao: any = useRef()

  console.log("data113", data)

  const onSubmit = async () => {
    if (typePayment === "TOSS") {
      navigate(`${UrlInternal.TOSS_MEMBERSHIP}/${data?.id}`, {
        state: data,
      })
      // dispatch(closeLoading())
    }
    if (typePayment === "KAKAO") {
      try {
        const res: any = await sucriberKakao({
          brandId: data?.id,
        })

        if (res.result) {
          window.location.href = res.data
        }
        // refKakao.current && refKakao.current.submit()
      } catch (error) {}
    }
  }

  return (
    <>
      <form
        ref={refKakao}
        action={`${
          import.meta.env.VITE_API_URL
        }/payment/request-pay-membership`}
        method="post"
        hidden
      >
        <input type="text" name="brandId" value={data?.brandId} />
      </form>

      <CommonModal
        width={450}
        onCancel={() => {
          handleClose && handleClose()
        }}
        className="text-[#000] modal-fullpage"
        {...props}
      >
        {isMobile && (
          <>
            <NavBarMb
              pageName="멤버십결제"
              className="mx-[-20px]"
              csGoback={() => handleClose?.()}
            />

            <div className="fixed left-0 right-0 bottom-0">
              <Button theme="main" onClick={onSubmit} disabled={!checked.agree}>
                결제하기
              </Button>
            </div>
          </>
        )}

        <div className={isMobile ? "m-0" : "mx-[-16px]"}>
          <h2 className="text-[20px] font-pretendard700 leading-normal mt-[-25px] text-center xs:block hidden">
            {t("멤버십결제")}
          </h2>

          <div className="xs:pt-[36px] pt-[24px] pb-[35px]">
            <h3 className="text-[18px] font-pretendard700 leading-[100%] mb-[22px]">
              멤버십 상품 정보
            </h3>
            <div className="flex gap-[17px]">
              <div className="border border-gray5 w-[88px] h-[88px]">
                <img className="" src={data?.logoImage} alt="logo brand" />
              </div>
              <div className="flex flex-col gap-[8px]">
                <div className="font-pretendard700 leading-[100%]">
                  {data?.brandName}
                </div>
                <div className="leading-[100%]">{data?.membershipName}</div>
                <div className="leading-[100%]">
                  월{" "}
                  <span className="font-pretendard700">
                    {formatPrice(Number(data?.sellingPrice))}원
                  </span>
                </div>
                <div className="leading-[100%]">정기구독(월간)</div>
              </div>
            </div>
          </div>

          <div className="h-[16px] bg-gray3 block mx-[-24px]" />

          <div className="pt-[44px] pb-[55px]">
            <h3 className="text-[18px] font-pretendard700 leading-[100%] mb-[22px]">
              결제 방법
            </h3>
            <div className="grid gap-[8px] mb-[23px]">
              <div
                className={`cursor-pointer text-[14px] border border-[#D4D4D4] h-[44px] text-center leading-[44px] ${
                  typePayment === "TOSS"
                    ? "font-pretendard700 bg-[#000] text-[#fff]"
                    : "text-[#000]"
                }`}
                onClick={() => seTypePayment("TOSS")}
              >
                신용/체크카드
              </div>
            </div>
          </div>

          <div className="h-[2px] bg-black block" />
          <div className="pt-[35px] pb-[34px] flex flex-col gap-[16px]">
            <div className="flex justify-between">
              <span className="text-[#757575] leading-[100%]">멤버십 금액</span>
              <span className="font-pretendard700 leading-[100%]">
                {formatPrice(Number(data?.rootPrice))}원
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#757575] leading-[100%]">할인</span>
              <span className="font-pretendard700 leading-[100%]">
                {formatPrice(
                  Number(data?.rootPrice) - Number(data?.sellingPrice),
                )}
                원
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-[18px] font-pretendard700 leading-[100%]">
                결제 금액
              </span>
              <span className="text-[18px] font-pretendard700 leading-[100%] text-[#539165]">
                {formatPrice(Number(data?.sellingPrice))}원
              </span>
            </div>
          </div>
          <div className="h-[2px] bg-black block" />

          <div className="pt-[25px] pb-[23px] pl-[4px]">
            <Selection
              onSelectChange={(checked) => setChecked(checked)}
              borderColor="#D4D4D4"
              labelStyle={{
                fontSize: "12px",
                lineHeight: "100%",
                fontFamily: "Pretendard-400",
              }}
              selectOption={[
                {
                  label: "필수 약관 및 매월 자동결제에 동의합니다.",
                  name: "agree",
                  defaultChecked: checked.agree,
                },
              ]}
            />
          </div>
          <div className="h-[1px] bg-[#E5E5E5] block" />

          <div className="pt-[19px] pb-[48px]">
            <div
              className="flex items-center gap-[6px] cursor-pointer"
              onClick={() =>
                window.open(`${window.location.origin}/privacy-policy`)
              }
            >
              <div className="text-[13px] text-[#666666] font-pretendard400 leading-[100%]">
                (필수) 개인정보 수집 및 이용
              </div>
              <img src={ArrowRightImg} alt="img" />
            </div>
            <div
              className="flex items-center gap-[6px] cursor-pointer mt-[14px]"
              onClick={() =>
                window.open(`${window.location.origin}/terms-of-use`)
              }
            >
              <div className="text-[13px] text-[#666666] font-pretendard400 leading-[100%]">
                (필수) 결제서비스 이용 약관
              </div>
              <img src={ArrowRightImg} alt="img" />
            </div>
            <div
              className="flex items-center gap-[6px] cursor-pointer mt-[14px]"
              onClick={() => {
                handleClose?.()
                handleOpenModal3rdPart?.()
              }}
            >
              <div className="text-[13px] text-[#666666] font-pretendard400 leading-[100%]">
                (필수) 제3자 정보 제공
              </div>
              <img src={ArrowRightImg} alt="img" />
            </div>
          </div>

          <div className="xs:pb-[45px]">
            <h5 className="text-[14px] text-[#666666] font-pretendard700 leading-[100%] mb-[16px]">
              멤버십 유의사항
            </h5>

            <div className="flex flex-col gap-[3px]">
              <div className="flex gap-[10px]">
                <div className="min-w-[3px] h-[3px] bg-[#666666] rounded-full mt-[8px]"></div>
                <div className="text-[13px] text-[#666666]">
                  정기 구독 결제는 구독 기간 마지막 날 등록된 카드로 자동 <br />
                  결제되며, 구독 기간은 자동 갱신됩니다.
                </div>
              </div>
              <div className="flex gap-[10px]">
                <div className="min-w-[3px] h-[3px] bg-[#666666] rounded-full mt-[8px]"></div>
                <div className="text-[13px] text-[#666666]">
                  구독 유지를 원하지 않으실 경우, 다음 결제일 하루 전까지 직접{" "}
                  <br />
                  구독을 해지하셔야 합니다.
                </div>
              </div>
              <div className="flex gap-[10px]">
                <div className="min-w-[3px] h-[3px] bg-[#666666] rounded-full mt-[8px]"></div>
                <div className="text-[13px] text-[#666666]">
                  앱을 삭제하거나 회원 탈퇴를 진행해도 구독 해지를 완료하지 않을
                  경우 자동 결제가 유지됩니다.
                </div>
              </div>
              <div className="flex gap-[10px]">
                <div className="min-w-[3px] h-[3px] bg-[#666666] rounded-full mt-[8px]"></div>
                <div className="text-[13px] text-[#666666]">
                  결제 후 7일 이내 멤버십 혜택 이용 이력이 없는 경우 전액 환불이{" "}
                  <br />
                  가능합니다.
                </div>
              </div>
            </div>
          </div>

          <div className="mb-[-40px] mx-[-24px] xs:block hidden">
            <Button
              theme="main"
              height={56}
              onClick={onSubmit}
              disabled={!checked.agree}
            >
              결제하기
            </Button>
          </div>
        </div>
      </CommonModal>
    </>
  )
}

export default RegisterMembership
