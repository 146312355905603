import PlusIcon from "@assets/icons/Plus.svg"
import { useNavigate } from "react-router-dom"
import logoBrandDefault from "src/assets/images/defaultBrandLogo.png"

import { TBRAND } from "src/models"
type Props = {
  plusBtn?: boolean
  size?: string
}
const BrandItem: React.FC<TBRAND & Props> = ({
  id,
  plusBtn,
  logoImage,
  brandName,
  size = "s",
}) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={() => navigate(`/brand-detail/${id}`)}
      className="flex flex-col justify-start items-center cursor-pointer"
    >
      <div className="bg-lightColor rounded-full max-w-[136px] max-h-[136px] overflow-hidden w-full border-[#E7E7E7] border-solid border-[1px]">
        <div
          style={{ backgroundImage: `url(${logoImage || logoBrandDefault})` }}
          className="flex flex-col items-center pt-[100%] bg-no-repeat bg-center bg-contain"
        ></div>
      </div>
      <div className="flex gap-[10px] items-start mt-[16px]">
        <h4 className="font-pretendard500 text-[11px] sm:text-[15px] text-center">
          {brandName}
        </h4>
        {plusBtn && (
          <div className="w-[25px] h-[25px] bg-primary4 items-center justify-center hidden sm:flex">
            <PlusIcon />
          </div>
        )}
      </div>
    </div>
  )
}

export default BrandItem
