import moment from "moment"

export const handleDate = (date: string) => {
  if (moment().diff(moment(date), "day") >= 10) {
    return moment(date).format("YYYY/MM/DD")
  } else {
    return moment(date).fromNow()
  }
}

export const handleDateNew = (date: string, lang: string) => {
  if (moment(date).diff(moment(), "seconds") > -60) {
    if (lang === "kor") {
      return "방금전"
    } else {
      return "just now"
    }
  } else {
    return handleDate(date)
  }
}

export const handleDateCommunity = (date: string) => {
  if (moment().diff(moment(date), "day") >= 10) {
    if (moment().format("YYYY") === moment(date).format("YYYY")) {
      return moment(date).format("MM.DD HH:mm")
    } else {
      return moment(date).format("YYYY.MM.DD HH:mm")
    }
  } else {
    return moment(date).fromNow()
  }
}

export const handleDateNewPost = (date: string, lang: string) => {
  if (moment(date).diff(moment(), "seconds") > -60) {
    if (lang === "kor") {
      return "방금전"
    } else {
      return "just now"
    }
  } else {
    return handleDateCommunity(date)
  }
}
