import { useEffect, useState } from "react"
import { homeAPi } from "src/api/homeApi"
import { TBRAND } from "src/models"
import { Voucher } from "src/models"
import GroupBuy from "./components/GroupBuy"
import { useAuth } from "src/customHook/useAuth"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import CommonModal from "src/components/Modal"
import InfoIcon from "@assets/icons/InfoIcon.svg"
import NavBarMb from "src/layout/Header/NavBarMb"
import useIsMobile from "src/customHook/useIsMobile"
import CreateAccountSuccess from "src/components/Modal/auth/CreateAccountSuccess"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import {
  isCreateSuccessSelect,
  openCreateAccountSuccess,
} from "src/features/user/userSlice"
import TopBrand from "./components/TopBrand"
import CenterListBrand from "./components/CenterListBrand"
import bottomBanner from "@assets/images/home/bottomBanner.png"
import bottomBannerMobile from "@assets/images/home/bottom_banner_mb.png"
import FeatureFeed from "../Brand/components/FeatureFeed"
import HighlightProducts from "../Brand/components/HighLightProduct"
import ListBrand from "../Brand/components/ListBrand"
import LoginListBrand from "./components/LoginListBrand"
import FilterAllBrand from "../Brand/components/FilterAllBrand"

interface DataHomeProps {
  popularBrand?: TBRAND[]
  recommendBrand?: TBRAND[]
  myBrand?: TBRAND[]
  groupBuying?: Voucher
  trendingBrand?: TBRAND[]
}

function titleCase(str: string) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)
}

function HomePage() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const errorCode = searchParams.get("errorCode")
  const message = searchParams.get("message") as string
  const navigate = useNavigate()
  const { isMobile } = useIsMobile(1023)
  const dispatch = useAppDispatch()
  const isCreateSuccess = useAppSelector(isCreateSuccessSelect)
  const { isLogined } = useAuth()
  const [dataHomePage, setDataHomePage] = useState<DataHomeProps>({})
  const [brandId, setBrandId] = useState<number>()

  const getData = async () => {
    try {
      const res: any = await homeAPi.getDataHomePage()
      if (res.result && !!res.data) {
        setBrandId(res.data.groupBuying?.brandId)
        setDataHomePage(res.data)
        // console.log("res.data.brandId", res.data.groupBuying.branId)
      } else {
        throw res
      }
    } catch (error) {}
  }

  useEffect(() => {
    getData()
  }, [isLogined])

  const handleClose = () => {
    navigate(`/`)
  }

  return (
    <div>
      {isMobile && <NavBarMb navType="home" pageName="MOAFRIENDS" />}
      <div className="relative flex flex-col">
        {dataHomePage?.groupBuying && (
          <div className="lg:mb-[89px] mb-[38px]">
            <TopBrand
              data={dataHomePage?.groupBuying}
              brandId={brandId}
              onComplete={() => {
                setDataHomePage({ ...dataHomePage, groupBuying: undefined })
                setTimeout(() => {
                  getData()
                }, 10000)
              }}
            />
          </div>
        )}
        {/* <FilterAllBrand
          dataFilter={dataFilterAllBrand}
          setIsFilterAllBrand={(e) => setIsFilterAllBrand(e)}
        /> */}

        {isLogined && (
          <div className="container order-2">
            <LoginListBrand />
          </div>
        )}
        <div className="container lg:mb-[121px] mb-[51px] order-3">
          <FeatureFeed title="요즘 HOT한 브랜드 근황은?" />
        </div>
        <div
          className={`bg-gray3 lg:mb-[79px] mb-[51px] ${
            dataHomePage?.groupBuying ? "order-4" : "order-1"
          }`}
        >
          <div className="container">
            <CenterListBrand />
          </div>
        </div>
        <div className="container lg:pb-[139px] pb-[100px] order-5">
          <HighlightProducts />
        </div>
        {/* <div className="lg:mb-[-110px] mb-[-62px] order-6">
          <img
            src={bottomBanner}
            alt="img"
            className="sm:block hidden w-full"
          />

          <div className="sm:hidden home__bg_banner flex items-center flex-col">
            <img src={bottomBannerMobile} alt="img" />
            <div className="border border-[#000] h-[42px] leading-[42px] text-center text-[18px] font-pretendard700 max-w-[204px] mt-[112px] mb-[27px] w-full">
              브랜드 입점 문의하기
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
export default HomePage
