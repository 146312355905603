import { useSearchParams } from 'react-router-dom'

type LangId = 'en' | 'kr'

function useSelectLanguage(): LangId {
  const [searchParams] = useSearchParams()

  return searchParams.get('lang') as LangId
}

export default useSelectLanguage
