import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export interface GetBalanceApi {
  page: number
  size: number
  querySearch?: string
  brandId?: number
  categoryId?: number
  sortType?: string
  sortDirect?: string
}
export type TRANSACTION_PARAMS = {
  page: number
  size: number
  brandId?: number | string | undefined | null
  startDate?: string
  endDate?: string
  filterInOut?: string
  querySearch?: string
}

export const createQrCodeApi = async (): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.CREATE_QR_CODE,
    method: "GET",
    tokenRequired: true,
  })
}

export const getNormalMembership = async (): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.NORMAL_MEMBERSHIP,
    method: "GET",
    tokenRequired: true,
  })
}

export const getBalanceApi = async (
  params: GetBalanceApi,
): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.MY_BALANCE,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const getBalanceDetailSummary = async (
  params: any,
): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.BALANCE_DETAIL_SUMMARY,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const transactionApi = {
  acceptPayment(data: { trxUID: string }) {
    return api.request({
      url: ApiUrl.ACCEPT_PAYMENT,
      method: "POST",
      tokenRequired: true,
      data,
    })
  },
  acceptPaymentVoucherCoupon(data: {
    trxUID: string
    amount: number
    voucherType: string
    note: string
  }) {
    return api.request({
      url: ApiUrl.ACCEPT_PAYMENT_VOUCHER_COUPON,
      method: "POST",
      tokenRequired: true,
      data,
    })
  },
  rejecpayment(data: { trxUID: string }) {
    return api.request({
      url: ApiUrl.REJECT_PAYMENT,
      method: "POST",
      tokenRequired: true,
      data,
    })
  },
  rejectPaymentVoucherCoupon(data: {
    trxUID: string
    amount: number
    voucherType: string
    note: string
  }) {
    return api.request({
      url: ApiUrl.REJECT_PAYMENT_VOUCHER_COUPON,
      method: "POST",
      tokenRequired: true,
      data,
    })
  },
  getTransactionHis(params: TRANSACTION_PARAMS) {
    return api.request({
      url: ApiUrl.TRANSACTION_HIS,
      method: "GET",
      tokenRequired: true,
      params,
    })
  },
  getOrderHis(params: any) {
    return api.request({
      url: ApiUrl.ORDER_HIS,
      method: "GET",
      tokenRequired: true,
      params,
    })
  },
}

export const getDataDetailNormalMembership = async (params: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.DETAIL_NORMAL_MEMBERSHIP,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}
