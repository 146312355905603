import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export interface FilterBrandParams {
  querySearch: string
  filter: string[]
  page: number
  size: number
}

export const getBrandDetail = async (id: number, size: number | string) => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.GET_BRAND_DETAIL,
    method: "GET",
    params: { id, size },
  })
}

export const getBrandPageData = async () => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.GET_BRAND_PAGE_DATA,
    method: "GET",
  })
}

export const filterBrand = async (payload: FilterBrandParams) => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.FILTER_BRAND,
    method: "POST",
    data: payload,
  })
}

export const getBrandHistoryMembership = async (payload: FilterBrandParams) => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.BRAND_HISTORY_MEMBERSHIP,
    method: "GET",
    params: payload,
  })
}

interface FilterAllBrandParams {
  querySearch: string
  filter: string[]
  page: number
  size: number
  voucherType?: string
  productType?: string
}

export const getBrandFilter = async (payload: FilterAllBrandParams) => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.BRAND_FILTER,
    method: "GET",
    params: payload,
  })
}

export const getBrandHistoryNormalMembership = async (
  payload: FilterBrandParams,
) => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.BRAND_HISTORY_NORMAL_MEMBERSHIP,
    method: "GET",
    params: payload,
  })
}
// export const filterMyBrand = async (payload: FilterBrandParams) => {
//   return api.request({
//     tokenRequired: true,
//     url: ApiUrl.FILTER_MY_BRAND,
//     method: "POST",
//     data: payload,
//   })
// }
