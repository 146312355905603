export const convertStatus = (status?: string) => {
  switch (status) {
    case "PAID":
      return "Paid"
    case "OWNED":
      return "Owned"
    case "REQUESTED":
      return "Requested"
    case "REFUNDED":
      return "Refunded"

    case "CANCEL":
      return "결제최소"
    case "CLOSE":
      return "구매확정"
    case "USED":
      return "맴버십이용"
    default:
      return ""
  }
}

export const convertStatusBg = (status?: string) => {
  switch (status) {
    case "PAID":
      return "bg-[#539165]"
    case "OWNED":
      return "bg-[#737373]"
    case "REQUESTED":
      return "bg-[#C70039]"
    case "REFUNDED":
      return "bg-[#737373]"

    case "CANCEL":
      return "bg-[#393939]"
    case "CLOSE":
      return "bg-[#737373]"
    case "USED":
      return "bg-[#09ABDE]"
    default:
      break
    // case "PAID":
    //   return "bg-primaryGreen"
    // case "OWNED":
    //   return "bg-gray9"
    // case "REQUESTED":
    //   return "bg-rose"
    // case "REFUNDED":
    //   return "bg-gray9"
    // default:
    //   break
  }
}

export const convertStatusTextColor = (status?: string) => {
  switch (status) {
    case "PAID":
      return "text-[#539165]"
    case "OWNED":
      return "text-[#737373]"
    case "REQUESTED":
      return "text-[#C70039]"
    case "REFUNDED":
      return "text-[#737373]"

    case "CANCEL":
      return "text-[#393939]"
    case "CLOSE":
      return "text-[#737373]"
    case "USED":
      return "text-[#09ABDE]"
    default:
      break
    // case "PAID":
    //   return "bg-primaryGreen"
    // case "OWNED":
    //   return "bg-gray9"
    // case "REQUESTED":
    //   return "bg-rose"
    // case "REFUNDED":
    //   return "bg-gray9"
    // default:
    //   break
  }
}

export const convertStatusPayment = (status?: string) => {
  switch (status) {
    case "WAITING_FOR_CONFIRMATION":
      return "Wait for confirmation"
    case "WAITING_FOR_DELIVERY":
      return "Waiting for delivery"
    case "DELIVERING":
      return "Delivering"
    case "DELIVERED":
      return "Delivered"
    case "REFUND_REQUEST":
      return "Refund request"
    case "REFUND_APPROVED":
      return "Refund approved"
    case "REFUND_REJECTED":
      return "Refund Rejected"
    case "CANCEL_ORDER":
      return "Cancel order"
    case "CANCEL_ORDER_AFTER_CONFIRM":
      return "Cancel order"
    case "CANCEL_ORDER_AFTER_DELIVERING":
      return "Cancel order"
    case "SUCCESS":
      return "Success"
    default:
      return
  }
}
