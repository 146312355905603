import { useAppSelector } from "src/app/hooks"
import { User } from "src/models"
import { Navigate } from "react-router-dom"

export const useAuth = (): {
  isLogined: boolean
  isLoading: boolean
  user: User | null
} => {
  const user = useAppSelector((state) => state.user)
  
  return {
    isLogined: !!user.currentUser,
    isLoading: user.loading,
    user: user.currentUser,
  }
}
