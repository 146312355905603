import { Form } from "antd"
import { useEffect, useRef, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { niceApi } from "src/api/niceApi"
import Loading from "src/components/Loading"
import { b64DecodeUnicode } from "src/util"

type Props = {}

function NiceApiForm({}: Props) {
  const ref = useRef<any>(null)
  const [searchParams] = useSearchParams()
  const location = useLocation()

  console.log("requestType", location.state)

  const [data, setData] = useState<{
    dataEncrypted?: string
    integrityValue?: string
    tokenVersionId?: string
  }>({})
  const getVerifyNice = async () => {
    try {
      const dataNice = searchParams.get("dataNice")
      if (dataNice) {
        const decodeDataNice = JSON.parse(b64DecodeUnicode(dataNice as string))
        console.log("decodeDataNice", decodeDataNice)

        setData(decodeDataNice)
      } else {
        const res: any = await niceApi.createVerify(location.state.requestType)
        if (res.result) {
          setData(res.data)
        } else {
          throw res
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getVerifyNice()
  }, [])

  useEffect(() => {
    let timer: any
    if (!!data?.dataEncrypted) {
      timer = setTimeout(() => {
        ref.current && ref.current.submit()
      }, 2000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [data?.dataEncrypted])

  return (
    <form
      name="form"
      id="form"
      ref={ref}
      action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
      method="post"
    >
      <input type="text" hidden id="m" name="m" value="service" readOnly />
      <input
        type="text"
        hidden
        id="enc_data"
        name="enc_data"
        value={data?.dataEncrypted?.replace("/s/g", "+")}
        readOnly
      />
      <input
        type="hidden"
        id="integrity_value"
        name="integrity_value"
        value={data?.integrityValue?.replace("/s/g", "+")}
        readOnly
      />
      <input
        type="hidden"
        id="token_version_id"
        name="token_version_id"
        value={data?.tokenVersionId?.replace("/s/g", "+")}
        readOnly
      />
      <Loading />
    </form>
  )
}

export default NiceApiForm
