import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { CsType, customerApi } from "src/api/customerApi"
import MinusLarge from "src/assets/icons/MinusLarge.svg"
import PlusLarge from "src/assets/icons/PlusLarge.svg"
import ArrowBendDownRight from "src/assets/icons/cs-center/ArrowBendDownRight.svg"
import Button from "src/components/Button"
import CreateInquiry from "src/components/Modal/CreateInquiry"
import DeleteInquiry from "src/components/Modal/DeleteInquiry"
import Pagination from "src/components/Pagination"
import CsTable from "src/components/Table"
import TabsComp from "src/components/Tabs"
import { useAuth } from "src/customHook/useAuth"
import useIsMobile from "src/customHook/useIsMobile"
import { Inquiry as InquiryModel } from "src/models/inquiry"
import "./style.scss"

// export type TypeInquiryList = "all" | "replied" | "unreplied"

interface Props {
  refreshList?: boolean
}

const Inquiry: React.FC<Props> = ({ refreshList }) => {
  const { isMobile } = useIsMobile(1023)
  const { t } = useTranslation()
  const { isLogined } = useAuth()
  const [expandedKeys, setExpandedKeys] = useState<any>([])
  const [inquiryList, setInquiryList] = useState<InquiryModel[]>([])
  const [totalInquiryList, setTotalInquiryList] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [inquiryId, setInquiryId] = useState<string | number>()
  // const params = useParams()

  const getInquiryList = async () => {
    try {
      const { data, result }: any = await customerApi.getInquiryList({
        page: searchParams.get("page") || 1,
        type:
          searchParams.get("type") === "ALL"
            ? undefined
            : (searchParams.get("type") as CsType),
      })

      if (result && data.datas) {
        setInquiryList(data.datas)
        setTotalInquiryList(data.pageable.totalElement)
      } else {
        throw data
      }
    } catch (error) {
      setInquiryList([])
      setTotalInquiryList(0)
    }
  }
  useEffect(() => {
    if (isLogined) {
      getInquiryList()
      setExpandedKeys([])
    } else {
      setInquiryList([])
      setTotalInquiryList(0)
    }
  }, [searchParams, isLogined, refreshList])

  const typeMappings: any = {
    MEMBERSHIP_USAGE: t("멤버십이용"),
    ORDER_PAYMENT: t("주문결제"),
    CANCELATION_REFUND: t("취소/환불"),
    OTHER: t("기타"),
  }

  const columns: ColumnsType<any> = [
    {
      title: t("문의유형"),
      dataIndex: "type",
      key: "type",
      width: 400,
      render: (_, record: InquiryModel) => (
        <div className="lg:text-[16px] text-[14px] text-black leading-[100%] font-pretendard400">
          {typeMappings[record.type] || record.type}
        </div>
      ),
    },
    {
      title: t("제목"),
      dataIndex: "title",
      key: "title",

      render: (_, record: InquiryModel) => (
        <div className="lg:text-[16px] text-[14px] text-black leading-[100%] font-pretendard400">
          {record?.subject}
        </div>
      ),
    },
    {
      title: t("답변유무"),
      dataIndex: "status",
      key: "status",
      render: (_, record: InquiryModel) => (
        <div className="lg:text-[16px] text-[14px] text-black leading-[100%] font-pretendard400">
          {record.isReply ? t("답변완료") : t("답변중")}
        </div>
      ),
    },
    {
      title: t("작성일"),
      dataIndex: "createDate",
      key: "createDate",
      render: (_, record: InquiryModel) => (
        <p className="lg:text-[16px] text-[14px] text-black leading-[100%] font-pretendard400">
          {dayjs(record.updatedTime || record.createdTime).format(
            "YYYY/MM/DD HH:mm:ss",
          )}
        </p>
      ),
    },
    {
      title: <></>,
      dataIndex: "delete",
      key: "delete",
      render: (_, record: InquiryModel) => (
        <div
          onClick={(e: any) => {
            e.stopPropagation()
            setOpenDeleteModal(true)
            setInquiryId(record.id)
          }}
          className="underline lg:text-[16px] text-[14px] text-black leading-[100%] font-pretendard400 cursor-pointer"
        >
          {t("삭제")}
        </div>
      ),
    },
    // Table.EXPAND_COLUMN,
  ]

  const columnsMb: ColumnsType<any> = [
    {
      // title: "",
      dataIndex: "title",
      key: "title",
      width: "100%",
      render: (_, record: InquiryModel) => (
        <div>
          <p className="text-[14px] text-gray1 leading-[100%] font-pretendard400 mb-[23px]">
            {dayjs(record.updatedTime || record.createdTime).format(
              "YYYY/MM/DD",
            )}
          </p>
          <p className="text-[14px] text-black leading-[100%] font-pretendard400 mb-[11px]">
            {typeMappings[record.type] || record.type}
          </p>
          <p className="text-[14px] text-black leading-[100%] font-pretendard700 mb-[24px]">
            {record?.subject}
          </p>
          <p className="text-[14px] text-black leading-[100%] font-pretendard400">
            {record.isReply ? "답변완료" : "답변중"}
          </p>
        </div>
      ),
    },
    {
      title: <></>,
      dataIndex: "delete",
      key: "delete",
      render: (_, record: InquiryModel) => (
        <div
          onClick={(e: any) => {
            e.stopPropagation()
            setOpenDeleteModal(true)
            setInquiryId(record.id)
          }}
          className="underline lg:text-[16px] text-[14px] text-black leading-[100%] font-pretendard400 cursor-pointer w-max"
        >
          {t("삭제")}
        </div>
      ),
    },
    // Table.EXPAND_COLUMN,
  ]

  const tabItems = [
    {
      key: "ALL",
      label: t("전체"),
    },
    {
      key: "MEMBERSHIP_USAGE",
      label: t("멤버십이용"),
    },
    {
      key: "ORDER_PAYMENT",
      label: t("주문결제"),
    },
    {
      key: "CANCELATION_REFUND",
      label: t("취소/환불"),
    },
    {
      key: "OTHER",
      label: t("기타"),
    },
  ]

  const handleChangeTabs = (key: string) => {
    searchParams.set("type", key)
    setSearchParams(searchParams)
  }

  // if (!isOfTypeTabs(type)) {
  //   return <Navigate to={"."} replace />
  // }

  return (
    <div className="mx-[-24px] lg:mx-0">
      <CreateInquiry
        isOpen={openCreateModal}
        width={680}
        handleClose={() => setOpenCreateModal(false)}
        createSuccess={() => {
          getInquiryList()
        }}
      />
      <DeleteInquiry
        isOpen={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        id={inquiryId}
        deleteSuccess={() => getInquiryList()}
      />
      {!isMobile && (
        <h3 className="text-[24px] leading-[100%] font-pretendard700 mb-[21px]">
          {t("1:1 문의")}
        </h3>
      )}
      <div className={isMobile ? "px-[24px] flex gap-[8px]" : ""}>
        {isMobile && (
          <Button
            onClick={() => setOpenCreateModal(true)}
            theme="main"
            variant="outline"
            height={32}
            paddingX={16}
            mainStyle={{ width: "fit-content", fontFamily: "Pretendard-400" }}
          >
            {t("1:1 문의 작성하기")}
          </Button>
        )}
        <Link
          to="/faq"
          className="text-[16px] leading-[100%] font-pretendard400 mb-[49px] block lg:border-0 border lg:h-auto h-[32px] lg:px-0 px-[16px] leading-[32px] lg:leading-auto border-black"
        >
          {t("FAQ 확인하기")} {!isMobile && ">"}
        </Link>
      </div>
      <div className="relative">
        <TabsComp
          // defaultActiveKey="MEMBERSHIP_USAGE"
          onChange={handleChangeTabs}
          theme="fence"
          items={tabItems}
          className="tab-inquiry"
        />
        {!isMobile && (
          <div className="absolute right-0 bottom-[16px]">
            <Button
              onClick={() => setOpenCreateModal(true)}
              theme="main"
              variant="outline"
              height={44}
              paddingX={16}
            >
              {t("1:1 문의 작성하기")}
            </Button>
          </div>
        )}
      </div>
      <CsTable
        className="mb-[34px] table-hidden-expand inquiry-table"
        // columns={isMobile ? columnsMb : columns}
        columns={isMobile ? columnsMb : columns}
        dataSource={inquiryList}
        showHeader={isMobile ? false : true}
        onRow={({ key }) =>
          expandedKeys.includes(key) && { className: "expand-parent" }
        }
        expandable={{
          onExpand: (expanded, { key }) => {
            const expandedKeysData = expanded ? [key] : []
            setExpandedKeys(expandedKeysData)
          },
          expandRowByClick: true,
          expandedRowKeys: expandedKeys,
          expandedRowRender: (record) => (
            <div className="lg:pb-[12px] px-[15px] lg:w-[calc(100%-185px)] lg:float-right text-left">
              <div className="font-pretendard500 lg:text-[16px] text-[14px] lg:pb-[37px] pb-[27px] pt-[24px] whitespace-pre-line">
                Q. {isMobile && <br />}
                {record?.description}
              </div>
              <div
                className={`flex items-start lg:py-[12px] lg:px-[8px] ${
                  isMobile ? "flex-col pb-[10px]" : "flex-row"
                }`}
              >
                {isMobile ? (
                  <>A.</>
                ) : (
                  <span className="w-[24px] h-[24px] mr-[16px] mt-[-5px]">
                    <ArrowBendDownRight />
                  </span>
                )}
                <div
                  className="font-pretendard500 lg:text-[16px] text-[14px] "
                  dangerouslySetInnerHTML={{
                    __html: record.answer,
                  }}
                />
              </div>
            </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <div
                className="cursor-pointer w-[20px] h-[20px] flex items-center justify-center"
                onClick={(e) => onExpand(record, e)}
              >
                <MinusLarge />
              </div>
            ) : (
              <div
                className="cursor-pointer w-[20px] h-[20px] flex items-center justify-center"
                onClick={(e) => onExpand(record, e)}
              >
                <PlusLarge />
              </div>
            ),
        }}
      />
      {totalInquiryList > 0 && (
        <Pagination
          rootClassName="flex justify-center"
          showSizeChanger={false}
          className="custom-pagination"
          total={totalInquiryList}
          onChange={(page) => {
            setSearchParams((prev) => ({ ...prev, page }))
          }}
          current={Number(searchParams.get("page")) || 1}
        />
      )}
    </div>
  )
}

export default Inquiry
