import { useTranslation } from "react-i18next"
import CommonModal, { CommonModalProps } from "src/components/Modal"

type ForgotPasswordProps = {
  handleClose: () => void
} & CommonModalProps

const DeleteRequestSuccess: React.FC<ForgotPasswordProps> = ({
  handleClose,
  ...props
}) => {
  const { t } = useTranslation()

  const onClose = () => {
    handleClose()
  }

  return (
    <CommonModal handleClose={onClose} {...props}>
      <div>
        <div className="flex items-center gap-[8px] justify-center">
          <h3 className="text-[22px] font-pretendard500 leading-normal">
            {t("Request sent successfully.")}
          </h3>
        </div>
        <p className="max-w-[284px] text-center mx-auto mt-[15px] text-15px text-gray1">
          {t("Once our admin approves, your account will be deleted.")}
        </p>
      </div>
    </CommonModal>
  )
}

export default DeleteRequestSuccess
