import { useTranslation } from "react-i18next"
import CommonModal, { CommonModalProps } from "src/components/Modal"

interface Props extends CommonModalProps {}

const ResetPasswordSuccess: React.FC<Props> = ({
  isOpen,
  handleClose,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} {...props}>
      <div className="text-[20px] lg:text-[22px] leading-normal font-pretendard500">
        {t("Reset password successfully.")}
      </div>
    </CommonModal>
  )
}

export default ResetPasswordSuccess
