import lock from "@assets/images/brand_detail/lock.png"
import { useTranslation } from "react-i18next"
import HTMLEllipsis from "react-lines-ellipsis/lib/html"
import ReactPlayer from "react-player"
import { useLocation, useNavigate } from "react-router-dom"
import CntComment from "src/components/CntComment"
import LikeFeed from "src/components/LikeFeed"
import VideoJS from "src/components/VideoJS"
import { useAuth } from "src/customHook/useAuth"

export type TFEED_CARD = {
  id?: number
  title?: string
  createTime?: string
  imgUrl?: string
  description?: string
  like?: number
  isLike?: boolean
  urlIncontentMetadata?: any
  onClick?: () => void
  data?: any
}

const FeedCard: React.FC<TFEED_CARD> = ({
  id,
  title,
  createTime,
  imgUrl,
  description,
  like,
  isLike,
  urlIncontentMetadata,
  onClick,
  data,
}) => {
  const { isLogined } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const handleClickDetail = () => {
    if (!(data?.onlyMembership && !data?.content)) {
      onClick?.()
    }
  }

  return (
    <div className="flex flex-col cursor-pointer md:border-none md:first:border-t-[1px] first:border-t-0 border-t-[16px] border-t-[#F5F5F5]">
      <div className="md:border-[1px] md:border-gray5 sm:pt-[40px] pt-[34px] sm:pb-[19px] md:px-[27px]">
        <div className="border-b-[1px] border-b-gray7 mb-[19px] pb-[19px] overflow-hidden">
          <div onClick={handleClickDetail}>
            {title && (
              <p className="sm:text-[24px] text-[16px] md:leading-[24px] leading-[20px] font-pretendard700 md:mb-[11px] mb-[7px] md:px-0 px-[24px]">
                {title}
              </p>
            )}
            {createTime && (
              <p className="sm:text-[16px] text-[12px] leading-[16px] font-pretendard400 md:mb-[28px] sm:mb-[18px] mb-[30px] text-gray1 md:px-0 px-[24px]">
                {createTime}
              </p>
            )}
          </div>
          {data?.video?.url ? (
            <div className="md:mb-[23px] mb-[12px]">
              <VideoJS
                url={data?.video?.url}
                thumbnail={data?.videoThumbnail}
              />
            </div>
          ) : (
            <>
              {(urlIncontentMetadata.length > 0 || imgUrl) && (
                <div className="mx-0 md:mb-[23px] mb-[12px] max-h-[450px]">
                  {/* <div className="mx-0 md:mb-[23px] mb-[12px] sm:h-[450px] h-[auto]">/ */}
                  {urlIncontentMetadata.length > 0 ? (
                    <div className="border border-[#D4D4D4] h-full md:mx-0 mx-[24px]">
                      <div
                        className="sm:py-[16px] py-[14px] px-[20px]"
                        onClick={handleClickDetail}
                      >
                        <div className="text-[#767575] font-pretendard500 sm:text-[16px] text-[12px]">
                          {urlIncontentMetadata[0]?.sitename}
                        </div>
                        <div className="sm:text-[22px] text-[14px] font-pretendard600 line-clamp-1">
                          {urlIncontentMetadata[0]?.title}
                        </div>
                        <div className="line-clamp-2 text-[#767575] font-pretendard500 sm:text-[15px] text-[12px] sm:pt-[11px] pt-[5px]">
                          {urlIncontentMetadata[0]?.description}
                        </div>
                      </div>

                      <ReactPlayer
                        key={id}
                        url={urlIncontentMetadata[0]?.url}
                        // playing={true}
                        // controls={true}
                        style={{
                          maxWidth: "100%",
                          height: "100%",
                          maxHeight: "200px",
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      {imgUrl && (
                        <img
                          src={imgUrl}
                          alt={title}
                          className="w-full h-full object-contain max-h-[450px]"
                          onClick={handleClickDetail}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
          <div onClick={handleClickDetail}>
            {data?.onlyMembership && !data?.content ? (
              <div className="flex items-center md:px-0 px-[24px]">
                <img src={lock} alt="lock" className="inline-block pr-[5px]" />
                멤버십 전용 콘텐츠입니다.
              </div>
            ) : (
              <>
                {data?.onlyMembership && (
                  <span>
                    <div className="bg-[#FF6E6E] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600">
                      멤버십
                    </div>
                  </span>
                )}
                {description && (
                  <HTMLEllipsis
                    unsafeHTML={
                      description
                        .replaceAll(urlIncontentMetadata[0]?.url, "")
                        .replaceAll(/<img[^>]*>/g, "")
                        .replaceAll(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, "")
                      // .replaceAll(
                      //   "\n",
                      //   '<p style="visibility: hidden;width:0">.</p>',
                      // )
                      // .replaceAll(
                      //   "&nbsp;",
                      //   '<p style="visibility: hidden;width:0">.</p>',
                      // )
                    }
                    maxLine={
                      urlIncontentMetadata.length <= 0 && !imgUrl ? 10 : 5
                    }
                    ellipsis={`... ${t("더 보기")}`}
                    basedOn="letters"
                    className="md:px-0 px-[24px]"
                    // className="whitespace-pre-line"
                  />
                  // <ShowMoreText
                  //   lines={urlIncontentMetadata.length <= 0 && !imgUrl ? 10 : 5}
                  //   more={
                  //     <span style={{ color: "#757575" }}> {t("더 보기")}</span>
                  //   }
                  //   className="sm:mx-0 sm:px-[24px] px-0 mx-[24px] md:px-0  mt-auto flex-1 text-[16px] leading-[27px] font-pretendard400 pointer-events-none"
                  //   // onClick={() => handleCLickDetail(dt)}
                  //   expanded={false}
                  //   truncatedEndingComponent={"..."}
                  //   keepNewLines
                  // >
                  //   {description
                  //     .replaceAll(urlIncontentMetadata[0]?.url, "")
                  //     .replaceAll(/<img[^>]*>/g, "")
                  //     .replaceAll(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, "")}
                  //   {/* <p
                  //     dangerouslySetInnerHTML={{
                  //       __html: description.replaceAll(
                  //         urlIncontentMetadata[0]?.url,
                  //         "",
                  //       ),
                  //     }}
                  //   ></p> */}
                  // </ShowMoreText>
                )}
              </>
            )}
          </div>
        </div>

        <div
          className={`text-right sm:mx-0 mx-[24px] flex gap-[20px] items-center ${
            data?.onlyMembership &&
            !data?.content &&
            isLogined &&
            "!pointer-events-none"
          }`}
          onClick={() => {
            if (!isLogined) {
              navigate({
                pathname: "/auth",
                search: `backUrl=${pathname}${search}`,
              })
            }
          }}
        >
          <LikeFeed
            count={like}
            active={isLike}
            id={id}
            classLabel="text-[16px]"
          />
          <CntComment
            count={data?.cntComment}
            onClick={() => onClick?.()}
            classText="text-[16px]"
          />
        </div>
      </div>
    </div>
  )
}

export default FeedCard
