import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Button from "src/components/Button"
import useIsMobile from "src/customHook/useIsMobile"
import { orderApi } from "src/api/orderApi"
import React, { useEffect, useState } from "react"
import { MoneyConvert } from "src/util/format"
import { Col, Row, message } from "antd"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import CancelOrder from "src/components/Modal/CancelOrder"
import { convertStatus, convertStatusBg } from "src/util/convertStatusPayment"
import { formatPrice } from "src/util/formatPrice"
import moment from "moment"
import ModalChangeInfoTransfer from "./ModalChangeInfoTransfer"
import ModalInfoTransfer from "src/components/ModalInfoTransfer"
import ModalUploadBill from "./ModalUploadBill"
import { uploadSingleFile } from "src/api/uploadSingleFile"

function OrderDetail() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isMobile } = useIsMobile(1023)
  const params = useParams()
  const [orderDetail, setOrderDetail] = useState<any>()
  const [isCanceled, setIsCanceled] = useState<boolean>(false)
  const [isOpenCancel, setIsOpenCancel] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage({ maxCount: 2 })
  const [searchParams, setSearchParams] = useSearchParams()
  const type = searchParams.get("type")

  const [isModalChangeInfoTransfer, setIsModalChangeInfoTransfer] =
    useState(false)
  const [isModalInfoTransfer, setIsModalInfoTransfer] = useState(false)
  const [isModalUploadBill, setIsModalUploadBill] = useState(false)
  const isTransferPayment = orderDetail?.isBanking

  const orderId = params?.id as string

  const [isLoading, setIsLoading] = useState(false)
  const getOrderDetail = async () => {
    try {
      const { data, result }: any =
        type === "order"
          ? await orderApi.getOrderDetail(orderId)
          : await orderApi.getOrderDetailNew(orderId)
      if (result) {
        setOrderDetail(data)
      } else {
        // navigate(-1)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getOrderDetail()
  }, [orderId])

  const convertPayment = (paymentMethod: string) => {
    switch (paymentMethod) {
      case "CREDIT":
        return "신용/체크카드"
      case "KAKAO":
        return "카카오페이"
      case "NAVER":
        return "네이버페이"
      case "BANKING":
        return "신용/체크카드"
      default:
        break
    }
  }

  const handleCancelOrder = async () => {
    const { result, data, error }: any =
      type === "order"
        ? await orderApi.cancelOrder(orderId)
        : orderDetail?.status !== "PAID"
        ? await orderApi.requestCancel(orderId)
        : await orderApi.cancelMembership(orderId)

    if (error) {
      messageApi.open({
        type: "error",
        content: error?.message,
      })
    }

    setIsOpenCancel(false)

    if (result) {
      getOrderDetail()

      // setOrderDetail((prev: any) => ({
      //   ...prev,
      //   ...data,
      //   orderHistory: prev?.orderHistory
      //     ? [
      //         ...prev.orderHistory,
      //         {
      //           status: data.status,
      //           createdTime: data.updated_time,
      //         },
      //       ]
      //     : prev?.orderHistory,
      // }))
      setIsCanceled(true)
    } else {
      throw data
    }
  }

  const handleRequestCancelRefund = async () => {
    const { result, data, error }: any =
      type === "order"
        ? orderDetail?.status === "REQUESTED"
          ? await orderApi.cancelRequestRefundOrder(orderId)
          : await orderApi.requestCancelRefundOrder(orderId)
        : await orderApi.requestCancelRefund(orderId)
    if (result) {
      getOrderDetail()
    }
  }

  const handleChangeInfo = async (values: any) => {
    try {
      const resp: any = await orderApi.changeInfoBanking({
        id: Number(orderId),
        bankAccountName: values.bankAccountName,
        // bankAccountNumber: values.bankAccountNumber,
        // bankName: values.bankName,
      })
      if (resp?.result) {
        getOrderDetail()
        setIsModalChangeInfoTransfer(false)
      }
    } catch (error) {}
  }

  const handleUploadBill = async (values: any) => {
    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append("file", values.originFileObj)
      const respUpload: any = await uploadSingleFile(formData)
      if (respUpload) {
        const resp: any = await orderApi.changeInfoBanking({
          id: Number(orderId),
          bill: respUpload.imageUrl,
        })
        if (resp?.result) {
          getOrderDetail()
          setIsModalUploadBill(false)
          setIsLoading(false)
        }
      }
    } catch (error) {}
  }

  return (
    <div className="">
      <div className="relative z-10">
        <div className="lg:gap-[20px] gap-[16px]">
          <div className="hidden md:flex justify-between font-pretendard700 text-[24px] leading-[100%] pb-[24px] border-b-[1px] border-b-primary mb-[32px]">
            <span className="leading-[100%]">{t("주문 상세 조회")}</span>
          </div>

          <div className="flex lg:gap-[59px] gap-[32px] xl:flex-row flex-col">
            <div className="flex-1">
              <p className="text-[18px] font-pretendard700 text-primary mb-[32px] leading-none">
                {t("주문 상품 정보")}
              </p>
              <div className="mb-[30px] md:mb-[32px] border-b-solid border-b md:border-b border-gray5 flex md:flex-row md:justify-normal">
                <div className="mr-[18px] mb-[36px]">
                  <div className="border border-solid w-[80px] h-[80px] md:w-[120px] md:h-[120px] flex justify-center items-center text-primaryGreen font-pretendard700">
                    <img
                      className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] object-cover aspect-square cursor-pointer"
                      src={
                        type === "order"
                          ? orderDetail?.voucherImage
                          : orderDetail?.logoImage
                      }
                      alt="img"
                      onClick={() => navigate(`/${orderDetail?.brandId}`)}
                    />
                  </div>
                </div>
                <div className="block flex-1">
                  <div className="flex">
                    <p className="text-[12px] md:text-[14px] font-pretendard400 leading-none mb-[8px] md:mb-[16px]">
                      {orderDetail?.brandName}
                    </p>
                    <div className="flex justify-end flex-1 ml-[20px]">
                      <div className="bg-black  h-[24px]">
                        <Button
                          onClick={() => {}}
                          className={`flex justify-center ${convertStatusBg(
                            orderDetail?.status,
                          )} text-white text-[10px] w-full font-pretendard700 h-full px-[5px] disabled:text-gray2 cursor-default`}
                        >
                          {t(orderDetail?.status)}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <p className="text-[16px] md:text-[18px] font-pretendard700 text-primary mb-[12px] md:mb-[32px] leading-none">
                    {type === "order"
                      ? orderDetail?.voucherName
                      : orderDetail?.membershipName}
                  </p>
                  {type === "order" && (
                    <div className="line-through text-[#767676] text-[13px] pb-[8px] mt-0 md:mt-[-20px]">
                      {formatPrice(
                        Number(orderDetail?.usageValue) / orderDetail?.quantity,
                      )}
                      원
                    </div>
                  )}
                  <span className="text-[12px] md:text-[14px] font-pretendard400 leading-none">
                    <span className="text-primaryRed">
                      [
                      {type === "order"
                        ? orderDetail?.discountPercentage
                        : formatPrice(
                            ((orderDetail?.rootPrice -
                              orderDetail?.sellingPrice) /
                              orderDetail?.rootPrice) *
                              100 || 0,
                          )}
                      %]{" "}
                    </span>
                    <span className="font-pretendard700">
                      {MoneyConvert(
                        type === "order"
                          ? Number(orderDetail?.voucherPrice)
                          : orderDetail?.sellingPrice,
                      ) || 0}
                      {t("원")}{" "}
                    </span>
                    / 수량 {type === "order" ? orderDetail?.quantity : "1"}개
                  </span>
                </div>
              </div>
              <div className="border-b-solid border-b-[16px] md:border-b-[16px] border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0">
                {type === "order" && (
                  <div className="flex justify-between items-baseline mb-[16px] md:mb-[15px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("형태")}
                    </p>
                    <div className="font-pretendard700">
                      {orderDetail?.orderType === "GIFT" ? "선물발송" : "구매"}
                    </div>
                  </div>
                )}

                <div className="flex justify-between items-baseline mb-[16px] md:mb-[15px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("멤버십 구분")}
                  </p>
                  <div className="font-pretendard700">
                    {type === "order" ? "프리미엄" : "일반"}
                  </div>
                  {/* <div className="font-pretendard700">{orderDetail?.type}</div> */}
                </div>

                <div className="flex justify-between items-baseline mb-[16px] md:mb-[15px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("멤버십 No.")}
                  </p>
                  <div>
                    {orderDetail?.membershipNo?.map((item: any, index: any) => {
                      return (
                        <p
                          key={index}
                          className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]"
                        >
                          {item}
                        </p>
                      )
                    })}
                  </div>
                </div>

                <div className="flex justify-between items-baseline mb-[20px]  md:mb-[40px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none md:min-w-auto min-w-[100px]">
                    {t("유효 기간")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px] text-right">
                    {dayjs(orderDetail?.endTime).format(
                      "YYYY년 MM월 DD일 HH시 mm분",
                    )}{" "}
                    ~{" "}
                    {dayjs(orderDetail?.expiredTime)
                      .subtract(1, "minutes")
                      .format("YYYY년 MM월 DD일 HH시 mm분")}
                  </p>
                </div>
              </div>
              <div className="pt-[32px] pb-[32px] md:pb-[60px] md:pb-0 border-b-solid border-b-[16px] md:border-none border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0">
                <div className="flex mb-[32px]">
                  <p className="text-[18px] font-pretendard700 text-primary leading-none">
                    {t("구매자 정보")}
                  </p>
                </div>
                <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("주문자")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                    {orderDetail?.displayName}
                  </p>
                </div>
                <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("이메일주소")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                    {orderDetail?.email}
                  </p>
                </div>
                <div className="flex justify-between items-baseline">
                  <p className="text-[14px] font-pretendard400 text-primary leading-none">
                    {t("휴대전화번호")}
                  </p>
                  <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                    {orderDetail?.phoneNumber}
                  </p>
                </div>
              </div>

              {isTransferPayment && (
                <div className="md:border-t-[16px] md:border-b-0 border-b-[16px] border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0 pt-[36px] pb-[21px] md:pb-0">
                  <div className="flex mb-[32px] justify-between items-center">
                    <p className="text-[18px] font-pretendard700 text-primary leading-none">
                      {t("입금 정보")}
                    </p>

                    <div
                      className="cursor-pointer w-[62px] h-[25px] text-center border border-[#000]"
                      onClick={() => setIsModalChangeInfoTransfer(true)}
                    >
                      Change
                    </div>
                  </div>

                  <div className="flex justify-between items-baseline mb-[16px] md:mb-[15px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("입금자명")}
                    </p>
                    <div className="font-pretendard700">
                      {orderDetail?.userBankAccountName}
                    </div>
                  </div>
                  {/* <div className="flex justify-between items-baseline mb-[16px] md:mb-[15px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("은행명")}
                    </p>
                    <div className="font-pretendard700">
                      {orderDetail?.bankName}
                    </div>
                  </div>
                  <div className="flex justify-between items-baseline mb-[16px] md:mb-[15px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("계좌번호")}
                    </p>
                    <div className="font-pretendard700">
                      {orderDetail?.bankAccountNumber}
                    </div>
                  </div> */}
                </div>
              )}

              {orderDetail?.orderType === "GIFT" && (
                <div className="pt-[32px]  md:border-b-0 border-b-[16px] md:border-t-[16px] border-t-0 border-gray3 mx-[-16px] px-[16px] md:mx-0 md:px-0">
                  <div className="flex mb-[32px]">
                    <p className="text-[18px] font-pretendard700 text-primary leading-none">
                      {t("선물받는 분 정보")}
                    </p>
                  </div>
                  <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("성함")}
                    </p>
                    <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                      {orderDetail?.receiverName}
                    </p>
                  </div>
                  <div className="flex justify-between items-baseline mb-[20px]  md:mb-[24px]">
                    <p className="text-[14px] font-pretendard400 text-primary leading-none">
                      {t("연락처")}
                    </p>
                    <p className="text-[14px] font-pretendard700 text-primary leading-none mb-[8px]">
                      {orderDetail?.receiverPhone}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="xl:w-[480px]">
              <div className="w-full md:bg-gray3 md:p-[40px] md:pt-[32px] mb-[20px]">
                <div className="flex items-center justify-between pb-[12px] md:pb-[0px] md:mb-[60px]">
                  <p className="text-[18px] font-pretendard700 text-primary leading-none">
                    {t("결제 정보")}
                  </p>
                </div>

                <div className="flex items-center mb-[24px] justify-between mt-[24px] md:mt-[40px]">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("총 상품 금액")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {MoneyConvert(
                      Number(
                        type === "order"
                          ? orderDetail?.usageValue
                          : orderDetail?.rootPrice,
                      ),
                    ) || 0}
                    원
                  </p>
                </div>

                <div className="flex items-center pb-[24px] justify-between">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {type === "order" ? "할인율" : t("할인 금액")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {type === "order"
                      ? `${formatPrice(orderDetail?.discountPercentage)}${t(
                          "%",
                        )}`
                      : `${formatPrice(
                          Number(orderDetail?.rootPrice) -
                            Number(orderDetail?.sellingPrice),
                        )}${t("원")}`}
                  </p>
                </div>

                <div className="flex items-center justify-between md:mt-[0] pb-[24px]">
                  <p className="text-[16px] font-pretendard700 text-primary leading-none">
                    {t("총 결제 금액")}
                  </p>
                  <p className="text-[16px] md:text-[24px] font-pretendard700 text-[#C70039] leading-none">
                    {MoneyConvert(
                      Number(
                        type === "order"
                          ? orderDetail?.totalValue
                          : orderDetail?.sellingPrice,
                      ),
                    ) || 0}
                    {t("원")}
                  </p>
                </div>

                <div className="flex items-center pb-[24px] justify-between">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("결제 방법")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {convertPayment(orderDetail?.paymentMethod)}
                  </p>
                </div>

                <div className="flex items-center pb-[24px] justify-between">
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {t("결제 일시")}
                  </p>
                  <p className="text-[12px] md:text-[14px] font-pretendard400 text-primary leading-none">
                    {dayjs(orderDetail?.createdTime).format("YYYY.MM.DD HH:mm")}
                  </p>
                </div>

                <div className="flex font-pretendard500 gap-x-[30px] pt-[12px] w-full">
                  {isTransferPayment ? (
                    <div className="flex gap-[20px] w-full">
                      <Button
                        theme="main"
                        variant={"outline"}
                        labelSize={16}
                        height={44}
                        onClick={() => setIsModalUploadBill(true)}
                      >
                        {t("Upload bill")}
                      </Button>
                      <Button
                        theme="main"
                        labelSize={16}
                        height={44}
                        onClick={() => setIsModalInfoTransfer(true)}
                      >
                        {t("입금할 계좌정보 확인")}
                      </Button>
                    </div>
                  ) : (
                    <>
                      {orderDetail?.status === "REFUNDED" ? (
                        <Button
                          onClick={() => {
                            setIsOpenCancel(true)
                          }}
                          disabled={!orderDetail?.canCancel}
                          className="flex justify-center border border-solid border-primary text-primary text-[16px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
                        >
                          환불 완료{" "}
                          {moment(orderDetail?.updatedTime).format(
                            "YYYY년 MM월 DD월",
                          )}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            if (orderDetail?.status === "REQUESTED") {
                              handleRequestCancelRefund()
                            } else {
                              setIsOpenCancel(true)
                            }
                          }}
                          disabled={
                            !orderDetail?.canCancel &&
                            orderDetail?.status !== "REQUESTED"
                          }
                          className="flex justify-center border border-solid border-primary text-primary text-[16px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
                        >
                          {orderDetail?.status === "REQUESTED"
                            ? t("환불 요청 취소하기")
                            : t("환불 요청")}
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <ul className="mt-[26px] list-disc pl-4">
                  <li className="text-[12px] font-pretendard400 text-gray1 leading-[170%]">
                    {t(
                      "주문 취소 및 환불 신청은 결제 후 7일 이내에 가능합니다.",
                    )}
                  </li>
                  <li className="text-[12px] font-pretendard400 text-gray1 leading-[170%]">
                    {t(
                      "주문 취소 접수 후 신용카드 취소 승인까지 XX일 예상 됩니다.",
                    )}
                  </li>
                </ul>
              </div>
              {orderDetail?.canViewHistory && (
                <Button
                  theme="main"
                  height={48}
                  onClick={() =>
                    navigate(`/my-membership/${orderDetail?.brandId}`)
                  }
                >
                  {t("멤버십 이용 내역 보기")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <CancelOrder
        open={isOpenCancel}
        handleClose={() => setIsOpenCancel(false)}
        handleConfirm={handleCancelOrder}
        data={orderDetail}
      />

      {/**transfer payment */}
      <ModalChangeInfoTransfer
        isOpen={isModalChangeInfoTransfer}
        data={orderDetail}
        handleClose={() => setIsModalChangeInfoTransfer(false)}
        getDataModalChangeInfo={(e) => handleChangeInfo(e)}
      />
      <ModalInfoTransfer
        isOpen={isModalInfoTransfer}
        handleClose={() => setIsModalInfoTransfer(false)}
        onSubmit={() => setIsModalInfoTransfer(false)}
        price={orderDetail?.totalValue}
        data={orderDetail}
      />
      <ModalUploadBill
        isOpen={isModalUploadBill}
        handleClose={() => setIsModalUploadBill(false)}
        onUploadBill={(values) => handleUploadBill(values[0])}
        isLoading={isLoading}
        data={orderDetail}
      />

      {contextHolder}
    </div>
  )
}

export default OrderDetail
