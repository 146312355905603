import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"

import arrow_back from "@assets/images/brand_detail/arrow_back.png"
import arrow_next from "@assets/images/brand_detail/arrow_next.png"
import new_release from "@assets/images/brand_detail/new_releases.png"
import { useCallback, useRef } from "react"
import useWindowSize from "src/customHook/useWindowSize"

import heart_check from "@assets/images/brand_detail/heart_check.png"
import lock from "@assets/images/brand_detail/lock.png"
import { useAuth } from "src/customHook/useAuth"
import { useAppSelector } from "src/app/hooks"
import { brandDataDetailSelect } from "src/features/brandData/brandDataSlice"

interface Props {
  data: any
  setIsModalComment: (data: any) => void
}

function Slider({ data, setIsModalComment }: Props) {
  const { width }: any = useWindowSize()
  const isMobile = width <= 768
  const { isLogined } = useAuth()
  const sliderRef: any = useRef(null)
  const isCheckMembershipAndLogin = data?.reqUserIsMembership || !isLogined
  const dataBrandDetail = useAppSelector(brandDataDetailSelect)

  const checkTypeLock =
    dataBrandDetail?.onlyMembershipView && !dataBrandDetail?.isMine

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideNext()
  }, [])

  return (
    <div className="relative">
      {!isMobile && data?.length > 3 && (
        <>
          <div
            className="absolute top-[50%] right-[-38px] -translate-y-[50%] cursor-pointer"
            onClick={handleNext}
          >
            <img alt="icon" src={arrow_next} />
          </div>
          <div
            className="absolute top-[50%] left-[-38px] -translate-y-[50%] cursor-pointer"
            onClick={handlePrev}
          >
            <img alt="icon" src={arrow_back} />
          </div>
        </>
      )}

      <Swiper
        slidesPerGroup={isMobile ? 1 : 3}
        slidesPerView={isMobile ? 1.15 : 3}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        ref={sliderRef}
      >
        {data
          .map((dt: any, index: number) => (
            <SwiperSlide key={index}>
              <div
                className={`p-[16px] border border-dashed border-[#D3D3D3] cursor-pointer min-h-[140px] ${
                  ((dt?.onlyMemberShip && !dt?.content) || checkTypeLock) &&
                  "bg-[#F5F5F5] border-none"
                }`}
                onClick={() => {
                  if (dt?.content) {
                    setIsModalComment(dt)
                  }
                }}
              >
                <div className="flex items-center gap-[8px]">
                  <img
                    src={dt?.postAvatar}
                    alt="logo"
                    className="border border-solid border-[#D4D4D4] w-[48px] h-[48px] aspect-square rounded-[50%]"
                  />
                  <div className="font-pretendard700">{dt?.creatorName}</div>
                  {dt?.creatorBrandId && <img src={new_release} alt="logo" />}
                </div>

                <div className="line-clamp-2 mt-[8px]">
                  {(dt?.onlyMemberShip && !dt?.content) || checkTypeLock ? (
                    <>
                      <img
                        src={lock}
                        alt="lock"
                        className="inline-block pr-[5px]"
                      />
                      멤버십 전용 콘텐츠입니다.
                    </>
                  ) : (
                    <>
                      {dt?.onlyMemberShip && (
                        <div className="bg-[#FF6E6E] mr-[5px] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600">
                          멤버십
                        </div>
                      )}
                      {dt?.content}
                    </>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))
          .concat(
            Array(
              isMobile ? 0 : data.length % 3 === 0 ? 0 : 3 - (data.length % 3),
            ).fill(<SwiperSlide></SwiperSlide>),
          )}
      </Swiper>
    </div>
  )
}

export default Slider
