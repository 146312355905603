import ImgHeart from "@assets/images/mymembership/heart_check_detail.png"
import { Col, Row, Spin } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  deletePost,
  editPost,
  getDataPost,
  reportPost,
} from "src/api/communityApi"
import {
  getBalanceDetailSummary,
  getDataDetailNormalMembership,
  transactionApi,
} from "src/api/myBalance"
import { uploadSingleFile } from "src/api/uploadSingleFile"
import Button from "src/components/Button"
import ModalBase from "src/components/ModalBase"
import Pagination from "src/components/Pagination"
import SelectCustom from "src/components/SelectCustom"
import CsTable from "src/components/Table"
import { UrlInternal } from "src/constant/endpointRoute"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import ModalComment from "src/pages/BrandDetail/Community/components/ModalComment"
import ModalPost from "src/pages/BrandDetail/Community/components/ModalPost"
import ModalReport from "src/pages/BrandDetail/Community/components/ModalReport"
import { MoneyConvert } from "src/util/format"
import ListComment from "./Component/ListComment"
import PostCommunity from "./Component/PostCommunity"
import ModalChangePayment from "./Component/ModalChangePayment"
import CommonModal from "src/components/Modal"

import IconNext from "@assets/images/mymembership/next-mms.png"
import ModalCancelMembership from "./Component/ModalCancelMembership"
import { formatPrice } from "src/util/formatPrice"
import {
  cancelMembershipNormalApi,
  cancelMembershipNormalNewApi,
  cancelRenewalsApi,
} from "src/api/brandApi"
import moment from "moment"

const dataTabMobile = [
  {
    label: "멤버십 이용내역",
    routes: UrlInternal.HISTORY_USING_MEMBERSHIP,
  },
  {
    label: "멤버십 혜택",
    routes: UrlInternal.CANCEL_MEMBER_SHIP,
  },
  {
    label: "커뮤니티 활동내역",
    routes: UrlInternal.COMMUNITY_MEMBERSHIP,
  },
]

const dataTabMobile2 = [
  {
    label: "멤버십 혜택",
    routes: UrlInternal.CANCEL_MEMBER_SHIP,
  },
  {
    label: "커뮤니티 활동내역",
    routes: UrlInternal.COMMUNITY_MEMBERSHIP,
  },
]

const dataFake = ["1", "2", "3", "4", "5", "6", "7"]

const MyMembershipDetail = () => {
  const { t } = useTranslation()
  const { isMobile } = useIsMobile(768)
  const { id } = useParams()
  const navigate = useNavigate()
  const [queryOrder, setQueryOrder] = useState<{
    page: number
    size: number
  }>({
    page: 1,
    size: 4,
  })

  const [queryTrans, setQueryTrans] = useState<{
    page: number
    size: number
    timeFilter: string
  }>({
    page: 1,
    size: 10,
    timeFilter: "",
  })

  const [balanceDetailSummary, setBalanceDetailSummary] = useState<any>({})
  const [dataDetailNormalMembership, setDataDetailNormalMembership] =
    useState<any>({})
  const [orderHistory, setOrderHistory] = useState<any>({})
  const [transactionHistory, setTransactionHistory] = useState<any>({})

  const dataTabCommunity = [
    {
      label: "포스트",
      value: "포스트",
    },
    {
      label: "댓글",
      value: "댓글",
    },
  ]
  const [tabCommunity, setTabCommunity] = useState(dataTabCommunity[0].value)

  const [dataPost, setDataPost] = useState<any[]>([])
  const [pageable, setPageable] = useState<any>({})
  const [isModalDeletePost, setIsModalDeletePost] = useState<boolean>(false)
  const [idDeletePost, setIdDeletePost] = useState<number>()
  const [isModalPost, setIsModalPost] = useState<boolean>(false)
  const [dataItemEditPost, setDataItemEditPost] = useState<any>({})
  const [isModalComment, setIsModalComment] = useState({
    isVisible: false,
    data: {},
  })
  const [isModalReport, setIsModalReport] = useState<boolean>(false)
  const [dataItemReport, setDataItemReport] = useState<any>({})
  const [isStatusModalReport, setIsStatusModalReport] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { state } = useLocation()
  const [isModalChangePayment, setIsModalChangePayment] =
    useState<boolean>(false)
  const [isModalCompleteChangePayment, setIsModalCompleteChangePayment] =
    useState<boolean>(false)
  const [isModalCancelMembership, setIsModalCancelMembership] =
    useState<boolean>(false)
  const [
    isModalCompleteMaintainIncentives,
    setIsModalCompleteMaintainIncentives,
  ] = useState<boolean>(false)
  const [isModalCompleteCancelTheOffer, setIsModalCompleteCancelTheOffer] =
    useState<boolean>(false)

  const [isLoadingData, setIsLoadingData] = useState(false)

  const TYPE_MEMBERSHIP = state?.activeTab === 1

  const getDataCommunity = async () => {
    try {
      const resp: any = await getDataPost({
        brandId: TYPE_MEMBERSHIP
          ? dataDetailNormalMembership?.brandId
          : Number(id),
        page: pageable.page ?? 1,
        size: 12,
        ofMine: 1,
        subscriberId: id,
      })
      if (resp.result) {
        setDataPost(resp.data.datas)
        setPageable(resp.data.pageable)
      } else {
        setDataPost([])
        setPageable({})
      }
    } catch (error) {
      setDataPost([])
      setPageable({})
    }
  }

  useEffect(() => {
    getDataCommunity()
  }, [pageable.page])

  const handleCardShowComment = async (data: any) => {
    if (isMobile) {
      navigate(
        `/${balanceDetailSummary?.balanceSummary?.brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_COMMENT}/${data?.postId}`,
      )
    } else {
      const resp: any = await getDataPost({
        page: 1,
        size: 1,
        id: data?.postId,
      })

      if (resp.result) {
        setIsModalComment({
          isVisible: true,
          data: resp.data.datas[0],
        })
      }
    }
  }
  const handleCancelModalComment = async () => {
    setDataPost([])
    setPageable({})
    await getDataCommunity()
    setIsModalComment({
      isVisible: false,
      data: {},
    })
  }
  const handleSubmitReport = async (data: number) => {
    try {
      const resp: any = await reportPost({
        refId: dataItemReport?.commentId ?? dataItemReport?.postId,
        content: `CONTENT_${data}`,
        type: dataItemReport?.commentId ? "COMMENT" : "C_POST",
      })
      if (resp.result) {
        setIsStatusModalReport(true)
        setIsModalReport(false)

        getDataCommunity()
      } else {
        setIsModalReport(false)
      }
    } catch (error) {
      setIsModalReport(false)
    }
  }

  const handleDeletePost = async () => {
    try {
      const resp: any = await deletePost({
        id: idDeletePost ?? 0,
      })
      if (resp.result) {
        setIsModalDeletePost(false)

        getDataCommunity()
      }
    } catch (error) {
      setIsModalDeletePost(false)
    }
  }

  const onSubmitPost = async (data: any) => {
    setIsLoading(true)
    try {
      const dataMedias = []
      if (data?.medias?.length > 0) {
        for (let i = 0; i < data.medias.length; i++) {
          if (data?.medias[i]?.idPreview) {
            dataMedias.push({
              mediaType: "IMAGE",
              url: data.medias[i].src,
            })
          } else {
            const formData = new FormData()
            formData.append("file", data.medias[i].originFileObj)
            const respUpload: any = await uploadSingleFile(formData)
            if (respUpload) {
              dataMedias.push({
                mediaType: "IMAGE",
                url: respUpload.imageUrl,
              })
            }
          }
        }
      }

      const resp: any = await editPost({
        content: data.content,
        onlyMembership: data.onlyMembership ?? false,
        id: data.postId,
        medias: dataMedias,
      })
      if (resp.result) {
        setIsLoading(false)
        setIsModalPost(false)

        getDataCommunity()
      }
    } catch (error) {
      setIsLoading(false)
      setIsModalPost(false)
    }
  }

  const allTime = [
    { value: "", label: t("All") },
    { value: "1M", label: t("1개월") },
    { value: "3M", label: t("3개월") },
    { value: "6M", label: t("6개월") },
    { value: "1Y", label: t("1년") },
  ]

  const TIME_NUMBER: any = { "1M": 1, "3M": 3, "6M": 6, "1Y": 12 }

  const columns: ColumnsType<any> = [
    {
      title: "구매일",
      dataIndex: "createdTime",
      key: "createdTime",
      render: (id, record) => {
        return (
          <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
            {dayjs(record?.createdTime).format("YYYY.MM.DD")}
          </p>
        )
      },
    },
    {
      title: t("브랜드"),
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
      render: (_, record) => {
        return (
          <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
            {record?.brandName}
          </p>
        )
      },
    },
    {
      title: t("멤버십 No."),
      dataIndex: "MembershipNo",
      key: "MembershipNo",
      align: "center",
      render: (_, record) => (
        <p className="text-[14px] text-primary font-pretendard400">
          {record?.brandCode}
          {record?.extend}
          {record?.no}
        </p>
      ),
    },
    {
      title: t("유효 기간"),
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
      render: (_, record) => (
        <p className="text-[14px] text-textColor6 font-pretendard400">
          {dayjs(record?.endTime).format("YYYY.MM.DD")} ~{" "}
          {dayjs(record?.expiredTime)
            .subtract(1, "minutes")
            .format("YYYY.MM.DD")}
        </p>
      ),
    },
    {
      title: () => (
        <p className="gap-[12px] font-pretendard700 pr-[40px]">{t("금액")}</p>
      ),
      dataIndex: "usageValue",
      key: "usageValue",
      align: "right",
      render: (_, record) => (
        <p className="gap-[12px] font-pretendard700">
          {MoneyConvert(record?.usageValue)}원
        </p>
      ),
    },
  ]

  const columnsMb: ColumnsType<any> = [
    {
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <>
          <div className="bg-gray3 py-[8px] mx-[-8px] px-[20px]">
            <span className="font-pretendard400 text-[14px] leading-[14px] text-primary">
              {dayjs(record?.createdTime).format("YYYY.MM.DD")}
            </span>
            <span className="font-pretendard400 text-[14px] leading-[14px] text-primary mx-[8px]">
              |
            </span>
            <span className="font-pretendard400 text-[14px] leading-[14px] text-primary">
              {record?.brandCode}
              {record?.extend}
              {record?.no}
            </span>
          </div>
          <div className="px-[14px]">
            <div className="mt-[24px] pb-[12px]">
              <div className="flex items-center mb-[12px]">
                <span className="font-pretendard400 text-[14px] leading-[14px] text-primary w-[80px]">
                  {t("구매일")}
                </span>
                <span className="font-pretendard400 text-[14px] leading-[14px] text-primary">
                  {dayjs(record?.createdTime).format("YYYY.MM.DD")}
                </span>
              </div>
              <div className="flex items-center mb-[12px]">
                <span className="font-pretendard400 text-[14px] leading-[14px] text-primary w-[80px]">
                  {t("유효 기간")}
                </span>
                <span className="font-pretendard400 text-[14px] leading-[14px] text-primary">
                  {dayjs(record?.endTime).format("YYYY.MM.DD")} ~{" "}
                  {dayjs(record?.expiredTime)
                    .subtract(1, "minutes")
                    .format("YYYY.MM.DD")}
                </span>
              </div>
              <div className="flex items-center mb-[12px]">
                <span className="font-pretendard400 text-[14px] leading-[14px] text-primary w-[80px]">
                  {t("총 구매금액")}
                </span>
                <span className="font-pretendard700 text-[14px] leading-[14px] text-primary">
                  {MoneyConvert(record?.usageValue)}원
                </span>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ]

  const columns2: ColumnsType<any> = [
    {
      title: "브랜드명",
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
      render: (text, record) => {
        return (
          <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
            {text}
          </p>
        )
      },
    },
    {
      title: "매장 정보",
      dataIndex: "branchName",
      key: "branchName",
      align: "center",
      render: (text, record) => {
        return (
          <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
            {text}
          </p>
        )
      },
    },
    {
      title: "결제 일시",
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (_, record) => {
        return (
          <p
            className={`text-[12px] md:text-[14px] text-primary font-pretendard400 ${
              isMobile ? "pl-[14px]" : ""
            }`}
          >
            {dayjs(record?.createdTime).format("YYYY.MM.DD")}
          </p>
        )
      },
    },
    {
      title: "결제 금액",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (_, record) => (
        <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
          {MoneyConvert(record?.amount)}원
        </p>
      ),
    },
    {
      title: "결제 상태",
      dataIndex: "refundStatus",
      key: "refundStatus",
      align: "center",
      render: (text, record) => {
        return (
          <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
            {convertRefundStatus(text)}
          </p>
        )
      },
    },
  ]

  const convertRefundStatus = (status: string) => {
    switch (status) {
      case "CAN_CANCEL":
        return "결제완료"
      case "CANCEL_ALL":
        return "전체취소"
      case "CANCEL_A_PART":
        return "일부취소 "
      case "DONE":
        return "환불완료"

      default:
        return ""
    }
  }

  const handleGetBalanceSumary = async () => {
    setIsLoadingData(true)
    try {
      let params = {
        brandId: id,
      }
      const { data } = await getBalanceDetailSummary(params)
      if (data) {
        setBalanceDetailSummary(data)
        setIsLoadingData(false)
      }
    } catch (error) {
      setBalanceDetailSummary({})
      setIsLoadingData(false)
      console.log(error)
    }
  }

  const handleGetDataDetailNormalMembership = async () => {
    setIsLoadingData(true)
    try {
      const { data } = await getDataDetailNormalMembership({
        id: state?.subscriberId || Number(id),
      })
      if (data) {
        setDataDetailNormalMembership(data)
        setIsLoadingData(false)
      }
    } catch (error) {
      setDataDetailNormalMembership({})
      setIsLoadingData(false)
      console.log(error)
    }
  }

  const handleGetTransactionHistory = async () => {
    try {
      let params = {
        brandId: id,
        filterInOut: "OUT",
        transactionType: "USING_VOUCHER",
        ...queryTrans,
      }
      const { data }: any = await transactionApi.getTransactionHis(params)
      if (data) {
        setTransactionHistory(data)
      }
    } catch (error) {
      setTransactionHistory({})
      console.log(error)
    }
  }

  useEffect(() => {
    if (TYPE_MEMBERSHIP) {
      handleGetDataDetailNormalMembership()
    } else {
      handleGetBalanceSumary()
    }
  }, [])

  useEffect(() => {
    if (!TYPE_MEMBERSHIP) handleGetTransactionHistory()
  }, [queryTrans])

  const handleType = (type: string) => {
    switch (type) {
      case "MEMBERSHIP":
        return (
          <img
            alt="icon"
            src={ImgHeart}
            className="mr-[10px] sm:h-auto h-[20px]"
          />
          // <div className="font-pretendard400 text-[12px] xs:text-[16px] text-[#d34d7b] p-[4px] border border-solid border-[#d34d7b] leading-none mr-[6px]">
          //   {t("맴버십")}
          // </div>
        )
      case "VIP":
        return (
          <div className="font-pretendard400 text-[12px] xs:text-[16px] text-[#C70039] sm:p-[4px] px-[4px] border border-solid border-[#C70039] sm:leading-none mr-[10px] sm:h-auto h-[20px] leading-[20px]">
            {t("VIP")}
          </div>
        )
      case "BOTH":
        return (
          <>
            {state?.activeTab === 0 ? (
              <div className="font-pretendard400 text-[12px] xs:text-[16px] text-[#C70039] sm:p-[4px] px-[4px] border border-solid border-[#C70039] sm:leading-none mr-[10px] sm:h-auto h-[20px] leading-[20px]">
                {t("VIP")}
              </div>
            ) : (
              <img
                alt="icon"
                src={ImgHeart}
                className="mr-[10px] sm:h-auto h-[20px]"
              />
            )}
          </>
        )
      default:
        return ""
    }
  }

  const handleSubmitChangePayment = () => {
    setIsModalChangePayment(false)
    setIsModalCompleteChangePayment(true)
  }

  const handleMaintainIncentives = async () => {
    setIsModalCancelMembership(false)

    setIsModalCompleteMaintainIncentives(true)
  }

  const handleCancelTheOffer = async () => {
    try {
      const resp: any = await cancelMembershipNormalNewApi({
        id: dataDetailNormalMembership?.id,
      })
      if (resp.result) {
        handleGetDataDetailNormalMembership()
        setIsModalCancelMembership(false)

        setIsModalCompleteCancelTheOffer(true)
      }
    } catch (error) {}
  }

  console.log("dataDetailNormalMembership", dataDetailNormalMembership)

  return (
    <>
      {/**modal comment */}
      <ModalComment
        isVisible={isModalComment.isVisible}
        setCancel={() => {
          handleCancelModalComment()
        }}
        data={isModalComment.data}
        onClickReportCommentID={(data) => {
          setIsModalComment({
            isVisible: false,
            data: {},
          })

          setIsModalReport(true)
          setDataItemReport(data)
        }}
      />
      {/**modal report */}
      <ModalReport
        isVisible={isModalReport}
        setCancel={() => setIsModalReport(false)}
        onSubmit={(e) => {
          handleSubmitReport(e)
        }}
      />
      {/* modal post */}
      <ModalPost
        isVisible={isModalPost}
        setCancel={() => setIsModalPost(false)}
        onSubmit={(e) => onSubmitPost(e)}
        isLoading={isLoading}
        dataEdit={dataItemEditPost}
      />

      {/* modal success report */}
      <ModalBase
        isVisible={isStatusModalReport}
        setCancel={() => setIsStatusModalReport(false)}
        className="sm:max-w-[380px] max-w-[100%]"
      >
        <div className="text-center mb-[60px]">
          <div>신고 접수가 완료되었습니다. 운영팀에서</div>
          <div>확인 후 처리하겠습니다.</div>
        </div>

        <div
          className="absolute bottom-0 w-full bg-[#000] text-[#fff] mx-[-16px] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
          onClick={() => setIsStatusModalReport(false)}
        >
          확인
        </div>
      </ModalBase>
      {/**delete post me */}
      <ModalBase
        isVisible={isModalDeletePost}
        setCancel={() => setIsModalDeletePost(false)}
        className="sm:max-w-[380px] max-w-[100%]"
      >
        <div className="text-center mb-[30px]">삭제하시겠습니까?</div>

        <div className="flex gap-[10px]">
          <div
            className="w-[50%] bg-[#fff] text-[#000] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer border border-solid border-[#000]"
            onClick={() => setIsModalDeletePost(false)}
          >
            취소
          </div>
          <div
            className="w-[50%] bg-[#000] text-[#fff] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
            onClick={handleDeletePost}
          >
            확인
          </div>
        </div>
      </ModalBase>

      {/**type membership */}
      <ModalChangePayment
        open={isModalChangePayment}
        handleClose={() => setIsModalChangePayment(false)}
        handleSubmit={() => handleSubmitChangePayment()}
      />
      <CommonModal
        width={327}
        onCancel={() => {
          setIsModalCompleteChangePayment(false)
        }}
        // open
        open={isModalCompleteChangePayment}
        className="text-[#000] modal-3rd-part-membership"
      >
        <div className="px-[15px] py-[35px]">
          <div className="text-center text-[16px] mb-[35px]">
            결제수단 변경이 완료되었습니다.{" "}
          </div>
          <div className="mx-[-24px] mb-[-39px]">
            <Button
              theme="main"
              onClick={() => setIsModalCompleteChangePayment(false)}
            >
              확인
            </Button>
          </div>
        </div>
      </CommonModal>
      {/**cancel membership */}
      <ModalCancelMembership
        open={isModalCancelMembership}
        handleClose={() => setIsModalCancelMembership(false)}
        handleMaintainIncentives={() => handleMaintainIncentives()}
        handleCancelTheOffer={() => handleCancelTheOffer()}
        data={dataDetailNormalMembership}
      />
      <CommonModal
        width={327}
        onCancel={() => {
          setIsModalCompleteMaintainIncentives(false)
        }}
        // open
        open={isModalCompleteMaintainIncentives}
        className="text-[#000] modal-3rd-part-membership"
      >
        <div className="font-pretendard700 text-center xs:pt-0 pt-[40px]">
          감사합니다!
        </div>
        <div className="text-center text-[16px] mb-[32px] mx-[-15px] ">
          앞으로도 멤버십 혜택을 많이 이용해주세요.
        </div>
        <div className="mx-[-24px] mb-[-39px]">
          <Button
            theme="main"
            onClick={() => setIsModalCompleteMaintainIncentives(false)}
          >
            확인
          </Button>
        </div>
      </CommonModal>
      <CommonModal
        width={327}
        onCancel={() => {
          setIsModalCompleteCancelTheOffer(false)
        }}
        open={isModalCompleteCancelTheOffer}
        className="text-[#000] modal-3rd-part-membership"
      >
        <div className="text-center text-[16px] mb-[32px]">
          자동결제 신청이 해지되었습니다. <br /> 유효 기간 동안은 멤버십이
          유지됩니다.
        </div>
        <div className="mx-[-24px] mb-[-39px]">
          <Button
            theme="main"
            onClick={() => setIsModalCompleteCancelTheOffer(false)}
          >
            확인
          </Button>
        </div>
      </CommonModal>

      {/* <Spin spinning={isLoadingData}> */}
      {isLoadingData ? (
        <div></div>
      ) : (
        // <Spin />
        <div className="lg:pt-[60px] relative">
          {isMobile && (
            <div className="relative">
              <NavBarMb
                pageName="멤버십 상세정보"
                // csGoback={() => navigate("/my-membership")}
              />
            </div>
          )}

          <div className="z-10 pb-[20px]  md:pb-[100px] lg:mt-0 relative">
            <div className="md:container">
              {!isMobile && (
                <div className="flex justify-between items-center pb-[24px] border-b-[2px] border-b-primary">
                  <div className="font-pretendard700 text-[24px] leading-[24px] ">
                    {!TYPE_MEMBERSHIP
                      ? t("프리미엄 멤버십 상세정보")
                      : t("멤버십 상세정보")}
                    {/* {t("멤버십 상세정보")} */}
                  </div>
                  {TYPE_MEMBERSHIP && (
                    <div
                      className={`px-[15px] h-[32px] leading-[32px] text-center border border-[#E7E7E7] text-[13px] text-[#555555] cursor-pointer ${
                        !dataDetailNormalMembership?.isExtend &&
                        "!cursor-not-allowed text-[#a1a3a8]"
                      }`}
                      onClick={() => {
                        if (dataDetailNormalMembership?.isExtend) {
                          setIsModalCancelMembership(true)
                        }
                      }}
                    >
                      {!dataDetailNormalMembership?.isExtend
                        ? "해지완료"
                        : "멤버십 자동결제 취소하기"}
                    </div>
                  )}
                </div>
              )}
              <div
                className={`${
                  TYPE_MEMBERSHIP ? "md:mt-[35px]" : "md:mt-[100px]"
                }`}
              >
                <div className="container">
                  <div className="lg:gap-[20px] gap-[16px]">
                    <div
                      className={`mb-[0px] ${
                        TYPE_MEMBERSHIP ? "md:mb-[58px]" : "md:mb-[105px]"
                      } mx-[-24px] md:mx-[0]`}
                    >
                      <Row
                        gutter={20}
                        className="lg:px-0 px-[24px] lg:bg-white bg-gray3"
                      >
                        <Col lg={12} sm={24} xs={24}>
                          <div className="h-full w-full bg-gray3 flex items-center justify-center flex-col py-[45px] border-b border-solid md:border-none">
                            {/* <div className="h-full w-full bg-gray3 flex items-center justify-center flex-col py-[45px] md:h-[428px] border-b border-solid md:border-none"> */}
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/${
                                    TYPE_MEMBERSHIP
                                      ? dataDetailNormalMembership?.brandId
                                      : balanceDetailSummary?.balanceSummary
                                          ?.brandId
                                  }`,
                                )
                              }
                            >
                              <div className="mx-[auto] flex border border-solid w-[80px] h-[80px] md:w-[140px] md:h-[140px] justify-center items-center text-primary font-pretendard700 mb-[9px] md:mb-[16px]">
                                <img
                                  className="w-[80px] h-[80px] md:w-[140px] md:h-[140px] object-cover aspect-square"
                                  src={
                                    TYPE_MEMBERSHIP
                                      ? dataDetailNormalMembership?.logoImage
                                      : balanceDetailSummary?.balanceSummary
                                          ?.logoImage
                                  }
                                  alt="f"
                                />
                              </div>
                              <p className="text-[14px] md:text-[18px] font-pretendard700 leading-[100%] sm:mb-[40px] mb-[30px] md:mb-[48px] text-center">
                                {TYPE_MEMBERSHIP
                                  ? dataDetailNormalMembership?.brandName
                                  : balanceDetailSummary?.balanceSummary
                                      ?.brandName}
                              </p>
                            </div>
                            <div className="flex items-center">
                              {handleType(
                                TYPE_MEMBERSHIP
                                  ? "MEMBERSHIP"
                                  : balanceDetailSummary?.balanceSummary?.type,
                              )}
                              {/* <div className="font-pretendard400 flex items-center text-[14px] md:text-[16px] text-primaryRed px-[8px] py-[3px] border border-solid border-primaryRed leading-none mr-[5px]">
                            VIP
                          </div> */}
                              <span className="text-[18px] md:text-[24px] font-pretendard400 leading-[100%]">
                                {TYPE_MEMBERSHIP
                                  ? dataDetailNormalMembership?.displayName
                                  : balanceDetailSummary?.balanceSummary
                                      ?.displayName}{" "}
                                {t("님")}
                              </span>
                            </div>
                          </div>
                        </Col>
                        {TYPE_MEMBERSHIP ? (
                          <Col lg={12} sm={24} xs={24}>
                            <Row
                              gutter={isMobile ? 0 : [0, 20]}
                              className="md:min-h-[380px]"
                            >
                              <Col
                                span={24}
                                className="border-b flex items-center justify-between border-solid md:border-none w-full py-[40px] md:min-h-[180px] bg-gray3 lg:pl-[26px] lg:pr-[42px]"
                              >
                                <div className="md:flex md:flex-row flex-1 items-center justify-between h-full w-full flex-col">
                                  <span className="text-[13px] md:text-[16px] mb-[8px] md:mb-0 font-pretendard400 leading-[100%] text-[#9D9D9D]">
                                    {t("잔여 금액")}
                                  </span>
                                  <div className="text-[24px] md:text-[32px] font-pretendard700 leading-[100%] text-[#9D9D9D]">
                                    0원
                                  </div>
                                </div>
                              </Col>
                              <Col
                                span={24}
                                className="w-full py-[36px] md:min-h-[228px] bg-gray3 lg:pl-[26px] lg:pr-[42px]"
                              >
                                <div className="flex items-start justify-between mb-[20px]">
                                  <span className="min-w-[100px] text-[13px] md:text-[16px] font-pretendard400 leading-[100%] text-[#484848]">
                                    {t("멤버십 No.")}
                                  </span>
                                  <div className="flex flex-wrap justify-end gap-[4px] md:gap-[8px]">
                                    {dataDetailNormalMembership?.membershipNo?.map(
                                      (item: any, index: any) => {
                                        return (
                                          <div
                                            className="bg-[#A3A3A3] px-[4px] md:px-[8px] py-[2px] text-[12px] md:text-[14px] text-primaryWhite font-pretendard400 leading-[100%]"
                                            key={index}
                                          >
                                            {item}
                                          </div>
                                        )
                                      },
                                    )}
                                  </div>
                                </div>
                                <div className="flex items-center justify-between mb-[20px]">
                                  <span className="min-w-[100px] text-[13px] md:text-[16px] font-pretendard400 leading-[100%] text-[#484848]">
                                    {t("멤버십 가입일")}
                                  </span>
                                  <span className="text-[13px] md:text-[16px] font-pretendard400 leading-[100%]">
                                    {moment(
                                      dataDetailNormalMembership?.createdTime,
                                    ).format("YYYY.MM.DD")}
                                  </span>
                                </div>
                                <div className="flex items-center justify-between mb-[20px]">
                                  <span className="min-w-[100px] text-[13px] md:text-[16px] font-pretendard400 leading-[100%] text-[#484848]">
                                    {t("유효 기간")}
                                  </span>
                                  <span className="text-[13px] md:text-[16px] font-pretendard400 leading-[100%]">
                                    {dayjs(
                                      dataDetailNormalMembership?.createdTime,
                                    ).format("YYYY.MM.DD")}{" "}
                                    ~{" "}
                                    {dayjs(
                                      dataDetailNormalMembership?.expiredTime,
                                    )
                                      .subtract(1, "minutes")
                                      .format("YYYY.MM.DD")}
                                  </span>
                                </div>
                                <div className="flex items-center justify-between">
                                  <span className="min-w-[100px] text-[13px] md:text-[16px] font-pretendard400 leading-[100%] text-[#484848]">
                                    {/* {t("다음 결제일")} */}
                                    다음 결제일
                                  </span>
                                  <span className="text-[13px] md:text-[16px] font-pretendard400 leading-[100%]">
                                    {!dataDetailNormalMembership?.isExtend
                                      ? "자동 결제 취소 됨"
                                      : dayjs(
                                          dataDetailNormalMembership?.expiredTime,
                                        )
                                          .subtract(1, "minutes")
                                          .format("YYYY.MM.DD")}
                                  </span>
                                </div>

                                <div
                                  className={`ml-auto border xs:border-[#333333] border-[#D9D9D9] py-[7.25px] text-center text-[13px] xs:max-w-[150px] mt-[30px] cursor-pointer ${
                                    !dataDetailNormalMembership?.isExtend &&
                                    "!cursor-not-allowed text-[#a1a3a8] !border-[#a1a3a8]"
                                  }`}
                                  onClick={() => {
                                    if (dataDetailNormalMembership?.isExtend) {
                                      setIsModalChangePayment(true)
                                    }
                                  }}
                                >
                                  결제 수단 변경
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Col lg={12} sm={24} xs={24}>
                            <Row
                              gutter={isMobile ? 0 : [0, 20]}
                              className="md:min-h-[380px]"
                            >
                              <Col
                                span={24}
                                className="border-b flex items-center justify-between border-solid md:border-none w-full py-[40px] md:min-h-[180px] bg-gray3 lg:pl-[26px] lg:pr-[42px]"
                              >
                                <div className="md:flex md:flex-row flex-1 items-center justify-between h-full w-full flex-col">
                                  <span className="text-[13px] md:text-[16px] mb-[8px] md:mb-0 font-pretendard400 leading-[100%] text-[#484848]">
                                    {t("잔여 금액")}
                                  </span>
                                  <div className="flex sm:flex-col sm:items-end items-center gap-[12px] sm:mt-auto mt-[10px] sm:justify-start justify-between">
                                    <div className="text-[24px] md:text-[32px] font-pretendard700 leading-[100%] text-[#C70039]">
                                      {MoneyConvert(
                                        balanceDetailSummary?.balanceSummary
                                          ?.totalBalance || 0,
                                      )}
                                      원
                                    </div>
                                    {/* {isMobile && ( */}
                                    <Button
                                      theme="custom"
                                      onClick={() => navigate("/qr-code")}
                                      width={80}
                                      height={30}
                                      labelSize={14}
                                      className="border border-[#000] w-[100px] h-[28px]"
                                    >
                                      MY월렛
                                    </Button>
                                    {/* )} */}
                                  </div>
                                </div>
                              </Col>
                              <Col
                                span={24}
                                className="w-full py-[36px] md:min-h-[280px] bg-gray3 lg:pl-[26px] lg:pr-[42px]"
                              >
                                <div className="flex items-start justify-between mb-[20px]">
                                  <span className="min-w-[100px] text-[13px] md:text-[16px] font-pretendard400 leading-[100%] text-[#484848]">
                                    {t("멤버십 No.")}
                                  </span>
                                  <div className="flex flex-wrap justify-end gap-[4px] md:gap-[8px]">
                                    {balanceDetailSummary?.membershipNo?.map(
                                      (item: any, index: any) => {
                                        return (
                                          <div className="bg-[#444444] px-[4px] md:px-[8px] py-[2px] text-[12px] md:text-[14px] text-primaryWhite font-pretendard400 leading-[100%]">
                                            {item?.brandCode}
                                            {item?.extend}
                                            {item?.no}
                                          </div>
                                        )
                                      },
                                    )}
                                  </div>
                                </div>
                                <div className="flex items-center justify-between mb-[20px]">
                                  <span className="min-w-[100px] text-[13px] md:text-[16px] font-pretendard400 leading-[100%] text-[#484848]">
                                    {t("프리미엄 멤버십 구매일")}
                                  </span>
                                  <span className="text-[13px] md:text-[16px] font-pretendard400 leading-[100%]">
                                    {moment(
                                      balanceDetailSummary?.balanceSummary
                                        ?.createdTime,
                                    ).format("YYYY.MM.DD")}
                                  </span>
                                </div>
                                <div className="flex items-center justify-between mb-[20px]">
                                  <span className="min-w-[100px] text-[13px] md:text-[16px] font-pretendard400 leading-[100%] text-[#484848]">
                                    {t("총 구매금액")}
                                  </span>
                                  <span className="text-[13px] md:text-[16px] font-pretendard400 leading-[100%]">
                                    {MoneyConvert(
                                      balanceDetailSummary?.balanceSummary
                                        ?.totalVoucherValue || 0,
                                    )}
                                    원
                                  </span>
                                </div>
                                <div className="flex items-center justify-between mb-[20px]">
                                  <span className="min-w-[100px] text-[13px] md:text-[16px] font-pretendard400 leading-[100%] text-[#484848]">
                                    {t("누적 사용금액")}
                                  </span>
                                  <span className="text-[13px] md:text-[16px] font-pretendard400 leading-[100%]">
                                    {MoneyConvert(
                                      balanceDetailSummary?.balanceSummary
                                        ?.totalOut || 0,
                                    )}
                                    원
                                  </span>
                                </div>
                                <div className="flex items-center justify-between">
                                  <span className="min-w-[100px] text-[13px] md:text-[16px] font-pretendard400 leading-[100%] text-[#484848]">
                                    {t("사용가능 기간")}
                                  </span>
                                  <span className="text-[13px] md:text-[15px] font-pretendard400 leading-[100%]">
                                    {dayjs(
                                      balanceDetailSummary?.balanceSummary
                                        ?.endTime,
                                    ).format("YYYY년 MM월 DD일 HH시 mm분")}
                                    ~
                                    {dayjs(
                                      balanceDetailSummary?.balanceSummary
                                        ?.expiredTime,
                                    )
                                      .subtract(1, "minutes")
                                      .format("YYYY년 MM월 DD일 HH시 mm분")}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    </div>

                    {!isMobile ? (
                      <>
                        {TYPE_MEMBERSHIP ? (
                          <>
                            <div className="font-pretendard700 text-[20px] leading-[18px] mb-[16px]">
                              {t("멤버십 혜택")}
                            </div>
                            <div className="border border-[#D9D9D9] py-[15px] flex">
                              <div className="w-[46%] flex gap-[29px] px-[40px] py-[24px]">
                                <div className="w-[100px] h-[100px]">
                                  <img
                                    src={dataDetailNormalMembership?.logoImage}
                                    alt="img"
                                  />
                                </div>
                                <div>
                                  <div className="font-pretendard700 text-[18px] text-[#539165] leading-[100%]">
                                    {dataDetailNormalMembership?.membershipName}
                                  </div>
                                  <div className="text-[#757575] text-[15px] pt-[8px] leading-[100%]">
                                    월 자동 결제
                                  </div>
                                  <div className="text-[18px] text-[#539165] pt-[21px] leading-[100%]">
                                    월{" "}
                                    <span className="font-pretendard700">
                                      {formatPrice(
                                        Number(
                                          dataDetailNormalMembership?.sellingPrice,
                                        ),
                                      )}
                                    </span>
                                    원
                                  </div>
                                  <div className="text-[#757575] text-[15px] pt-[8px] leading-[100%]">
                                    {formatPrice(
                                      ((Number(
                                        dataDetailNormalMembership?.rootPrice,
                                      ) -
                                        Number(
                                          dataDetailNormalMembership?.sellingPrice,
                                        )) /
                                        Number(
                                          dataDetailNormalMembership?.rootPrice,
                                        )) *
                                        100,
                                    )}
                                    % 할인
                                  </div>
                                </div>
                              </div>
                              <div className="w-[54%] px-[40px] py-[24px] border-l border-[#D9D9D9]">
                                <div className="font-pretendard700 text-[#2D2D2D] mb-[16px]">
                                  멤버십 혜택
                                </div>
                                <div className="flex gap-[12px] flex-col">
                                  {dataDetailNormalMembership?.preferential?.map(
                                    (item: any, index: number) => (
                                      <div
                                        className={`flex gap-[12px]`}
                                        // className={`flex gap-[10px] ${
                                        //   dataDetailNormalMembership?.preferential
                                        //     ?.length > 6
                                        //     ? "w-[calc(33%_-_6.5px)]"
                                        //     : dataDetailNormalMembership
                                        //         ?.preferential?.length > 3
                                        //     ? "w-[calc(50%_-_6px)]"
                                        //     : "w-[100%]"
                                        // }`}
                                        key={index}
                                      >
                                        <div className="min-w-[3px] h-[3px] bg-[#2D2D2D] rounded-full mt-[6px]"></div>
                                        <div className="text-[#2D2D2D] leading-[100%]">
                                          {item}
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex justify-between items-center">
                              <div className="font-pretendard700 text-[18px] leading-[18px]">
                                {t("프리미엄 멤버십 이용 내역")}
                                {/* {t("멤버십 이용내역")} */}
                              </div>

                              <div className="flex gap-[15px] items-center">
                                <div className="text-[14px]">기간</div>
                                <SelectCustom
                                  width={110}
                                  placeholder={t("All status")}
                                  value={queryTrans.timeFilter}
                                  onChange={(e) => {
                                    setQueryTrans((prev) => ({
                                      ...prev,
                                      page: 1,
                                      timeFilter: e,
                                    }))
                                  }}
                                  options={allTime}
                                />
                              </div>
                            </div>

                            <div
                              className={`flex pb-[9px] mt-[20px] md:mt-[30px] gap-[28px]`}
                            >
                              <p className="font-pretendard400 text-[12px] md:text-[14px] leading-[14px]">
                                {t("총 N건", {
                                  number:
                                    transactionHistory?.pageable?.totalElement,
                                })}
                              </p>
                              <p className="font-pretendard400 text-[12px] md:text-[14px] leading-[14px]">
                                {dayjs()
                                  .subtract(
                                    TIME_NUMBER[queryTrans.timeFilter] || 12,
                                    "month",
                                  )
                                  .format("YYYY.MM.DD")}{" "}
                                ~ {dayjs().format("YYYY.MM.DD")}
                              </p>
                            </div>
                            <div className="border-t border-t-solid border-t-primary lg:mx-0 mx-[-24px]">
                              <CsTable
                                className="mb-[34px]"
                                columns={columns2}
                                dataSource={transactionHistory?.datas}
                                // showHeader={false}
                              />
                              {transactionHistory?.datas &&
                                transactionHistory?.datas?.length > 0 && (
                                  <Pagination
                                    showSizeChanger={false}
                                    rootClassName="flex justify-center"
                                    className="custom-pagination"
                                    total={
                                      transactionHistory?.pageable?.totalElement
                                    }
                                    pageSize={queryTrans?.size}
                                    current={queryTrans?.page}
                                    onChange={(page) => {
                                      setQueryTrans((prev) => ({
                                        ...prev,
                                        page,
                                      }))
                                    }}
                                  />
                                )}
                            </div>
                          </>
                        )}

                        <div className="flex justify-between items-center mt-[62px] mb-[18px]">
                          <div className="font-pretendard700 text-[18px] leading-[18px]">
                            {t("커뮤니티 활동 내역")}
                          </div>
                        </div>

                        <div className="flex pb-[30px]">
                          {dataTabCommunity.map((item, index: number) => (
                            <div
                              className={`text-[18px] cursor-pointer leading-[18px] ${
                                item.value === tabCommunity &&
                                "font-pretendard700"
                              } ${
                                index === 0
                                  ? "pr-[16px] border-r border-r-[#D4D4D4]"
                                  : "pl-[16px]"
                              }`}
                              key={index}
                              onClick={() => setTabCommunity(item.value)}
                            >
                              {item.label}
                            </div>
                          ))}
                        </div>

                        {tabCommunity === dataTabCommunity[0].value ? (
                          <>
                            <div className="flex flex-col sm:gap-[64px] gap-[35px]">
                              {dataPost &&
                                dataPost.length > 0 &&
                                dataPost.map((dt: any, index) => (
                                  <div key={index}>
                                    <PostCommunity
                                      setIsEditPost={(data) => {
                                        setIsModalPost(true)
                                        setDataItemEditPost(data)
                                      }}
                                      onSubmitDeletePost={(id: number) => {
                                        setIsModalDeletePost(true)
                                        setIdDeletePost(id)
                                      }}
                                      setIsModalComment={(data) =>
                                        handleCardShowComment(data)
                                      }
                                      data={dt}
                                    />
                                  </div>
                                ))}
                            </div>
                            {dataPost && dataPost?.length > 0 && (
                              <Pagination
                                showSizeChanger={false}
                                rootClassName="flex justify-center"
                                className="custom-pagination"
                                total={pageable?.totalElement}
                                pageSize={pageable?.size}
                                current={pageable?.page}
                                onChange={(page) => {
                                  setPageable({
                                    ...pageable,
                                    page: page,
                                  })
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <ListComment
                            onClickReportCommentID={() => {}}
                            brandIdPush={
                              balanceDetailSummary?.balanceSummary?.brandId
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div>
                        {TYPE_MEMBERSHIP ? (
                          <>
                            {dataTabMobile2.map((item, index) => (
                              <div
                                className={`py-[19px] px-[25px] mx-[-24px] border-b border-b-[#F5F5F5] `}
                                key={index}
                                onClick={() => {
                                  navigate(item.routes, {
                                    state: {
                                      id: dataDetailNormalMembership?.id,
                                      brandId:
                                        dataDetailNormalMembership?.brandId,
                                    },
                                  })
                                }}
                              >
                                {item.label}
                              </div>
                            ))}
                          </>
                        ) : (
                          <>
                            {dataTabMobile.map((item, index) => (
                              <div
                                className={`py-[19px] px-[25px] mx-[-24px] border-b border-b-[#F5F5F5] ${
                                  index === 1 && "text-[#c0b2b2]"
                                }`}
                                key={index}
                                onClick={() => {
                                  if (index !== 1) {
                                    navigate(item.routes, {
                                      state: {
                                        brandId: Number(id),
                                      },
                                    })
                                  }
                                }}
                              >
                                {item.label}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {TYPE_MEMBERSHIP && (
                  <div
                    className={`flex justify-center items-center gap-[5px] mt-[61px] xs:hidden `}
                    // onClick={() => setIsModalCancelMembership(true)}
                    onClick={() => {
                      if (dataDetailNormalMembership?.isExtend) {
                        setIsModalCancelMembership(true)
                      }
                    }}
                  >
                    <div
                      className={`text-[#868585] ${
                        !dataDetailNormalMembership?.isExtend &&
                        "!cursor-not-allowed text-[#a1a3a8]"
                      }`}
                    >
                      {!dataDetailNormalMembership?.isExtend
                        ? "해지완료"
                        : "멤버십 자동결제 취소하기"}
                    </div>
                    <img src={IconNext} alt="IconNext" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* </Spin> */}
    </>
  )
}

export default MyMembershipDetail
