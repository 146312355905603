// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"

import "./index.scss"
// import required modules
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getProduct } from "src/api/homeApi"
import useWindowSize from "src/customHook/useWindowSize"
import { Pagination } from "swiper/modules"
import CardMobile from "./CardMobile"
import ContentCard from "./ContentCard"
import ShopCard from "src/components/Card/ShopCard"
import { useLocation } from "react-router-dom"

function HighlightProducts() {
  const [data, setData] = useState<any[]>([])
  const { t } = useTranslation()
  const { state } = useLocation()

  useEffect(() => {
    ;(async function getDataProduct() {
      try {
        const res: any = await getProduct({
          pageSize: 12,
        })
        if (res.result) {
          setData(res.data.datas)

          const positionScrollY = sessionStorage.getItem("positionScrollY")
          if (positionScrollY && state?.currentPage) {
            setTimeout(() => {
              window.scrollTo(
                0,
                Number(sessionStorage.getItem("positionScrollY")),
              )
            }, 0)
          }
        }
      } catch (error) {}
    })()
  }, [])

  const { width }: any = useWindowSize()
  const isMobile = width <= 540

  return (
    data.length > 0 && (
      <div className="container-highlight">
        <div className="sm:mb-[40px] sm:pb-[24px] sm:text-[24px] sm:border-b-2 border-b-[#000] pb-[20px] text-[18px] font-pretendard700">
          {t("하이라이트 상품")}
        </div>
        {isMobile ? (
          <CardMobile data={data} />
        ) : (
          <Swiper
            slidesPerGroup={4}
            slidesPerView={4}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {data
              .map((dt, index) => (
                <SwiperSlide key={index}>
                  {/* <ContentCard dt={dt} /> */}
                  <ShopCard data={dt} className="h-full" />
                </SwiperSlide>
              ))
              .concat(
                Array(data.length % 4 === 0 ? 0 : 4 - (data.length % 4)).fill(
                  <SwiperSlide></SwiperSlide>,
                ),
              )}
          </Swiper>
        )}
      </div>
    )
  )
}

export default HighlightProducts
