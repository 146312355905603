import CloseIcon from "@assets/images/brand_detail/close.svg"

interface Props {
  isFooter?: boolean
  title?: string
  children?: React.ReactNode
  isVisible: boolean
  setCancel: () => void
  className?: string
  classNameContent?: string
}

function ModalBase({
  isFooter,
  title,
  children,
  isVisible,
  setCancel,
  className,
  classNameContent,
}: Props) {
  return (
    isVisible && (
      <div
        className="fixed z-[10000] overflow-y-auto top-0 w-full left-0"
        // className="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
        id="modal"
      >
        <div className="flex items-center justify-center min-height-100vh px-[16px] text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-[rgba(0,0,0,.45)] opacity-75" />
          </div>
          <span className="inline-block align-middle h-screen">&#8203;</span>
          <div
            className={`${className} inline-flex flex-col align-center bg-white rounded-[2px] text-left overflow-hidden shadow-xl transform transition-all align-middle w-full
            max-h-[80vh] overflow-y-auto relative`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div
              className={`bg-white px-[16px] py-[17px] overflow-y-auto ${classNameContent}`}
            >
              <div className="flex justify-end absolute right-[16px] top-[17px] z-10">
                <div onClick={setCancel} className="cursor-pointer">
                  <CloseIcon />
                </div>
              </div>
              {title && (
                <div className="text-[18px] font-pretendard700 text-center pb-4">
                  {title}
                </div>
              )}
              <div className="mt-[24px]">{children}</div>
            </div>
            {isFooter && (
              <div className="px-4 py-3 flex gap-[15px]">
                <button
                  type="button"
                  className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 flex-1"
                >
                  <i className="fas fa-times"></i> Cancel
                </button>
                <button
                  type="button"
                  className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 flex-1"
                >
                  <i className="fas fa-plus"></i> Create
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default ModalBase
