import { userApi } from "src/api/userApi"
import { loginInfo } from "src/pages/Auth/LoginRegister/LoginForm"

enum USER_ROLE {
  "USER",
  "ADMIN",
}

export class User {
  id?: number
  accessToken?: string
  email?: string
  avatar?: string
  userRole?: USER_ROLE
  phoneNumber?: string
  displayName?: string
  registeredAt?: string
  hasMyVouchers?: boolean
  constructor(props: User | null) {
    for (const key in this) {
      if (props?.hasOwnProperty(key)) {
        ;(this as any)[key] = (props as any)[key]
      }
    }
  }
  login(payload: loginInfo) {
    return userApi.login(payload)
  }
  deactive(payload: { password: string }) {
    return userApi.deactiveAccount(payload)
  }
  getVoucherNearlyExpired = async ({
    page = "1",
    size = "10",
  }: {
    page?: string | number
    size?: string | number
  }) => {
    const { data } = await userApi.getVoucherNearlyExpired({ page, size })
    const datas = data.datas
    const total = data.pageable.totalElement
    return [total, datas]
  }
}
