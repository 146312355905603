import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getListVoucherUsageHistory } from "src/api/voucherUsagehistoryApi"
import Pagination from "src/components/Pagination"
import { UrlInternal } from "src/constant/endpointRoute"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"

function VoucherUsageHistory() {
  const navigate = useNavigate()
  const { isMobile } = useIsMobile(768)
  const [dataSource, setDataSource] = useState<any>([])
  const [pageable, setPageable] = useState<any>()
  const [searchParams, setSearchParams] = useSearchParams()
  const query = {
    page: searchParams.get("page") ? Number(searchParams.get("page")) : 1,
    size: 8,
  }
  const { t } = useTranslation()

  const getData = async () => {
    try {
      const resp: any = await getListVoucherUsageHistory(query)
      if (resp.result) {
        setDataSource(resp.data.datas)
        setPageable(resp.data.pageable)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  return (
    <>
      {isMobile && <NavBarMb pageName="Voucher usage history" />}
      <div className="container md:mt-[68px] mt-[40px]">
        <div className="text-[24px] font-pretendard700 pb-[16px] border-b border-[#000000] mb-[50px] md:block hidden">
          {t("Voucher usage history")}
        </div>
        {dataSource.length > 0 ? (
          <div className="grid md:grid-cols-4 grid-cols-2 md:gap-[27px] gap-[20px]">
            {dataSource?.map((item: any, i: number) => (
              <div
                onClick={() =>
                  navigate(UrlInternal.VOUCHER_USAGE_HISTORY + "/" + item.id)
                }
                className="cursor-pointer"
                key={i}
              >
                <img
                  src={item?.logoImage}
                  alt="img"
                  className="w-full object-cover aspect-square"
                />
                <div className="mt-[28px] font-pretendard700">
                  {item?.brandName}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center my-[200px] text-[16px]">
            현재 보유한 티켓이 없습니다. <br />
            티켓은 각 브랜드 스토어에서 구매 가능합니다.
          </div>
        )}

        {dataSource && pageable?.totalElement?.length > 8 && (
          <Pagination
            showSizeChanger={false}
            rootClassName="flex justify-center"
            className="custom-pagination mt-[50px]"
            total={pageable?.totalElement}
            pageSize={query?.size}
            current={Number(query?.page)}
            onChange={(page) => {
              searchParams.set("page", page.toString())
              setSearchParams(searchParams)
            }}
          />
        )}
      </div>
    </>
  )
}

export default VoucherUsageHistory
