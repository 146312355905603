import { formatPrice } from "src/util/formatPrice"
import LikeImg from "@assets/images/brand_page/like.png"
import LikeActiveImg from "@assets/images/brand_page/like-active.png"
import { useEffect, useState } from "react"
import { toggleLike, toggleLikeCommunity } from "src/api/brandApi"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "src/customHook/useAuth"

interface Props {
  count?: number
  active?: boolean
  id?: number
  classLabel?: string
  classIcon?: string
  isCommunity?: boolean
}

function LikeFeed({
  active = false,
  count = 0,
  id,
  classLabel,
  classIcon,
  isCommunity,
}: Props) {
  const [stateActive, setStateActive] = useState(active)
  const [countActive, setCountActive] = useState(count)
  const { pathname } = useLocation()
  const { isLogined } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    setCountActive(count)
  }, [count])
  useEffect(() => {
    setStateActive(active)
  }, [active])

  const handleLike = async () => {
    if (!isLogined && isCommunity) {
      navigate({
        pathname: "/auth",
        search: `backUrl=${pathname}?tab=community`,
      })
    } else {
      try {
        const res: any = isCommunity
          ? await toggleLikeCommunity({ id })
          : await toggleLike({ id })

        if (res.result) {
          setStateActive((prev) => !prev)
          setCountActive(stateActive ? countActive - 1 : countActive + 1)
        }
      } catch (error) {}
    }
  }

  return (
    <div
      className="inline-flex gap-[7px] cursor-pointer items-center"
      onClick={handleLike}
    >
      <img
        src={stateActive ? LikeActiveImg : LikeImg}
        alt="like"
        className={`max-h-[16px] ${classIcon}`}
      />
      <div className={`text-[12px] font-pretendard500 ${classLabel}`}>
        {formatPrice(countActive)}
      </div>
    </div>
  )
}

export default LikeFeed
