import {
  EventSourceMessage,
  fetchEventSource,
} from "@microsoft/fetch-event-source"
import ApiConfig from "src/configs/apiConfig"
import { store } from "src/app/store"
import { openNotEnough } from "src/features/confirmTransaction/confirmTransactionSlice"

export let ctrl = new AbortController()
type SSEEVENT_TYPE =
  | "REQUEST_CONFIRM_PAYMENT"
  | "PAYMENT_RESULT"
  | "REQUEST_USING_VOUCHER_COUPON"
  | "CHANGE_PASS"
const apiConfig = ApiConfig()

export async function initForFetchEventSource(
  accToken?: string,
  options?: {
    recall?: boolean
    onRequestConfirm?: (ev: EventSourceMessage) => void
  },
) {
  if (options?.recall) {
    ctrl.abort()
    ctrl = new AbortController()
  }
  await fetchEventSource(`${apiConfig.BASE_URL}/sse/stream`, {
    headers: {
      Authorization: `Bearer ${accToken}`,
      "Cache-Control": "no-cache",
      Accept: "text/event-stream",
    },

    method: "GET",
    onmessage(ev) {
      const typeEv: SSEEVENT_TYPE = ev.event as SSEEVENT_TYPE

      console.log("typeEv", typeEv)

      if (
        typeEv === "REQUEST_CONFIRM_PAYMENT" ||
        typeEv === "REQUEST_USING_VOUCHER_COUPON" ||
        typeEv === "CHANGE_PASS"
      ) {
        options?.onRequestConfirm && options?.onRequestConfirm(ev)
      }

      if (JSON.parse(ev.data)?.transaction_status === "NOT_ENOUGH") {
        // const stateData = store.getState()
        // console.log('stateData', stateData);
        store.dispatch(openNotEnough(JSON.parse(ev.data)))
      }
    },
    onclose() {
      console.log("Connection closed by the server")
    },
    openWhenHidden: true,
    signal: ctrl.signal,
  })
}
