import React, { useEffect } from "react"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { useParams } from "react-router-dom"

type Props = {}

const SupplierEn: React.FC<Props> = () => {
  const { isMobile } = useIsMobile(1023)
  const dataUser = useAppSelector(userDataSelect)
  const dispatch = useAppDispatch()
  const { brandName } = useParams()
  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  return (
    <>
      {isMobile && <NavBarMb pageName="MOAFRIENDS" />}
      <div className="container">
        <div className="mx-auto px-[0] xs:px-[16px] lg:pt-[70px] pb-[40px] xs:pb-[64px]">
          <div
            className={
              isMobile ? "py-[20px] px-[8px] bg-white rounded-[15px]" : ""
            }
          >
            <div
              className={`bg-bgTextTop bg-contain flex items-center ${"border-solid border-b-2 border-b-primary pb-[15px] xs:pb-[24px]"}`}
            >
              <h1
                className={`${
                  isMobile ? "text-[18px]" : "text-[24px]"
                } text-primary uppercase font-pretendard700 leading-none`}
              >
                MoaFriends Third-Party Data Provision Consent
              </h1>
            </div>
            <div
              className={`shadow-custom7 ${"lg:h-[532px] h-[calc(100vh-230px)] pt-[32px] pb-[42px] border border-solid mt-[30px]"}`}
            >
              <div
                className={`scroll-style-1 overflow-y-auto h-full ${
                  isMobile ? "pl-[16px] pr-[16px]" : "px-[28px]"
                }`}
              >
                <p className="text-[16px] text-primary font-pretendard400 leading-[27px]">
                  [Required] MoaFriends Third-Party Data Provision Consent
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  For user identification, member management, and service
                  provision within the MoaFriends service, we will provide your
                  personal information, along with{" "}
                  <span className="font-pretendard700">
                    {dataUser?.displayName}
                  </span>
                  , along with your membership number. This information will be
                  promptly destroyed upon withdrawal of consent or service
                  termination. You have the right to refuse the consent below,
                  and refusal to provide required consent may restrict your use
                  of the service. Refusing optional consent may limit access to
                  some services for the stated purposes.
                </p>
                <div className="mb-[30px]" />
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  [Recipient]
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  <span className="font-pretendard700">{brandName}</span>
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  [Required Information]
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  ID, Email, Phone Number, Birth Year, Birthday, Gender
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  [Purpose of Provision]
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  User identification within the MoaFriends service, membership
                  brand member management, and service provision
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  [Retention Period]
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  Promptly destroyed upon withdrawal of consent or service
                  termination.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SupplierEn
