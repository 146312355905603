import React, { useEffect } from "react"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { useParams } from "react-router-dom"

type Props = {}

const SupplierKor: React.FC<Props> = () => {
  const { isMobile } = useIsMobile(1023)
  const dataUser = useAppSelector(userDataSelect)
  const dispatch = useAppDispatch()
  const { brandName } = useParams()
  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  return (
    <>
      {isMobile && <NavBarMb pageName="MOAFRIENDS" />}
      <div className="container">
        <div className="mx-auto px-[0] xs:px-[16px] lg:pt-[70px] pb-[40px] xs:pb-[64px]">
          <div
            className={
              isMobile ? "py-[20px] px-[8px] bg-white rounded-[15px]" : ""
            }
          >
            <div
              className={`bg-bgTextTop bg-contain flex items-center ${"border-solid border-b-2 border-b-primary pb-[15px] xs:pb-[24px]"}`}
            >
              <h1
                className={`${
                  isMobile ? "text-[18px]" : "text-[24px]"
                } text-primary uppercase font-pretendard700 leading-none`}
              >
                개인정보 제 3자 제공
              </h1>
            </div>
            <div
              className={`shadow-custom7 ${"lg:h-[532px] h-[calc(100vh-230px)] pt-[32px] pb-[42px] border border-solid mt-[30px]"}`}
            >
              <div
                className={`scroll-style-1 overflow-y-auto h-full ${
                  isMobile ? "pl-[16px] pr-[16px]" : "px-[28px]"
                }`}
              >
                <p className="text-[16px] text-primary font-pretendard400 leading-[27px]">
                  [필수]모아프렌즈 개인정보 제3자 제공동의
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  모아프렌즈 서비스 내 이용자 식별, 회원관리 및 서비스 제공을
                  위해 회원번호와 함께{" "}
                  <span className="font-pretendard700">
                    {dataUser?.displayName}
                  </span>
                  님의 개인정보를 제공합니다. 해당 정보는 동의 철회 또는 서비스
                  탈퇴 시 지체없이 파기됩니다. 아래 동의를 거부 할 권리가
                  있으며, 필수 동의를 거부할 경우 서비스 이용이 제한됩니다. 선택
                  동의를 거부할 경우 기재된 목적의 일부 서비스 이용이 제한될 수
                  있습니다.
                </p>
                <div className="mb-[30px]" />
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  [제공 받는 자]
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  [모아프렌즈 회원가입]
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  멤버십 브랜드명
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  <span className="font-pretendard700">{brandName}</span>
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  [필수 제공 항목]
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  아이디, 이메일, 휴대폰번호, 출생연도, 생일, 성별
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  [제공 목적]
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  모아프렌즈 서비스 내 이용자 식별, 멤버십 브랜드 회원관리 및
                  서비스 제공
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  [보유 기간]
                </p>
                <p className="text-[16px] text-textColor23 font-pretendard400 leading-[27px]">
                  동의 철회 또는 서비스 탈퇴 시 지체없이 파기
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SupplierKor
