import NavBarMb from "src/layout/Header/NavBarMb"
import useIsMobile from "src/customHook/useIsMobile"
import CommonModal, { CommonModalProps } from "src/components/Modal"
import ImgCard from "@assets/images/mymembership/card.png"
import { useState } from "react"
import Selection from "@components/Selection"

interface Props extends CommonModalProps {
  open: boolean
  handleClose?: () => void
  handleSubmit?: () => void
}

const dataRadio = [
  {
    label: "신용/체크카드",
    value: "신용/체크카드",
  },
  {
    label: "네이버페이",
    value: "네이버페이",
  },
  {
    label: "카카오페이",
    value: "카카오페이",
  },
]

function ModalChangePayment({
  open,
  handleClose,
  handleSubmit,
  ...props
}: Props) {
  const { isMobile } = useIsMobile(475)
  const [dataSelected, setDataSelected] = useState("신용/체크카드")
  const [checked, setChecked] = useState<Record<string, boolean>>({
    agree: false,
  })

  return (
    <CommonModal
      width={375}
      onCancel={() => {
        handleClose && handleClose()
      }}
      open={open}
      className="text-[#000] modal-3rd-part-membership modal-fullpage"
      {...props}
    >
      {isMobile && (
        <>
          <NavBarMb
            pageName="멤버십 상세정보"
            className="mx-[-24px]"
            csGoback={() => handleClose?.()}
          />
        </>
      )}

      <h2 className="text-[18px] font-pretendard700 leading-normal mt-[-25px] text-center xs:block hidden">
        멤버십 상세정보
      </h2>
      <div className="xs:mt-[42px] mt-[24px] mb-[20px] text-[16px] font-pretendard700">
        등록된 결제 수단
      </div>
      <div className="border border-[#EBEBEB] text-center w-full h-[128px] flex flex-col justify-center items-center">
        <div>
          <img src={ImgCard} alt="card" />
        </div>
        <div className="font-pretendard700 mt-[8px]">신용카드</div>
      </div>
      <div className="mt-[37px] mb-[20px] text-[16px] font-pretendard700">
        결제수단 선택
      </div>

      <div className="flex flex-col gap-[8px]">
        {dataRadio.map((item, index) => (
          <div
            className="border border-[#EBEBEB] h-[44px] leading-[44px] px-[18px] flex gap-[12px] items-center cursor-pointer"
            key={index}
            // onClick={() => setDataSelected(item.value)}
          >
            <div
              className={`flex justify-center items-center min-w-[16px] h-[16px] rounded-full border border-[#D9D9D9] ${
                dataSelected === item.value && "!border-[#111]"
              } ${index !== 0 && "bg-[#eee] cursor-not-allowed"}`}
            >
              {dataSelected === item.value && (
                <div className="w-[10px] h-[10px] rounded-full bg-[#111]"></div>
              )}
            </div>
            <div>{item.label}</div>
          </div>
        ))}
      </div>

      <div className="pt-[56px] pb-[17px]">
        <Selection
          onSelectChange={(checked) => setChecked(checked)}
          borderColor="#D4D4D4"
          labelStyle={{
            fontSize: "13px",
            lineHeight: "100%",
            fontFamily: "Pretendard-400",
          }}
          selectOption={[
            {
              label: "(필수)매월 자동 결제에 동의합니다.",
              name: "agree",
              defaultChecked: checked.agree,
            },
          ]}
        />
      </div>

      <div className="flex mx-[-24px] xs:mb-[-39px] xs:static fixed left-0 right-0 bottom-0">
        <div
          className="flex-1 cursor-pointer bg-[#ECECEC] h-[48px] leading-[48px] text-center text-[16px]"
          onClick={() => handleClose?.()}
        >
          취소
        </div>
        <div
          className={`flex-1 cursor-pointer bg-[#000000] h-[48px] leading-[48px] text-center text-[16px] text-[#fff] ${
            !checked.agree && "!cursor-not-allowed text-[#eee]"
          }`}
          onClick={() => {
            if (checked.agree) {
              handleSubmit?.()
            }
          }}
        >
          변경하기
        </div>
      </div>
    </CommonModal>
  )
}

export default ModalChangePayment
