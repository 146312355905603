import shape from "@assets/images/brand_detail/shape.png"
import lock from "@assets/images/brand_detail/lock.png"

function PostOnlyMembership() {
  return (
    <div className="flex sm:gap-[32px] gap-[19.5px]">
      <div className="flex items-center justify-center sm:w-[45px] sm:h-[45px] w-[40px] h-[40px] bg-[#F5F5F5] rounded-[50%]">
        <img src={lock} alt="logo" className="" />
      </div>

      <div className="flex items-center justify-center bg-[#F5F5F5] sm:p-[24px] py-[16px] px-[20px] relative w-full h-[230px]  flex-1">
        <img
          src={shape}
          alt="shape"
          className="absolute left-[-12px] top-[16px]"
        />

        <div className="text-center">
          멤버십 전용 게시판입니다.
          <br /> 사용을 원하시는 경우 멤버십에 가입해 주세요.
        </div>
      </div>
    </div>
  )
}

export default PostOnlyMembership
