import IconQrCode from "@assets/icons/mypage/icon-qr-code.svg"
import RightArrowWhite16 from "@assets/icons/RightArrowWhite16.svg"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useWindowSize from "src/customHook/useWindowSize"
import { listDefaultAvt } from "src/pages/MyPage/config"

import QrImg from "@assets/images/my_page/qr_code_scanner.png"
import CommentImg from "@assets/images/my_page/comment.png"
import QrImgMobile from "@assets/images/my_page/qr_code_scanner_mobile.png"
import CommentImgMobile from "@assets/images/my_page/comment_mobile.png"
import CreditCardImg from "@assets/images/my_page/credit_card_heart.png"
import { UrlInternal } from "src/constant/endpointRoute"

export type TMyPageBannerProps = {
  avatar: string
  displayName: string
  memberType?: "VIP" | "NORMAL"
  gender: "MALE" | "FEMALE"
  totalVip: string | number
  dataUser?: any
}

const MyPageBanner: React.FC<TMyPageBannerProps> = ({
  avatar,
  displayName,
  memberType = "NORMAL",
  gender = "MALE",
  totalVip = 0,
  dataUser,
}) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const { width }: any = useWindowSize()

  console.log("dataUser", dataUser)

  console.log("i18n", i18n.language)

  return (
    <div className="md:flex justify-between lg:mb-[42px] md:bg-black1 py-[24px] md:px-[26px] md:mt-0 mt-[-37px]">
      <div className="flex-1 flex justify-between">
        <div className="flex-none w-[64px] h-[64px] lg:w-[80px] lg:h-[80px] border border-solid rounded-full overflow-hidden mr-[20px]">
          <img
            src={avatar || listDefaultAvt[gender]}
            alt="avatar"
            className="object-cover"
          />
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <div>
            <div>
              <span className="font-pretendard700 md:text-primaryWhite text-[#000000]">
                {displayName}
              </span>{" "}
              <span className="text-[#999999]">{t("님")}</span>
            </div>
            <div className="text-[#999999] md:text-[14px] text-[12px]">
              {dataUser?.totalMembership}개 브랜드의 멤버
            </div>
          </div>
          <div className="hidden md:flex gap-[10px] mt-auto">
            <div
              className="border border-[#424242] p-[9px] flex items-center gap-[5.5px] h-[25px] cursor-pointer"
              onClick={() => navigate("/qr-code")}
            >
              <img src={QrImg} alt="qr" />
              <div className="text-[#EBEBEB] text-[12px]">MY 월렛</div>
            </div>
            <div
              className="border border-[#424242] p-[9px] flex items-center gap-[5.5px] h-[25px] cursor-pointer"
              onClick={() => navigate(UrlInternal.COMMUNITY_MEMBERSHIP)}
            >
              <img src={CommentImg} alt="qr" />
              <div className="text-[#EBEBEB] text-[12px]">MY 활동</div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="hidden md:flex flex-none w-[180px] flex-col justify-center text-center gap-[11px] cursor-pointer"
        onClick={() =>
          navigate(UrlInternal.MY_MEMBERSHIP, {
            state: {
              active: 0,
            },
          })
        }
      >
        <p className="text-[14px] text-gray8">{t("프리미엄 멤버십")}</p>
        <p className="text-[16px] font-pretendard700 text-primaryWhite">
          {dataUser?.totalPremium}
        </p>
      </div>
      <div
        className="hidden md:flex flex-none w-[180px] flex-col justify-center text-center gap-[11px] border-l-[1px] border-l-gray9 cursor-pointer"
        onClick={() =>
          navigate(UrlInternal.MY_MEMBERSHIP, {
            state: {
              active: 1,
            },
          })
        }
      >
        <p className="text-[14px] text-gray8">{t("멤버십")}</p>
        <p className="text-[16px] font-pretendard700 text-primaryWhite">
          {dataUser?.totalNormal}
        </p>
      </div>

      <div className="md:hidden">
        <div className="bg-[#303033] py-[16px] mx-[-24px] mt-[20px] mb-[32px] flex px-[15px]">
          <div
            className="w-[50%] text-center py-[2px]"
            onClick={() =>
              navigate(UrlInternal.MY_MEMBERSHIP, {
                state: {
                  active: 0,
                },
              })
            }
          >
            <div className="text-[#A3A3A3] text-[13px]">프리미엄 멤버십</div>
            <div className="font-pretendard700 text-[24px] text-[#fff]">
              {dataUser?.totalPremium}
            </div>
          </div>
          <div
            className="w-[50%] text-center py-[2px] border-l border-l-[#FFFFFF1A]"
            onClick={() =>
              navigate(UrlInternal.MY_MEMBERSHIP, {
                state: {
                  active: 1,
                },
              })
            }
          >
            <div className="text-[#A3A3A3] text-[13px]">멤버십</div>
            <div className="font-pretendard700 text-[24px] text-[#fff]">
              {dataUser?.totalNormal}
            </div>
          </div>
        </div>

        <div className="flex justify-between pb-[32px] border-b-[5px] border-b-[#F5F5F5]">
          <div onClick={() => navigate(UrlInternal.MY_MEMBERSHIP)}>
            <img src={CreditCardImg} alt="icon" className="mx-auto" />
            <div className="mt-[15px] text-[14px]">MY 멤버십</div>
          </div>
          <div onClick={() => navigate("/qr-code")}>
            <img src={QrImgMobile} alt="icon" className="mx-auto" />
            <div className="mt-[15px] text-[14px]">MY 월렛</div>
          </div>
          <div onClick={() => navigate(UrlInternal.COMMUNITY_MEMBERSHIP)}>
            <img src={CommentImgMobile} alt="icon" className="mx-auto" />
            <div className="mt-[15px] text-[14px]">MY 활동</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyPageBanner
