import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getMyFollower, getMyMembership } from "src/api/homeApi"
import { UrlInternal } from "src/constant/endpointRoute"
import useIsMobile from "src/customHook/useIsMobile"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"

const listTab = ["MY 멤버십", "관심 브랜드"]

const LoginListBrand = () => {
  const { isMobile } = useIsMobile(540)
  const [currentTab, setCurrentTab] = useState("MY 멤버십")
  const navigate = useNavigate()
  const [dataMyMembership, setDataMyMembership] = useState<any[]>([])
  const [dataMyFollower, setDataMyFollower] = useState<any[]>([])
  const { t } = useTranslation()
  const data = currentTab === "MY 멤버십" ? dataMyMembership : dataMyFollower

  const getDataMyMembership = async () => {
    try {
      const res: any = await getMyMembership()
      if (res.result) {
        setDataMyMembership(res.data)
      }
    } catch (error) {
      setDataMyMembership([])
    }
  }

  const getDataMyFollower = async () => {
    try {
      const res: any = await getMyFollower()
      if (res.result) {
        setDataMyFollower(res.data)
      }
    } catch (error) {
      setDataMyFollower([])
    }
  }

  useEffect(() => {
    getDataMyMembership()
    getDataMyFollower()
  }, [])

  // useEffect(() => {
  //   ;(async function getData() {
  //     try {
  //       const res: any =
  //         currentTab === "MY 멤버십"
  //           ? await getMyMembership()
  //           : await getMyFollower()

  //       if (res.result) {
  //         setData(res.data)
  //       }
  //     } catch (error) {
  //       setData([])
  //     }
  //   })()
  // }, [currentTab])

  return (
    !(dataMyMembership.length <= 0 && dataMyFollower.length <= 0) && (
      <div className="lg:mb-[117px] mb-[51px]">
        <div className="flex lg:mb-[51px] mb-[25px] justify-between">
          <div className="flex">
            {listTab?.map((item) => (
              <div
                key={item}
                className="px-[18px] border-l-[2px] border-l-gray6 first:pl-0 first:border-l-0"
              >
                <button
                  className={`text-[18px] lg:leading-[43px] leading-[18px] ${
                    currentTab === item
                      ? "font-pretendard700"
                      : "font-pretendard400"
                  }`}
                  onClick={() => {
                    setCurrentTab(item)
                    if (item === "MY 멤버십") {
                      getDataMyMembership()
                    } else {
                      getDataMyFollower()
                    }
                  }}
                >
                  {t(item)}
                </button>
              </div>
            ))}
          </div>
          <button
            className="text-[14px]"
            onClick={() => navigate("/my-membership")}
          >
            + {t("전체보기")}
          </button>
        </div>
        <div className="flex gap-[20px] overflow-x-auto no-scrollbar">
          <Swiper
            slidesPerView={6}
            spaceBetween={isMobile ? 16 : 20}
            pagination={{
              clickable: true,
            }}
            className="custom-pagination-style"
            breakpoints={{
              1200: {
                slidesPerView: 6,
              },
              1024: {
                slidesPerView: 4.5,
              },
              540: {
                slidesPerView: 4.5,
              },
              0: {
                slidesPerView: 3.5,
              },
            }}
          >
            {data
              ?.map((item: any, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <div onClick={() => navigate(`${item?.id}`)} key={item.id}>
                      <div className="border border-gray5 md:w-[180px] md:h-[180px] w-[80px] h-[80px]">
                        <img
                          src={item?.logoImage}
                          alt="img"
                          className="w-[100%] h-[100%] object-contain"
                        />
                      </div>
                      {/* <div className="text-[#2D2D2D] sm:text-[20px] text-[12px] font-pretendard700 sm:mt-[18px] mt-[5px]">
                      {item?.brandName}
                    </div> */}
                    </div>
                  </SwiperSlide>
                )
              })
              .concat(
                Array(data.length >= 6 ? 0 : 6 - data.length).fill(
                  <SwiperSlide></SwiperSlide>,
                ),
              )}
          </Swiper>
        </div>
      </div>
    )
  )
}

export default LoginListBrand
