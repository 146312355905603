import ChatText from "@assets/images/brand_detail/ChatText.png"
import { formatPrice } from "src/util/formatPrice"

interface Props {
  count: number | string
  onClick?: () => void
  classText?: string
}

function CntComment({ count, onClick, classText }: Props) {
  return (
    <div
      className="flex items-center gap-[5px] cursor-pointer"
      onClick={onClick}
    >
      <img src={ChatText} alt="ChatText" className="max-h-[21px]" />
      <div className={`text-[12px] font-pretendard500 ${classText}`}>
        {formatPrice(Number(count))}
      </div>
    </div>
  )
}

export default CntComment
