import React from "react"
import HeadIcon from "@assets/icons/HeadIcon.svg"

type BlockTitleProps = {
  title: string
  icon?: JSX.Element
}

const BlockTitle: React.FC<BlockTitleProps> = ({ title, icon }) => {
  return (
    <div className="flex items-center gap-[6px]">
      {/* {icon || <HeadIcon />} */}
      <h3 className="uppercase font-pretendard700 text-[20px] xs:text-[28px]">
        {title}
      </h3>
    </div>
  )
}

export default BlockTitle
