import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { reportFeed, reportPost } from "src/api/communityApi"
import ModalBase from "src/components/ModalBase"
import NavBarMb from "src/layout/Header/NavBarMb"

const dataReport = [
  {
    title: "욕설, 비방, 인신공격",
    value: 1,
  },
  {
    title: "중복 게시",
    value: 2,
  },
  {
    title: "선정적이거나 폭력적인 게시글 또는 프로필 사진",
    value: 3,
  },
  {
    title: "사기, 거짓, 홍보의 게시글 또는 프로필 사진",
    value: 4,
  },
  {
    title: "초상권, 저작권 침해의 게시글 또는 프로필 사진",
    value: 5,
  },
  {
    title: "개인정보 게시",
    value: 6,
  },
]

function ReportPageMobile() {
  const [selected, setSelected] = useState(dataReport[0].value)
  const [isModalSuccessReport, setIsModalSuccessReport] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  const handleSubmitReport = async () => {
    try {
      const resp: any = state?.isFeed
        ? await reportPost({
            refId: state?.commentId ?? state?.postId,
            content: `CONTENT_${selected}`,
            type: "F_COMMENT",
          })
        : await reportPost({
            refId: state?.commentId ?? state?.postId,
            content: `CONTENT_${selected}`,
            type: state?.commentId ? "COMMENT" : "C_POST",
          })
      if (resp.result) {
        setIsModalSuccessReport(true)
        setSelected(dataReport[0].value)
      } else {
      }
    } catch (error) {}
  }

  return (
    <>
      <ModalBase
        isVisible={isModalSuccessReport}
        setCancel={() => {
          navigate(-1)
          setIsModalSuccessReport(false)
        }}
        className="sm:max-w-[380px] max-w-[100%]"
      >
        <div className="mb-[60px]">
          신고 접수가 완료되었습니다. 운영팀에서 확인 후 처리하겠습니다.
        </div>

        <div
          className="absolute bottom-0 w-full bg-[#000] text-[#fff] mx-[-16px] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
          onClick={() => {
            navigate(-1)
            setIsModalSuccessReport(false)
          }}
        >
          확인
        </div>
      </ModalBase>

      <NavBarMb pageName={"신고하기"} />
      <div className="flex h-[calc(100vh_-_60px)] overflow-hidden fixed inset-0 z-[1001] top-[60px]">
        <div className="flex flex-col justify-between overflow-y-auto absolute inset-0 bg-[#fff] z-10 top-[24px]">
          <div className="px-[24px] flex-1 overflow-y-auto">
            <div className="text-[18px] #000 font-pretendard700 pb-[14px]">
              신고 사유
            </div>
            <div className="text-[14px] #000">신고 사유를 선택해 주세요. </div>
            <div className="text-[14px] #000 pb-[25px]">
              신고가 적절하지 않을 경우, 커뮤니티 사용에 제재를 받을 수
              있습니다.
            </div>

            {dataReport.map((item, index) => (
              <div
                className="flex items-center justify-between py-[14px] border-0 border-solid border-b border-b-[#E5E5E5] cursor-pointer"
                key={index}
                onClick={() => setSelected(item.value)}
              >
                <div
                  className={`text-[14px] ${
                    selected === item.value && "font-pretendard700"
                  }`}
                >
                  {item.title}
                </div>
                {selected === item.value ? (
                  <div className="w-[15px] h-[15px] leading-[15px] border border-solid border-[#111] rounded-[50%] flex justify-center items-center cursor-pointer">
                    <div className="bg-[#111] rounded-[50%] w-[7px] h-[7px]"></div>
                  </div>
                ) : (
                  <div className="w-[15px] h-[15px] leading-[15px] border border-solid border-[#A3A3A3] rounded-[50%] cursor-pointer"></div>
                )}
              </div>
            ))}
          </div>

          <div
            className="w-full bg-[#000] text-[#fff] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
            onClick={handleSubmitReport}
          >
            신고하기
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportPageMobile
