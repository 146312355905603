import imgDeals from "@assets/images/image175.png"
import noDataImg from "@assets/images/no-data.png"
import { useNavigate, useSearchParams } from "react-router-dom"
import BlockTitle from "src/components/BlockTitle"
import BrandCarouse from "src/components/BrandCarouse"
import GroupBuyingCard, { DealProps } from "src/components/Card/GroupBuyingCard"
import Pagination from "src/components/Pagination"
import { TBRAND } from "src/models"
import { useEffect, useState } from "react"
import { searchDetailApi } from "src/api/search"
import dayjs from "dayjs"

type Props = {}
interface DataSearchDetail {
  listBrand: TBRAND[]
  listVoucher: any
}

function Search({}: Props) {
  const navigate = useNavigate()
  const [searchParam, setSearchParam] = useSearchParams()
  const [searchParams] = useSearchParams()
  const textSearch = searchParams.get("q")
  const [totalVoucher, setTotalVoucher] = useState<number>(0)
  const [totalBrand, setTotalBrand] = useState<number>(0)
  const [dataSearch, setDataSearch] = useState<DataSearchDetail>({
    listBrand: [],
    listVoucher: [],
  })

  const listType: any = {
    FINISHED: "finished",
    IN_PROGRESS: "in-progress",
    INCOMING: "up-coming",
  }

  const fetchData = async () => {
    try {
      const paramsSearch =
        textSearch && textSearch.trim() !== ""
          ? {
              querySearch: textSearch,
              page: Number(searchParam.get("page")) || 1,
              size: 9,
            }
          : {}
      const { data }: any = await searchDetailApi(paramsSearch)
      if (data) {
        console.log(data?.voucher?.datas)

        const listVoucherFormat = data?.voucher?.datas.map((item: any) => {
          const newItem: DealProps = {
            id: item.id,
            title: item.voucherName || "",
            desc: item.voucherDescription || item.description || "",
            price: item.voucherPrice || 0,
            voucherImage: item.voucherImage || imgDeals || "",
            logoImage: item.logoImage,
            available: item.quantity - item.totalSold || 0,
            tag: item.tags,
            type: listType[item.progress],
            startTime: dayjs(item.startTime).format(),
            endTime: dayjs(item.endTime).format(),
            totalValue: item.totalGroupBuyingValue,
            sold: item.totalSold,
            // isSoldOut: !!item.isSoldOut,
            isSoldOut: item.quantity === item.totalSold,
            isRemind: item.isRemind,
          }
          return newItem
        })
        setTotalVoucher(+data?.voucher?.pageable?.totalElement)
        setTotalBrand(+data?.brand?.totalElement)
        setDataSearch({
          listBrand: data.brand.brand,
          listVoucher: listVoucherFormat,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  console.log("dataSearch?.listVoucher", dataSearch?.listVoucher)

  useEffect(() => {
    fetchData()
  }, [textSearch, searchParam])

  return (
    <div className="pt-[140px] pb-[100px] relative">
      <div className="container">
        <div className="pb-[32px] pt-[75px]">
          <h1 className="text-[40px] text-primaryRed uppercase font-pretendard700">
            Search: {textSearch}
          </h1>
          <p className="text-[16px] text-textColor21 font-pretendard500 leading-normal">
            Showing all {(totalVoucher || 0) + (totalBrand || 0)} results
          </p>
        </div>
        <div className="w-full h-[10px] bg-grayBg3" />
        {dataSearch.listBrand.length > 0 ||
        dataSearch.listVoucher.length > 0 ? (
          <>
            {dataSearch.listBrand.length > 0 && (
              <div className="pt-[67px]">
                <BrandCarouse
                  data={dataSearch.listBrand}
                  title="brand"
                  searchResultsNumber={totalBrand > 5 ? 5 : totalBrand}
                  hiddenViewAll
                />
              </div>
            )}
            {dataSearch.listBrand.length > 0 &&
              dataSearch.listVoucher.length > 0 && (
                <div className="w-full h-[1px] bg-[#E7E7E7] mt-[59px]" />
              )}
            {dataSearch.listVoucher.length > 0 && (
              <div className="mt-[68px]">
                <div className="mb-[26px] flex items-center gap-[19px]">
                  <BlockTitle title="Voucher" />
                  <p className="text-textColor10 text-[16px] font-pretendard500 leading-normal">
                    Showing all {totalVoucher}{" "}
                    {totalVoucher > 1 ? "results" : "result"}
                  </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[24px] gap-y-[60px]">
                  {dataSearch?.listVoucher.map(
                    (item: DealProps, index: number) => (
                      <GroupBuyingCard
                        key={index}
                        {...item}
                        remindSuccess={() => {
                          fetchData()
                        }}
                        isSoldOut={item?.quantity === item?.totalSold}
                      />
                    ),
                  )}
                </div>
                {totalVoucher > 9 && (
                  <div className="mt-[80px]">
                    <Pagination
                      total={totalVoucher}
                      current={Number(searchParam.get("page")) || 1}
                      pageSize={9}
                      showSizeChanger={false}
                      onChange={(page) => {
                        searchParam.set("page", page.toString())
                        setSearchParam(searchParam)
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="h-full pt-[97px] pb-[23px] flex items-center justify-center flex-col gap-[24px]">
            <img src={noDataImg} alt="no data" />
            <p className="text-[20px] text-[#B1B0B0] font-pretendard500 leading-normal">
              No data available in this section, back{" "}
              <span
                onClick={() => navigate("/")}
                className="text-[20px] text-primary font-pretendard500 leading-normal cursor-pointer"
              >
                homepage
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Search
