import centerBanner from "@assets/images/home/centerBanner.png"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getBrand, getCategoryBrand } from "src/api/homeApi"
import { UrlInternal } from "src/constant/endpointRoute"
import useIsMobile from "src/customHook/useIsMobile"
import "swiper/css/pagination"
import { Autoplay } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css/pagination"
import { FreeMode } from "swiper/modules"

const CenterListBrand = () => {
  const { isMobile } = useIsMobile(768)
  const [currentCategory, setCurrentCategory] = useState(0)
  const [dataCategory, setDataCategory] = useState<any[]>([])
  const [dataBrand, setDataBrand] = useState<any[]>([])
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const getDataBrand = async (params?: any) => {
    try {
      const res: any = await getBrand({
        filter: "",
        page: 1,
        size: 30,
        ...params,
      })
      if (res.result) {
        setDataBrand(res.data.datas)
      }
    } catch (error) {}
  }

  // useEffect(() => {
  //   getDataBrand()
  // }, [])

  useEffect(() => {
    ;(async function getDataCategory() {
      try {
        const res: any = await getCategoryBrand()
        if (res.result) {
          setDataCategory(res.data)
          // setCurrentCategory(res.data[0].id)

          getDataBrand({
            filter: "",
            // filter: [res.data[0].categoryName],
          })
        }
      } catch (error) {}
    })()
  }, [])

  return (
    <div className="pt-[66px]">
      <div className="flex gap-[60px] mb-[54px] md:flex-row flex-col">
        <div
          className={`flex-1 flex justify-center md:mx-0 mx-[-24px] ${
            isMobile ? "order-2" : "order-1"
          }`}
        >
          <img src={centerBanner} alt="" />
        </div>
        <div
          className={`flex-1 lg:mt-[60px] flex flex-col md:items-start md:text-left items-center text-center ${
            isMobile ? "order-1" : "order-2"
          }`}
        >
          <p className="lg:text-[48px] text-[32px] lg:leading-[48px] leading-[32px] font-pretendard700 lg:mb-[23px] mb-[12px]">
            {t("모아, FRIENDS")}
          </p>
          <p className="lg:text-[48px] text-[32px] lg:leading-[48px] leading-[32px] font-pretendard700 lg:mb-[48px] mb-[31px]">
            {t("브랜드와 친구가 되다")}
          </p>
          <p className="text-[18px] leading-[30.6px] lg:mb-[45px] mb-[31px]">
            {t(
              "내 취향이 반영된 브랜드와 조금 더 특별한 관계가 되고 싶다면? 브랜드 멤버십에 가입하고 전용 콘텐츠, 특별 할인가, 리미티드 에디션 등 멤버십 회원에게만 제공되는 혜택을 만나보세요.",
            )}
          </p>
          <button
            className="text-[16px] flex items-center justify-center h-[40px] px-[20px] border border-primary"
            onClick={() => navigate(UrlInternal.BRAND)}
          >
            {t("브랜드 둘러보기")}
          </button>
        </div>
      </div>
      <div className="mb-[57px]">
        <div className="mb-[36px] w-[100%]">
          <Swiper
            // ref={swiperRef}
            // className="swiper-category-brand"
            freeMode={true}
            slidesPerView={"auto"}
            spaceBetween={0}
            // spaceBetween={8}
            pagination={{
              clickable: false,
            }}
            modules={[FreeMode]}
          >
            {[{ id: 0, korName: "전체", engName: "ALL" }, ...dataCategory]?.map(
              (item: any, i) => (
                <SwiperSlide className="w-auto" key={item.value}>
                  <div
                    className={`px-[13.5px] border-l border-[#BDBDBD] ${
                      i === 0 && "pl-0 border-l-0"
                    }`}
                  >
                    <div
                      onClick={() => {
                        setCurrentCategory(item.id)
                        getDataBrand({
                          filter: item.id === 0 ? "" : [item.categoryName],
                        })
                      }}
                      className={`cursor-pointer text-[18px] leading-[18px] pb-[8px] ${
                        currentCategory === item.id
                          ? "font-pretendard700 border-b-[2px] border-b-primary"
                          : "font-pretendard400"
                      }`}
                    >
                      {i18n.language === "kor" ? item.korName : item.engName}
                    </div>
                  </div>
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </div>

        <div className="md:mx-0">
          <Swiper
            slidesPerView={8.5}
            spaceBetween={isMobile ? 16 : 20}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="custom-pagination-style"
            breakpoints={{
              1200: {
                slidesPerView: 8.5,
              },
              991: {
                slidesPerView: 7.5,
              },
              768: {
                slidesPerView: 5.5,
              },
              0: {
                slidesPerView: 4,
              },
            }}
          >
            {dataBrand?.map((item: any, index: number) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className="border border-gray5 md:w-[120px] md:h-[120px] w-[80px] h-[80px] cursor-pointer"
                    onClick={() => navigate(`/${item?.id}`)}
                  >
                    <img
                      src={item.logoImage}
                      alt="brand image"
                      className="w-[100%] h-[100%] object-contain"
                    />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default CenterListBrand
