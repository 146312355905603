import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const getDataListShop = async (params: {
  page?: number
  size?: number
  brandId?: number
  productType?: string
  querySearch?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.LIST_SHOP,
    method: "GET",
    params: params,
    tokenRequired: true,
  })
}

export const getDataDetailShop = async (params: {
  id: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.DETAIL_SHOP,
    method: "GET",
    params: params,
    tokenRequired: true,
  })
}

export const paymentKakao = async (
  data: FormData,
): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.PAYMENT_KAKAO,
    method: "POST",
    data,
    // tokenRequired: true,
  })
}
