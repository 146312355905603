import { createSlice } from "@reduxjs/toolkit"
import { AppThunk, RootState } from "src/app/store"
import {
  FilterBrandParams,
  filterBrand,
  getBrandDetail,
  getBrandPageData,
} from "src/services/Brand"
import { subscribeBrand } from "src/services/User"

export interface BrandDataState {
  data: any | null
  detailData: any | null
  filterData: any | null
  filterMyData: any | null
  isUpdateData: boolean
}

const initialState: BrandDataState = {
  data: null,
  detailData: null,
  filterData: null,
  filterMyData: null,
  isUpdateData: false,
}

export const brandDataSlice = createSlice({
  name: "brandData",
  initialState,
  reducers: {
    setBrandData: (state, action) => {
      state.data = action.payload
    },
    setBrandDetailData: (state, action) => {
      state.detailData = action.payload
    },
    setBrandFilterData: (state, action) => {
      state.filterData = action.payload
    },
    setMyBrandFilterData: (state, action) => {
      state.filterMyData = action.payload
    },
    onUpdateData: (state) => {
      state.isUpdateData = !state.isUpdateData
    },
  },
})

export const {
  setBrandData,
  setBrandDetailData,
  setBrandFilterData,
  setMyBrandFilterData,
  onUpdateData,
} = brandDataSlice.actions

export const getDataBrandPage = (): AppThunk => async (dispatch) => {
  try {
    const resp: any = await getBrandPageData()
    if (resp?.result) {
      dispatch(setBrandData(resp?.data))
    } else {
      dispatch(setBrandData(null))
    }
  } catch (error) {}
}

export const getBrandDetailData =
  (brandId: number): AppThunk =>
  async (dispatch) => {
    try {
      const resp: any = await getBrandDetail(brandId, "10000")
      if (resp?.result) {
        dispatch(setBrandDetailData(resp?.data))
      } else {
        dispatch(setBrandDetailData(null))
      }
    } catch (error) {
      console.log(error)
    }
  }

export const onSubcribe =
  (brandId: number): AppThunk =>
  async (dispatch) => {
    try {
      const resp: any = await subscribeBrand({
        brandId,
      })
      if (resp?.result) {
        dispatch(getDataBrandPage())
        dispatch(onUpdateData())
      }
    } catch (error) {
      console.log(error)
    }
  }

export const onSubcribeDetail =
  (brandId: number): AppThunk =>
  async (dispatch) => {
    try {
      const resp: any = await subscribeBrand({
        brandId,
      })
      if (resp?.result) {
        dispatch(getBrandDetailData(brandId))
      }
    } catch (error) {
      console.log(error)
    }
  }

export const handleFilterBrand =
  (params: FilterBrandParams): AppThunk =>
  async (dispatch) => {
    try {
      const resp: any = await filterBrand(params)
      if (resp?.result) {
        dispatch(setBrandFilterData(resp?.data))
      } else {
        dispatch(setBrandFilterData(null))
      }
    } catch (error) {
      console.log(error)
    }
  }

// export const handleFilterMyBrand =
// (params: FilterBrandParams): AppThunk =>
// async (dispatch) => {
//   try {
//     const resp: any = await filterMyBrand(params)
//     if (resp?.result) {
//       dispatch(setMyBrandFilterData(resp?.data))
//     } else {
//       dispatch(setMyBrandFilterData(null))
//     }
//   } catch (error) {
//     console.log(error)
//   }
// }

export const brandDataDetailSelect = (state: RootState) =>
  state.brandData.detailData
export const brandDataSelect = (state: RootState) => state.brandData.data
export const filterBrandDataSelect = (state: RootState) =>
  state.brandData.filterData
export const filterMyBrandDataSelect = (state: RootState) =>
  state.brandData.filterMyData
export const updateDataSelect = (state: RootState) =>
  state.brandData.isUpdateData

export default brandDataSlice.reducer
