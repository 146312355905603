import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"
import "swiper/css/navigation"
import "swiper/css/pagination"

import "./style.scss"
// import required modules
import { Pagination } from "swiper/modules"

interface Props {
  className?: string
  data: any
}

function GalleryImage({ className, data }: Props) {
  return (
    <Swiper
      slidesPerView={"auto"}
      freeMode
      pagination={{
        type: "fraction",
      }}
      modules={[Pagination]}
      autoHeight={true}
      className={className}
    >
      {data &&
        data.length > 0 &&
        data?.map((dt: any, i: number) => (
          <SwiperSlide key={i}>
            <div className="relative sm:w-[50%] w-[100%]">
              <img src={dt?.url} alt="a" />
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  )
}

export default GalleryImage
