import { Form, message } from "antd"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { userApi } from "src/api/userApi"
import CSInput from "src/components/Input/Index"
import CommonModal, { CommonModalProps } from "src/components/Modal"

type DeleteAccountProps = {
  handleClose: () => void
  handleOpenForgotPassword?: () => void
  handleOpenSuccess?: () => void
} & CommonModalProps

const DeleteAccount: React.FC<DeleteAccountProps> = ({
  handleClose,
  handleOpenForgotPassword,
  handleOpenSuccess,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const onFinish = async (values: any) => {
    // console.log(values)
    // handleClose()

    try {
      const resp: any = await userApi.deleteAccount({
        password: values.password,
      })
      if (resp?.result) {
        handleOpenSuccess && handleOpenSuccess()
      } else {
        // message.error(
        //   "Password is not correct. Please check your password carefully",
        //   5,
        // )
        form.setFields([
          {
            name: "password",
            errors: [
              t(
                "Password is not correct. Please check your password carefully",
              ),
            ],
          },
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isOpen === false) {
      form.resetFields()
    }
  }, [isOpen])

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} {...props}>
      <p className="font-pretendard500 text-[20px]">{t("회원탈퇴")}</p>
      <p className="text-gray1 text-[14px] mt-[6px]">
        {t("delete account subtitle")}
      </p>
      <div className="mt-4">
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
        >
          <CSInput
            inputType="password"
            name="password"
            placeholder={t("입력하세요.")}
            inputHeight={48}
            className="!mb-[10px]"
            borderColor="#D4D4D4"
            rules={[
              {
                required: true,
                message: t("Password is required."),
              },
            ]}
          />
          <div className="flex items-center gap-[10px] mb-[40px]">
            <button
              className="w-full border-[1px] border-primary h-[48px]"
              onClick={handleClose}
              type="button"
            >
              {t("Cancel")}
            </button>
            <button className="bg-primary w-full h-[48px] font-pretendard700 text-primaryWhite">
              {t("Delete account")}
            </button>
          </div>
          <div className="flex justify-center mb-[19px]">
            <button
              className="text-end text-primary underline"
              onClick={handleOpenForgotPassword}
              type="button"
            >
              {t("Forgot password?")}
            </button>
          </div>
        </Form>
      </div>
    </CommonModal>
  )
}

export default DeleteAccount
