import React from "react"
import { Navigate, Outlet } from "react-router"
import { useAuth } from "src/customHook/useAuth"
import { useLocation } from "react-router-dom"

type Props = {}

function AuthLayout({}: Props) {
  const { isLogined } = useAuth()
  const location = useLocation()

  if (!isLogined) {
    return (
      <Navigate
        to={{
          pathname: "/auth",
          search: `backUrl=${location.pathname}${`&${location.search.slice(
            1,
          )}`}`,
        }}
        replace
      />
    )
  }
  return <Outlet />
}

export default AuthLayout
