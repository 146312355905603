import { useNavigate, useParams } from "react-router-dom"
import { MoneyConvert } from "src/util/format"
import Button from "../../Button"
import { useTranslation } from "react-i18next"
import CommonModal from ".."
import { UrlInternal } from "src/constant/endpointRoute"
import "./index.scss"
import NavBarMb from "src/layout/Header/NavBarMb"
import useIsMobile from "src/customHook/useIsMobile"
type Props = {}

function RegisterMembershipSuccess({}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const status = urlParams.get("status")
  const membershipName = urlParams.get("membershipName")
  const brandName = urlParams.get("brandName")
  const logoImage = urlParams.get("logoImage")
  const params = useParams()
  const { id } = params
  const brandId = Number(id)
  const { isMobile } = useIsMobile(475)

  const brandNameRoute = params.brandName

  const handleClose = () => {
    navigate(`/${brandNameRoute}`)
  }

  return (
    <CommonModal
      isOpen={status === "SUCCESS"}
      // isOpen={true}
      handleClose={handleClose}
      width={375}
      className="text-[#000000] modal-fullpage"
    >
      {isMobile && (
        <>
          <NavBarMb
            pageName={brandName ?? ""}
            className="mx-[-24px]"
            csGoback={() => handleClose?.()}
          />

          <div className="fixed left-0 right-0 bottom-0">
            <Button
              theme="main"
              height={44}
              onClick={() => navigate(`/${brandName}`)}
            >
              {brandName} 홈으로
            </Button>
          </div>
        </>
      )}

      <div className="w-full h-full bg-primaryWhite">
        <div className="text-center max-w-[192px] mx-auto mb-[51px] xs:mt-0 mt-[117px]">
          <img
            src={logoImage ?? ""}
            alt="haha"
            className="w-[160px] h-[160px] aspect-square"
          />
          <div className="mt-[24px] mb-[13px] font-pretendard700 text-[16px]">
            멤버십 가입이 완료되었습니다.
          </div>
          <div className="text-[#666666]">
            지금부터 회원님께만 드리는 <br />
            특별한 혜택을 누려보세요!
          </div>
        </div>

        <div className="mb-[-40px] mx-[-40px] xs:block hidden">
          <Button
            theme="main"
            height={44}
            onClick={() => navigate(`/${brandName}`)}
          >
            {brandName} 홈으로
          </Button>
        </div>
      </div>
    </CommonModal>
  )
}

export default RegisterMembershipSuccess
