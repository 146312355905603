import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export interface SingUpParams {
  email: string
  password: string
  displayName: string
  gender: "MALE" | "FEMALE"
  birthDate: string
  phoneNumber: string
  emailReceive: boolean
  accountType?: string
  snsId?: string
}

export interface DeactiveAccountParams {
  password: string
  trxUID?: string
}

export interface SubscribeBrandParams {
  brandId: number
}

export const singUp = async (payload: SingUpParams) => {
  return api.request({
    url: ApiUrl.SIGN_UP,
    method: "POST",
    data: payload,
  })
}

export const getMyInfo = async () => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.GET_ME,
    method: "GET",
  })
}

export const deactiveAccount = async (payload: DeactiveAccountParams) => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.DEACTIVE_ACCOUNT,
    method: "POST",
    data: payload,
  })
}

export const subscribeBrand = async (payload: SubscribeBrandParams) => {
  return api.request({
    tokenRequired: true,
    url: ApiUrl.SUBSCRIBE,
    method: "POST",
    data: payload,
  })
}
