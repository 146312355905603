import { ConfigProvider, Table } from "antd"
import type { AnyObject } from "antd/es/_util/type"
import type { ColumnsType, TableProps } from "antd/es/table"
import SortByNumber from "src/assets/icons/table/SortByNumber.svg"

import "./style.scss"
// import { ReactNode } from "react"
// import PreviousIcon from "@assets/icons/Previous.svg"
// import NextIcon from "@assets/icons/Next.svg"
type Props = {
  columns: ColumnsType<any>
  dataSource: any
} & TableProps<AnyObject>
const CsTable: React.FC<Props> = ({ columns, dataSource, ...props }) => {
  // const itemRender = (_: any, type: string, originalElement: ReactNode) => {
  //   if (type === "prev") {
  //     return (
  //       <div className="flex items-center h-full justify-end">
  //         <PreviousIcon />
  //       </div>
  //     )
  //   }
  //   if (type === "next") {
  //     return (
  //       <div className="flex items-center h-full justify-start">
  //         <NextIcon />
  //       </div>
  //     )
  //   }
  //   return originalElement
  // }
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Pretendard-400",
          borderRadius: 50,
          colorPrimary: "#000000",
          fontSize: 16,
        },
      }}
    >
      <Table
        id="custom-table"
        showSorterTooltip
        columns={columns.map((item) =>
          item.sorter
            ? {
                ...item,
                sortIcon({ sortOrder }) {
                  return (
                    <div className="ml-1">
                      <SortByNumber />
                    </div>
                  )
                },
              }
            : item,
        )}
        dataSource={dataSource?.map((x: any, i: number) => ({
          ...x,
          key: x.key ? x.key : i,
        }))}
        pagination={false}
        // pagination={{
        //   position: ["bottomCenter"],
        //   showSizeChanger: false,
        //   itemRender: itemRender,
        // }}
        {...props}
      />
    </ConfigProvider>
  )
}

export default CsTable
