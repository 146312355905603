import { createSlice } from "@reduxjs/toolkit"

export interface DrawerState {
  open: boolean
}

const initialState: DrawerState = {
  open: false,
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const drawerMenu = createSlice({
  name: "drawer",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    open: (state) => {
      state.open = true
    },
    close: (state) => {
      state.open = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
})

export const { open, close } = drawerMenu.actions
export default drawerMenu.reducer
