import React, { useEffect, useState } from "react"

type Props = {
  defaultValue?: number
  available: number
  onChange?: (num: number) => void
  className?: string
  disabled?: boolean
}

function OrderInput({
  available,
  defaultValue = 1,
  onChange = () => {},
  className,
  disabled,
}: Props) {
  const [quantity, setQuantity] = useState(defaultValue)

  const handleQuantity = (num: number) => {
    setQuantity((prev) => prev + num)
  }
  useEffect(() => {
    onChange(quantity)
  }, [onChange, quantity])
  return (
    <div
      className={`border border-solid overflow-hidden flex justify-between items-center text-[18px] ${className}`}
    >
      <button
        className="px-[10px] py-[5px] w-[44px] min-w-[44px] h-[44px] cursor-pointer hover:bg-[#DFDFDF]
     transition-all ease-linear duration-300 disabled:cursor-not-allowed disabled:hover:bg-stone-300 disabled:text-[#BABABA]"
        onClick={() => {
          if (quantity > 1) return handleQuantity(-1)
        }}
        disabled={disabled || quantity <= 1}
      >
        <p className="text-[24px]">-</p>
      </button>
      <input
        type="text"
        className="w-12 w-full md:w-[44px] h-[44px] border-x border-x-solid text-center bg-transparent focus:outline-none !text-[18px] font-pretendard500 disabled:text-[#BABABA]"
        disabled={disabled}
        value={quantity}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) {
            if (Number(e.target.value) > available) {
              setQuantity(available)
            } else {
              setQuantity(Number(e.target.value))
            }
          }
        }}
        onBlur={() => {
          if (quantity < 1) {
            setQuantity(1)
          }
        }}
      ></input>
      <button
        className=" px-[10px] py-[5px] w-[44px] min-w-[44px] h-[44px] cursor-pointer hover:bg-[#DFDFDF]
        transition-all ease-linear duration-300 disabled:cursor-not-allowed disabled:hover:bg-stone-300 disabled:text-[#BABABA]"
        disabled={disabled || quantity >= available}
        onClick={() => {
          if (quantity < available) return handleQuantity(1)
        }}
      >
        <p className="text-[24px]">+</p>
      </button>
    </div>
  )
}

export default OrderInput
