import HamburgerDark from "@assets/icons/Hamburger_mobile_dark.svg"
import MenuDark from "@assets/icons/Menu_mobile_dark.svg"
import SearchMobile from "@assets/icons/Search_mobile.svg"
import LoginIcon from "@assets/icons/header/icon-login.svg"
import LogoutIcon from "@assets/icons/header/icon-logout.svg"
import MypageIcon from "@assets/icons/header/icon-mypage.svg"
import Logo from "@assets/images/logo.svg"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import IconButton from "src/components/Button/IconButton"
import { useAuth } from "src/customHook/useAuth"
import { onOpen } from "src/features/Search/searchSlice"

import { open } from "src/features/drawer/drawerSlice"
import { logout } from "src/features/user/userSlice"
import { menuList, menuListPc } from "../config"
import useCheckMatchPath from "src/customHook/useCheckMatchPath"

import SearchIcon from "@assets/images/home/icon_search.png"
import SearchBrand from "./SearchBrand"
import { UrlInternal } from "src/constant/endpointRoute"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"

function Header() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  // const isHomePage = useCheckMatchPath("/")
  // const isBrandDetail = useCheckMatchPath("/brand-detail/:id")
  // const isGoupBuyingDetail = useCheckMatchPath("/group-buying/detail/:id")
  const isBrandDetail = pathname.includes("/brand-detail")
  const isGoupBuyingDetail =
    pathname.includes("/group-buying") &&
    !pathname.includes("/group-buying/order")

  const [scrollDirection, setScrollDirection] = useState<"down" | "up" | null>(
    null,
  )
  const { isLogined, user } = useAuth()

  const dataUser = useAppSelector(userDataSelect)

  const isSearchHome = [
    `/${UrlInternal.BRAND}`,
    UrlInternal.SEARCH_BRAND,
  ].includes(pathname)

  const [scrollY, setScrollY] = useState<number>(0)
  const { t } = useTranslation()
  const [openSearch, setOpenSearch] = useState<boolean>(false)
  const [querySearchChange, setQuerySearchChange] = useState<string>("")

  useEffect(() => {
    let lastScrollY = window.scrollY
    const updateScrollDirection = () => {
      const scrollY = window.scrollY
      const direction = scrollY > lastScrollY ? "down" : "up"
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
      setScrollY(window.scrollY)
    }
    window.addEventListener("scroll", updateScrollDirection) // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection) // clean up
    }
  }, [scrollDirection])

  const handleLogout = async () => {
    try {
      await dispatch(logout())
      navigate({
        pathname: "/auth",
        search: `backUrl=${pathname}`,
      })
    } catch (error) {}
  }

  return (
    <>
      <SearchBrand
        openSearch={openSearch}
        setOpenSearch={() => setOpenSearch(false)}
      />

      <div
        className={`w-[100%] transition-all duration-500 z-[1000] ${
          scrollDirection === "down" ? "-top-[200px]" : "top-0"
        }`}
      >
        {(isBrandDetail || isGoupBuyingDetail) && (
          <div className="h-[40px] bg-primary flex items-center justify-center text-primaryWhite font-pretendard600 text-[18px]">
            {t("MOAFRIENDS에서 프리미엄 브랜드 멤버십을 경험하세요.")}
          </div>
        )}
        <div
          className={`h-[96px] border-b-[1px] border-[#D9D9D9] flex items-center`}
        >
          <div className="container flex items-center justify-between">
            <div className="flex items-center gap-[34px]">
              <Link to="/" className="">
                <Logo />
              </Link>
              <nav
                className={`hidden lg:flex gap-[25px] font-pretendard500 text-[12px] lg:text-[15px] `}
              >
                {menuListPc.map((item, index) => {
                  if (
                    !(isLogined
                      ? !dataUser?.hasMyVouchers &&
                        item.link === UrlInternal.MY_VOUCHERS
                      : !isLogined && item.link === UrlInternal.MY_VOUCHERS)
                  ) {
                    return (
                      <Link
                        key={index}
                        className={`text-[16px] uppercase font-pretendard500 ${
                          pathname.includes(item.link) && "font-pretendard700"
                        }`}
                        to={item.link}
                      >
                        {t(`${item.title}`)}
                      </Link>
                    )
                  }
                })}
              </nav>
            </div>
            <div className="hidden lg:flex items-end gap-[8px]">
              {isSearchHome && (
                <div className="border border-[#EAEAEA] flex items-center m-auto h-[42px] px-[15px]">
                  <input
                    type="text"
                    className={`w-[162px] pr-[10px] focus-visible:outline-none bg-transparent text-[#000000] text-[14px] flex-1 placeholder:text-[#666666]`}
                    onChange={(e) => {
                      setQuerySearchChange(e.target.value)
                    }}
                    placeholder="Search"
                    value={querySearchChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(
                          `${UrlInternal.SEARCH_BRAND}?searchValue=${querySearchChange}`,
                        )
                      }
                    }}
                  />
                  <div
                    className="cursor-pointer max-w-[16px]"
                    onClick={() => {
                      navigate(
                        `${UrlInternal.SEARCH_BRAND}?searchValue=${querySearchChange}`,
                      )
                    }}
                  >
                    <img src={SearchIcon} alt="search" />
                  </div>
                </div>
                // <IconButton
                //   className={`!p-0 !text-[12px] uppercase font-pretendard500`}
                //   onClick={() => {
                //     setOpenSearch(true)
                //     // navigate("/my-page")
                //   }}
                // >
                //   <div className="h-[36px] w-[36px] flex justify-center items-center mx-auto">
                //     <SearchIcon />
                //   </div>
                //   {t("Search")}
                // </IconButton>
              )}

              <IconButton
                className={`!p-0 !text-[12px] uppercase font-pretendard500`}
                onClick={() => {
                  navigate("/my-page")
                }}
              >
                <MypageIcon />
                {t("MY")}
              </IconButton>
              {isLogined ? (
                <IconButton
                  onClick={handleLogout}
                  className={`!p-0 !text-[12px] uppercase font-pretendard500`}
                >
                  <>
                    <LogoutIcon />
                    {t("로그아웃")}
                  </>
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    navigate({
                      pathname: "/auth",
                      search: `backUrl=${pathname}`,
                    })
                  }}
                  className={`!p-0 !text-[12px] uppercase font-pretendard500`}
                >
                  <>
                    <LoginIcon />
                    {t("로그인")}
                  </>
                </IconButton>
              )}
            </div>
            <div className="lg:hidden pb-2">
              <div className="flex">
                <div
                  className="pl-5 py-1 cursor-pointer"
                  onClick={() => {
                    dispatch(onOpen())
                  }}
                >
                  <SearchMobile />
                </div>
                <div className="pl-5 py-1 cursor-pointer" onClick={() => {}}>
                  <HamburgerDark />
                </div>
                <div
                  className="pl-5 py-1 cursor-pointer"
                  onClick={() => {
                    dispatch(open())
                  }}
                >
                  <MenuDark />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
