import { useNavigate } from "react-router-dom"
import Button from "src/components/Button"
import CommonModal from "src/components/Modal"
import { UrlInternal } from "src/constant/endpointRoute"

interface Props {
  isOpen: boolean
  handleClose: () => void
  handleOk: () => void
}

function PopupRefundSuccess({ isOpen, handleClose, handleOk }: Props) {
  const navigate = useNavigate()

  return (
    <CommonModal
      onCancel={handleClose}
      className="modal-membership text-[#000] w-[100vh] h-[100vh] modal-fullpage font-pretendard400"
      isOpen={isOpen}
      isCloseOutside
    >
      <div className="px-[16px] mt-[34px]">
        <div className="font-pretendard700 text-[20px]">
          Refund request submitted
        </div>
        <div className="mt-[20px] mb-[30px] text-[#757575]">
          Your request is under review. Results will be quickly updated on My
          order history page after 3-5 working days
        </div>

        <div className="mt-[40px] flex gap-[10px]">
          <Button
            onClick={() => {
              navigate(UrlInternal.MY_ORDER_HISTORY + "?type=PRODUCT")
            }}
            className="flex justify-center border border-solid border-primary text-primary text-[14px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
          >
            Go to Order history
          </Button>
          <Button
            onClick={handleOk}
            className="flex justify-center bg-[#000] text-[#fff] text-[14px] w-full font-pretendard400 h-[44px] px-[16px] disabled:text-gray2 disabled:bg-gray6 disabled:border-none"
          >
            확인
          </Button>
        </div>
      </div>
    </CommonModal>
  )
}

export default PopupRefundSuccess
