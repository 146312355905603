import ShareIcon from "@assets/icons/header/icon-share.svg"
import NextIcon from "@assets/icons/NextIcon20.svg"
import QrcodeIcon from "@assets/icons/QrcodeIcon.svg"
import groupBuy from "@assets/images/GroupBuy_temp.png"
import imgDeals from "@assets/images/image175.png"
import dayjs from "dayjs"
import { useState } from "react"
import Countdown from "react-countdown"
import { useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { BottomSheet } from "react-spring-bottom-sheet"
import { Tooltip } from "react-tooltip"
import { homeAPi } from "src/api/homeApi"
import Button from "src/components/Button"
import OrderInput from "src/components/Input/OrderInput"
import EvenEnd from "src/components/Modal/PaymentModal/EventEnd"
import MaximumPurchaseLimit from "src/components/Modal/PaymentModal/MaximumPurchaseLimit"
import MaxQtyModal from "src/components/Modal/PaymentModal/MaxQtyModal"
import TabsComp from "src/components/Tabs"
import useIsMobile from "src/customHook/useIsMobile"
import { Voucher } from "src/models"
import { copyLink } from "src/util/copyLink"
import { MoneyConvert } from "src/util/format"
import ImgCLock from "@assets/images/shop/clock.png"
import IconQuestion from "@assets/images/shop/question.png"
import IconClose from "@assets/images/shop/close.png"

import moment from "moment"
import Service from "../Service"
import { UrlInternal } from "src/constant/endpointRoute"
import { formatPrice } from "src/util/formatPrice"
import { Popover } from "antd"
import { useAuth } from "src/customHook/useAuth"
type Props = {
  showTitle?: boolean
  data?: any
  onComplete?: () => void
}

function CardBrandShop({ showTitle = true, data, onComplete }: Props) {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(1)
  const [openEvenEnd, setOpenEvenEnd] = useState<boolean>(false)
  const [openMaximumPurchaseLimit, setOpenMaximumPurchaseLimit] =
    useState<boolean>(false)
  const [openMaxQtyModal, setOpenMaxQtyModal] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const { isMobile } = useIsMobile(768)
  const [openPopover, setOpenPopover] = useState(false)
  const { isLogined } = useAuth()
  const { pathname } = useLocation()

  console.log("isLogined", isLogined)

  const checkOrder = async (isGift?: boolean) => {
    if (!isLogined) {
      navigate({
        pathname: "/auth",
        search: `backUrl=${pathname}`,
      })
    } else {
      try {
        const resp: any = await homeAPi.checkGb(data.id, "GB_DETAIL", quantity)
        // console.log(resp)
        if (resp?.result) {
          navigate(`${UrlInternal.BRAND_SHOP_ORDER}/${data.id}`, {
            state: {
              quantity: quantity,
            },
          })
        } else {
          if (resp?.errorCode === "ENDED_PURCHASE") {
            setOpenEvenEnd(true)
            return
          }
          if (
            resp?.errorCode === "OUT_OF_PURCHASE" ||
            resp?.errorCode === "MORE_THAN_SPECIFIED_QUANTITY"
          ) {
            setOpenMaximumPurchaseLimit(true)
            return
          }
          if (resp?.errorCode === "NOT_ENOUGH_VOUCHER") {
            setOpenMaxQtyModal(true)
            return
          }
        }
      } catch (error) {
        console.log("checkOrder error", error)
      }
    }
  }

  function onDismiss() {
    setOpen(false)
  }

  if (!data) {
    return <></>
  }
  const voucherData = new Voucher(data)

  const items = [
    {
      key: "1",
      label: t("DETAIL"),
      children: (
        <div className="xs:my-[32px] my-[8px] mx-[0] md:mx-[40px]">
          {voucherData?.voucherDetail?.length > 0 ? (
            voucherData?.voucherDetail?.map((item: any, index: number) => (
              <img
                key={index}
                className="object-cover md:w-auto w-full mb-5"
                src={item?.url}
                alt="detail"
              />
            ))
          ) : (
            <div className="pt-[50px]">{/* <NoData /> */}</div>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: t("INFORMATION"),
      children: (
        <div className="xs:my-[32px] my-[8px] mx-[25px] xs:mx-[40px]">
          <Service data={data} />
        </div>
      ),
    },
  ]

  const handleProductType = (data?: string) => {
    if (data === "VOUCHER") {
      return (
        <span className="p-[3px] uppercase text-[#fff] text-[10px] bg-[#EEBA00] inline-block">
          {t("VOUCHER")}
        </span>
      )
    }
    if (data === "COUPON") {
      return (
        <span className="p-[3px] uppercase text-[#fff] text-[10px] bg-[#40C003] inline-block">
          {t("COUPON")}
        </span>
      )
    }
    if (data === "PRODUCT") {
      return (
        <span className="p-[3px] uppercase text-[#fff] text-[10px] bg-[#0098EE] inline-block">
          {t("PRODUCT")}
        </span>
      )
    }
    if (data === "E_CONTENT") {
      return (
        <span className="p-[3px] uppercase text-[#fff] text-[10px] bg-[#7B61FF] inline-block">
          {t("E-CONTENT")}
        </span>
      )
    }

    return
  }

  const handleCheckAvailable = () => {
    // !voucherData?.quantity
    // ? 99999999999999
    // : Math.min(
    //     Number(voucherData?.quantity) -
    //       Number(voucherData?.totalSold),
    //     Number(
    //       voucherData?.maxPurchaseQuantity || 100000000,
    //     ),
    //   )

    if (voucherData?.productType === "E_CONTENT") {
      return 1
    } else if (!voucherData?.quantity) {
      // return 99999999999999
      return voucherData.maxPurchaseQuantity
        ? voucherData.maxPurchaseQuantity - (voucherData.totalBought || 0)
        : 99999999999
    } else {
      return Math.min(
        Number(voucherData?.quantity) - Number(voucherData?.totalSold),
        Number(voucherData?.maxPurchaseQuantity || 100000000),
      )
    }
  }

  const handleTextBtn = () => {
    if (!isLogined) {
      return "구매하기"
    } else {
      if (voucherData.progress === "INCOMING") {
        return "판매 예정인 상품입니다"
      } else {
        if (
          voucherData?.quantity === voucherData?.totalSold ||
          !voucherData?.canBuy ||
          !voucherData?.hasSlot
        ) {
          if (
            voucherData?.onlyPremierMessage &&
            voucherData?.onlyMembershipMessage
          ) {
            return "프리미엄 멤버십인 회원과 멤버십 회원만 구매 가능합니다"
          } else if (voucherData?.onlyPremierMessage) {
            return "프리미엄 멤버십인 회원만 구매 가능합니다"
          } else if (voucherData?.onlyMembershipMessage) {
            return "멤버십 회원만 구매 가능합니다"
          } else {
            return "더이상 구매하실 수 없습니다"
          }
        } else {
          return "구매하기"
        }
      }
      // return voucherData?.quantity === voucherData?.totalSold ||
      //   !voucherData?.canBuy ||
      //   !voucherData?.hasSlot
      //   ? voucherData?.onlyMembershipMessage
      //     ? "프리미엄 멤버십인 회원만 구매 가능합니다"
      //     : "더이상 구매하실 수 없습니다"
      //   : "구매하기"
    }
  }

  const hide = () => {
    setOpenPopover(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopover(newOpen)
  }

  const checkDisable = () => {
    return (
      (voucherData?.quantity === voucherData?.totalSold ||
        Boolean(voucherData?.progress === "INCOMING") ||
        // !voucherData?.price ||
        !voucherData?.canBuy ||
        !voucherData?.hasSlot) &&
      isLogined
    )
  }

  return (
    <>
      <div>
        <div className={`flex flex-col md:flex-row gap-x-12 gap-y-6 flex-wrap`}>
          <div
            className={`relative overflow-hidden cursor-pointer flex-1 w-full h-full aspect-square ${
              !showTitle && "container"
            } lg:px-0`}
          >
            <img
              src={voucherData.image || imgDeals}
              alt=""
              className="w-full h-full object-cover"
            />

            {voucherData?.isSoldOut && (
              <div className="absolute inset-0 bg-[#00000080] flex items-center justify-center">
                <div className="border border-[#ffffff80] w-[213px] h-[52px] leading-[52px] text-[30px] text-[#ffffff80] font-pretendard900 text-center -rotate-[18deg]">
                  SOLD OUT!
                </div>
              </div>
            )}
          </div>
          <div className="flex-1 xs:px-0 px-[24px]">
            <div className="md:mb-[32px] md:border-b border-b-solid border-gray5 flex">
              <div className="mr-[18px] md:mb-[36px]">
                <div className="hidden md:flex border border-solid w-[72px] h-[72px] flex justify-center items-center text-primaryGreen font-pretendard700 mb-[9px]">
                  {voucherData.logoImage ? (
                    <img
                      src={voucherData.logoImage || groupBuy}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    voucherData.brandName
                  )}
                </div>
                <Link to={`/${data.brandId}`} className="">
                  <div className="md:border border-solid md:min-w-[72px] h-[24px] flex md:justify-end items-center font-pretendard400 text-[12px]">
                    {isMobile ? (
                      <>
                        {voucherData.brandName} <NextIcon />
                      </>
                    ) : (
                      <>
                        {t("브랜드홈")} <NextIcon />
                      </>
                    )}
                  </div>
                </Link>
              </div>
              <div className="hidden md:block">
                <p className="text-[16px] font-pretendard700 leading-4 mb-[8px]">
                  {voucherData.brandName}
                </p>
                <span className="text-[12px] font-pretendard400 leading-[150%] line-clamp-3">
                  {voucherData.slogan}
                </span>
              </div>
            </div>
            <div className="mt-[12px]">
              <div className="flex items-start justify-between">
                <div>{handleProductType(voucherData.productType)}</div>
                <div
                  data-tooltip-id="copyTooltip"
                  data-tooltip-content="Copied"
                  className="ml-4 w-[24px] h-[24px] min-w-[24px] md:w-[36px] md:h-[36px] md:min-w-[36px] icon-full cursor-pointer"
                  onClick={() => {
                    copyLink()
                  }}
                >
                  <ShareIcon />
                </div>
                <Tooltip id="copyTooltip" events={["click"]} />
              </div>
              <div className="text-[16px] md:text-[20px] font-pretendard700 text-primary leading-0">
                {voucherData.voucherName}
              </div>

              {voucherData.productType === "VOUCHER" && (
                <div className="flex gap-[10px] mt-[7px]">
                  <div>
                    <img src={ImgCLock} alt="clock" />
                  </div>

                  <div className="flex gap-[7px]">
                    <div className="text-[14px]">사용 유효기간</div>
                    <div className="text-[#15A0A1] text-[14px] font-pretendard700">
                      {moment(voucherData.dateOfUse).format("YYYY년 MM월 DD일")}
                      ~
                      {moment(voucherData.expiredTime)
                        .subtract(1, "minutes")
                        .format("YYYY년 MM월 DD일")}
                    </div>
                  </div>
                </div>
              )}

              {voucherData.endTime && (
                <div className="mt-[5px] text-[14px]">
                  판매 종료일 :{" "}
                  {moment(voucherData.endTime).format("YYYY년 MM월 DD일")}
                </div>
              )}

              <p className="text-[16px] font-pretendard400 text-gray1 mt-[18px] md:mt-[32px] leading-none line-through">
                {MoneyConvert(voucherData.voucherPrice || 0)}원
              </p>

              <div className="mt-[8px] mb-[24px] md:mb-[32px]">
                <p className="text-[18px] md:text-[24px] font-pretendard700 text-primary  leading-none">
                  <span className="text-[red]">
                    {/* {voucherData?.discountPercentage
                    ? voucherData?.discountPercentage + "%"
                    : ""}{" "} */}
                    {formatPrice(
                      100 -
                        (Number(voucherData.price) /
                          Number(voucherData.voucherPrice)) *
                          100,
                    )}
                    %
                  </span>
                  {MoneyConvert(voucherData?.price) || 0}원
                </p>

                {voucherData.hasMembershipMessage && (
                  <div className="flex gap-[15px] items-center mt-[8px] leading-none">
                    <div className="flex gap-[9px]">
                      <div>해당 브랜드의 멤버십에 가입하면?</div>
                      <div className="text-[#D32F2F] font-pretendard700">
                        {formatPrice(
                          Number(voucherData?.priceForMembership) || 0,
                        )}
                        원
                      </div>
                    </div>
                    {!data?.isSubscribed && data?.hasNormalMembership && (
                      <div
                        className="text-[#fff] font-pretendard500 bg-[#000] py-[6px] px-[10px] cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/${data?.brandId}?type=isRegisterMembership`,
                          )
                        }
                      >
                        멤버십 가입하기
                      </div>
                    )}
                  </div>
                )}
                {voucherData.hasPremierMessage && (
                  <div className="flex gap-[3px] items-center mt-[8px] leading-none">
                    <div className="flex gap-[9px]">
                      <div>해당 브랜드의 프리미엄을 이용하고 있다면?</div>
                      <div className="text-[#D32F2F] font-pretendard700">
                        {formatPrice(Number(voucherData?.priceForPremier) || 0)}
                        원
                      </div>
                    </div>

                    <div>
                      <Popover
                        content={
                          <div className="max-w-[420px] px-[6px] pb-[8px]">
                            <img
                              src={IconClose}
                              alt="img"
                              className="ml-auto"
                            />
                            <div className="text-[14px] font-pretendard700">
                              모아프렌즈 프리미엄 멤버십이란?
                            </div>
                            <div className="text-[12px] my-[15px]">
                              모아프렌즈와 브랜드가 세상 어디에도 없는 특별한
                              혜택으로 구성되어, 짧은 기간 내에 한정수량만
                              판매하는 프리미엄 서비스입니다. 모아프렌즈 메인
                              화면에서 현재 판매되고 있는 프리미엄을 확인할 수
                              있으며 상단의 ‘프리미엄 멤버십’ 메뉴에서 판매 예정
                              중인 프리미엄도 확인 가능합니다.
                            </div>
                            <div className="text-[12px]">
                              해당 브랜드에서 프리미엄 회원을 위한 제품을
                              판매하는 경우 프리미엄 멤버십 적용 기간 동안 해당
                              가격으로 구매하실 수 있습니다.
                            </div>
                          </div>
                        }
                        // title="Title"
                        trigger="click"
                        open={openPopover}
                        onOpenChange={handleOpenChange}
                      >
                        <img
                          src={IconQuestion}
                          alt="img"
                          className="cursor-pointer"
                        />
                      </Popover>
                    </div>
                  </div>
                )}
                {voucherData.hasCustomerMessage && (
                  <div className="flex gap-[9px] mt-[8px] leading-none">
                    <div>해당 브랜드의 멤버십이 아니었다면?</div>
                    <div className="text-[#000] font-pretendard700">
                      {formatPrice(Number(voucherData?.priceForCustomers) || 0)}
                      원
                    </div>
                  </div>
                )}
              </div>

              {voucherData.productType !== "VOUCHER" && (
                <>
                  {voucherData?.quantity && (
                    <div className="flex items-center mb-[14px]">
                      <p
                        className={`${
                          voucherData.status === "FINISHED"
                            ? "min-w-[150px] md:min-w-[175px]"
                            : "min-w-[125px]"
                        } text-[14px] md:text-[16px] font-pretendard400 text-primary leading-none`}
                      >
                        {t("잔여 수량")}
                      </p>
                      <p className="text-[14px] md:text-[16px] font-pretendard700 text-primary leading-none">
                        {voucherData?.quantity === voucherData?.totalSold ? (
                          <span className="text-[red] font-pretendard700">
                            매진
                          </span>
                        ) : (
                          MoneyConvert(
                            Number(voucherData.quantity) -
                              Number(voucherData.totalSold) || 0,
                          )
                        )}
                      </p>
                    </div>
                  )}
                </>
              )}
              {voucherData.status !== "FINISHED" && (
                <>
                  {voucherData.productType !== "VOUCHER" && (
                    <>
                      {!(
                        voucherData?.progress === "IN_PROGRESS" &&
                        !voucherData?.endTime
                      ) && (
                        <div className="flex items-center">
                          <p className="min-w-[125px] text-[14px] md:text-[16px] font-pretendard400 text-primary leading-none">
                            {voucherData.status === "INCOMING"
                              ? "Open in"
                              : t("구매 가능 기간")}
                          </p>

                          <Countdown
                            // overtime
                            date={voucherData.getCountTime()}
                            onComplete={onComplete}
                            renderer={({
                              formatted: { days, hours, minutes, seconds },
                            }) => {
                              return (
                                <p className="text-[14px] md:text-[16px] font-pretendard700 text-primary leading-none">
                                  {Number(days) > 0 ? days : "0"}일 {hours}
                                  시간 {minutes}분 {seconds}초 남음
                                </p>
                              )
                            }}
                          />
                        </div>
                      )}

                      {voucherData.productType === "PRODUCT" && (
                        <div className="mt-[14px] text-[14px]">
                          {t("Shipping fee is")}{" "}
                          <span className="text-[#0098EE] font-pretendard700">
                            {formatPrice(voucherData?.shippingCost ?? 0)}w
                          </span>
                        </div>
                      )}

                      {/* {!data?.isSubscribed && data?.hasNormalMembership && (
                        <div className="bg-[#f5f5f5cc] px-[16px] py-[15px] mt-[36px] mb-[16px] text-[14px]">
                          <span
                            className="font-pretendard700 text-[#15a0a1] cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/${data?.brandId}?type=isRegisterMembership`,
                              )
                            }
                          >
                            Join membership
                          </span>{" "}
                          <span>to get exclusive price.</span>{" "}
                          <span>Membership price:</span>{" "}
                          <span className="font-pretendard700">
                            {formatPrice(voucherData?.priceForMembership ?? 0)}
                            원
                          </span>
                        </div>
                      )} */}
                    </>
                  )}

                  {voucherData.progress === "INCOMING" && (
                    <div className="font-pretendard400 text-[14px]">
                      판매시작일 :{" "}
                      {moment(voucherData.startTime).format(
                        "YYYY년 MM월 DD일 HH시 mm분",
                      )}
                    </div>
                  )}

                  <div
                    className={`hidden md:flex ${
                      voucherData.productType === "VOUCHER" &&
                      "pt-[39px] border-t border-[#D4D4D4] mt-[8px]"
                    }`}
                  >
                    <OrderInput
                      defaultValue={quantity}
                      disabled={
                        voucherData?.isLimited ||
                        voucherData?.quantity === voucherData?.totalSold ||
                        voucherData?.status === "INCOMING"
                      }
                      available={handleCheckAvailable()}
                      className="w-[132px]"
                      onChange={setQuantity}
                    />
                  </div>

                  {voucherData.maxPurchaseQuantity && (
                    <div className="mt-[8px] text-[#757575] text-[14px]">
                      *Maximum puchase quantity is{" "}
                      {voucherData?.maxPurchaseQuantity}.
                    </div>
                  )}
                  {/* {voucherData.productType !== "VOUCHER" && (
                    <div className="mt-[8px] text-[#757575] text-[14px]">
                      *Maximum puchase quantity is{" "}
                      {voucherData?.maxPurchaseQuantity}.
                    </div>
                  )} */}
                </>
              )}
            </div>

            {voucherData.status !== "FINISHED" && (
              <>
                <div className="hidden md:flex font-pretendard500 gap-x-[20px] pt-[32px]">
                  <Button
                    theme="main"
                    onClick={() => {
                      // navigate(`/group-buying/order/${data.id}`, {
                      //   state: {
                      //     quantity: quantity,
                      //   },
                      // })
                      checkOrder()
                    }}
                    disabled={checkDisable()}
                  >
                    {handleTextBtn()}
                  </Button>
                </div>
              </>
            )}

            {voucherData.status === "FINISHED" && (
              <>
                <div className="hidden md:flex font-pretendard500 gap-x-[20px] pt-[32px]">
                  <Button theme="main" disabled>
                    판매가 종료된 상품입니다
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-[8px] md:mt-[63px]">
          <TabsComp theme="detailTab" items={items} />
        </div>

        {params.id
          ? !open && (
              <div className="flex items-center  md:hidden fixed z-20 h-[80px] left-0 bottom-0 gap-x-[8px] w-full bg-[#fff] px-[15px] py-[12px]">
                {voucherData.status !== "FINISHED" && (
                  <Button
                    onClick={() => {
                      setOpen(true)
                    }}
                    disabled={checkDisable()}
                    className="flex w-full text-white text-[18px] px-[16px] h-full font-pretendard700 disabled:text-gray2 bg-black"
                  >
                    {handleTextBtn()}
                  </Button>
                )}
                {voucherData.status === "FINISHED" && (
                  <Button
                    disabled
                    className="flex w-full text-white text-[18px] px-[16px] h-full font-pretendard700 disabled:text-gray2 bg-black"
                  >
                    판매가 종료된 상품입니다
                  </Button>
                )}
              </div>
            )
          : !open && (
              <div className="flex justify-between items-center bg-black md:hidden fixed z-20 h-[70px] left-0 bottom-0 gap-x-[30px] w-full">
                <div
                  className="px-[16px] h-full flex justify-between items-center"
                  onClick={() => {
                    navigate(`/qr-code`)
                  }}
                >
                  <QrcodeIcon />
                </div>
                <Button
                  onClick={() => setOpen(true)}
                  disabled={checkDisable()}
                  className="flex text-white text-[18px] px-[16px] h-full font-pretendard700 disabled:text-gray2"
                >
                  {handleTextBtn()}
                </Button>
              </div>
            )}
        {open && (
          <div
            className="bg-bottomSheetOverlay fixed w-[100vw] h-[100vh] left-0 top-0"
            onClick={onDismiss}
          ></div>
        )}
        <BottomSheet
          open={open}
          onDismiss={onDismiss}
          snapPoints={({ minHeight }) => minHeight}
          className="relative z-10"
          blocking={false}
        >
          <div>
            <div className="px-[24px]">
              <span className="text-[12px] font-pretendard400 text-gray1 leading-none block my-[16px]">
                {t("X회 최대 Y개 수량만 구매 가능합니다.", {
                  purchasesNumber: "1",
                  quantity: voucherData?.maxPurchaseQuantity || 0,
                })}
              </span>
              <OrderInput
                defaultValue={quantity}
                disabled={
                  voucherData?.isLimited ||
                  voucherData?.quantity === voucherData?.totalSold ||
                  voucherData?.status === "INCOMING"
                }
                available={handleCheckAvailable()}
                className="w-full"
                onChange={setQuantity}
              />

              <div className="flex items-center mb-[12px] justify-between mt-[40px]">
                <p className="text-[14px] font-pretendard700 text-primary leading-none">
                  {t("총 상품 금액")}
                </p>
                <p className="text-[14px] font-pretendard700 text-primary leading-none">
                  {/* {MoneyConvert(Number(voucherData?.rootPrice || 0) * quantity)}
                  원 */}
                  {MoneyConvert(Number(voucherData?.voucherPrice) * quantity) ||
                    0}
                  {t("원")}
                </p>
              </div>

              <div className="flex items-center mb-[20px] justify-between">
                <p className="text-[14px] font-pretendard700 text-primary leading-none">
                  {t("할인율")}
                </p>
                <p className="text-[14px] font-pretendard400 text-primary leading-none">
                  {/* {MoneyConvert(
                    (Number(voucherData?.discountPercentage) / 100) *
                      Number(voucherData?.rootPrice) *
                      quantity || 0,
                  )}
                  원 */}
                  {formatPrice(
                    100 -
                      (Number(voucherData.price) /
                        Number(voucherData.voucherPrice)) *
                        100,
                  )}
                  %
                </p>
              </div>

              <div className="flex items-center justify-between pt-[24px] border-t-2 border-t-solid border-primary">
                <p className="text-[16px] font-pretendard700 text-primary leading-none">
                  {t("구매 가능 금액")}
                </p>
                <p className="text-[20px] font-pretendard800 text-primary leading-none">
                  {MoneyConvert(Number(voucherData.price) * quantity || 0)}
                  {t("원")}
                </p>
              </div>
            </div>
            <div className="flex font-pretendard500 pt-[32px] gap-[8px] mx-[24px] mb-[20px]">
              <Button
                onClick={() => {
                  // navigate(`/group-buying/order/${data.id}`, {
                  //   state: {
                  //     quantity: quantity,
                  //   },
                  // })
                  checkOrder()
                }}
                disabled={checkDisable()}
                className="flex justify-center bg-black text-white text-[18px] w-full font-pretendard700 h-[56px] px-[16px] disabled:text-gray2"
              >
                {handleTextBtn()}
              </Button>
            </div>
          </div>
        </BottomSheet>
      </div>
      <EvenEnd
        isOpen={openEvenEnd}
        handleClose={() => {
          setOpenEvenEnd(false)
          navigate(`${UrlInternal.BRAND_SHOP_DETAIL}/${data.id}`)
        }}
        isShop
      />
      <MaximumPurchaseLimit
        isOpen={openMaximumPurchaseLimit}
        handleClose={() => {
          setOpenMaximumPurchaseLimit(false)
          // console.log("123213")
          navigate(`${UrlInternal.BRAND_SHOP_DETAIL}/${data.id}`)
        }}
      />
      <MaxQtyModal
        isOpen={openMaxQtyModal}
        handleClose={() => {
          setOpenMaxQtyModal(false)
          navigate(`${UrlInternal.BRAND_SHOP_DETAIL}/${data.id}`)
        }}
      />
    </>
  )
}

export default CardBrandShop
