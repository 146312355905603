import { Form, Input, SelectProps, message } from "antd"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { customerApi } from "src/api/customerApi"
import CommonModal, { CommonModalProps } from "."
import Button from "../Button"
import CSInput from "../Input/Index"
import SuccessIcon from "@assets/icons/Success_primary.svg"
import { useTranslation } from "react-i18next"
import SelectCustom from "../SelectCustom"

interface Props extends CommonModalProps {
  id?: string | number
  deleteSuccess?: () => void
}

const DeleteInquiry: React.FC<Props> = ({
  handleClose,
  deleteSuccess,
  id,
  ...props
}) => {
  const { t } = useTranslation()

  const handleDelete = async () => {
    if (!id) {
      return
    }
    try {
      const { result }: any = await customerApi.deleteInquiry({ id })
      if (result) {
        deleteSuccess && deleteSuccess()
        handleClose && handleClose()
      } else {
        message.error("Delete failed")
      }
    } catch (error) {}
  }

  return (
    <>
      <CommonModal
        onCancel={() => {
          handleClose && handleClose()
        }}
        {...props}
      >
        <h3 className="text-[20px] font-pretendard500 leading-normal mb-[8px]">
          Delete this inquiry permanently?
        </h3>
        <p className="text-[14px] text-gray1 leading-[20px]">
          If the inquiry has been replied, you won't be able to find it
          elsewhere. If it hasn't been replied, you won't receive an answer.{" "}
        </p>
        <p className="text-[14px] text-gray1 leading-[20px]">
          Are you sure you want to delete it?
        </p>
        <div className="mt-[20px] flex gap-[10px]">
          <Button
            theme="main"
            variant="outline"
            height={48}
            width={155}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            theme="main"
            variant="primary"
            height={48}
            width={155}
            onClick={handleDelete}
          >
            Delete it
          </Button>
        </div>
      </CommonModal>
    </>
  )
}

export default DeleteInquiry
