import imgDeals from "@assets/images/image175.png"
import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { groupBuyAPi } from "src/api/groupBuyApi"
import Button from "src/components/Button"
import GroupBuyingCard from "src/components/Card/GroupBuyingCard"
import NoData from "src/components/NoData"
import Pagination from "src/components/Pagination"
import useIsMobile from "src/customHook/useIsMobile"
import { Voucher } from "src/models"

export type TypeVoucher = "in-progress" | "up-coming" | "finished" | "all"

type TTOTAL_DATA = {
  inProgressVoucher: number
  incomingVoucher: number
  finishedVoucher: number
  allCount: number
}

function VoucherList() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const swiperRef = useRef<any | null>(null)
  const [searchParam, setSearchParam] = useSearchParams()
  const [voucherInprogress, setVoucherInprogress] = useState<
    Voucher | undefined
  >()
  const [voucherList, setVoucherList] = useState<any[]>([])
  const [brandId, setBrandId] = useState<number>()
  const [totalVoucher, setTotalVoucher] = useState(0)
  const { isMobile } = useIsMobile(1023)
  const query = {
    page: Number(searchParam.get("page")) || 1,
    size: 8,
  }
  const params = useParams()
  const { pathname } = useLocation()
  const initTotal = {
    inProgressVoucher: 0,
    incomingVoucher: 0,
    finishedVoucher: 0,
    allCount: 0,
  }
  const [totalData, setTotalData] = useState<TTOTAL_DATA>(initTotal)

  const getTotalData = async () => {
    try {
      const { data, result }: any = await groupBuyAPi.getTotalVoucher()
      console.log("fetch setTotalData", data)
      if (result) {
        setTotalData(data)
      } else {
        setTotalData(initTotal)
      }
    } catch (error) {
      console.log("getTotalData", error)
      setTotalData(initTotal)
    }
  }

  useEffect(() => {
    getTotalData()
  }, [])

  const type: TypeVoucher = (params.type || "all") as TypeVoucher
  const isOfTypeTabs = (keyInput: string): keyInput is TypeVoucher => {
    return ["all", "in-progress", "up-coming", "finished"].includes(keyInput)
  }

  const getInprogressData = async () => {
    try {
      const resp: any = await groupBuyAPi.getInProgress(query)
      if (resp?.result) {
        const voucherList = resp.data.datas
        setVoucherList(voucherList)
        setTotalVoucher(resp.data.pageable.totalElement)
      } else {
        setTotalVoucher(0)
        setVoucherList([])
      }
    } catch (error) {
      console.log("getInprogressData error, error")
    }
  }

  const getUpCommingData = async () => {
    try {
      const resp: any = await groupBuyAPi.getInComing(query)
      if (resp?.result) {
        const dataList = resp.data.datas
        setVoucherList(dataList)
        setTotalVoucher(resp.data.pageable.totalElement)
      } else {
        setTotalVoucher(0)
        setVoucherList([])
      }
    } catch (error) {
      setTotalVoucher(0)
      setVoucherList([])
      console.log("getUpCommingData error, error")
    }
  }

  const getFinishedData = async () => {
    try {
      const resp: any = await groupBuyAPi.getFinished(query)
      if (resp?.result) {
        const dataList = resp.data.datas
        setVoucherList(dataList)
        setTotalVoucher(resp.data.pageable.totalElement)
      } else {
        setTotalVoucher(0)
        setVoucherList([])
      }
    } catch (error) {
      setTotalVoucher(0)
      setVoucherList([])
      console.log("getFinishedData error, error")
    }
  }

  const getAllData = async () => {
    try {
      const resp: any = await groupBuyAPi.getAll(query)
      if (resp?.result) {
        const dataList = resp.data.datas
        setVoucherList(dataList)
        setTotalVoucher(resp.data.pageable.totalElement)
      } else {
        setTotalVoucher(0)
        setVoucherList([])
      }
    } catch (error) {
      setTotalVoucher(0)
      setVoucherList([])
      console.log("getAllData error, error")
    }
  }

  useEffect(() => {
    swiperRef?.current?.swiper?.on("click", function (e: any) {
      swiperRef?.current?.swiper?.slideTo(e?.slidesGrid[e?.clickedIndex])
    })
  }, [])

  useEffect(() => {
    if (params.type === "in-progress") {
      getInprogressData()
    }
    if (params.type === "finished") {
      getFinishedData()
    }
    if (params.type === "up-coming") {
      getUpCommingData()
    }
    if (params.type === "all") {
      getAllData()
    }
  }, [type, searchParam])

  const remindSuccess = (id: any) => {
    setVoucherList((prev) => {
      const temp = [...prev]
      const result = temp.map((item: any) => {
        if (item.id === id) {
          return { ...item, isRemind: true }
        }
        return item
      })
      return result
    })
  }
  const routes = [
    {
      label: `${t("ALL")} (${totalData.allCount || 0})`,
      path: "/group-buying/all",
    },
    {
      label: `${t("IN_PROGRESS")} (${totalData.inProgressVoucher || 0})`,
      path: "/group-buying/in-progress",
    },
    {
      label: `${t("UP_COMING")} (${totalData.incomingVoucher || 0})`,
      path: "/group-buying/up-coming",
    },
    {
      label: `${t("FINISHED")} (${totalData.finishedVoucher || 0})`,
      path: "/group-buying/finished",
    },
  ]
  if (!isOfTypeTabs(type)) {
    return <Navigate to={"../all"} replace />
  }

  const handleSlideTo = (index: number) => {
    if (swiperRef.current) {
      swiperRef?.current?.swiper?.slideTo(index)
    }
  }

  return (
    <div className="md:mx-0 mx-[-24px]">
      {/* {isMobile ? (
        <div className="max-w-[95%] mx-[16px] mt-[24px]">
          <Swiper slidesPerView={5} spaceBetween={16} freeMode ref={swiperRef}>
            {routes.map((item, index) => {
              return (
                <SwiperSlide key={index} className="!w-auto flex justify-start">
                  <Button
                    key={index}
                    className={`text-[14px] text-primary ${
                      item.path === pathname ? "font-pretendard700" : ""
                    }`}
                    onClick={() => {
                      handleSlideTo(index)
                      navigate(
                        {
                          pathname: item.path,
                          search: "",
                        },
                        { preventScrollReset: false },
                      )
                    }}
                  >
                    <p className="line-clamp-1">{item.label}</p>
                  </Button>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row items-center gap-[16px] relative z-20">
          {routes.map((item, index) => {
            return (
              <Button
                key={index}
                className={`text-[18px] text-primary ${
                  item.path === pathname ? "font-pretendard700" : ""
                }`}
                onClick={() => {
                  navigate(item.path, { preventScrollReset: false })
                }}
              >
                {item.label}
              </Button>
            )
          })}
        </div>
      )} */}
      <div className="flex items-center gap-[16px] relative z-20 justify-center lg:justify-start mt-[24px] lg:mt-0">
        {routes.map((item, index) => {
          return (
            <Button
              key={index}
              className={`lg:text-[18px] text-[14px] text-primary ${
                item.path === pathname ? "font-pretendard700" : ""
              }`}
              onClick={() => {
                navigate(item.path, { preventScrollReset: false })
              }}
            >
              {item.label}
            </Button>
          )
        })}
      </div>
      <div className="mt-[22px]">
        {voucherList?.length > 0 ? (
          <>
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xs:gap-[20px] gap-x-[3px] md:gap-y-[20px] gap-y-[28px] lg:mt-0 mt-[20px]">
              {voucherList &&
                voucherList.map((item, index) => {
                  return (
                    <GroupBuyingCard
                      key={index}
                      id={item.id}
                      title={item.brandName || ""}
                      desc={item.voucherName || ""}
                      price={item.voucherPrice || 0}
                      discountPercentage={item.discountPercentage || ""}
                      voucherImage={item.voucherImage || imgDeals || ""}
                      logoImage={item.logoImage}
                      available={item.quantity || 0}
                      tag={item.tags}
                      type={type}
                      startTime={dayjs(item.startTime).format()}
                      endTime={dayjs(item.endTime).format()}
                      totalValue={item.totalGroupBuyingValue}
                      sold={item.totalSold || 0}
                      // isSoldOut={!!item.isSoldOut}
                      isSoldOut={item?.quantity === item?.totalSold}
                      isRemind={!!item.isRemind}
                      remindSuccess={remindSuccess}
                      onRefresh={() => {
                        setVoucherList(
                          voucherList.filter((v) => v.id !== item.id),
                        )
                        // setTotalData({
                        //   ...totalData,
                        //   incomingVoucher:
                        //     Number(totalData?.incomingVoucher) - 1,
                        // })
                      }}
                      progress={item.progress}
                    />
                  )
                })}
            </div>
            <div className="mt-[80px]">
              {totalVoucher > 8 && (
                <Pagination
                  total={totalVoucher}
                  current={query.page}
                  pageSize={8}
                  showSizeChanger={false}
                  onChange={(page) => {
                    searchParam.set("page", page.toString())
                    setSearchParam(searchParam)
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div className="mt-[200px] text-center text-[18px] font-pretendard500">
            {/* <NoData /> */}
            모아프렌즈만의 프리미엄 멤버십을 기대해 주세요
          </div>
        )}
      </div>
    </div>
  )
}

export default VoucherList
