import ImgReset from "@assets/images/brand_page/reset.png"
import { Spin } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getBrand } from "src/api/homeApi"
import NoData from "src/components/NoData"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"

import IconSearch from "@assets/images/home/icon_search_mobile.png"

function SearchFilterBrand() {
  const [dataBrand, setDataBrand] = useState<any[]>([])
  const [pageable, setPageable] = useState<any>({})
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const valueSearch = searchParams.get("searchValue")
  const { isMobile } = useIsMobile(1023)

  const [queryChange, setQueryChange] = useState("")

  const getDataBrand = async (isLoadmore?: boolean, page?: number) => {
    try {
      const res: any = await getBrand({
        page: page ?? 1,
        size: 12,
        filter: "",
        querySearch: valueSearch ?? "",
      })

      if (res.result) {
        setDataBrand(res.data.datas)
        setPageable(res.data.pageable)

        if (isLoadmore) {
          setDataBrand([...dataBrand, ...res.data.datas])
        }
      } else {
        setDataBrand([])
        setPageable({})
      }
    } catch (error) {
      setDataBrand([])
      setPageable({})
    }
  }

  useEffect(() => {
    getDataBrand()
  }, [valueSearch])

  const handleLoadMore = () => {
    setTimeout(() => {
      getDataBrand(true, pageable.page + 1)
    }, 1500)
  }

  return (
    <>
      {isMobile && (
        <div className="relative">
          <NavBarMb pageName="Search" csGoback={() => navigate("/")} />
        </div>
      )}

      <div className="container sm:mt-[60px] mt-[30px]">
        <div className="sm:flex hidden justify-between pb-[20px] border-b border-b-[#000] mb-[40px]">
          <div className="text-[24px] font-pretendard700">
            {t("검색 결과")}: {valueSearch}
          </div>
        </div>

        <div className="sm:hidden border border-[#000000] flex gap-[15px] items-center h-[56px] px-[16px] mb-[28px]">
          <img
            src={IconSearch}
            alt="IconSearch"
            className="max-w-[17px]"
            onClick={() =>
              setSearchParams((params) => {
                params.set("searchValue", queryChange)
                return params
              })
            }
          />
          <input
            type="text"
            placeholder={"Search for your brand..."}
            className={`xs:w-[226px] w-[150px] placeholder:text-[#757575] focus-visible:outline-none bg-transparent text-[#000000] text-[16px] flex-1`}
            onChange={(e) => {
              setQueryChange(e.target.value)
            }}
            value={queryChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearchParams((params) => {
                  params.set("searchValue", queryChange)
                  return params
                })
              }
            }}
          />
          <div
            className="text-[#666666] text-[14px]"
            onClick={() =>
              setSearchParams((params) => {
                params.set("searchValue", queryChange)
                return params
              })
            }
            // onClick={() => {
            //   setQueryChange("")
            //   setSearchParams((params) => {
            //     params.set("searchValue", "")
            //     return params
            //   })
            // }}
          >
            검색
          </div>
        </div>

        <div className="pb-[15px] text-[#757575] text-[14px]">
          {pageable?.totalElement <= 0 || !pageable?.totalElement ? (
            "Brands(0)"
          ) : (
            <>
              {t("Showing all")} {pageable?.totalElement ?? 0} {t("results")}
            </>
          )}
        </div>

        {dataBrand && dataBrand?.length > 0 ? (
          <InfiniteScroll
            dataLength={dataBrand?.length}
            next={handleLoadMore}
            hasMore={pageable?.totalElement > dataBrand?.length}
            loader={
              <div className="flex justify-center items-center py-[30px] overflow-hidden">
                <Spin tip="Loading" size="small" />
              </div>
            }
          >
            <div className="md:grid-cols-4 xs:grid-cols-3 grid grid-cols-2 gap-[21px]">
              {dataBrand?.map((item: any, index) => (
                <div
                  className="cursor-pointer"
                  key={index}
                  onClick={() => navigate(`/${item.id}`)}
                >
                  <div className="border border-[#D4D4D4] aspect-square">
                    <img
                      src={item?.logoImage}
                      alt="img"
                      className="w-[100%] h-[100%] object-contain max-w-[100%]"
                    />
                  </div>
                  <div className="mt-[16px] font-pretendard700">
                    {item?.brandName}
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="my-[87px] text-center text-[20px]">
            해당 브랜드가 검색되지 않았습니다.
          </div>
        )}
      </div>
    </>
  )
}

export default SearchFilterBrand
