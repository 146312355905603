import SelectCustom from "../SelectCustom"
import "./style.scss"

interface Props {
  value: string
  onChange: (value: string) => void
  options: any[]
}

function SelectFilterComment({ value, onChange, options }: Props) {
  return (
    <SelectCustom
      value={value}
      onChange={(e) => {
        onChange(e)
      }}
      options={options}
      height={40}
      className="select-filter-comment"
      rootClassName="dropdown-filter-comment"
      placement="bottomRight"
    />
  )
}

export default SelectFilterComment
