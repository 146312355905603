import moment from "moment"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getDetailNotification } from "src/api/notificationApi"

function DetailNotification() {
  const [data, setData] = useState<any>({})
  const params = useParams()
  const id = params.id

  useEffect(() => {
    ;(async function getDetail() {
      const resp = await getDetailNotification({
        id: Number(id),
      })

      if (resp.data) {
        setData(resp.data[0])
      } else {
        setData({})
      }
    })()
  }, [])

  return (
    <div>
      <div className="sm:text-[20px] text-[18px] font-pretendard600">
        {data?.title}
      </div>
      <div className="text-[#757575] text-[14px] pt-[15px] sm:pb-[21px] pb-[9px]">
        {moment(data?.created_time).format("YYYY/MM/DD")}
      </div>

      <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
    </div>
  )
}

export default DetailNotification
