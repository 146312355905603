import { LoadingOutlined } from "@ant-design/icons"
import { Checkbox, Form, message } from "antd"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { AppDispatch } from "src/app/store"
import Button from "src/components/Button"
import CheckboxComp from "src/components/Checkbox"
import { default as Input } from "src/components/Input/Index"
import ForgotPassword from "src/components/Modal/auth/ForgotPassword"
import { useCountTime } from "src/customHook/useCountTime"
import {
  login as loginAction,
  loginFailed,
  loginSelect,
  loginSuccess,
} from "src/features/user/userSlice"
import { getUserInfo } from "src/features/userData/userDataSlice"
import { User } from "src/models"
import { forgotPassword } from "src/services/Auth"
const TIME_TO_SENDEMAIL = 60
const EMAIL_SEND_KEY = "email_send"

export interface loginInfo {
  email: string
  password: string
}

function LoginForm() {
  const [messageApi, contextHolder] = message.useMessage({
    maxCount: 2,
    top: 60,
  })
  const login = useAppSelector(loginSelect)
  const { loading } = login
  const dispatch: AppDispatch = useAppDispatch()
  // const { countTime, handleSetCountTime } = useCountTime(0, EMAIL_SEND_KEY)
  const { t } = useTranslation()
  const [isOpenForgot, setOpenForgot] = useState<boolean>(false)
  const [submittable, setSubmittable] = useState<boolean>(false)
  // const [isRemember, setIsRemember] = useState<boolean>(false)
  const [form] = Form.useForm()

  const errorMess = (mess: string) => {
    messageApi.open({
      type: "error",
      content: mess,
    })
  }

  // const isReadySend = useMemo(() => {
  //   return countTime <= 0
  // }, [countTime > 0])

  const loginAsync = async (loginInfo: loginInfo) => {
    dispatch(loginAction())
    const user = new User(null)
    const res: any = await user.login(loginInfo)

    if (res?.error?.errorCode === "USER_INACTIVE") {
      dispatch(loginFailed())
      message.error("Your account is inactive!")
      return
    }
    if (res?.result) {
      dispatch(loginSuccess(res.data))

      dispatch(getUserInfo())
    } else {
      dispatch(loginFailed())
      errorMess(
        t(
          "Invalid Username or Password. Please make sure your username and password are entered correctly.",
        ),
      )
    }
  }

  // const onFinish = async (values: any) => {
  //   try {
  //     const resp: any = await forgotPassword({
  //       email: values.email,
  //     })
  //     // console.log(resp)
  //     if (resp?.result) {
  //       // setOpenSuccess(true)
  //       message.success(resp?.message)
  //     } else {
  //       message.error(resp?.error?.message)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   if (isReadySend) {
  //     setOpenForgot(false)
  //     handleSetCountTime(TIME_TO_SENDEMAIL)
  //   }
  // }

  const handleSubmitLogin = async (values: any) => {
    const loginInfo: loginInfo = {
      email: values.email,
      password: values.password,
    }
    loginAsync(loginInfo)
    localStorage.setItem("checkLinkBackMobile", JSON.stringify("1"))
  }

  const handleRememberLogin = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("isRemember", e.target.checked)
  }

  const handleCloseForgot = () => {
    setOpenForgot(false)
  }
  const values = Form.useWatch([], form)

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      },
    )
  }, [form, values])

  return (
    <div>
      {contextHolder}
      <Form
        form={form}
        onFinish={handleSubmitLogin}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          isRemember: false,
        }}
      >
        <Input
          name="email"
          placeholder={t("아이디 (이메일주소)")}
          // label={t("USERNAME")}
          rules={[{ required: true, message: t("Please type user!") }]}
          className="input-no-boxshadow"
        />
        <Input
          className="has-show-password input-no-boxshadow"
          inputType="password"
          name="password"
          placeholder={t("패스워드")}
          // placeholder={t("비밀번호 (8~12자 영문+숫자+특수문자)")}
          // label={t("PASSWORD")}
          rules={[{ required: true, message: t("Please type password!") }]}
        />

        <div className="mt-[24px]">
          <Button theme="main" disabled={!submittable}>
            {loading ? <LoadingOutlined /> : t("로그인")}
          </Button>
        </div>
        <div className="flex justify-between items-center relative mt-[13px]">
          <Form.Item style={{ marginBottom: 0 }} name="isRemember">
            <CheckboxComp
              onChange={handleRememberLogin}
              label={t("자동 로그인")}
            />
          </Form.Item>
          <p
            className="text-gray1 text-[14px] lg:text-[16px] cursor-pointer leading-[100%]"
            onClick={() => {
              setOpenForgot(true)
            }}
          >
            {t("비밀번호 찾기")}
          </p>
        </div>
        <ForgotPassword isOpen={isOpenForgot} handleClose={handleCloseForgot} />
      </Form>
    </div>
  )
}

export default LoginForm
