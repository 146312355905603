import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const getDataMyOrderHistory = async (params: {
  page?: number
  size?: number
  brandId?: number
  type?: string
  timeFilter?: string
  voucherType?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.LIST_ORDER_HISTORY,
    method: "GET",
    params: params,
    tokenRequired: true,
  })
}
