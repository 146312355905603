import HomeIcon from "@assets/images/navigation/home.svg"
import PersonIcon from "@assets/images/navigation/person.svg"
import ActivityIcon from "@assets/images/navigation/local_activity.svg"
import StoreFrontIcon from "@assets/images/navigation/storefront.svg"
import "./style.scss"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const dataMenu = [
  {
    title: "홈",
    icon: <HomeIcon />,
    link: "/",
    path: "/home",
  },
  {
    title: "프리미엄",
    icon: <ActivityIcon />,
    link: "/group-buying/all",
    path: "/group-buying",
  },
  {
    title: "브랜드",
    icon: <StoreFrontIcon />,
    link: "/brand",
    path: "/brand",
  },
  {
    title: "MY",
    icon: <PersonIcon />,
    link: "/my-page",
    path: "/my-page",
  },
]

function NavigationMobile() {
  const location = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const handleActiveMenu = (path: string) => {
    if (location.pathname === "/" && path === "/home") {
      return "active"
    } else {
      if (location.pathname.includes(path)) {
        return "active"
      }
    }
  }

  return (
    <div className="container-navigation" id="container-navigation">
      <div className="fixed left-0 right-0 bottom-0 bg-[#fff] z-[1000]">
        <div className="flex justify-between items-center h-[56px]">
          {dataMenu.map((dt, i) => (
            <div
              className={`text-center cursor-pointer w-[25%] ${handleActiveMenu(
                dt.path,
              )}`}
              key={i}
              onClick={() => navigate(dt.link)}
            >
              <div className="flex justify-center">{dt.icon}</div>
              <div className="title mt-[2px] text-[11px]">{t(dt.title)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NavigationMobile
