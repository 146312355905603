import React, { ReactNode, ReactPropTypes } from "react"
import NodataImg from "@assets/images/nodata-img.svg"
import { useLocation, useNavigate } from "react-router-dom"
import { Trans } from "react-i18next"
type Props = {
  className?: string
  children?: ReactNode | ReactNode[]
}

export default function NoData({ children, className }: Props) {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <div className={"flex justify-center items-center flex-col " + className}>
      <div className="nodata-mobile">
        <NodataImg />
      </div>
      <p className="text-[16px] xs:text-[20px] text-primary font-pretendard400 leading-normal text-center mt-[20px] max-w-[290px]">
        <Trans i18nKey="no data text">
          No data available in this section back
          <span
            onClick={() => navigate("/")}
            className="text-[16px] xs:text-[20px] text-primaryBlue font-pretendard400 leading-normal cursor-pointer"
          >
            homepage
          </span>
        </Trans>
      </p>
    </div>
  )
}
