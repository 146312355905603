import CloseWhiteIcon from "@assets/icons/CloseWhite.svg"
import SearchIcon from "@assets/icons/SearchWhite38.svg"
import { Input } from "antd"
import { ChangeEvent, memo, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDebounce } from "src/customHook/useDebounce"
import "../style.scss"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { isOpenSearchSelect, onClose } from "../searchSlice"
import { searchApi } from "src/api/search"
import useIsMobile from "src/customHook/useIsMobile"
import { useTranslation } from "react-i18next"

type Props = {}

export interface ItemSearch {
  id: string
  title: string
}
export interface DataSearch {
  listBrand: ItemSearch[]
  listVoucher: ItemSearch[]
}

function SearchDesktop({}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isOpenSearch = useAppSelector(isOpenSearchSelect)
  const { isMobile } = useIsMobile(1023)
  const [textSearch, setTextSearch] = useState<string>("")
  const [dataSearch, setDataSearch] = useState<DataSearch>({
    listBrand: [],
    listVoucher: [],
  })
  const debouncedInputValue = useDebounce<string>(textSearch, 500)

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value)
  }

  useEffect(() => {
    if (!isMobile) {
      const fetchData = async () => {
        try {
          const paramsSearch =
            textSearch.trim() !== "" ? { querySearch: textSearch } : {}
          const { data }: any = await searchApi(paramsSearch)

          if (data) {
            const listBrand: ItemSearch[] = data.brand.map((item: any) => ({
              id: item.id,
              title: item.brandName,
            }))
            const listVoucher: ItemSearch[] = data.voucher.map((item: any) => ({
              id: item.id,
              title: item.voucherName,
            }))

            setDataSearch({
              listBrand,
              listVoucher,
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
      if (isOpenSearch) {
        fetchData()
      }
    }
  }, [debouncedInputValue, isOpenSearch])
  // console.log(dataSearch)

  return (
    <>
      <div
        onClick={() => dispatch(onClose())}
        className="close absolute top-[87px] right-[87px] cursor-pointer"
      >
        <CloseWhiteIcon />
      </div>
      <div className="max-w-[1488px] px-[88px] m-auto w-full relative">
        <div className="search-form">
          <Input
            value={textSearch}
            onChange={(e) => handleSearch(e)}
            placeholder={t("Type words and hit enter")}
            onPressEnter={() => {
              dispatch(onClose())
              setTextSearch("")
              navigate(`/search?q=${textSearch}`)
            }}
          />
          <div
            className="cursor-pointer"
            onClick={() => {
              dispatch(onClose())
              setTextSearch("")
              navigate(`/search?q=${textSearch}`)
            }}
          >
            <SearchIcon />
          </div>
        </div>
        {(dataSearch.listBrand.length > 0 ||
          dataSearch.listVoucher.length > 0) && (
          <div className="w-full py-[26px]">
            <div className="scrollbar overflow-y-auto max-h-[330px]">
              {dataSearch.listBrand.length > 0 && (
                <div className="mb-[24px]">
                  <h4 className="text-[12px] leading-normal uppercase text-textColor9 font-pretendard600 mb-[7px]">
                    Brand
                  </h4>
                  {dataSearch.listBrand.map((item) => (
                    <p
                      onClick={() => {
                        navigate(`/brand-detail/${item.id}`)
                        dispatch(onClose())
                        setTextSearch("")
                      }}
                      className="cursor-pointer text-[20px] leading-normal text-white font-pretendard600 mb-[9px]"
                      key={item.id}
                    >
                      {item.title}
                    </p>
                  ))}
                </div>
              )}
              {dataSearch.listVoucher.length > 0 && (
                <div>
                  <h4 className="text-[12px] leading-normal uppercase text-textColor9 font-pretendard600 mb-[7px]">
                    voucher
                  </h4>
                  {dataSearch.listVoucher.map((item) => (
                    <p
                      onClick={() => {
                        navigate(`/group-buying/detail/${item.id}`)
                        dispatch(onClose())
                        setTextSearch("")
                      }}
                      className="cursor-pointer text-[20px] leading-normal text-white font-pretendard600 mb-[9px]"
                      key={item.id}
                    >
                      {item.title}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default memo(SearchDesktop)
