import IconBacktop from "@assets/icons/footer/back_top_icon.svg"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import SelectLang from "src/components/SelectLang"
import useIsMobile from "src/customHook/useIsMobile"
import useCheckMatchPath from "src/customHook/useCheckMatchPath"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import Loading from "src/components/Loading"
import { useAppSelector } from "src/app/hooks"
import { loadingSelect } from "src/features/loading/loadingSlice"
import FbImg from "@assets/images/footer/fb.png"
import BlogImg from "@assets/images/footer/blog.png"
import InstaImg from "@assets/images/footer/insta.png"
import KakaoImg from "@assets/images/footer/kakao.png"

function Footer() {
  const isHomePage = useCheckMatchPath("/")
  const isGoupBuyingDetail = useCheckMatchPath("/group-buying/detail/:id")
  const isGoupBuyingOrder = useCheckMatchPath("/group-buying/order/:id")
  const { isMobile } = useIsMobile(1023)
  const { t } = useTranslation()
  const [isShowBackToTop, setIsShowBackToTop] = useState<boolean>(true)
  const isLoading = useAppSelector(loadingSelect)
  const pathname = window.location.pathname
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [isFixed, setIsFixed] = useState<boolean>(true)

  useEffect(() => {
    if (
      pathname.includes("terms-of-use") ||
      pathname.includes("privacy-policy") ||
      (isMobile && pathname.includes("qr-code"))
    ) {
      setIsShowBackToTop(false)
    } else {
      setIsShowBackToTop(true)
    }
  }, [])

  useLayoutEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY

      setScrollPosition(currentPosition)

      const footerContent: any = document.getElementById("footer-content")
      const scrollY = window.scrollY
      const innerHeight = window.innerHeight
      const scrollHeight = document.documentElement.scrollHeight
      const offsetHeightEle = footerContent.offsetHeight

      if (
        footerContent &&
        scrollY + innerHeight >= scrollHeight - offsetHeightEle
      ) {
        setIsFixed(false)
      } else {
        setIsFixed(true)
      }
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    setIsShowBackToTop(scrollPosition > 200)
  }, [scrollPosition])

  const hanldeBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  const footerList = [
    {
      title: t("About MOAFRIENDS"),
      link: "https://moafriendshost.notion.site/12e73e992b4380f6bb10f25e23f1c413",
      target: "_blank",
    },
    {
      title: t("브랜드 관리 사이트"),
      link: "https://brand.moafriends.com/",
      target: "_blank",
    },
    {
      title: t("개인정보처리방침"),
      link: "/privacy-policy",
      target: "_blank",
    },
    {
      title: t("이용약관"),
      link: "/terms-of-use",
      target: "_blank",
    },
    {
      title: t("FAQ"),
      link: "/faq",
      target: "_self",
    },
  ]

  return (
    <>
      <div
        className={`bg-primaryWhite  ${
          isMobile && (isHomePage || isGoupBuyingDetail || isGoupBuyingOrder)
            ? "pb-[56px]"
            : ""
        }`}
        id="footer"
      >
        <div className="lg:h-[110px] h-[62px]">
          {isShowBackToTop && (
            <div
              className={
                isFixed ? "lg:fixed right-[362px] bottom-[195px]" : "container"
              }
            >
              <div className="pt-[26px] pb-[36px] flex justify-end">
                <button
                  className={`lg:w-[48px] lg:h-[48px] w-[48px] h-[48px] border-[1px] border-gray11 flex items-center justify-center rounded-[50%] px-[7px] ${
                    isFixed ? "lg:fixed" : "lg:static"
                  } fixed ${
                    isMobile &&
                    (isHomePage || isGoupBuyingDetail || isGoupBuyingOrder)
                      ? "bottom-[90px]"
                      : "sm:bottom-[20px] bottom-[71px]"
                  }  right-[16px] z-10 bg-primaryWhite`}
                  onClick={hanldeBackToTop}
                >
                  <IconBacktop />
                </button>
              </div>
            </div>
          )}
        </div>
        {isLoading && <Loading />}
        <div
          id="footer-content"
          className="flex flex-col border-t border-t-primary"
        >
          <div className="flex border-b-[1px] border-b-gray5 lg:py-[20px] py-[14px]">
            <div className="container">
              <div className="lg:flex">
                {footerList.map((item, index) => (
                  <Link
                    target={isMobile ? "_self" : item.target}
                    key={index}
                    to={item.link}
                    className={`inline-block lg:text-[14px] text-[11px] text-black2 lg:px-[16px] px-[12px] ${
                      index !== footerList.length - 1
                        ? "first:pl-0 relative before:absolute before:w-[1px] before:h-[14px] before:bg-gray6 before:top-[50%] before:translate-y-[-50%] before:right-0"
                        : ""
                    }`}
                  >
                    {item.title}
                  </Link>
                ))}
                <div className="lg:w-auto w-[100px] lg:mt-0 mt-[15px] lg:pl-[16px] lg:px-[12px] lg:relative lg:before:absolute lg:before:w-[1px] lg:before:h-[14px] lg:before:bg-gray6 lg:before:top-[50%] lg:before:translate-y-[-50%] lg:before:left-0">
                  <SelectLang />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray10 pt-[36px] pb-[76px]">
            <div className="container">
              <div className="flex justify-between md:flex-row flex-col">
                <div className="flex flex-col text-gray9 text-[11px]">
                  <p className="font-pretendard700 sm:mb-[16px] mb-[12px] sm:text-[12px] text-[11px]">
                    {t("(주) 모아프렌즈")}
                  </p>

                  <div className="flex flex-col gap-[8px]">
                    <div className="sm:text-[12px] text-[11px] text-[#737373]">
                      상호명: 주식회사 모아프렌즈{" "}
                      <span className="px-[8px]">|</span> 사업자등록번호:
                      625-87-02322
                      <span
                        className="underline pl-[8px] cursor-pointer"
                        onClick={() =>
                          window.open(
                            "https://www.ftc.go.kr/bizCommPop.do?wrkr_no=6258702322",
                          )
                        }
                      >
                        사업자정보확인
                      </span>
                    </div>
                    <div className="sm:text-[12px] text-[11px] text-[#737373]">
                      통신판매업 신고번호: 제2024-충남천안-0384호
                    </div>
                    <div className="sm:text-[12px] text-[11px] text-[#737373]">
                      주소: 충청남도 천안시 서북구 천안천4길 32 3층{" "}
                      <span className="px-[8px]">|</span>
                      대표이사/개인정보책임자: 김동욱
                    </div>
                    <div className="sm:text-[12px] text-[11px] text-[#737373]">
                      고객센터 0504-1400-4800 (평일 10시 30분~17시 30분,
                      점심시간 12시~1시)
                    </div>
                    <div className="sm:text-[12px] text-[11px] text-[#737373]">
                      제휴 및 문의:{" "}
                      <span className="underline">info@moafriends.com</span>
                    </div>
                    <div className="text-[#A3A3A3] sm:text-[12px] text-[11px]">
                      © 2023. MOAFRIENDS CO., Ltd All rights reserved
                    </div>
                  </div>

                  <div className="flex gap-[12px] mt-[18px]">
                    <img
                      src={KakaoImg}
                      alt="img"
                      className="cursor-pointer"
                      onClick={() =>
                        window.open("https://pf.kakao.com/_QxoLtb")
                      }
                    />
                    {/* <img src={FbImg} alt="img" className="cursor-pointer" /> */}
                    <img
                      src={InstaImg}
                      alt="img"
                      className="cursor-pointer"
                      onClick={() =>
                        window.open("https://www.instagram.com/moa.friends")
                      }
                    />
                    {/* <img src={BlogImg} alt="img" className="cursor-pointer" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
