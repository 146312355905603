import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getDataDetailShop } from "src/api/shopApi"
import useIsMobile from "src/customHook/useIsMobile"
import GroupBuy from "src/pages/Home/components/GroupBuy"
import CardBrandShop from "./CardBrandShop"
import { message } from "antd"

function BrandShopDetail() {
  const navigate = useNavigate()

  const { isMobile } = useIsMobile(474)

  const [voucherDetail, setVoucherDetail] = useState<any>({})
  const params = useParams()

  const getVoucherDetail = async () => {
    try {
      const res: any = await getDataDetailShop({
        id: Number(params.id),
      })
      if (res.result && !!res.data) {
        setVoucherDetail(res.data)
      } else {
        console.log("vao ", res)
        if (res?.error?.errorCode === "ONLY_MEMBERSHIP") {
          message.error("멤버십 전용 상품입니다")
        }
        if (res?.error?.errorCode === "HIDDEN_GB") {
          message.error("해당 상품은 현재 블라인드 되었습니다")
        }
        throw res
      }
    } catch (error) {}
  }

  useEffect(() => {
    getVoucherDetail()
  }, [])

  return (
    <div className={isMobile ? "mx-[-24px]" : ""}>
      {voucherDetail?.brandId && (
        <CardBrandShop
          data={voucherDetail}
          onComplete={() => {
            // getVoucherDetail()
            setVoucherDetail(undefined)
            voucherDetail?.progress === "INCOMING" &&
              navigate("/group-buying/up-coming")
          }}
        />
      )}
    </div>
  )
}

export default BrandShopDetail
