import { useDaumPostcodePopup } from "react-daum-postcode"
import CSInput from "../Input/Index"
import { Form } from "antd"
type Props = {
  label?: string
  name: string
  placeholder: string
  onCompleteAddress: (value: string) => void
  type?: "input" | "button"
  btnClassName?: string
  wrapClassName?: string
}
export const Postcode = ({
  label,
  name,
  placeholder,
  onCompleteAddress = () => {},
  type = "input",
  btnClassName,
  wrapClassName,
}: Props) => {
  const open = useDaumPostcodePopup()
  const handleComplete = (data: any) => {
    let fullAddress = data.address
    let extraAddress = ""

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : ""
    }
    onCompleteAddress(data)
    return fullAddress
  }

  const handleClick = () => {
    open({ onComplete: handleComplete })
  }
  if (type === "button") {
    return (
      <Form.Item name={name} className={wrapClassName}>
        <button className={btnClassName} onClick={handleClick} type="button">
          {label}
        </button>
      </Form.Item>
    )
  }
  return (
    <CSInput
      label={label}
      name={name}
      placeholder={placeholder}
      onClick={handleClick}
    ></CSInput>
  )
}
