import "swiper/css/pagination"
import "swiper/css/free-mode"
import { Swiper, SwiperSlide } from "swiper/react"
import BlockTitle from "src/components/BlockTitle"
import BrandItem from "src/components/BrandItem"
import { Pagination } from "swiper/modules"
import { useTranslation } from "react-i18next"
import "./style.css"
import { TBRAND } from "src/models"
import { useNavigate } from "react-router-dom"

type Props = {
  data?: TBRAND[]
  plusBtn?: boolean
  title: string
  titleIcon?: JSX.Element
  searchResultsNumber?: number
  type?: "default" | "viewAll"
  showSubText?: boolean
  hiddenViewAll?: boolean
}

function BrandCarouse({
  title,
  titleIcon,
  data,
  plusBtn,
  searchResultsNumber,
  type = "default",
  showSubText,
  hiddenViewAll,
}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // console.log('data', data);

  if (!data?.length) {
    return <></>
  }

  return (
    <div className="container" id="brand-slide">
      {/* My brand-------------------------------------------- */}
      <div>
        <div className="flex items-center justify-between mb-[25px] sm:mb-[27px]">
          <div className="flex items-center gap-[18px] relative z-10">
            {data && data?.length > 0 && (
              <BlockTitle title={`${title}`} icon={titleIcon} />
            )}
            <div className="w-[1px] h-[19px] bg-grayBg7" />
            {showSubText ? (
              <div className="text-[14px] xs:text-[18px] text-textColor22 font-pretendard500 cursor-pointer">
                Subscribe favorite brands!
              </div>
            ) : (
              !hiddenViewAll && (
                <div
                  onClick={() => navigate("/my-membership")}
                  className="text-[14px] xs:text-[18px] text-primary hover:underline hover:text-primary font-pretendard500 cursor-pointer"
                >
                  {t("VIEW_ALL")}
                </div>
              )
            )}
            {searchResultsNumber && (
              <p className="text-textColor10 text-[16px] font-pretendard500 leading-normal cursor-pointer">
                Showing all {searchResultsNumber} results
              </p>
            )}
          </div>
        </div>
        <Swiper
          className="mySwiper"
          spaceBetween={10}
          slidesPerView={2}
          slidesPerGroup={2}
          modules={type === "default" ? [Pagination] : undefined}
          pagination={{
            clickable: type === "default" ? true : false,
          }}
          breakpoints={{
            378: {
              spaceBetween: 20,
              slidesPerView: 4,
            },
            640: {
              spaceBetween: 20,
              slidesPerView: 5,
            },
            768: {
              spaceBetween: 30,
              slidesPerView: 6,
            },
            1024: {
              spaceBetween: 44,
              slidesPerView: 8,
            },
          }}
        >
          {data &&
            data.map((item: TBRAND, index: number) => (
              <SwiperSlide className="w-[74px] xs:w-[150px]" key={index}>
                <BrandItem {...item} plusBtn={plusBtn} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  )
}

export default BrandCarouse
