import ChatText from "@assets/images/brand_detail/ChatText.png"
import edit_square from "@assets/images/brand_detail/edit_square.png"
import MoreText from "@assets/images/brand_detail/more_vert_comment.png"
import { Spin } from "antd"
import { useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import TextareaAutosize from "react-textarea-autosize"
import {
  commentListFeed,
  createCommentFeed,
  deleteCommentFeed,
  editCommentFeedApi,
} from "src/api/communityApi"
import { useAppSelector } from "src/app/hooks"
import LikeFeed from "src/components/LikeFeed"
import ModalBase from "src/components/ModalBase"
import { useAuth } from "src/customHook/useAuth"
import { brandDataDetailSelect } from "src/features/brandData/brandDataSlice"
import { formatPrice } from "src/util/formatPrice"
import { handleDateNewPost } from "src/util/handleDate"

import new_release from "@assets/images/brand_detail/new_releases.png"
import shapeBt from "@assets/images/brand_detail/shadow-bt.png"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player"
import SelectFilterComment from "src/components/SelectFilterComment"
import VideoJS from "src/components/VideoJS"

interface Props {
  isVisible: boolean
  setCancel: () => void
  onClickReportCommentID: (data: any) => void
  data: any
}

function ModalDetailFeed({
  isVisible,
  setCancel,
  data,
  onClickReportCommentID,
}: Props) {
  const dataBrandDetail = useAppSelector(brandDataDetailSelect)
  const { isLogined, user } = useAuth()
  const [dataComment, setDataComment] = useState<any[]>([])
  const [content, setContent] = useState<string>()
  const [pageable, setPageable] = useState<any>({})
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const brandSiteRef: any = useRef<HTMLDivElement>(null)
  const [isModalReport, setIsModalReport] = useState({
    isOpen: false,
    id: null,
  })
  const [qtyComment, setQtyComment] = useState(0)

  const [editComment, setEditComment] = useState<any>({
    commentId: null,
    isEdit: false,
    content: null,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const commentID = searchParams.get("commentID")
  const postID = searchParams.get("postID")
  const widthBrandSite =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth

  const urlIncontentMetadata = data?.urlIncontentMetadata
    ? JSON.parse(data?.urlIncontentMetadata)
    : []

  const { i18n } = useTranslation()

  const optionsSortSelect = [
    {
      value: "latest",
      label: "Latest comment",
    },
    {
      value: "newest",
      label: "Oldest comment",
    },
  ]
  const [sortSelect, setSortSelect] = useState(optionsSortSelect[0].value)

  // useEffect(() => {
  //   if ((commentID || postID) && data && widthBrandSite >= 475) {
  //     if (!data?.content) {
  //       navigate({
  //         pathname: "/auth",
  //         search: `backUrl=${pathname}${search}`,
  //       })
  //     }
  //   }
  // }, [data, commentID, postID])

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden"
    }
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isVisible])

  console.log("data", data)

  const getDataList = async (
    isLoadmore?: boolean,
    page?: number,
    size?: number,
    sortFilter?: string,
  ) => {
    try {
      const respBrandPushCommentId = []
      if (data?.commentIdBrandSite) {
        const resp: any = await commentListFeed({
          page: page ?? 1,
          size: size ?? 12,
          feedId: data?.id,
          id: data?.commentIdBrandSite,
          sortFilter: sortFilter || sortSelect,
        })
        if (resp.result) {
          if (resp.data.datas.length > 0) {
            respBrandPushCommentId.push(resp.data.datas[0])
          }
        }
      }

      const resp: any = await commentListFeed({
        page: page ?? 1,
        size: size ?? 12,
        feedId: data?.id,
        excludeId: data?.commentIdBrandSite
          ? Number(data?.commentIdBrandSite)
          : null,
        sortFilter: sortFilter || sortSelect,
      })
      if (resp.result) {
        const dataResp = [...respBrandPushCommentId, ...resp.data.datas]

        setDataComment(dataResp)
        setPageable(resp.data.pageable)

        if (isLoadmore) {
          setDataComment([...dataComment, ...dataResp])
        }
      } else {
        setDataComment([])
        setPageable({})
      }
    } catch (error) {
      setDataComment([])
      setPageable({})
    }
  }

  useEffect(() => {
    if (isVisible) {
      setQtyComment(data?.cntComment)
      getDataList()
    }
  }, [isVisible])

  useEffect(() => {
    if (isVisible) {
      if (data?.commentIdBrandSite) {
        brandSiteRef.current?.focus()
      }
    }
  }, [isVisible, data])

  const handlePostComment = async () => {
    if (content) {
      try {
        const resp: any = await createCommentFeed({
          feedId: data?.id,
          content: content,
        })
        if (resp.result) {
          getDataList()
          setContent("")

          setQtyComment((prev) => prev + 1)
        }
      } catch (error) {}
    }
  }

  const handleLoadMore = () => {
    if (pageable?.hasNext) {
      setTimeout(() => {
        getDataList(true, pageable.page + 1)
      }, 1500)
    }
  }

  const handleDeleteComment = async (commentID: number) => {
    try {
      const resp: any = await deleteCommentFeed({
        commentId: commentID,
      })
      if (resp.result) {
        getDataList(false, 1, pageable.page * 12)
        // const dt = dataComment.filter((dt: any) => dt.commentId !== commentID)
        // setDataComment(dt)

        // setPageable({
        //   ...resp.data.pageable,
        //   totalElement:
        //     resp.data.pageable.totalElement - 1 > 0
        //       ? resp.data.pageable.totalElement - 1
        //       : 0,
        // })
      }
    } catch (error) {}
  }

  const handleSubmitEdit = async () => {
    if (editComment.content) {
      try {
        const resp: any = await editCommentFeedApi({
          commentId: editComment.commentId,
          content: editComment.content,
        })

        if (resp.result) {
          setEditComment({
            commentId: null,
            isEdit: false,
            content: null,
          })
          getDataList()
        }
      } catch (error) {}
    }
  }

  const renderActionPost = (
    isMinePost: boolean,
    isMineBrand: boolean,
    dt: any,
  ) => {
    if (isMinePost) {
      return (
        <>
          <div
            className="hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              setEditComment({
                ...editComment,
                isEdit: true,
                commentId: dt?.commentId,
                content: dt?.content,
              })
            }}
          >
            수정하기
          </div>
          <div
            className="mt-[5px] hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              handleDeleteComment(dt?.commentId)
            }}
          >
            삭제하기
          </div>
        </>
      )
    } else {
      if (!isMineBrand) {
        return (
          <div
            className={`hover:font-pretendard600 hover:underline text-[14px] ${
              dt?.reported && "text-[#C8C8C8] cursor-not-allowed"
            }`}
            onClick={() => {
              if (!dt?.reported) {
                onClickReportCommentID?.(dt)
              }
            }}
          >
            신고하기
          </div>
        )
      } else {
        return (
          <div
            className="mt-[5px] hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              handleDeleteComment(dt?.commentId)
            }}
          >
            삭제하기
          </div>
        )
      }
    }
  }

  return (
    <ModalBase
      isVisible={isVisible}
      setCancel={setCancel}
      className="sm:max-w-[740px] max-w-[100%]"
      classNameContent="py-[30px] px-[20px]"
    >
      <div className="flex flex-col">
        <div
          className="flex-1 overflow-y-auto mx-[-20px] px-[20px] max-h-[60vh]"
          id="scrollableDiv"
        >
          <div className="flex gap-[8px] items-center pb-[20px] border-0 border-b border-solid border-[#D9D9D9]">
            <img
              src={data?.brandThumbnail}
              // src={data?.thumbnail ?? data?.brandThumbnail}
              alt="icon"
              className="w-[48px] h-[48px] aspect-square rounded-[50%]"
            />
            <div>
              <div className="font-pretendard700 flex items-center min-h-[24px]">
                {data?.title}
              </div>
              <div className="pt-[8px] text-[12px] text-[#757575]">
                {handleDateNewPost(
                  data?.updatedAt ?? data?.createdAt,
                  i18n.language,
                )}
              </div>
            </div>
          </div>

          {data?.media && JSON.parse(data?.media)?.length > 0 && (
            <div className="flex flex-col gap-[24px] pb-[16px]">
              {JSON.parse(data?.media)?.map((item: any, index: number) => (
                <img
                  src={item?.url}
                  alt="img"
                  key={index}
                  className="max-w-[100%]"
                />
              ))}
            </div>
          )}

          <div className="pb-[16px] border-0 border-b border-solid border-[#D9D9D9]">
            {data?.video?.url && (
              <VideoJS
                url={data?.video?.url}
                thumbnail={data?.videoThumbnail}
              />
            )}
            <div
              className="pt-[20px] pb-[45px] content-html "
              dangerouslySetInnerHTML={{
                __html: data?.content,
              }}
            ></div>
            {urlIncontentMetadata.length > 0 && (
              <div className="border border-[#D4D4D4] mb-[40px]">
                <div className="py-[16px] px-[20px]">
                  <div className="text-[#767575] font-pretendard500 text-[12px]">
                    {urlIncontentMetadata[0]?.sitename}
                  </div>
                  <div className="text-[14px] font-pretendard600 line-clamp-1">
                    {urlIncontentMetadata[0]?.title}
                  </div>
                  <div className="line-clamp-2 text-[#767575] font-pretendard500 text-[12px] pt-[11px]">
                    {urlIncontentMetadata[0]?.description}
                  </div>
                </div>
                <ReactPlayer
                  url={urlIncontentMetadata[0]?.url}
                  style={{
                    maxWidth: "100%",
                    height: "100%",
                    maxHeight: "300px",
                  }}
                />
              </div>
            )}

            <div className="flex gap-[20px]">
              <LikeFeed
                classLabel="sm:text-[16px] text-[14px] !font-pretendard400"
                classIcon="sm:max-h-[16px] max-h-[13.75px]"
                count={data?.cntLike}
                active={data?.doLike}
                id={data?.id}
              />
              <div className="flex sm:items-center gap-[5px] cursor-pointer">
                <img
                  src={ChatText}
                  alt="ChatText"
                  className="sm:max-h-[26px] max-h-[21px]"
                />
                <div className="sm:text-[16px] text-[14px]">
                  {formatPrice(qtyComment)}
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-[140px] ml-auto mt-[8px] mb-[21px]">
            <SelectFilterComment
              options={optionsSortSelect}
              value={sortSelect}
              onChange={(e) => {
                setSortSelect(e)
                getDataList(false, undefined, undefined, e)
              }}
            />
          </div>

          <div>
            <InfiniteScroll
              dataLength={dataComment?.length}
              next={handleLoadMore}
              hasMore={pageable?.totalElement > dataComment?.length}
              loader={
                <div className="flex justify-center items-center py-[30px] overflow-hidden">
                  <Spin tip="Loading" size="small" />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              <div className="mt-[20px] flex flex-col gap-[20px]">
                {dataComment &&
                  dataComment?.map((dt: any, index) => (
                    <div className="flex gap-[8px]" key={index}>
                      <img
                        src={dt?.commentAvatar}
                        alt="icon"
                        className="w-[24px] h-[24px] aspect-square rounded-[50%]"
                      />
                      <div className="flex-1">
                        <div className="flex items-center justify-between">
                          <div className="font-pretendard700 flex items-center">
                            {dt?.creatorName}{" "}
                            {dt?.creatorIsTargetBrand && (
                              <img
                                src={new_release}
                                alt="logo"
                                className="inline-block pl-[8px]"
                              />
                            )}
                            {dt?.creatorIsMembershipOfTargetBrand && (
                              <div className="bg-[#FF6E6E] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600 ml-[8px]">
                                멤버십
                              </div>
                            )}
                            {index === 0 && (
                              <input
                                autoFocus={
                                  data?.commentIdBrandSite ? true : false
                                }
                                ref={brandSiteRef}
                                className="h-0 w-0"
                              />
                            )}
                          </div>
                          <div
                            className="cursor-pointer relative"
                            onClick={() =>
                              setIsModalReport({
                                isOpen: !isModalReport.isOpen,
                                id: dt?.commentId,
                              })
                            }
                          >
                            <img src={MoreText} alt="more text" />

                            {isModalReport.isOpen &&
                              isModalReport.id === dt?.commentId && (
                                <div className="absolute top-[0] right-[30px] shadow-custom3 bg-[#fff] w-[135px] p-[10px] text-left z-10">
                                  <img
                                    src={shapeBt}
                                    alt="icon"
                                    className="absolute right-[-17px] top-[2px] rotate-90 z-[-10]"
                                  />
                                  <div className="absolute right-[-1px] top-[7px] bg-[#fff] w-[12px] h-[12px]"></div>
                                  {renderActionPost(
                                    dt?.isMine,
                                    dataBrandDetail?.isMine,
                                    dt,
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="pt-[4px] pb-[20px] text-[12px] text-[#757575]">
                          {handleDateNewPost(
                            dt?.updatedAt ?? dt?.createdAt,
                            i18n.language,
                          )}
                        </div>
                        {editComment.isEdit &&
                        editComment.commentId === dt?.commentId ? (
                          <div className="flex pb-[16px]">
                            <TextareaAutosize
                              className="bg-[#F5F5F5] py-[7px] px-[20px] w-full focus:outline-none text-[14px] placeholder:text-[#A3A3A3] max-w-[100%] resize-none flex-1"
                              onChange={(e) => {
                                setEditComment({
                                  ...editComment,
                                  content: e.target.value,
                                })
                              }}
                              value={editComment.content}
                              maxLength={2000}
                            />
                            <div className="flex">
                              <div
                                className="px-[19px] text-[14px] font-pretendard500 text-[#8A8A8A] border-0 border-solid border-r border-r-[#E1E1E1] leading-[35px] cursor-pointer"
                                onClick={() =>
                                  setEditComment({
                                    isEdit: false,
                                    commentId: null,
                                    content: null,
                                  })
                                }
                              >
                                취소
                              </div>
                              <div
                                className="pl-[19px] text-[14px] font-pretendard500 leading-[35px] cursor-pointer"
                                onClick={handleSubmitEdit}
                              >
                                업로드
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="pb-[16px] whitespace-pre-line"
                            dangerouslySetInnerHTML={{
                              __html: dt?.content,
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>

        <div>
          <div
            className={`flex gap-[10px] items-center bg-[#fff] pt-[20px]`}
            // className={`flex gap-[10px] items-center bg-[#fff] pt-[20px] ${
            //   !isLogined && "pointer-events-none"
            // }`}
            onClick={() => {
              if (!isLogined) {
                navigate({
                  pathname: "/auth",
                  search: `backUrl=${pathname}${search}`,
                })
              }
            }}
          >
            <div className="flex-1">
              <TextareaAutosize
                className="bg-[#F5F5F5] py-[10.5px] px-[20px] w-full focus:outline-none text-[14px] placeholder:text-[#A3A3A3] max-w-[100%] resize-none "
                placeholder="댓글을 입력하세요"
                onChange={(e) => setContent(e.target.value)}
                value={content}
                maxLength={2000}
              />
            </div>
            <img
              src={edit_square}
              alt="edit_square"
              className="w-[28px] aspect-square h-[28px] cursor-pointer mb-[10px]"
              onClick={handlePostComment}
            />
          </div>

          <div className="text-right text-[#A3A3A3] text-[14px] mr-[38px]">
            {content?.length ?? 0}/2000
          </div>
        </div>
      </div>
    </ModalBase>
  )
}

export default ModalDetailFeed
