import { useEffect, useState } from "react"
import ModalBase from "src/components/ModalBase"

const dataReport = [
  {
    title: "욕설, 비방, 인신공격",
    value: 1,
  },
  {
    title: "중복 게시",
    value: 2,
  },
  {
    title: "선정적이거나 폭력적인 게시글 또는 프로필 사진",
    value: 3,
  },
  {
    title: "사기, 거짓, 홍보의 게시글 또는 프로필 사진",
    value: 4,
  },
  {
    title: "초상권, 저작권 침해의 게시글 또는 프로필 사진",
    value: 5,
  },
  {
    title: "개인정보 게시",
    value: 6,
  },
]

interface Props {
  setCancel: () => void
  isVisible: boolean
  onSubmit: (data: number) => void
}

function ModalReport({ isVisible, setCancel, onSubmit }: Props) {
  const [selected, setSelected] = useState(dataReport[0].value)

  useEffect(() => {
    return setSelected(dataReport[0].value)
  }, [isVisible])

  return (
    <ModalBase
      isVisible={isVisible}
      setCancel={setCancel}
      className="sm:max-w-[380px] max-w-[100%]"
      title="신고하기"
    >
      <div className="mt-[-24px] pb-[80px] px-[10px]">
        <div className="text-[18px] #000 font-pretendard700 pb-[10px]">
          신고 사유
        </div>
        <div className="text-[14px] #000">신고 사유를 선택해 주세요. </div>
        <div className="text-[14px] #000 pb-[9px]">
          신고가 적절하지 않을 경우, 커뮤니티 사용에 제재를 받을 수 있습니다.
        </div>

        {dataReport.map((item, index) => (
          <div
            className="flex items-center justify-between py-[14px] border-0 border-solid border-b border-b-[#E5E5E5] cursor-pointer"
            key={index}
            onClick={() => setSelected(item.value)}
          >
            <div
              className={`text-[14px] ${
                selected === item.value && "font-pretendard700"
              }`}
            >
              {item.title}
            </div>
            {selected === item.value ? (
              <div className="w-[15px] h-[15px] leading-[15px] border border-solid border-[#111] rounded-[50%] flex justify-center items-center cursor-pointer">
                <div className="bg-[#111] rounded-[50%] w-[7px] h-[7px]"></div>
              </div>
            ) : (
              <div className="w-[15px] h-[15px] leading-[15px] border border-solid border-[#A3A3A3] rounded-[50%] cursor-pointer"></div>
            )}
          </div>
        ))}
      </div>

      <div
        className="absolute bottom-0 w-full bg-[#000] text-[#fff] mx-[-16px] h-[48px] leading-[48px] text-center font-pretendard700 cursor-pointer"
        onClick={() => onSubmit(selected)}
      >
        신고하기
      </div>
    </ModalBase>
  )
}

export default ModalReport
