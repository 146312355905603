import heart_check from "@assets/images/brand_detail/heart_check.png"
import MoreText from "@assets/images/brand_detail/more_vert_comment.png"
import new_release from "@assets/images/brand_detail/new_releases.png"
import shapeBt from "@assets/images/brand_detail/shadow-bt.png"
import { Spin } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { useParams } from "react-router-dom"
import TextareaAutosize from "react-textarea-autosize"
import {
  commentListMyMembership,
  deleteComment,
  editCommentApi,
} from "src/api/communityApi"
import { useAppSelector } from "src/app/hooks"
import { brandDataDetailSelect } from "src/features/brandData/brandDataSlice"
import { handleDateNewPost } from "src/util/handleDate"

interface Props {
  onClickReportCommentID: (data: any) => void
  brandIdPush?: number | null
}

function ListComment({ onClickReportCommentID, brandIdPush }: Props) {
  const dataBrandDetail = useAppSelector(brandDataDetailSelect)
  const [editComment, setEditComment] = useState<any>({
    commentId: null,
    isEdit: false,
    content: null,
  })
  const { i18n } = useTranslation()

  const [isModalReportListComment, setIsModalReportListComment] = useState({
    isOpen: false,
    id: null,
  })
  const [pageable, setPageable] = useState<any>({})
  const { id } = useParams()
  const [dataComment, setDataComment] = useState<any[]>([])

  const getDataList = async (
    isLoadmore?: boolean,
    page?: number,
    size?: number,
  ) => {
    try {
      const resp: any = await commentListMyMembership({
        page: page ?? 1,
        size: size ?? 12,
        brandId: brandIdPush ?? null,
        subscriberId: brandIdPush ? null : id,
        // brandId: brandIdPush ?? Number(id),
      })
      if (resp.result) {
        const dataResp = resp.data.datas

        console.log("dataResp", dataResp)

        setDataComment(dataResp)
        setPageable(resp.data.pageable)

        if (isLoadmore) {
          setDataComment([...dataComment, ...dataResp])
        }
      } else {
        setDataComment([])
        setPageable({})
      }
    } catch (error) {
      setDataComment([])
      setPageable({})
    }
  }

  useEffect(() => {
    getDataList()
  }, [brandIdPush])

  const handleSubmitEdit = async () => {
    if (editComment.content) {
      try {
        const resp: any = await editCommentApi({
          commentId: editComment.commentId,
          content: editComment.content,
        })

        if (resp.result) {
          setEditComment({
            commentId: null,
            isEdit: false,
            content: null,
          })
          getDataList()
        }
      } catch (error) {}
    }
  }

  const handleDeleteComment = async (commentID: number) => {
    try {
      const resp: any = await deleteComment({
        commentId: commentID,
      })
      if (resp.result) {
        getDataList(false, 1, pageable.page * 12)
      }
    } catch (error) {}
  }

  const handleLoadMore = () => {
    setTimeout(() => {
      getDataList(true, pageable.page + 1)
    }, 1500)
  }

  const renderActionPost = (
    isMinePost: boolean,
    isMineBrand: boolean,
    dt: any,
  ) => {
    if (isMinePost) {
      return (
        <>
          <div
            className="hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              setEditComment({
                ...editComment,
                isEdit: true,
                commentId: dt?.commentId,
                content: dt?.content,
              })
            }}
          >
            수정하기
          </div>
          <div
            className="mt-[5px] hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              handleDeleteComment(dt?.commentId)
            }}
          >
            삭제하기
          </div>
        </>
      )
    } else {
      if (!isMineBrand) {
        return (
          <div
            className={`hover:font-pretendard600 hover:underline text-[14px] ${
              dt?.reported && "text-[#C8C8C8] cursor-not-allowed"
            }`}
            onClick={() => {
              if (!dt?.reported) {
                onClickReportCommentID?.(dt)
              }
            }}
          >
            신고하기
          </div>
        )
      } else {
        return (
          <div
            className="mt-[5px] hover:font-pretendard600 hover:underline text-[14px]"
            onClick={() => {
              handleDeleteComment(dt?.commentId)
            }}
          >
            삭제하기
          </div>
        )
      }
    }
  }

  return (
    <InfiniteScroll
      dataLength={dataComment?.length}
      next={handleLoadMore}
      hasMore={pageable?.totalElement > dataComment?.length}
      loader={
        <div className="flex justify-center items-center py-[30px] overflow-hidden">
          <Spin tip="Loading" size="small" />
        </div>
      }
      style={{ overflow: "unset" }}
    >
      <div className="flex flex-col">
        {dataComment &&
          dataComment?.map((dt: any, index: number) => (
            <div
              className={`flex gap-[20px] pb-[25px] mb-[25px] border-b border-b-[#E6E6E6]`}
              key={index}
            >
              <img
                src={dt?.targetBrandLogoImage}
                alt="icon"
                className="w-[40px] h-[40px] aspect-square border border-[#D9D9D9]"
              />
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <div className="font-pretendard700 flex items-center text-[14px]">
                    {dt?.creatorName}{" "}
                    {dt?.creatorIsTargetBrand && (
                      <img
                        src={new_release}
                        alt="logo"
                        className="inline-block pl-[8px]"
                      />
                    )}
                    {dt?.creatorIsMembershipOfTargetBrand && (
                      <div className="bg-[#FF6E6E] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600 ml-[8px]">
                        멤버십
                      </div>
                    )}
                  </div>
                  <div
                    className="cursor-pointer relative"
                    onClick={() =>
                      setIsModalReportListComment({
                        isOpen: !isModalReportListComment.isOpen,
                        id: dt?.commentId,
                      })
                    }
                  >
                    <img src={MoreText} alt="more text" />

                    {isModalReportListComment.isOpen &&
                      isModalReportListComment.id === dt?.commentId && (
                        <div className="absolute top-[0] right-[30px] shadow-custom3 bg-[#fff] w-[135px] p-[10px] text-left z-10">
                          <img
                            src={shapeBt}
                            alt="icon"
                            className="absolute right-[-17px] top-[2px] rotate-90 z-[-10]"
                          />
                          <div className="absolute right-[-1px] top-[7px] bg-[#fff] w-[12px] h-[12px]"></div>
                          <div
                            className="hover:font-pretendard600 hover:underline text-[14px]"
                            onClick={() => {
                              setEditComment({
                                ...editComment,
                                isEdit: true,
                                commentId: dt?.commentId,
                                content: dt?.content,
                              })
                            }}
                          >
                            수정하기
                          </div>
                          <div
                            className="mt-[5px] hover:font-pretendard600 hover:underline text-[14px]"
                            onClick={() => {
                              handleDeleteComment(dt?.commentId)
                            }}
                          >
                            삭제하기
                          </div>
                          {/* {renderActionPost(
                          dt?.isMine,
                          dataBrandDetail?.isMine,
                          dt,
                        )} */}
                        </div>
                      )}
                  </div>
                </div>
                <div className="pt-[4px] pb-[20px] text-[12px] text-[#757575]">
                  {handleDateNewPost(
                    dt?.updatedAt ?? dt?.createdAt,
                    i18n.language,
                  )}
                </div>
                {editComment.isEdit &&
                editComment.commentId === dt?.commentId ? (
                  <div className="flex pb-[16px]">
                    <TextareaAutosize
                      className="bg-[#F5F5F5] py-[7px] px-[20px] w-full focus:outline-none text-[14px] placeholder:text-[#A3A3A3] max-w-[100%] resize-none flex-1"
                      onChange={(e) => {
                        setEditComment({
                          ...editComment,
                          content: e.target.value,
                        })
                      }}
                      value={editComment.content}
                      maxLength={2000}
                    />
                    <div className="flex">
                      <div
                        className="px-[19px] text-[14px] font-pretendard500 text-[#8A8A8A] border-0 border-solid border-r border-r-[#E1E1E1] leading-[35px] cursor-pointer"
                        onClick={() =>
                          setEditComment({
                            isEdit: false,
                            commentId: null,
                            content: null,
                          })
                        }
                      >
                        취소
                      </div>
                      <div
                        className="pl-[19px] text-[14px] font-pretendard500 leading-[35px] cursor-pointer"
                        onClick={handleSubmitEdit}
                      >
                        업로드
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className=" whitespace-pre-line text-[14px]"
                    dangerouslySetInnerHTML={{
                      __html: dt?.content,
                    }}
                  ></div>
                )}
              </div>
            </div>
          ))}
      </div>
    </InfiniteScroll>
  )
}

export default ListComment
