import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "src/app/store"
import { User } from "src/models"

export interface UserState {
  loading: boolean
  currentUser: User | null
  isCreateSuccess: boolean
}

const initialState: UserState = {
  loading: false,
  currentUser: null,
  isCreateSuccess: false,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state) => {
      state.loading = true
    },
    loginSuccess: (state, action) => {
      state.loading = false
      state.currentUser = action.payload
    },
    loginFailed: (state) => {
      state.loading = false
      state.currentUser = null
    },
    logout: (state) => {
      state.loading = false
      state.currentUser = null
    },
    updateAvatar: (state: UserState, action) => {
      if (state.currentUser) {
        state.currentUser.avatar = action.payload
      }
    },
    openCreateAccountSuccess: (state: UserState, action) => {
      state.isCreateSuccess = action.payload
    },
  },
})

export const {
  login,
  loginSuccess,
  loginFailed,
  logout,
  updateAvatar,
  openCreateAccountSuccess,
} = userSlice.actions

export const loginSelect = (state: RootState) => state.user
export const tokenSelect = (state: RootState) =>
  state.user.currentUser?.accessToken
export const isCreateSuccessSelect = (state: RootState) =>
  state.user.isCreateSuccess

export default userSlice.reducer
