import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const getListEContent = async (params: {
  page?: number
  size?: number
  brandId?: number
  querySearch?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.LIST_E_CONTENT,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const getDetailEContent = async (params: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.DETAIL_E_CONTENT,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const deleteEContent = async (data: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.DELETE_E_CONTENT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const readEContent = async (data: {
  id?: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.READ_E_CONTENT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}
