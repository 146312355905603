import upload from "@assets/images/brand_detail/upload.png"
import { Spin, Upload, UploadFile, UploadProps } from "antd"
import ModalBase from "src/components/ModalBase"

import { useEffect, useState } from "react"
import CheckboxPost from "src/components/CheckboxPost"
import "./style.scss"

import close from "@assets/images/brand_detail/close.png"
import { useAuth } from "src/customHook/useAuth"
import { useLocation, useParams } from "react-router-dom"
import { useAppSelector } from "src/app/hooks"
import { brandDataDetailSelect } from "src/features/brandData/brandDataSlice"
import { checkNormalMembership } from "src/api/brandApi"

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

interface Props {
  isVisible: boolean
  setCancel: () => void
  onSubmit: (data: any) => void
  isLoading?: boolean
  dataEdit?: any
}
function ModalPost({
  isVisible,
  setCancel,
  onSubmit,
  isLoading = false,
  dataEdit,
}: Props) {
  const [count, setCount] = useState(0)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [previewImage, setPreviewImage] = useState<object[]>([])

  const [content, setContent] = useState<string | undefined>()
  const [onlyMembership, setOnlyMembership] = useState<boolean>(false)
  const { user }: any = useAuth()
  const { state } = useLocation()
  const dataBrandDetail = useAppSelector(brandDataDetailSelect)
  const params = useParams()
  const { brandName } = params
  const [dataCheckNormalMembership, setDataCheckNormalMembership] =
    useState(false)

  useEffect(() => {
    if (isVisible) {
      ;(async function getDataCheckNormalMembership() {
        const { data } = await checkNormalMembership({
          brandName: brandName,
        })
        if (data) {
          setDataCheckNormalMembership(data)
        }
      })()
    }
  }, [isVisible])

  useEffect(() => {
    if (dataEdit) {
      setContent(dataEdit?.content)
      setOnlyMembership(dataEdit?.onlyMembership)

      const dtMedias = dataEdit?.media && JSON.parse(dataEdit.media)

      const dtFileList: any = []
      if (dtMedias?.length > 0) {
        for (let i = 0; i < dtMedias.length; i++) {
          dtFileList.push({
            idPreview: Date.now(),
            src: dtMedias[i].url,
          })
        }
      }

      setFileList(dtFileList)
      setPreviewImage(dtFileList)
    }
  }, [dataEdit])

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    return file.url || (file.preview as string)
  }

  const onChangeAntd: UploadProps["onChange"] = async ({
    fileList: newFileList,
  }) => {
    if (previewImage.length < 10) {
      const dtPreviewImage: any = previewImage.concat(newFileList).slice(0, 10)
      const dtFileList: any = fileList.concat(newFileList).slice(0, 10)

      const dataPreview = []
      for (let i = 0; i < dtPreviewImage.length; i++) {
        if (dtPreviewImage[i].idPreview) {
          dataPreview.push(dtPreviewImage[i])
        } else {
          const itemPreview = await handlePreview(dtPreviewImage[i])
          dataPreview.push({
            idPreview: Date.now(),
            src: itemPreview,
          })
        }
      }

      setFileList(dtFileList)
      setPreviewImage(dataPreview)
    }
  }

  const handleSubmit = async () => {
    try {
      if (content || fileList.length > 0) {
        await onSubmit({
          content: content ?? " ",
          medias: fileList,
          onlyMembership,
          isEdit: dataEdit?.postId ? true : false,
          postId: dataEdit?.postId,
        })

        setFileList([])
        setPreviewImage([])

        setContent(undefined)
        setCount(0)
      }
    } catch (error) {}
  }

  return (
    <ModalBase
      isVisible={isVisible}
      setCancel={setCancel}
      className="sm:max-w-[740px] max-w-[100%]"
      classNameContent="px-[20px] pt-[20px] pb-[40px]"
    >
      <Spin spinning={isLoading}>
        <div className="pb-[24px] text-[20px] font-pretendard700 border-0 border-b border-solid border-b-[#BDBDBD]">
          포스트 작성
        </div>
        <textarea
          rows={5}
          className="w-full mt-[24px] border-0 border-b border-solid border-b-[#BDBDBD] focus:outline-none h-[269px] placeholder:text-[#A3A3A3]"
          onChange={(e) => {
            setCount(e.target.value.length)
            setContent(e.target.value)
          }}
          maxLength={2000}
          style={{ resize: "none" }}
          placeholder="자유롭게 포스트를 남겨보세요.
상대방을 불쾌하게 하는 욕설이나 비하 표현은 삼가주세요."
          value={content}
        />
        <div className="text-right mt-[8px] mb-[24px] text-[#A3A3A3] text-[14px]">
          {count}/2000
        </div>

        <div className="flex justify-between items-center">
          <div className="flex max-w-[380px] gap-[8px] flex-wrap">
            {previewImage?.length > 0 &&
              previewImage?.map((dt: any, index) => (
                <div
                  className="relative"
                  onClick={() => {
                    const dtPreview = [...previewImage]
                    const result = dtPreview.splice(index, 1)
                    setPreviewImage(dtPreview)

                    const dtFileList = [...fileList]
                    const resultFileList = dtFileList.splice(index, 1)
                    setFileList(dtFileList)
                  }}
                  key={index}
                >
                  <div className="absolute right-[5px] top-[5px] rounded-[50%] bg-[#fff] p-[4px] cursor-pointer">
                    <img src={close} alt="close" />
                  </div>
                  <img
                    src={dt.src}
                    alt="img upload"
                    className="w-[68px] h-[68px] aspect-square object-cover"
                  />
                </div>
              ))}
            {previewImage.length < 10 && (
              <div
                className={`flex flex-col justify-between ${
                  fileList.length > 0 && "w-[68px] h-[68px]"
                }`}
              >
                {previewImage.length > 0 && (
                  <div className="text-[#A3A3A3] text-[13px]">
                    ({previewImage.length}/10)
                  </div>
                )}
                <div className="cursor-pointer max-h-[30px]">
                  <Upload
                    fileList={[]}
                    customRequest={dummyRequest}
                    onChange={onChangeAntd}
                    multiple
                    maxCount={10}
                    showUploadList={false}
                    accept="image/*"
                  >
                    <img src={upload} alt="icon" />
                  </Upload>
                </div>
              </div>
            )}
          </div>
          <div className="flex gap-[16px] items-center">
            <div>
              {dataCheckNormalMembership && (
                <CheckboxPost
                  label="멤버십 전용"
                  onChangeValue={(e) => setOnlyMembership(e)}
                />
              )}
            </div>
            <div
              className="font-pretendard700 text-[#fff] h-[44px] w-[99px] leading-[44px] text-center bg-[#000] cursor-pointer"
              onClick={handleSubmit}
            >
              작성하기
            </div>
          </div>
        </div>
      </Spin>
    </ModalBase>
  )
}

export default ModalPost
