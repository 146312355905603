// import '../style.scss'

import useWindowSize from "src/util/hook/useWindowSize"
import BackToTop from "../BackToTop"
import { useEffect, useState } from "react"
import { isScrolledToBottom } from "src/util/helpersFuc"

interface FooterProps {}
const FooterKr: React.FC<FooterProps> = () => {
  const { width }: any = useWindowSize()
  const [isBottom, setIsBottom] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      if (width <= 1280) {
        if (isScrolledToBottom()) {
          setIsBottom(true)
        } else {
          setIsBottom(false)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [width])

  return (
    <div className="relative">
      <div className="bg-[#F4F4F4] md:py-[40px] md:px-[64px] px-[20px] pt-[21px] pb-[28px] flex md:flex-row flex-col md:gap-[50px] gap-[16px] justify-evenly">
        <div>
          <h4 className="text-[15px] font-spoqa400 font-[400] leading-[30px] tracking-[-0.525px] uppercase text-[#333]">
            COMPANY
          </h4>
          <p className="text-[14px] font-spoqa500 leading-[22px] tracking-[-0.49px] uppercase text-[#333]">
            주식회사 모아프렌즈
            <br />
            사업자 등록번호 : 625-87-02322
            <br />
            통신판매업 신고번호: 2022-충남아산-0502
            <br />
            대표이사/개인정보관리자: 김동욱
            <br />
            충청남도 아산시 배방읍 희망로46번길 45-11, 4층 406호{" "}
            {width < 540 && <br />}(충남콘텐츠기업지원센터)
          </p>
        </div>

        <div>
          <h4 className="text-[15px] font-[400] font-spoqa400 leading-[30px] tracking-[-0.525px] uppercase text-[#333]">
            Contact
          </h4>
          <p className="text-[14px] font-spoqa500 leading-[22px] tracking-[-0.49px] text-[#333]">
            고객문의: cs.members@moa-friends.com
            <br />
            +82-10-6686-1133
          </p>
          <br className="md:block hidden" />
          <p className="text-[15px] font-spoqa400 leading-[30px] tracking-[-0.525px] text-[#333]">
            ⓒ 2023. MOAFRIENDS CO., Ltd All rights reserved
          </p>
        </div>
      </div>
      <div
        className={`${
          isBottom
            ? "absolute right-[15px] top-[-20px]"
            : "fixed bottom-[50px] right-[30px]"
        }`}
      >
        <BackToTop />
      </div>
    </div>
  )
}

export default FooterKr
