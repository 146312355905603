import { useEffect, useState } from "react"
import { customerApi } from "src/api/customerApi"
export type FAQ_TYPE = {
  id: string | number
  createdTime: string
  deletedTime: string
  name: string
  status: string
  updatedTime: string
}
export const useFaqType = () => {
  const [faqType, setFaqType] = useState<FAQ_TYPE[]>([])
  const getFaqTypes = async () => {
    try {
      const res: any = await customerApi.getFAQTypes()
      const data = res.data
      const result = res.result
      if (result) {
        setFaqType(data)
      } else {
        throw res
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getFaqTypes()
  }, [])
  return faqType
}
