import { useAppDispatch, useAppSelector } from "src/app/hooks"
import Button from "src/components/Button"
import InfoIcon from "@assets/icons/InfoIcon.svg"

import CommonModal from "src/components/Modal"
import {
  completeTransaction,
  statusSelect,
} from "src/features/confirmTransaction/confirmTransactionSlice"
import { MoneyConvert } from "src/util/format"

function TransactionExpired() {
  const status = useAppSelector(statusSelect)
  // console.log("status", status)

  // const dataBrand: any = data

  const dispatch = useAppDispatch()
  const handleClose = () => {
    dispatch(completeTransaction())
  }
  return (
    <CommonModal
      isOpen={status === "EXPIRED"}
      // isOpen={true}
      handleClose={handleClose}
      imageTop={<InfoIcon />}
    >
      <p className="font-pretendard700 text-[20px] text-primary mt-[-8px] mb-[8px] leading-[30px]">
        Transaction Expired
      </p>
      <p className="text-gray1 text-[14px] leading-[20px]">
        The charging request has expired. Please initiate a new transaction if
        needed.
      </p>
      <div className="mt-[24px]">
        <Button
          theme="main"
          onClick={() => {
            handleClose()
          }}
        >
          I understand
        </Button>
      </div>
    </CommonModal>
  )
}

export default TransactionExpired
