import bgTop from "@assets/images/background_top.png"
import notLoginImg from "@assets/images/not-login.png"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import Button from "src/components/Button"

const NotLogin: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className="pt-[140px] relative">
      <div className="h-[470px] absolute flex justify-center w-full top-0 bg-primary ">
        <div className="right-0 bottom-0 h-full -z-1">
          <img
            src={bgTop}
            alt="background-top"
            className="object-cover brightness-150 h-full"
          />
        </div>
      </div>
      <div className="relative z-10 pb-[100px]">
        <div className="container">
          <div className="flex flex-col items-center px-[45px] py-[30px] bg-lightColor mb-[10px] rounded-[26px] pt-[100px] pb-[137px]">
            <img src={notLoginImg} alt="" />
            <div className="text-[20px] font-pretendard500 text-textColor11 flex my-[25px]">
              You have not logged in yet, you need to
              <Link to={"/auth"}>
                <p className="text-primary text-[20px] font-pretendard500 mx-[5px] hover:underline hover:text-textColor">
                  login
                </p>
              </Link>
              to use this
            </div>
            <Button
              className="!p-0 w-[160px] h-[40px] !bg-bgBtn1"
              onClick={() => {
                navigate("/auth")
              }}
            >
              {t("LOGIN")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotLogin
