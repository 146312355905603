import { useTranslation } from "react-i18next"
import CommonModal from "src/components/Modal"

interface Props {
  isOpen?: boolean
  handleClose?: () => void
}

function MaximumPurchaseLimit({ isOpen, handleClose }: Props) {
  const { t } = useTranslation()

  return (
    <div>
      <CommonModal isOpen={isOpen} handleClose={handleClose}>
        <p className="font-pretendard500 text-[22px] text-primary leading-normal text-center">
          You have reached the maximum purchase limit.
        </p>
      </CommonModal>
    </div>
  )
}

export default MaximumPurchaseLimit
