import { useCallback, useEffect, useState } from "react"
type TIME_OBJ = {
    timeRequest: Date
    countTime: number
}
export const useCountTime = (num: number, key?: string) => {
    const [countTime, setCountTime] = useState<number>(num)
    const handleSetCountTime = useCallback((num: number) => {
        setCountTime(num)
        const emailSendObj: TIME_OBJ = {
            timeRequest: new Date(),
            countTime,
        }
        if (key) {
            localStorage.setItem(key, JSON.stringify(emailSendObj))
        }
    }, [countTime, key])
    useEffect(() => {
        const timeObj: TIME_OBJ = key ? JSON.parse(
            localStorage.getItem(key) as string,
        ) : null
        if (timeObj) {
            const seconds =
                (new Date().getTime() - new Date(timeObj.timeRequest).getTime()) / 1000
            if (seconds >= timeObj.countTime) {
                setCountTime(0)
                if (key) {
                    localStorage.removeItem(key)
                }
            } else {
                handleSetCountTime(Math.floor(timeObj.countTime - seconds))
            }
        }
    }, [key])
    useEffect(() => {
        const interval = setInterval(() => {
            if (countTime !== 0) {
                handleSetCountTime(countTime - 1)
    }
        }, 1000)
return () => clearInterval(interval)
    }, [countTime])

return { countTime, handleSetCountTime }
}