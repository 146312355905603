import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import CommonModal from ".."
type Props = {}

function ErrorPayment({}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const urlParams = new URLSearchParams(window.location.search)
  const [searchParams, setSearchParams] = useSearchParams()
  const errorCode = searchParams.get("errorCode")

  const handleClose = () => {
    // navigate(`/my-page/order-detail/${id}?type=order`)
    searchParams.delete("errorCode")
    setSearchParams(searchParams)
  }

  return (
    <CommonModal
      isOpen={errorCode === "FAILURE"}
      // isOpen={true}
      handleClose={handleClose}
    >
      <div className="w-full h-full bg-primaryWhite">
        <div className="text-primary text-[18px] font-pretendard600 leading-normal text-center">
          결제가 실패했습니다. <br />
          확인이 필요합니다.
        </div>
      </div>
    </CommonModal>
  )
}

export default ErrorPayment
