import ChatText from "@assets/images/brand_detail/ChatText.png"
import heart_check from "@assets/images/brand_detail/heart_check.png"
import more_vert from "@assets/images/brand_detail/more_vert.png"
import shapeBt from "@assets/images/brand_detail/shadow-bt.png"
import shape from "@assets/images/brand_detail/shape.png"
import new_release from "@assets/images/brand_detail/new_releases.png"
import lock from "@assets/images/brand_detail/lock.png"

import { useRef, useState } from "react"
import LikeFeed from "src/components/LikeFeed"
import GalleryImage from "./GalleryImage"
import { BottomSheet } from "react-spring-bottom-sheet"
import useWindowSize from "src/customHook/useWindowSize"
import { useNavigate, useParams } from "react-router-dom"
import { UrlInternal } from "src/constant/endpointRoute"
import { handleDateNewPost } from "src/util/handleDate"
import { formatPrice } from "src/util/formatPrice"
import { useAppSelector } from "src/app/hooks"
import { useAuth } from "src/customHook/useAuth"
import { brandDataDetailSelect } from "src/features/brandData/brandDataSlice"
import ShowMoreText from "react-show-more-text"
import { useTranslation } from "react-i18next"
import { useOutsideAlerter } from "src/util/hook/useOutSide"

interface Props {
  setIsModalReport: (data: any) => void
  setIsModalComment: (data: any) => void
  data: any
  onSubmitDeletePost: (id: number) => void
}

function Post({
  setIsModalReport,
  setIsModalComment,
  data,
  onSubmitDeletePost,
}: Props) {
  const [isModal, setIsModal] = useState(false)
  const [isModalReportMobile, setIsModalReportMobile] = useState<boolean>(false)
  const { width }: any = useWindowSize()
  const isMobile = width <= 475
  const navigate = useNavigate()
  const dataBrandDetail = useAppSelector(brandDataDetailSelect)
  const { isLogined } = useAuth()
  const { t, i18n } = useTranslation()
  const { brandName } = useParams()
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => setIsModal(false))
  const dataMedia = data?.media ? JSON.parse(data?.media) : []

  return (
    <>
      {/**report mobile*/}
      {isModalReportMobile && (
        <div
          className="bg-bottomSheetOverlay fixed w-[100vw] h-[100vh] left-0 top-0 z-10"
          onClick={() => setIsModalReportMobile(false)}
        ></div>
      )}
      <BottomSheet
        open={isModalReportMobile}
        onDismiss={() => setIsModalReportMobile(false)}
        snapPoints={({ minHeight }) => minHeight}
        className="bottom-sheet"
        blocking={false}
      >
        {data?.isMine || dataBrandDetail?.isMine ? (
          <div
            className="pt-[15px] pb-[31px] px-[24px] text-[14px]"
            onClick={() => {
              onSubmitDeletePost?.(data?.postId)
              setIsModalReportMobile(false)
            }}
          >
            삭제하기
          </div>
        ) : (
          <div
            className={`pt-[15px] pb-[31px] px-[24px] text-[14px] ${
              data?.reported && "text-[#C8C8C8] cursor-not-allowed"
            }`}
            onClick={() => {
              if (!data?.reported) {
                navigate(
                  `/${brandName}/${UrlInternal.BRAND_DETAIL_COMMUNITY_REPORT}`,
                  {
                    state: data,
                  },
                )
              }
            }}
          >
            신고하기
          </div>
        )}
      </BottomSheet>

      <div className="flex sm:gap-[32px] gap-[19.5px]">
        <img
          src={data?.postAvatar}
          alt="logo"
          className="sm:w-[45px] sm:h-[45px] w-[40px] h-[40px] aspect-square rounded-[50%] flex-1"
        />
        <div className="bg-[#F5F5F5] sm:p-[24px] py-[16px] px-[20px] relative w-full">
          <img
            src={shape}
            alt="shape"
            className="absolute left-[-12px] top-[16px]"
          />
          <div className="font-pretendard700 flex items-center">
            {data?.creatorName}{" "}
            {data?.creatorBrandId && (
              <img
                src={new_release}
                alt="logo"
                className="inline-block pl-[8px]"
              />
            )}
            {isLogined && data?.creatorIsMembershipOfTargetBrand && (
              <div className="bg-[#FF6E6E] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600 ml-[8px]">
                멤버십
              </div>
            )}
          </div>
          <div className="sm:pt-[10px] pt-[7px]  text-[#757575] text-[12px]">
            {handleDateNewPost(
              data?.updatedAt ?? data?.createdAt,
              i18n.language,
            )}
          </div>
          {dataMedia.length > 0 && (
            <div className="sm:pt-[20px] sm:pb-[15px] pb-[20px] pt-[10px]">
              <GalleryImage data={dataMedia} />
            </div>
          )}
          <div className="sm:pb-[20px] pb-[22px] border-0 border-b border-solid border-[#BDBDBD] pt-[5px]">
            {data?.onlyMemberShip && !data?.content ? (
              <div className="flex items-center">
                <img src={lock} alt="lock" className="inline-block pr-[5px]" />
                멤버십 전용 콘텐츠입니다.
              </div>
            ) : (
              <>
                {data?.onlyMemberShip && (
                  <div className="bg-[#FF6E6E] mr-[5px] text-center w-[35px] h-[15px] inline-block text-[#fff] text-[11px] font-pretendard600">
                    멤버십
                  </div>
                )}
                <ShowMoreText
                  lines={
                    data?.media && JSON.parse(data?.media).length > 0 ? 5 : 10
                  }
                  more={
                    <span style={{ color: "#757575" }}>{t("더 보기")}</span>
                  }
                  className="text-[16px] break-words pointer-events-none"
                  onClick={() => {}}
                  expanded={false}
                  truncatedEndingComponent={" ..."}
                  keepNewLines
                >
                  {data?.content}
                </ShowMoreText>
              </>
            )}
          </div>
          <div className="flex justify-between sm:mt-[20px] mt-[22px]">
            <div className="flex gap-[20px]">
              <LikeFeed
                classLabel="sm:text-[16px] text-[14px] !font-pretendard400"
                classIcon="sm:max-h-[16px] max-h-[13.75px]"
                count={data?.cntLike}
                active={data?.doLike}
                id={data?.postId}
                isCommunity
              />
              <div
                className="flex sm:items-center gap-[5px] cursor-pointer"
                onClick={() => {
                  if (data?.content) {
                    setIsModalComment(data)
                  }
                }}
              >
                <img
                  src={ChatText}
                  alt="ChatText"
                  className="sm:max-h-[26px] max-h-[21px]"
                />
                <div className="sm:text-[16px] text-[14px]">
                  {formatPrice(Number(data?.cntComment))}
                </div>
              </div>
            </div>
            <div className="cursor-pointer relative">
              <div
                className="w-[24px] h-[24px] flex justify-center items-center"
                onClick={() => {
                  if (data?.content && isLogined) {
                    isMobile
                      ? setIsModalReportMobile(true)
                      : setIsModal((prev) => !prev)
                  }
                }}
              >
                <img src={more_vert} alt="icon" />
              </div>

              {isModal && (
                <div
                  className="absolute top-[160%] right-[-6px] shadow-custom3 bg-[#fff] w-[135px] p-[20px] text-left z-[100]"
                  ref={wrapperRef}
                >
                  <img
                    src={shapeBt}
                    alt="icon"
                    className="absolute right-[7px] top-[-16px]"
                  />
                  <div className="absolute right-[12px] top-[-1px] bg-[#fff] w-[12px] h-[12px]"></div>
                  {data?.isMine || dataBrandDetail?.isMine ? (
                    <div
                      className="hover:font-pretendard600 hover:underline"
                      onClick={() => {
                        onSubmitDeletePost(data?.postId)
                        setIsModal(false)
                      }}
                    >
                      삭제하기
                    </div>
                  ) : (
                    <div
                      className={`hover:font-pretendard600 hover:underline ${
                        data?.reported && "text-[#C8C8C8] cursor-not-allowed"
                      }`}
                      onClick={() => {
                        if (!data?.reported) {
                          setIsModalReport(data)
                          setIsModal(false)
                        }
                      }}
                    >
                      신고하기
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Post
