import ArrowDownIcon from "@assets/icons/DownArrow13.svg"
import ArrowTopIcon from "@assets/icons/TopArrow13.svg"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useIsMobile from "src/customHook/useIsMobile"
import reactLocalStorage from "src/util/localStorage"
import "./style.scss"
import { useSearchParams } from "react-router-dom"
import moment from "moment"

type Props = {}

interface LanguagesType {
  id: string
  name: string
}

const languages: LanguagesType[] = [
  {
    id: "kor",
    name: "Korean",
  },
  {
    id: "en",
    name: "English",
  },
]

function SelectLang({}: Props) {
  const { t, i18n } = useTranslation()
  const { isMobile } = useIsMobile(1023)

  const [activeLang, setActiveLang] = useState<LanguagesType>({
    id: "kor",
    name: "Korean",
  })
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false)
  const langsRender = languages.filter((item: any) => item.id !== activeLang.id)
  const langLocalId = reactLocalStorage.get("language")
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const langLocal = searchParams.get("lang") ?? langLocalId

    if (langLocal) {
      const objLang: any = languages.find((item) => item.id === langLocal)
      setActiveLang(objLang)
    }
  }, [langLocalId])

  const handleChangeLangMobile = () => {
    setIsOpenPopup(!isOpenPopup)

    if (activeLang.id === "kor") {
      i18n.changeLanguage("en")
      reactLocalStorage.set("language", "en")
      setActiveLang({
        id: "en",
        name: "English",
      })
    } else {
      i18n.changeLanguage("kor")
      reactLocalStorage.set("language", "kor")
      setActiveLang({
        id: "kor",
        name: "Korean",
      })
    }
  }

  useEffect(() => {
    i18n.changeLanguage(activeLang.id)
  }, [activeLang])

  return (
    <div className="relative">
      <div
        className="flex items-center gap-2 cursor-pointer lang-select"
        onClick={() => setIsOpenPopup(!isOpenPopup)}
      >
        {/* <img src={activeLang.img} alt="" className="w-[24px] h-4" /> */}
        <span className="lg:text-[14px] text-[11px] text-black2 tracking-[-0.525px]">
          {activeLang.name}
        </span>
        {isOpenPopup ? <ArrowTopIcon /> : <ArrowDownIcon />}
      </div>
      {isOpenPopup && (
        <div className="absolute top-[calc(100% + 5px)] left-0 right-0 width-full px-[6px] py-[8px] bg-white">
          {langsRender.map((lang: LanguagesType) => (
            <div
              key={lang.id}
              onClick={() => {
                setActiveLang(lang)
                reactLocalStorage.set("language", lang.id)
                setIsOpenPopup(false)

                moment.locale("en", {
                  relativeTime: {
                    // future: lang.id === "kor" ? "방금전" : "just now",
                    // future: "in %s",
                    past: lang.id === "kor" ? "%s 전" : "%s ago",
                    s: lang.id === "kor" ? "초" : "seconds",
                    ss: lang.id === "kor" ? "%s초" : "%ss",
                    m: lang.id === "kor" ? "1분" : "a minute",
                    mm: lang.id === "kor" ? "%d분" : "%dm",
                    h: lang.id === "kor" ? "1시간" : "1h",
                    hh: lang.id === "kor" ? "%d시간" : "%dh",
                    d: lang.id === "kor" ? "1일" : "a day",
                    dd: lang.id === "kor" ? "%d일" : "%dd",
                    M: "a month",
                    MM: "%dM",
                    y: "a year",
                    yy: "%dY",
                  },
                })

                if (searchParams.has("lang")) {
                  searchParams.delete("lang")
                  setSearchParams(searchParams)
                }
              }}
              className="flex items-center gap-[5px] cursor-pointer"
            >
              {/* <img
                  style={{ width: "24px", height: "16px" }}
                  src={lang.img}
                  alt={lang.name}
                /> */}
              <span className="lg:text-[14px] text-[11px] font-pretendard500 text-primary leading-[21px]">
                {lang.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SelectLang
