import React, { useEffect, useRef, useState } from "react"
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk"
import { nanoid } from "nanoid"
import { useLocation, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import {
  getUserInfo,
  userDataSelect,
} from "src/features/userData/userDataSlice"
import { closeLoading, openLoading } from "src/features/loading/loadingSlice"

// 구매자의 고유 아이디를 불러와서 customerKey로 설정하세요.
// 이메일・전화번호와 같이 유추가 가능한 값은 안전하지 않습니다.
// const clientKey = "test_gck_DpexMgkW36xWe7R2bJ6NrGbR5ozO"
const clientKey = import.meta.env.VITE_KEY_GROUP_SALE
const customerKey = nanoid()
// const paymentWidget = PaymentWidget(clientKey, PaymentWidget.ANONYMOUS) // 비회원 결제

function TossPayProduct() {
  const [paymentWidget, setPaymentWidget] = useState<any>(null)
  const paymentMethodsWidgetRef = useRef(null)
  // const [price, setPrice] = useState(50_000)
  const { state } = useLocation()
  const dataUser = useAppSelector(userDataSelect)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  console.log("state", state)

  useEffect(() => {
    const fetchPaymentWidget = async () => {
      dispatch(openLoading())
      try {
        const loadedWidget: any = await loadPaymentWidget(
          clientKey,
          customerKey,
        )
        setPaymentWidget(loadedWidget)
      } catch (error) {
        console.error("Error fetching payment widget:", error)
      }
    }

    fetchPaymentWidget()
  }, [])

  useEffect(() => {
    if (paymentWidget == null) {
      return
    }

    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      "#payment-widget",
      { value: state?.price },
      { variantKey: "DEFAULT" },
    )

    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" })

    paymentMethodsWidgetRef.current = paymentMethodsWidget

    if (paymentMethodsWidgetRef.current) {
      setTimeout(() => {
        dispatch(closeLoading())
      }, 2500)
    }
  }, [paymentWidget, state?.price])

  useEffect(() => {
    const paymentMethodsWidget: any = paymentMethodsWidgetRef.current

    if (paymentMethodsWidget == null) {
      return
    }

    paymentMethodsWidget.updateAmount(state?.price)
  }, [state?.price])

  const handlePaymentRequest = async () => {
    // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
    try {
      await paymentWidget?.requestPayment({
        orderId: nanoid(),
        orderName: state?.orderName,
        customerEmail: dataUser?.email,
        customerName: dataUser?.displayName,
        customerMobilePhone: dataUser?.verifiedPhoneNumber,
        successUrl: `${
          import.meta.env.VITE_API_URL
        }/payment/approve-product-toss?quantity=${state?.quantity}&userId=${
          dataUser?.id
        }&gpId=${state?.gpId}&shippingFee=${state?.shippingFee}&phoneNumber=${
          state?.phoneNumber
        }&name=${state?.name}&noteToSeller${state?.noteToSeller}&addressName=${
          state?.addressName
        }&addressCode=${state?.addressCode}`,
        failUrl: `${window.location.origin}/fail`,
        // _skipAuth: "FORCE_SUCCESS",
      })
    } catch (error) {
      console.error("Error requesting payment:", error)
    }
  }

  return (
    <div className="container my-[50px]">
      {/* 할인 쿠폰 */}
      {/* <label htmlFor="coupon-box">
        <input
          id="coupon-box"
          type="checkbox"
          onChange={(event) => {
            setPrice(event.target.checked ? price - 5_000 : price + 5_000)
          }}
        />
        <span>5,000원 쿠폰 적용</span>
      </label> */}
      {/* 결제 UI, 이용약관 UI 영역 */}
      <div id="payment-widget" />
      <div id="agreement" className="inline-block" />
      {/* 결제하기 버튼 */}
      <button
        onClick={handlePaymentRequest}
        className="block mx-[30px] px-[50px] py-[10px] text-[#fff] bg-[#000] rounded-[5px]"
      >
        결제하기
      </button>
    </div>
  )
}

export default TossPayProduct
