import "swiper/css/pagination"

export type Props = {
  data: any
  onClick?: () => void
}

const BrandItem: React.FC<Props> = ({ data, onClick }) => {
  return (
    <div
      className="w-[100%] aspect-square cursor-pointer "
      onClick={() => onClick?.()}
    >
      <div className="border border-gray5 sm:mb-[16px] mb-[12px] h-[100%] ">
        <img
          src={data?.logoImage}
          alt="bn image"
          className="h-[100%] object-contain w-[100%]"
        />
      </div>
      <h3 className="sm:text-[16px] text-[12px] font-pretendard700 leading-[100%]">
        {data?.brandName}
      </h3>
    </div>
  )
}
export default BrandItem
