import { createSlice } from "@reduxjs/toolkit"
import { AppThunk, RootState } from "src/app/store"
import { getAllCategoryRequest } from "src/services/Category"

export interface OptionBrandCategory {
  value: string
  label: string
}

export interface BrandCategoriesState {
  data: any | null
}

const initialState: BrandCategoriesState = {
  data: null,
}

export const brandCategoriesSlice = createSlice({
  name: "brandCategories",
  initialState,
  reducers: {
    setBrandCategories: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setBrandCategories } = brandCategoriesSlice.actions

export const getBrandCategoryList = (): AppThunk => async (dispatch) => {
  try {
    const resp: any = await getAllCategoryRequest()
    if (resp?.result) {
      const dataFormat = resp.data.map((item: any) => {
        const newItem: OptionBrandCategory = {
          label: item.categoryName,
          value: item.id,
          ...item,
        }
        return newItem
      })
      dispatch(setBrandCategories(dataFormat))
    } else {
      dispatch(setBrandCategories(null))
    }
  } catch (error) {
    console.log(error)
  }
}

export const brandCategoriesSelect = (state: RootState) =>
  state.brandCategories.data

export default brandCategoriesSlice.reducer
