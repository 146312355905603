import React, { useState, useEffect } from "react"
import ArrowTop from "src/assets/icons/arrow-top.svg"
import SelectLanguage from "../SelectLanguage"
import { isScrolledToBottom } from "src/util/helpersFuc"
import useWindowSize from "src/util/hook/useWindowSize"

interface BackToTopProps {}

const BackToTop: React.FC<BackToTopProps> = () => {
  const { width }: any = useWindowSize()
  const [showButton, setShowButton] = useState<boolean>(false)
  // const [isBottom, setIsBottom] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
      // if (width < 540) {
      //   console.log('vao day');

      //   if (isScrolledToBottom()) {
      //     setIsBottom(true)
      //   } else {
      //     setIsBottom(false)
      //   }
      // }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <div className={`grid gap-[4px] ${showButton ? "visible" : "hidden"}`}>
      <div
        className="cursor-pointer flex items-center gap-[11px]"
        onClick={handleBackToTop}
      >
        <div className="uppercase text-[13px] tracking-[-0.455px] text-[#333]">
          Back to top
        </div>
        <div>
          <ArrowTop />
        </div>
      </div>
      <SelectLanguage />
    </div>
  )
}

export default BackToTop
