import BackSearchIcon from "@assets/icons/BackSearchIcon.svg"
import MbToggleMenuIcon from "@assets/icons/MbToggleMenuIcon.svg"
import imgDeals from "@assets/images/image175.png"
import noDataImg from "@assets/images/no-data.png"
import dayjs from "dayjs"
import { memo, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { searchDetailApi } from "src/api/search"
import { useAppDispatch } from "src/app/hooks"
import BrandItem from "src/components/BrandItem"
import GroupBuyingCard, { DealProps } from "src/components/Card/GroupBuyingCard"
import Pagination from "src/components/Pagination"
import { open } from "src/features/drawer/drawerSlice"
import { TBRAND } from "src/models"
import { onClose } from "../searchSlice"
import "../style.scss"
import NoData from "src/components/NoData"

type Props = {}

type TabSearch = "VOUCHER" | "BRAND"

interface DataSearchDetail {
  listBrand: TBRAND[]
  listVoucher: any
}

function SearchMobileResult({}: Props) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const textSearch = searchParams.get("querySearch")
  const [totalVoucher, setTotalVoucher] = useState<number>(0)
  const [totalBrand, setTotalBrand] = useState<number>(0)
  const [dataSearch, setDataSearch] = useState<DataSearchDetail>({
    listBrand: [],
    listVoucher: [],
  })
  const [tabSearch, setTabSearch] = useState<TabSearch>("VOUCHER")
  // const [pageAble, setPageAble] = useState<{ page: number; size: number }>({
  //   page: 1,
  //   size: 10,
  // })

  const listType: any = {
    FINISHED: "finished",
    IN_PROGRESS: "in-progress",
    INCOMING: "up-coming",
  }

  const fetchData = async () => {
    try {
      const paramsSearch =
        textSearch && textSearch.trim() !== ""
          ? {
              querySearch: textSearch,
              page: Number(searchParams.get("page")) || 1,
              size: 10,
            }
          : {}
      const { data }: any = await searchDetailApi(paramsSearch)
      if (data) {
        const listVoucherFormat = data?.voucher?.datas.map((item: any) => {
          const newItem: DealProps = {
            id: item.id,
            title: item.voucherName || "",
            desc: item.voucherDescription || item.description || "",
            price: item.voucherPrice || 0,
            voucherImage: item.voucherImage || imgDeals || "",
            logoImage: item.logoImage,
            available: item.quantity - item.totalSold || 0,
            tag: item.tags,
            type: listType[item.progress],
            startTime: dayjs(item.startTime).format(),
            endTime: dayjs(item.endTime).format(),
            totalValue: item.totalGroupBuyingValue,
            sold: item.totalSold,
            // isSoldOut: !!item.isSoldOut,
            isSoldOut: item.quantity === item.totalSold,
            isRemind: item.isRemind,
          }
          return newItem
        })
        setTotalVoucher(+data?.voucher?.pageable?.totalElement)
        setTotalBrand(+data?.brand?.totalElement)
        setDataSearch({
          listBrand: data.brand.brand,
          listVoucher: listVoucherFormat,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [textSearch, searchParams])

  return (
    <>
      <div className="search-header relative bg-primary h-[144px] justify-center flex items-center px-[15px]">
        <div
          className="absolute left-[15px] top-[53px]"
          onClick={() => {
            dispatch(onClose())
            navigate("/")
          }}
        >
          <BackSearchIcon />
        </div>
        <h2 className="uppercase text-[#B6F7F8] text-[14px] pb-[18px]">
          Search page
        </h2>
        <div
          className="absolute right-[15px] top-[53px]"
          onClick={() => dispatch(open())}
        >
          <MbToggleMenuIcon />
        </div>
      </div>
      <div className="search-body">
        <div>
          <h1 className="px-[20px] pt-[31px] text-[16px] text-primaryRed mb-[3px] font-pretendard700 leading-normal uppercase">
            Search: {textSearch}
          </h1>
          {!(
            dataSearch.listBrand.length > 0 || dataSearch.listVoucher.length > 0
          ) && (
            <p className="px-[20px] text-[14px] text-textColor10 font-pretendard500">
              Showing all 0 results
            </p>
          )}
          <div className="h-[6px] w-full mt-[15px] bg-grayBg3" />
          {(dataSearch.listBrand.length > 0 ||
            dataSearch.listVoucher.length > 0) && (
            <div
              className={`search-filter flex gap-[10px] justify-start pt-[20px] pb-[10px]`}
            >
              <button
                className={`h-[32px] px-[10px] rounded-[50px] bg-[#F7F7F7] text-[14px] text-primary font-pretendard500 leading-normal ${
                  tabSearch === "VOUCHER" ? "bg-bgBtn1 text-white" : ""
                }`}
                onClick={() => setTabSearch("VOUCHER")}
              >
                Voucher ({totalVoucher})
              </button>
              <button
                className={`h-[32px] px-[10px] rounded-[50px] bg-[#F7F7F7] text-[14px] text-primary font-pretendard500 leading-normal ${
                  tabSearch === "BRAND" ? "bg-bgBtn1 text-white" : ""
                }`}
                onClick={() => setTabSearch("BRAND")}
              >
                Brand ({totalBrand > 5 ? 5 : totalBrand})
              </button>
            </div>
          )}
        </div>
      </div>
      {dataSearch.listBrand.length > 0 || dataSearch.listVoucher.length > 0 ? (
        <div className="scrollbar-none px-[16px] py-[33px] max-h-[calc(100vh-200px)] overflow-y-auto overflow-x-hidden]">
          {tabSearch === "VOUCHER" ? (
            <>
              {dataSearch.listVoucher.length > 0 ? (
                <div>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[24px] gap-y-[60px]">
                    {dataSearch?.listVoucher?.map(
                      (item: DealProps, index: number) => (
                        <GroupBuyingCard
                          key={index}
                          {...item}
                          remindSuccess={() => {
                            fetchData()
                          }}
                          isSoldOut={item?.quantity === item?.totalSold}
                        />
                      ),
                    )}
                  </div>
                  {totalVoucher > 10 && (
                    <div className="mt-[50px]">
                      <Pagination
                        total={totalVoucher}
                        current={Number(searchParams.get("page")) || 1}
                        showSizeChanger={false}
                        onChange={(page) => {
                          searchParams.set("page", page.toString())
                          setSearchParams(searchParams)
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <NoData>
                  <p className="text-[18px] text-[#B1B0B0] font-pretendard500 leading-normal text-center mt-[20px]">
                    No data available in this section, back{" "}
                    <span
                      onClick={() => navigate("/")}
                      className="text-[18px] text-primary font-pretendard500 leading-normal cursor-pointer"
                    >
                      homepage
                    </span>
                  </p>
                </NoData>
              )}
            </>
          ) : (
            <>
              {dataSearch.listBrand.length > 0 ? (
                <div className="grid grid-cols-3 md:grid-cols-5 gap-[20px]">
                  {dataSearch?.listBrand?.map((item: TBRAND) => (
                    <BrandItem key={item.id} {...item} />
                  ))}
                </div>
              ) : (
                <NoData>
                  <p className="text-[18px] text-[#B1B0B0] font-pretendard500 leading-normal text-center mt-[20px]">
                    No data available in this section, back{" "}
                    <span
                      onClick={() => navigate("/")}
                      className="text-[18px] text-primary font-pretendard500 leading-normal cursor-pointer"
                    >
                      homepage
                    </span>
                  </p>
                </NoData>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="h-full pt-[97px] pb-[68px] flex items-center justify-center flex-col gap-[24px]">
          <img className="max-w-[235px]" src={noDataImg} alt="no data" />
          <p className="text-[18px] text-[#B1B0B0] font-pretendard500 leading-normal text-center">
            No data available in this section, back{" "}
            <span
              onClick={() => navigate("/")}
              className="text-[18px] text-primary font-pretendard500 leading-normal cursor-pointer"
            >
              homepage
            </span>
          </p>
        </div>
      )}
    </>
  )
}

export default memo(SearchMobileResult)
