import { getDetailFeed } from "./../api/brandApi"
export enum ApiUrl {
  // Auth
  LOGIN = "/auth/sign-in",
  SIGN_UP = "/auth/sign-up",
  GET_ME = "/user/me",
  CHANGE_PASSWORD = "/auth/change-password",
  RESET_PASSWORD = "/auth/reset-password",
  FORGOT_PASSWORD = "/auth/forgot-password",
  DELETE_ACCOUNT = "/auth/cancel-membership",
  DELETE_ACCOUNT_SNS = "/auth/cancel-membership-sns",
  // User
  GET_OTP = "/auth/send-mail-otp",
  GET_OTP_DELETE = "/auth/send-request-leave-email",
  GET_SUBCRIBED = "/user/get-subscribed-brand",
  SUBCRIBE_BRAND = "/user/subscribe-brand",
  DEACTIVE_ACCOUNT = "/user/deactive-account",
  DEACTIVE_ACCOUNT_SNS = "/auth/deactive-sns-account",
  SUBSCRIBE = "/user/subscribe-brand",
  GET_ALL_SUBSCRIBE = "/user/get-subscribed-brand",
  GET_BRAND_DETAIL = "/brand/details",
  GET_BRAND_PAGE_DATA = "/brand/get-brand-page-data",
  GET_BRAND_PAGE_SEARCH = "/brand/get-brand-page-data",
  FILTER_BRAND = "brand/search-brand-page-data",
  FILTER_MY_BRAND = "/brand/my-brand-data",
  CATEGORIES = "/categories/get-all",
  CATEGORIES_DETAIL = "/categories/get-details",
  UPDATE_ACCOUNT = "/user/update",

  BRAND_HISTORY_MEMBERSHIP = "/brand/premium-brand",
  BRAND_HISTORY_NORMAL_MEMBERSHIP = "/normal-membership/membership-brand",
  BRAND_FILTER = "brand/all-brand",

  // Home Page
  HOMEPAGE_DATA = "/homepage/homepage-data",
  CHECK_GB = "/homepage/check-gb",

  SEARCH = "/homepage/auto-complete-heading",
  SEARCH_DETAIL = "/homepage/heading-search",
  // My balance
  MY_BALANCE = "/u-b-payment/list-balance",
  BALANCE_DETAIL_SUMMARY = "/u-b-payment/balance-detail-summary",
  ORDER_HIS = "/u-b-payment/order-history",
  TRANSACTION_HIS = "/u-b-payment/transaction-history",
  ACCEPT_PAYMENT = "/u-b-payment/accept-payment",
  REJECT_PAYMENT = "/u-b-payment/reject-payment",
  CREATE_QR_CODE = "/u-b-payment/create-payment-code",
  LIST_VOUCHER = "/u-b-payment/list-voucher",
  NORMAL_MEMBERSHIP = "/u-b-payment/normal-membership",

  DETAIL_NORMAL_MEMBERSHIP = "normal-membership/order-details",
  // Voucher
  GET_GROUPBUY_INCOMING = "/gb/get-incoming",
  GET_GROUPBUY_INPROGRESS = "/gb/get-in-progress",
  GET_GROUPBUY_FINISHED = "/gb/get-finished",
  GET_GROUPBUY_ALL = "/gb/get-all",
  GET_VOUCHER_DETAIL = "/gb/details",
  GET_VOUCHER_TOTAL = "/gb/gb-count",
  REMIND_VOUCHER = "/gb/remind",
  PAYMENT_REQUEST = "/payment/request-pay",
  INICIS_APPROVE = "/payment/inicis-approve",
  REJECT_PAYMENT_VOUCHER_COUPON = "/u-b-payment/reject-voucher-coupon",
  ACCEPT_PAYMENT_VOUCHER_COUPON = "/u-b-payment/accept-voucher-coupon",

  // upload
  UPLOAD_SINGLE_FILE = "/upload/single-file",

  // Order
  LIST_ORDER = "/order/order-list?",
  DETAIL_ORDER = "/order/details",
  DETAIL_ORDER_NEW = "/normal-membership/order-details",
  CANCEL_ORDER = "/order/cancel-order",
  LIST_ORDER_NORMAL_MEMBERSHIP = "normal-membership/history?",
  CANCEL_MEMBERSHIP = "/normal-membership/cancel-membership",
  REQUEST_CANCEL = "/normal-membership/request-cancel",
  REQUEST_CANCEL_REFUND = "/normal-membership/cancel-request",
  REQUEST_CANCEL_REFUND_ORDER = "/order/cancel-order",
  CANCEL_REQUEST_REFUND_ORDER = "/order/cancel-request",
  LIST_ORDER_GIFT = "/order/received-list?",
  CHANGE_INFO_BANKING = "/order/change-info",
  //order product
  CANCER_ORDER_PRODUCT = "/order/cancel-before-confirm",
  REQUEST_REFUND_PRODUCT = "/order/cancel-product-order",
  COMPLETE_ORDER_PRODUCT = "/order/done-order",

  // Customer service
  FAQ_LIST = "/faq/list",
  FAQ_TYPES = "/faq/type-list",
  ANNOUNCEMENT_LIST = "/annoucement/list",
  ANNOUNCEMENT_TYPE = "/annoucement/type-list",
  INQUIRY_LIST = "/inquiry/list",
  INQUIRY_REPLY_LIST = "/inquiry/replied-list",
  INQUIRY_UNREPLY_LIST = "/inquiry/unreplied-list",
  INQUIRY_CREATE = "/inquiry/create",
  INQUIRY_DELETE = "/inquiry/delete-inquiry",
  CS_TOTAL = "/annoucement/cs-count",
  INQUIRY_TOTAL = "/inquiry/count",

  // Nice Api
  CREATE_VERIFY = "/nice-api/create-request-verify",

  //brand
  LIST_FEED = "/feed/list",
  TOGGLE_LIKE = "/feed/toggle-like",
  TOGGLE_FOLLOW = "/brand/toggle-follow",
  DETAIL_FEED = "/feed/detail",
  SUCRIBER_KAKAO = "/payment/request-pay-membership",
  BRAND_MAPPING_DATA = "/common/brand-mapping-data",
  MEMBERSHIP_DETAIL = "brand/membership-details",
  CANCEL_RENEWALS = "/normal-membership/cancel-renewals",
  CANCEL_MEMBERSHIP_NORMAL = "/normal-membership/cancel-membership",
  CANCEL_MEMBERSHIP_NORMAL_NEW = "/normal-membership/cancel-renewals",
  //home
  GET_PRODUCT = "/gb/highlight-gb",
  GET_CATEGORY_BRAND = "/categories/get-all",
  GET_BRAND = "/brand/brand-data",
  GET_MY_MEMBERSHIP = "/homepage/my-membership",
  GET_MY_FOLLOWER = "/homepage/following-brand",

  //community
  COMMUNITY_CREATE_POST = "/community/create",
  COMMUNITY_UPDATE_POST = "/community/update",
  COMMUNITY_REPORT = "report/report",
  COMMUNITY_TOGGLE_LIKE = "/community/toggle-like",
  COMMUNITY_LIST_POST = "/community/list",
  COMMUNITY_DELETE = "/community/delete",
  COMMUNITY_LIST_COMMENT = "community/comment/list",
  COMMUNITY_LIST_COMMENT_MY_MEMBERSHIP = "community/comment/mine",
  COMMUNITY_CREATE_COMMENT = "community/comment/create",
  COMMUNITY_DELETE_COMMENT = "community/comment/delete",
  COMMUNITY_EDIT_COMMENT = "community/comment/update",
  COMMUNITY_LIST_BRAND = "community/list-brand-activity",

  //feed
  FEED_LIST_COMMENT = "feed/comment/list",
  FEED_CREATE_COMMENT = "feed/comment/create",
  FEED_DELETE_COMMENT = "feed/comment/delete",
  FEED_EDIT_COMMENT = "feed/comment/update",
  FEED_REPORT = "feed/report",

  CUSTOMER_ID_TOSS = "/payment/request-buy-normal-membership",
  CHECK_NORMAL_MEMBERSHIP = "/normal-membership/check-normal-membership",

  //notification
  NOTIFICATION_LIST = "annoucement/list",
  DETAIL_NOTIFICATION = "/annoucement/details",

  //code gift
  CODE_GIFT = "/order/receive-gb-gift",

  LIST_BANNER_BRAND = "/brand/list-banner",

  //shop
  LIST_SHOP = "/brand/list-product",
  DETAIL_SHOP = "/brand/product-details",
  PAYMENT_KAKAO = "/payment/request-product-pay",
  //my voucher
  LIST_MY_VOUCHER = "/u-b-payment/list-voucher",
  //my order history
  LIST_ORDER_HISTORY = "/order/order-list",

  //my e content
  LIST_E_CONTENT = "/user/e-content",
  DETAIL_E_CONTENT = "/user/e-content-details",
  DELETE_E_CONTENT = "/user/delete-e-content",
  READ_E_CONTENT = "/user/read-e-content",
  //voucher usage history
  LIST_VOUCHER_USAGE_HISTORY = "/u-b-payment/usage-history-brand",
  DETAIL_VOUCHER_USAGE_HISTORY = "/u-b-payment/transaction-history",

  //banking
  BANKING_PAYMENT = "/payment/buy-premium-by-bank",
  FREE_MONEY_PAYMENT = "/payment/buy-premium",

  DOWNLOAD_FILE = "/upload/download-from-s3",
}
