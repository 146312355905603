import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { createBrowserHistory } from "history"
import { clearAllData, store } from "src/app/store"
import ApiConfig from "src/configs/apiConfig"
import { logout } from "src/features/user/userSlice"

interface AxiosRequestConfigCustom extends AxiosRequestConfig {
  tokenRequired?: boolean
}
const history = createBrowserHistory({ window })
const apiConfig = ApiConfig()
class API {
  private api: AxiosInstance

  constructor() {
    this.api = axios.create({
      baseURL: apiConfig.BASE_URL,
      headers: {},
    })
  }

  private setAuthToken(): void {
    const token = store.getState().user.currentUser?.accessToken
    this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`
  }

  public async request<T>(config: AxiosRequestConfigCustom): Promise<T> {
    if (config.tokenRequired) {
      this.setAuthToken()
    }

    if (config.headers) {
      config.headers["Content-Type"] =
        config.headers["Content-Type"] || "application/json"
    } else {
      config.headers = {
        "Content-Type": "application/json",
      }
    }
    return new Promise((resolve, reject) => {
      this.api
        .request(config)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 403 || err.response.status === 401) {
              // store.dispatch(logout())
              clearAllData()
              history.push("/auth")
              window.location.reload()
            }
            resolve({ data: [], error: err.response.data } as T)
          }
          reject(err)
        })
    })
  }
}

const api = new API()
export default api
