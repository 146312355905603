import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { transactionApi } from "src/api/myBalance"
import Pagination from "src/components/Pagination"
import SelectCustom from "src/components/SelectCustom"
import CsTable from "src/components/Table"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import { filterBrand, getBrandHistoryMembership } from "src/services/Brand"
import { MoneyConvert } from "src/util/format"
import { formatPrice } from "src/util/formatPrice"

const TIME_NUMBER: any = { "1M": 1, "3M": 3, "6M": 6, "1Y": 12 }

function HistoryUsingMembership() {
  const { t } = useTranslation()
  const { state } = useLocation()
  const [queryTrans, setQueryTrans] = useState<{
    page: number
    size: number
    timeFilter: string
    brandId: string
  }>({
    page: 1,
    size: 10,
    timeFilter: "",
    brandId: state?.brandId ?? "",
  })
  const [pageable, setPageable] = useState<any>({})
  const { isMobile } = useIsMobile(768)
  const [transactionHistory, setTransactionHistory] = useState<any>([])

  const [dataListBrand, setDataListBrand] = useState<any>([])

  const getDataBrand = async () => {
    try {
      const { data }: any = await getBrandHistoryMembership({
        querySearch: "",
        filter: [],
        page: 1,
        size: 999999,
      })
      if (data) {
        let dataResp = [
          {
            value: "",
            label: t("All"),
          },
        ]

        for (let i = 0; i < data.datas.length; i++) {
          dataResp.push({
            value: data.datas[i].id,
            label: data.datas[i].brandName,
          })
        }

        setDataListBrand(dataResp)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getDataBrand()
  }, [])

  const handleGetTransactionHistory = async (
    isLoadmore?: boolean,
    page?: number,
  ) => {
    try {
      let params = {
        transactionType: "USING_VOUCHER",
        // brandId: state?.brandId ?? null,
        filterInOut: "OUT",
        ...queryTrans,
        page: page ?? queryTrans.page,
      }
      const { data }: any = await transactionApi.getTransactionHis(params)
      if (data) {
        let dataResp = data.datas
        if (isLoadmore) {
          setTransactionHistory([...transactionHistory, ...dataResp])
        } else {
          setTransactionHistory(dataResp)
        }
        setPageable(data.pageable)
      }
    } catch (error) {
      setTransactionHistory({})
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetTransactionHistory()
  }, [queryTrans])

  const allTime = [
    { value: "", label: t("All") },
    { value: "1M", label: t("1개월") },
    { value: "3M", label: t("3개월") },
    { value: "6M", label: t("6개월") },
    { value: "1Y", label: t("1년") },
  ]

  const columns2: ColumnsType<any> = [
    {
      title: "브랜드명",
      dataIndex: "brandName",
      key: "brandName",
      align: "center",
      render: (text, record) => {
        return (
          <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
            {text}
          </p>
        )
      },
    },
    {
      title: "매장 정보",
      dataIndex: "branchName",
      key: "branchName",
      align: "center",
      render: (text, record) => {
        return (
          <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
            {text}
          </p>
        )
      },
    },
    {
      title: "결제 일시",
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      render: (_, record) => {
        return (
          <p
            className={`text-[12px] md:text-[14px] text-primary font-pretendard400 ${
              isMobile ? "pl-[14px]" : ""
            }`}
          >
            {dayjs(record?.createdTime).format("YYYY.MM.DD")}
          </p>
        )
      },
    },
    {
      title: "결제 금액",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (_, record) => (
        <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
          {MoneyConvert(record?.amount)}원
        </p>
      ),
    },
    {
      title: "결제 상태",
      dataIndex: "refundStatus",
      key: "refundStatus",
      align: "center",
      render: (text, record) => {
        return (
          <p className="text-[12px] md:text-[14px] text-primary font-pretendard400">
            {convertRefundStatus(text)}
          </p>
        )
      },
    },
  ]
  const convertRefundStatus = (status: string) => {
    switch (status) {
      case "CAN_CANCEL":
        return "결제완료"
      case "CANCEL_ALL":
        return "전체취소"
      case "CANCEL_A_PART":
        return "일부취소 "
      case "DONE":
        return "환불완료"

      default:
        return ""
    }
  }

  return (
    <>
      {!isMobile ? (
        <>
          <div className="md:container mt-[63px]">
            <div className="font-pretendard700 text-[24px] leading-[24px] pb-[24px] border-b-[2px] border-b-primary">
              {t("MY월렛 이용내역")}
            </div>

            <div className="flex justify-between mt-[50px] mb-[17px]">
              <div className="flex gap-[49.5px]">
                {!state?.brandId && (
                  <div className="flex gap-[14px] items-center">
                    <div className="text-[14px]">브랜드</div>
                    <SelectCustom
                      placeholder={t("All status")}
                      value={queryTrans.brandId}
                      onChange={(e) => {
                        setQueryTrans((prev) => ({
                          ...prev,
                          brandId: e,
                        }))
                      }}
                      options={dataListBrand}
                      height={40}
                      width={155}
                    />
                  </div>
                )}
                <div className="flex gap-[14px] items-center">
                  <div className="text-[14px]">기간</div>
                  <SelectCustom
                    placeholder={t("All status")}
                    value={queryTrans.timeFilter}
                    onChange={(e) => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        page: 1,
                        timeFilter: e,
                      }))
                    }}
                    options={allTime}
                    height={40}
                    width={155}
                  />
                </div>
              </div>
              <div className="flex gap-[28px]">
                <div className="text-[14px]">
                  {t("총 N건", {
                    number: pageable?.totalElement ?? 0,
                  })}
                </div>
                <div className="text-[14px]">
                  {dayjs()
                    .subtract(TIME_NUMBER[queryTrans.timeFilter] || 12, "month")
                    .format("YYYY.MM.DD")}{" "}
                  ~ {dayjs().format("YYYY.MM.DD")}
                </div>
              </div>
            </div>

            <div className="border-t border-t-solid border-t-primary lg:mx-0 mx-[-24px]">
              <CsTable
                className="mb-[34px]"
                columns={columns2}
                dataSource={transactionHistory}
                // showHeader={false}
              />
              {transactionHistory && transactionHistory?.length > 0 && (
                <Pagination
                  showSizeChanger={false}
                  rootClassName="flex justify-center"
                  className="custom-pagination"
                  total={pageable?.totalElement}
                  pageSize={queryTrans?.size}
                  current={queryTrans?.page}
                  onChange={(page) => {
                    setQueryTrans((prev) => ({ ...prev, page }))
                  }}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <NavBarMb
            pageName="MY월렛 이용내역"
            // csGoback={() => navigate("/my-membership")}
          />

          <div className="px-[24px] mt-[19px] mb-[9px]">
            <div className="flex gap-[16px]">
              {!state?.brandId && (
                <div className="flex-1">
                  <div className="pb-[10px] text-[14px]">브랜드</div>
                  <SelectCustom
                    placeholder={t("All status")}
                    value={queryTrans.brandId}
                    onChange={(e) => {
                      setQueryTrans((prev) => ({
                        ...prev,
                        brandId: e,
                      }))
                    }}
                    options={dataListBrand}
                    height={40}
                  />
                </div>
              )}
              <div className="flex-1">
                <div className="pb-[10px] text-[14px]">기간</div>
                <SelectCustom
                  placeholder={t("All status")}
                  value={queryTrans.timeFilter}
                  onChange={(e) => {
                    setQueryTrans((prev) => ({
                      ...prev,
                      page: 1,
                      timeFilter: e,
                    }))
                  }}
                  options={allTime}
                  height={40}
                />
              </div>
            </div>

            <div className="flex justify-between mt-[39px]">
              <div className="text-[13px] text-[#444444]">
                {t("총 N건", {
                  number: pageable?.totalElement ?? 0,
                })}
              </div>
              <div className="text-[13px] text-[#444444]">
                {dayjs()
                  .subtract(TIME_NUMBER[queryTrans.timeFilter] || 12, "month")
                  .format("YYYY.MM.DD")}{" "}
                ~ {dayjs().format("YYYY.MM.DD")}
              </div>
            </div>
          </div>

          {transactionHistory &&
            transactionHistory?.length > 0 &&
            transactionHistory?.map((item: any, i: number) => (
              <div className="pb-[45px]" key={i}>
                <div className="px-[24px] py-[17px] font-pretendard700 bg-[#F5F5F5] mb-[14px]">
                  {item?.brandName}
                </div>
                <div className="flex gap-[35px] px-[24px]">
                  <div className="flex flex-col gap-[15px]">
                    <div className="text-[14px] text-[#666666]">매장 정보</div>
                    <div className="text-[14px] text-[#666666]">결제 일시</div>
                    <div className="text-[14px] text-[#666666]">결제 금액</div>
                    <div className="text-[14px] text-[#666666]">결제 상태</div>
                  </div>
                  <div className="flex flex-col gap-[15px]">
                    <div className="font-pretendard500 text-[14px]">
                      {item?.branchName}
                    </div>
                    <div className="font-pretendard500 text-[14px]">
                      {moment(item?.createdTime).format("YYYY.MM.DD HH:mm")}
                    </div>
                    <div className="font-pretendard500 text-[14px]">
                      {formatPrice(item?.amount)}원
                    </div>
                    <div className="font-pretendard500 text-[14px]">
                      {convertRefundStatus(item?.refundStatus)}
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="fixed bottom-[55px] w-full bg-[#fff] py-[20px] z-10">
            <div
              className="border border-[#000] h-[40px] leading-[40px] text-center bg-[#fff] mx-[24px]"
              onClick={() => {
                if (pageable?.hasNext) {
                  handleGetTransactionHistory(true, pageable.page + 1)
                }
              }}
            >
              더 보기
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default HistoryUsingMembership
