import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { getDataListVoucher } from "src/api/myVoucherApi"
import Button from "src/components/Button"
import CommonModal from "src/components/Modal"
import SelectCustom from "src/components/SelectCustom"
import CardCoupon from "./CardCoupon"
import CardVoucher from "./CardVoucher"
import { getBrandFilter, getBrandHistoryMembership } from "src/services/Brand"
import Pagination from "src/components/Pagination"
import useIsMobile from "src/customHook/useIsMobile"
import NavBarMb from "src/layout/Header/NavBarMb"
import moment from "moment"

function MyVouchers() {
  const [searchParams, setSearchParams] = useSearchParams()
  const type = searchParams.get("voucherType")
  const { t } = useTranslation()

  const [isModalQr, setIsModalQr] = useState<any>({
    isOpen: false,
    image: null,
  })

  const [dataSource, setDataSource] = useState<any[]>([])
  const [dataTab, setDataTab] = useState<any>({})
  const [pageable, setPageable] = useState<any>({})
  const query: any = {
    page: Number(searchParams.get("page")) || 1,
    size: 8,
    filterType: searchParams.get("filterType") || "",
    voucherType: searchParams.get("voucherType") || "VOUCHER_NEW",
    brandId: searchParams.get("brandId") || "",
  }
  const [dataListBrand, setDataListBrand] = useState<any>([])
  const { isMobile } = useIsMobile(768)

  const getDataBrand = async () => {
    try {
      const { data }: any = await getBrandFilter({
        querySearch: "",
        filter: [],
        page: 1,
        size: 999999,
        voucherType: query.voucherType || "VOUCHER_NEW",
      })
      if (data) {
        let dataResp = [
          {
            value: "",
            label: t("All"),
          },
        ]

        for (let i = 0; i < data.datas.length; i++) {
          dataResp.push({
            value: data.datas[i].id,
            label: data.datas[i].brandName,
          })
        }

        setDataListBrand(dataResp)
      } else {
        setDataListBrand([])
      }
    } catch (error) {
      setDataListBrand([])
    }
  }

  useEffect(() => {
    getDataBrand()
  }, [query.voucherType])

  const routes = [
    {
      label: `${t("Voucher")} (${dataTab.voucherCount || 0})`,
      path: "VOUCHER_NEW",
    },
    {
      label: `${t("Coupon")} (${dataTab.couponCount || 0})`,
      path: "COUPON",
    },
  ]

  const getAllData = async () => {
    try {
      const resp: any = await getDataListVoucher(query)
      if (resp?.result) {
        setDataSource(resp.data.datas)
        setDataTab(resp.data.dataAdd.summaryCount)
        setPageable(resp.data.pageable)
      } else {
        setPageable({})
        setDataTab({})
        setDataSource([])
      }
    } catch (error) {
      setPageable({})
      setDataTab({})
      setDataSource([])
    }
  }

  useEffect(() => {
    getAllData()
  }, [searchParams])

  const handleModalQr = (data: any) => {
    setIsModalQr({
      image: data,
      isOpen: true,
    })
  }

  useEffect(() => {
    if (searchParams.get("typeQr") === "SUCCESS_QR") {
      setIsModalQr({ ...isModalQr, isOpen: false })
    }
  }, [searchParams])

  console.log("isModalQr", isModalQr)
  console.log(
    "moment() > moment(isModalQr?.image?.dateOfUse)",
    moment() > moment(isModalQr?.image?.dateOfUse),
  )

  return (
    <>
      <CommonModal
        // isOpen
        isOpen={isModalQr.isOpen}
        isCloseOutside
        handleClose={() => setIsModalQr({ ...isModalQr, isOpen: false })}
      >
        <div className="text-center mt-[-7px] mb-[21px]">
          <div className="font-pretendard500 text-[20px] mb-[19px] leading-none">
            {t("My QR code", {
              type: type === "COUPON" ? t("Coupon") : t("Voucher"),
            })}
          </div>
          {!(
            moment() > moment(isModalQr?.image?.dateOfUse) &&
            moment() <
              moment(
                moment(isModalQr?.image?.expiredTime).subtract(1, "minutes"),
              )
          ) ? (
            <div className="bg-[#000] rounded-[23px] px-[22px] pt-[44px] pb-[25px] max-w-[245px] mx-auto">
              <div className="text-[#fff] text-[30px] font-pretendard600">
                {t("아직 사용 기간이 아닙니다.")}
              </div>
              <div className="text-[#fff] text-[14px] mt-[20px]">
                {t("사용 기간에 다시 이용해 주세요.")}
              </div>

              <div className="p-[20px] pb-[40px] text-[#fff] text-[16px]">
                <div>사용기간:</div>
                <div>
                  {moment(isModalQr?.image?.dateOfUse).format("YYYY-MM-DD")}{" "}
                  <br />~
                  {moment(isModalQr?.image?.expiredTime)
                    .subtract(1, "minutes")
                    .format("YYYY-MM-DD")}
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-[#000] rounded-[23px] px-[22px] pt-[44px] pb-[25px] max-w-[245px] mx-auto">
              <div className="text-[#fff] text-[30px] font-pretendard600">
                {t("Tap to scan")}
              </div>
              <div className="text-[#9F9F9F] text-[14px] mt-[10px] mb-[49px]">
                {t("The app will require access to the camera")}
              </div>

              <div className="p-[20px]">
                <img
                  src={isModalQr?.image?.qrUrl}
                  alt="qr"
                  className="w-full aspect-square bg-[#fff]"
                />
              </div>
            </div>
          )}
        </div>
      </CommonModal>

      {isMobile ? (
        <>
          <NavBarMb
            pageName="My Vouchers"
            // csGoback={() => navigate("/my-membership")}
          />
          <div className="flex mt-[17px]">
            {routes.map((item, index) => (
              <div
                className={`text-[#000] text-[16px] flex-1 text-center border-b border-b-[#E5E5E5] pb-[12px] ${
                  (item.path === type || (!type && index === 0)) &&
                  "font-pretendard700 border-b-[2px] !border-b-[#000000]"
                }`}
                key={index}
                onClick={() => {
                  searchParams.set("voucherType", item.path)
                  setSearchParams(searchParams)
                }}
              >
                {item.label}
              </div>
            ))}
          </div>

          <div className="container">
            <div className="flex gap-[16.5px] mt-[20px] mb-[13px]">
              <div className="flex-1">
                <div className="text-[14px] pb-[8px]">Filter</div>
                <SelectCustom
                  placeholder={t("All")}
                  // value={queryTrans.paymentStatus}
                  onChange={(e) => {
                    searchParams.set("filterType", e)
                    setSearchParams(searchParams)
                  }}
                  options={[
                    { value: "", label: t("All") },
                    { value: "lowestPrice", label: t("Lowest price") },
                    { value: "highestPrice", label: t("Highest price") },
                    { value: "nearestExpiry", label: t("Nearest expiry date") },
                    {
                      value: "furthestExpiry",
                      label: t("Furthest expiry date"),
                    },
                  ]}
                  height={40}
                />
              </div>
              <div className="flex-1">
                <div className="text-[14px] pb-[8px]">브랜드</div>
                <SelectCustom
                  placeholder={t("All")}
                  // value={queryTrans.paymentStatus}
                  onChange={(e) => {
                    searchParams.set("brandId", e)
                    setSearchParams(searchParams)
                  }}
                  options={dataListBrand}
                  height={40}
                />
              </div>
            </div>

            {dataSource && dataSource?.length > 0 ? (
              <>
                {type !== "COUPON" ? (
                  <div className="grid grid-cols-1 gap-[23px]">
                    {dataSource?.map((item, index) => (
                      <CardVoucher
                        key={index}
                        onClickQr={handleModalQr}
                        data={item}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-[30px]">
                    {dataSource?.map((item, index) => (
                      <CardCoupon
                        key={index}
                        onClickQr={handleModalQr}
                        data={item}
                      />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <div className="text-center my-[150px]">
                현재 보유한 티켓이 없습니다. <br />
                티켓은 각 브랜드 스토어에서 구매 가능합니다.
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="container leading-[100%]">
          <div className="mt-[60px] pb-[24px] border-b-[2px] border-[#000000] font-pretendard700 text-[24px]">
            {t("My Vouchers")}
          </div>

          <div className="flex items-center justify-between my-[29px]">
            <div className="flex items-center gap-[16px] relative z-20 justify-center lg:justify-start mt-[24px] lg:mt-0">
              {routes.map((item, index) => {
                return (
                  <Button
                    key={index}
                    className={`lg:text-[18px] text-[14px] text-primary capitalize ${
                      item.path === type || (!type && index === 0)
                        ? "font-pretendard700"
                        : ""
                    }`}
                    onClick={() => {
                      searchParams.set("voucherType", item.path)
                      setSearchParams(searchParams)
                    }}
                  >
                    {t(item.label)}
                  </Button>
                )
              })}
            </div>

            <div className="flex gap-[29.5px]">
              <div className="flex gap-[14px] items-center">
                <div className="text-[14px]">Filter</div>
                <SelectCustom
                  placeholder={t("All")}
                  // value={queryTrans.paymentStatus}
                  onChange={(e) => {
                    searchParams.set("filterType", e)
                    setSearchParams(searchParams)
                  }}
                  options={[
                    { value: "", label: t("All") },
                    { value: "lowestPrice", label: t("Lowest price") },
                    { value: "highestPrice", label: t("Highest price") },
                    { value: "nearestExpiry", label: t("Nearest expiry date") },
                    {
                      value: "furthestExpiry",
                      label: t("Furthest expiry date"),
                    },
                  ]}
                  height={40}
                  width={155}
                />
              </div>
              <div className="flex gap-[14px] items-center">
                <div className="text-[14px]">브랜드</div>
                <SelectCustom
                  placeholder={t("All")}
                  // value={queryTrans.paymentStatus}
                  onChange={(e) => {
                    searchParams.set("brandId", e)
                    setSearchParams(searchParams)
                  }}
                  options={dataListBrand}
                  height={40}
                  width={155}
                />
              </div>
            </div>
          </div>

          {dataSource && dataSource?.length > 0 ? (
            <>
              {type !== "COUPON" ? (
                <div className="grid grid-cols-2 gap-x-[63px] gap-y-[25px]">
                  {dataSource?.map((item, index) => (
                    <CardVoucher
                      key={index}
                      onClickQr={handleModalQr}
                      data={item}
                    />
                  ))}
                </div>
              ) : (
                <div className="grid grid-cols-4 gap-x-[20px] gap-y-[40px]">
                  {dataSource?.map((item, index) => (
                    <CardCoupon
                      key={index}
                      onClickQr={handleModalQr}
                      data={item}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="text-center my-[150px]">
              현재 보유한 티켓이 없습니다. <br />
              티켓은 각 브랜드 스토어에서 구매 가능합니다.
            </div>
          )}
        </div>
      )}

      {dataSource && dataSource?.length > 0 && pageable?.totalElement > 8 && (
        <Pagination
          showSizeChanger={false}
          rootClassName="flex justify-center"
          className="custom-pagination mt-[50px]"
          total={pageable?.totalElement}
          pageSize={query?.size}
          current={query?.page}
          onChange={(page) => {
            searchParams.set("page", String(page))
            setSearchParams(searchParams)
          }}
        />
      )}
    </>
  )
}

export default MyVouchers
