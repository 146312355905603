import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const orderApi = {
  getListOrder(params: any): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.LIST_ORDER,
      method: "GET",
      params: { ...params, size: 10 },
      tokenRequired: true,
    })
  },
  getListOrderNormalMembership(params: any): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.LIST_ORDER_NORMAL_MEMBERSHIP,
      method: "GET",
      params: { ...params, size: 10 },
      tokenRequired: true,
    })
  },
  getOrderDetail(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.DETAIL_ORDER,
      method: "GET",
      params: { id },
      tokenRequired: true,
    })
  },
  getOrderDetailNew(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.DETAIL_ORDER_NEW,
      method: "GET",
      params: { id },
      tokenRequired: true,
    })
  },
  cancelOrder(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.CANCEL_ORDER,
      method: "POST",
      data: { id },
      tokenRequired: true,
    })
  },
  cancelOrderProduct(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.CANCER_ORDER_PRODUCT,
      method: "POST",
      data: { id },
      tokenRequired: true,
    })
  },
  cancelRefundRequestProduct(params: {
    id: number
    reason: string
    refundAmount: number
    refundMethod: string
    refundImage: any
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.REQUEST_REFUND_PRODUCT,
      method: "POST",
      data: params,
      tokenRequired: true,
    })
  },
  completeOrderProduct(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.COMPLETE_ORDER_PRODUCT,
      method: "POST",
      data: { id },
      tokenRequired: true,
    })
  },
  cancelMembership(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.CANCEL_MEMBERSHIP,
      method: "POST",
      data: { id },
      tokenRequired: true,
    })
  },
  requestCancel(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.REQUEST_CANCEL,
      method: "POST",
      data: { id },
      tokenRequired: true,
    })
  },

  requestCancelRefund(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.REQUEST_CANCEL_REFUND,
      method: "POST",
      data: { id },
      tokenRequired: true,
    })
  },

  requestCancelRefundOrder(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.REQUEST_CANCEL_REFUND_ORDER,
      method: "POST",
      data: { id },
      tokenRequired: true,
    })
  },

  cancelRequestRefundOrder(id: string | number): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.CANCEL_REQUEST_REFUND_ORDER,
      method: "POST",
      data: { id },
      tokenRequired: true,
    })
  },

  getListOrderGift(params: any): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.LIST_ORDER_GIFT,
      method: "GET",
      params: { ...params, size: 10 },
      tokenRequired: true,
    })
  },

  changeInfoBanking(data: {
    id: number
    bankName?: string
    bankAccountName?: string
    bankAccountNumber?: string
    bill?: string
  }): Promise<AxiosRequestConfig> {
    return api.request({
      url: ApiUrl.CHANGE_INFO_BANKING,
      method: "POST",
      data,
      tokenRequired: true,
    })
  },
}
