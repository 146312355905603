import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { getDataDetailNormalMembership } from "src/api/myBalance"
import NavBarMb from "src/layout/Header/NavBarMb"
import { formatPrice } from "src/util/formatPrice"

function CancelMembership() {
  const { state } = useLocation()
  const [dataDetailNormalMembership, setDataDetailNormalMembership] =
    useState<any>({})

  const handleGetDataDetailNormalMembership = async () => {
    try {
      const { data } = await getDataDetailNormalMembership({
        id: Number(state?.id),
      })
      if (data) {
        setDataDetailNormalMembership(data)
      }
    } catch (error) {
      setDataDetailNormalMembership({})
      console.log(error)
    }
  }
  useEffect(() => {
    handleGetDataDetailNormalMembership()
  }, [])

  return (
    <>
      <NavBarMb pageName={dataDetailNormalMembership?.brandName} />
      <div className="px-[24px]">
        <div className="border border-[#D9D9D9] p-[20px] pb-[24px] mt-[42px]">
          <div className="w-[80px] h-[80px] aspect-square mb-[16px]">
            <img src={dataDetailNormalMembership?.logoImage} alt="img" />
          </div>
          <div className="pb-[24px] border-b border-b-[#D9D9D9] leading-[100%]">
            <div className="flex justify-between pb-[8px]">
              <div className="text-[16px] font-pretendard700 text-[#539165]">
                {dataDetailNormalMembership?.membershipName}
              </div>
              <div className="text-[16px] text-[#539165]">
                월{" "}
                <span className="font-pretendard700">
                  {formatPrice(
                    Number(dataDetailNormalMembership?.rootPrice) -
                      Number(dataDetailNormalMembership?.sellingPrice),
                  )}
                </span>
                원
              </div>
            </div>
            <div className="flex justify-between">
              <div className="text-[#666666] text-[13px]">월 자동 결제</div>
              <div className="text-[#666666] text-[13px]">
                {formatPrice(
                  ((Number(dataDetailNormalMembership?.rootPrice) -
                    Number(dataDetailNormalMembership?.sellingPrice)) /
                    Number(dataDetailNormalMembership?.rootPrice)) *
                    100,
                )}
                % 할인
              </div>
            </div>
          </div>
          <div className="mt-[28px] mb-[12px] text-[16px] font-pretendard700">
            {dataDetailNormalMembership?.brandName} 멤버십 혜택
          </div>

          <div className="flex flex-col gap-[12px]">
            {dataDetailNormalMembership?.preferential?.map(
              (item: any, index: number) => (
                <div className="flex gap-[10px]" key={index}>
                  <div className="min-w-[3px] h-[3px] bg-[#000000] rounded-full mt-[6px]"></div>
                  <div className="text-[#000000] leading-[100%]">{item}</div>
                </div>
              ),
            )}
          </div>
        </div>

        <div className="mb-[65px] mt-[49px]">
          {dataDetailNormalMembership?.images?.map(
            (item: any, index: number) => (
              <img src={item} alt="img" key={index} />
            ),
          )}
        </div>
      </div>
    </>
  )
}

export default CancelMembership
