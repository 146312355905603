import AppleIcon from "@assets/icons/social/Apple.svg"
import KakaoIcon from "@assets/icons/social/Kakao.svg"
import NaverIcon from "@assets/icons/social/Naver.svg"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { useAuth } from "src/customHook/useAuth"
import jwt_decode from "jwt-decode"
import { useAppDispatch } from "src/app/hooks"
import { loginSuccess } from "src/features/user/userSlice"
import Button from "src/components/Button"
import { Trans } from "react-i18next"
import ArrowRightImg from "@assets/images/my_page/navigate_next.png"

// dev
// const clientAppleId = "dev-api.moafriends.com"
// const clientGoogleId =
//   "291660065549-g58nho1i1h3fborbhsaeullaucmgso3c.apps.googleusercontent.com"

//main
// const clientAppleId = 'api.moafriends.com';
// const clientGoogleId = '756862647868-lechbgjc9mt5h8h3pbot7bcsfmkjosat.apps.googleusercontent.com';

//dev
// const urlKakaoLogin = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=64c873359b4ab8fbd6dfeefd9343e2c3&redirect_uri=${
//   import.meta.env.VITE_API_URL
// }/auth/callback-kakao`
// const urlNaverLogin = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=zTGA0mZlrkL_Ozo0tnZI&redirect_uri=${
//   import.meta.env.VITE_API_URL
// }/auth/callback-naver`
// const urlGoogleLogin = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&access_type=offline&redirect_uri=${
//   import.meta.env.VITE_API_URL
// }/auth/callback-google&response_type=code&client_id=${clientGoogleId}`
// const urlAppleLogin = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id=${clientAppleId}&redirect_uri=${
//   import.meta.env.VITE_API_URL
// }/auth/callback-apple&state=YOUR_STATE&scope=email%20name&response_mode=form_post`

//main
// const urlKakaoLogin = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=cfa0a847d43dae5acd341e048550135b&redirect_uri=https://api.moafriends.com/api/v1/auth/callback-kakao`
// const urlNaverLogin = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=zTGA0mZlrkL_Ozo0tnZI&redirect_uri=https://api.moafriends.com/api/v1/auth/callback-naver`
// const urlGoogleLogin = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&access_type=offline&redirect_uri=https://api.moafriends.com/api/v1/auth/callback-google&response_type=code&client_id=756862647868-lechbgjc9mt5h8h3pbot7bcsfmkjosat.apps.googleusercontent.com`
// const urlAppleLogin = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id=api.moafriends.com&redirect_uri=https://api.moafriends.com/api/v1/auth/callback-apple&state=YOUR_STATE&scope=email%20name&response_mode=form_post`

const urlKakaoLogin = import.meta.env.VITE_KAKAO_LOGIN_URL
const urlNaverLogin = import.meta.env.VITE_NAVER_LOGIN_URL
const urlGoogleLogin = import.meta.env.VITE_GOOGLE_LOGIN_URL
const urlAppleLogin = import.meta.env.VITE_APPLE_LOGIN_URL

type Props = {}

function SNSGroup({}: Props) {
  // console.log("ạdkh")

  const { isLogined } = useAuth()
  const { t } = useTranslation()
  const [searchParam] = useSearchParams()
  const backUrl = searchParam.get("backUrl") || "/"
  const [isRegister, setIsRegister] = useState<boolean>(false)
  const navigate = useNavigate()
  const { hash } = useLocation()
  const dispatch = useAppDispatch()
  // useEffect(() => {
  //   const token = searchParam.get("accessToken")
  //   if (token) {
  //     const decoded: any = jwt_decode(token)
  //     dispatch(loginSuccess({ ...decoded, accessToken: token }))
  //   }
  // }, [dispatch, searchParam])
  // useEffect(() => {
  //   setIsRegister(hash === "#register")
  // }, [hash])
  // if (isLogined) {
  //   return <Navigate to={backUrl} />
  // }

  console.log("backUrl", btoa(backUrl))

  return (
    <>
      {!isRegister && (
        <div>
          <p className="text-center">
            <span className="font-pretendard400 text-gray1 cursor-pointer lg:mt-[70px] mt-[48px] lg:text-[16px] text-[14px] uppercase block">
              {t("SNS 계정으로 간편하게 시작하세요")}
            </span>
          </p>
          <div className="flex justify-center mt-2 gap-[16px]">
            <div className="rounded-[3px] overflow-hidden">
              <Link to={`${urlKakaoLogin}&state=${btoa(backUrl)}`}>
                <KakaoIcon />
              </Link>
            </div>
            {!Number(import.meta.env.VITE_IS_SHOW_APPLE_LOGIN) && (
              <div className="rounded-[3px] overflow-hidden">
                <Link to={`${urlAppleLogin}&state=${btoa(backUrl)}`}>
                  <AppleIcon />
                </Link>
              </div>
            )}
            <div className="rounded-[3px] overflow-hidden">
              <Link to={`${urlNaverLogin}&state=${btoa(backUrl)}`}>
                <NaverIcon />
              </Link>
            </div>
          </div>
          {/* <p className="text-center mt-[42px] font-pretendard500 lg:text-[16px] text-[14px] text-black">
            <Trans i18nKey="지금 가입하면 5% 할인 쿠폰을 드려요">
              지금 가입하면
              <span className="font-pretendard700">5% 할인 쿠폰</span>을 드려요
            </Trans>
          </p> */}
          {/* <div className="lg:mt-[19px] mt-[10px]"> */}
          <div className="lg:mt-[39px] mt-[30px]">
            <Button
              onClick={() => navigate("register")}
              variant="outline"
              theme="main"
            >
              {t("이메일로 회원가입")}
            </Button>
          </div>

          <div
            className="flex gap-[7px] items-center justify-center sm:mt-[58px] mt-[42px] cursor-pointer"
            onClick={() => window.open("https://brand.moafriends.com/")}
          >
            <div>브랜드 입점 안내</div>
            <div>
              <img src={ArrowRightImg} alt="arrow" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SNSGroup
