import { useState } from "react"
import Countdown from "react-countdown"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import useIsMobile from "src/customHook/useIsMobile"
import { Voucher } from "src/models"
import groupBuy from "@assets/images/GroupBuy_temp.png"

import ProductBannerDefault from "@assets/images/home/bannerProduct.png"

type Props = {
  showTitle?: boolean
  mainColor?: string
  colorButton?: string
  titleEx?: boolean
  data?: any
  brandId?: number
  onComplete?: () => void
}

const TopBrand: React.FC<Props> = ({ showTitle = true, data, onComplete }) => {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(1)
  const [openEvenEnd, setOpenEvenEnd] = useState<boolean>(false)
  const [openMaximumPurchaseLimit, setOpenMaximumPurchaseLimit] =
    useState<boolean>(false)
  const [openMaxQtyModal, setOpenMaxQtyModal] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const { isMobile } = useIsMobile(768)
  if (!data) {
    return <></>
  }
  const voucherData = new Voucher(data)

  console.log("data", data)

  return (
    <div
      className={`h-[640px] flex items-center justify-center flex-col relative`}
    >
      <img
        src={
          data?.displayBackground ? data?.backgroundImage : ProductBannerDefault
        }
        alt="img"
        className="absolute inset-0 z-[-1] w-full h-full object-cover"
      />
      {/* <div className="bg-homeProductBanner h-[640px] flex items-center justify-center flex-col"> */}
      <div className="md:flex sm:w-[180px] sm:h-[180px] w-[120px] h-[120px] flex justify-center items-center sm:mb-[20px] mb-[16px]">
        <img
          src={
            data?.displayLogo
              ? data?.gbLogoImage
              : voucherData.logoImage || groupBuy
          }
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <p className="text-[18px] font-pretendard700 leading-[18px] sm:mb-[24px] mb-[44px] text-primaryWhite">
        {voucherData.brandName}
      </p>
      <p className="sm:text-[48px] text-[32px] font-pretendard700 sm:leading-[48px] leading-[32px] sm:mb-[11px] mb-[16px] text-primaryWhite">
        {voucherData.status === "INCOMING" ? "UP COMING" : "ON SALE"}
      </p>
      <Countdown
        // overtime
        date={voucherData.getCountTime()}
        onComplete={onComplete}
        renderer={({ formatted: { days, hours, minutes, seconds } }) => {
          return (
            <div className="flex sm:text-[48px] text-[32px] font-pretendard700 sm:leading-[48px] leading-[32px] text-primaryWhite gap-[10px] mb-[32px]">
              <div className="flex flex-col items-center">
                <p className="pb-[4px] mb-[9px] border-b border-b-gray6">
                  {Number(days) > 0 ? days : "0"}
                </p>
                <p className="text-[12px] font-pretendard300 leading-[12px]">
                  DAYS
                </p>
              </div>
              <div className="">:</div>
              <div className="flex flex-col items-center">
                <p className="pb-[4px] mb-[9px] border-b border-b-gray6">
                  {hours}
                </p>
                <p className="text-[12px] font-pretendard300 leading-[12px]">
                  HOURS
                </p>
              </div>
              <div className="">:</div>
              <div className="flex flex-col items-center">
                <p className="pb-[4px] mb-[9px] border-b border-b-gray6">
                  {minutes}
                </p>
                <p className="text-[12px] font-pretendard300 leading-[12px]">
                  MIN
                </p>
              </div>
              <div className="">:</div>
              <div className="flex flex-col items-center">
                <p className="pb-[4px] mb-[9px] border-b border-b-gray6">
                  {seconds}
                </p>
                <p className="text-[12px] font-pretendard300 leading-[12px]">
                  SEC
                </p>
              </div>
            </div>
          )
        }}
      />
      <button
        className="h-[40px] px-[20px] flex items-center justify-center text-primaryWhite text-[16px] border border-primaryWhite"
        onClick={() => navigate(`group-buying/detail/${data.id}`)}
      >
        {t("프리미엄 멤버십 상세보기")}
      </button>
    </div>
  )
}

export default TopBrand
