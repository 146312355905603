import logo1 from 'src/assets/imgs/logo-brand/line1-logo-1.png'
import logo2 from 'src/assets/imgs/logo-brand/line1-logo-2.png'
import logo3 from 'src/assets/imgs/logo-brand/line1-logo-3.png'
import logo4 from 'src/assets/imgs/logo-brand/line1-logo-4.png'
import logo5 from 'src/assets/imgs/logo-brand/line1-logo-5.png'
import logo6 from 'src/assets/imgs/logo-brand/line1-logo-6.png'
import logo7 from 'src/assets/imgs/logo-brand/line1-logo-7.png'
import logo8 from 'src/assets/imgs/logo-brand/line1-logo-8.png'

import logo21 from 'src/assets/imgs/logo-brand/line2-logo-1.png'
import logo22 from 'src/assets/imgs/logo-brand/line2-logo-2.png'
import logo23 from 'src/assets/imgs/logo-brand/line2-logo-3.png'
import logo24 from 'src/assets/imgs/logo-brand/line2-logo-4.png'
import logo25 from 'src/assets/imgs/logo-brand/line2-logo-5.png'
import logo26 from 'src/assets/imgs/logo-brand/line2-logo-6.png'
import logo27 from 'src/assets/imgs/logo-brand/line2-logo-7.png'
import logo28 from 'src/assets/imgs/logo-brand/line2-logo-8.png'

import './style.scss'
import useWindowSize from 'src/util/hook/useWindowSize'

interface ItemType {
  id: string
  img: string
}

const partners: ItemType[] = [
  { id: '1', img: logo1 },
  { id: '2', img: logo2 },
  { id: '3', img: logo3 },
  { id: '4', img: logo4 },
  { id: '5', img: logo5 },
  { id: '6', img: logo6 },
  { id: '7', img: logo7 },
  { id: '8', img: logo8 },
  // { id: '9', img: logo9 },
  // { id: '10', img: logo10 },
]

const partners2: ItemType[] = [
  { id: '1', img: logo21 },
  { id: '2', img: logo22 },
  { id: '3', img: logo23 },
  { id: '4', img: logo24 },
  { id: '5', img: logo25 },
  { id: '6', img: logo26 },
  { id: '7', img: logo27 },
  { id: '8', img: logo28 },
  // { id: '9', img: logo29 },
  // { id: '10', img: logo210 },
]

interface Block6Props {}
const Block6Kr: React.FC<Block6Props> = () => {
  const { width }: any = useWindowSize()

  return (
    <div className="lg:pt-[124px] md:pt-[85px] lpSmm:pt-[70px] pt-[51px] pb-[50px]">
      <h3
        className="lg:text-[45px] md:text-[38px] text-[26px] font-spoqa500 text-[#111] text-center uppercase ld:mb-[84px] md:mb-[54px] mb-[32px] block"
        data-aos="fade-up"
      >
        모아프렌즈와 함께하는
        <br />
        브랜드입니다
      </h3>
      <div
        className="parters-marquee-wrap"
        style={{
          zoom: width < 768 ? '0.4' : width < 1024 ? '0.7' : undefined,
        }}
      >
        <div className="partners-marquee partners-marquee1">
          <div className="marquee">
            <div className="marquee-conts">
              {partners.map((item: ItemType) => (
                <img key={item.id} src={item.img} alt="logo" />
              ))}
            </div>
          </div>
          <div className="marquee marquee2">
            <div className="marquee-conts">
              {partners.map((item: ItemType) => (
                <img key={item.id} src={item.img} alt="logo" />
              ))}
            </div>
          </div>
        </div>

        <div className="partners-marquee partners-marquee2">
          <div className="marquee reverse">
            <div className="marquee-conts">
              {partners2.map((item: ItemType) => (
                <img key={item.id} src={item.img} alt="logo" />
              ))}
            </div>
          </div>
          <div className="marquee marquee2 reverse">
            <div className="marquee-conts">
              {partners2.map((item: ItemType) => (
                <img key={item.id} src={item.img} alt="logo" />
              ))}
            </div>
          </div>
        </div>

        <div className="partners-marquee partners-marquee3">
          <div className="marquee">
            <div className="marquee-conts">
              {partners.map((item: ItemType) => (
                <img key={item.id} src={item.img} alt="logo" />
              ))}
            </div>
          </div>
          <div className="marquee marquee2">
            <div className="marquee-conts">
              {partners.map((item: ItemType) => (
                <img key={item.id} src={item.img} alt="logo" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Block6Kr
