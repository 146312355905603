import React from "react"
import { useTranslation } from "react-i18next"
import PrivacyPolicyEn from "./en"
import PrivacyPolicyKr from "./kr"

type Props = {}

const PrivacyPolicy: React.FC<Props> = () => {
  const { i18n } = useTranslation()
  if (i18n.language === "en") {
    // return <PrivacyPolicyEn />
    return <PrivacyPolicyEn />
  } else {
    return <PrivacyPolicyKr />
  }
}

export default PrivacyPolicy
