import "swiper/css/pagination"
import { Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import BrandItem from "./BrandItem"
import useIsMobile from "src/customHook/useIsMobile"
import { useNavigate } from "react-router-dom"
import { UrlInternal } from "src/constant/endpointRoute"
import { useTranslation } from "react-i18next"

export type Props = {
  data: any
  isCarousel?: boolean
  title: string
}

const BrandRow: React.FC<Props> = ({ data, isCarousel, title }) => {
  const { isMobile } = useIsMobile(1023)
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="brand-row-comp relative">
      <div className="flex justify-between items-center mb-[24px]">
        <h3 className="lg:text-[24px] text-[18px] leading-[100%] font-pretendard700 w-[40%]">
          {t(title)}
        </h3>
        {!isCarousel && (
          <div
            className="text-[14px] font-pretendard400 leading-[23.8px] underline w-[60%] flex justify-end cursor-pointer"
            onClick={() => navigate("/my-membership")}
          >
            + {t("전체보기")}
          </div>
        )}
      </div>
      {!isCarousel ? (
        <div className="flex lg:gap-[21px] gap-[10px]">
          {data?.slice(0, 4).map((item: any, index: number) => {
            return (
              <div
                className="lg:w-[calc(25%_-_15.75px)] w-[calc(25%_-_7.5px)] aspect-square"
                key={index}
              >
                <BrandItem
                  data={item}
                  onClick={() => navigate(`/${item.id}`)}
                />
              </div>
            )
          })}
        </div>
      ) : (
        <div className="mr-[-24px] lg:mr-auto">
          <Swiper
            slidesPerGroup={4}
            slidesPerView={4}
            spaceBetween={isMobile ? 10 : 21}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="custom-pagination-style"
          >
            {data
              ?.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <BrandItem
                    data={item}
                    onClick={() => navigate(`/${item.id}`)}
                  />
                </SwiperSlide>
              ))
              .concat(
                Array(data.length % 4 === 0 ? 0 : 4 - (data.length % 4)).fill(
                  <SwiperSlide></SwiperSlide>,
                ),
              )}
          </Swiper>
        </div>
      )}
    </div>
  )
}

export default BrandRow
