import React from "react"
type ICON_BUTTON_MOD = "full" | "half"
type Props = {
  mode?: ICON_BUTTON_MOD
}

function IconButton({
  className,
  mode = "full",
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & Props) {
  return (
    <button
      className={`p-[11px] text-[18px] ${
        mode === "full" ? "rounded-full" : "rounded-[8px]"
      } ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

export default IconButton
