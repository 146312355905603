import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import Button from "src/components/Button"
import CommonModal from "src/components/Modal"
import { completeTransaction } from "src/features/confirmTransaction/confirmTransactionSlice"

function InsufficientBalance() {
  const { status, data }: any = useAppSelector((state) => state.confirmModal)
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const handleClose = () => {
    dispatch(completeTransaction())
  }
  return (
    <CommonModal
      isOpen={status === "NOT_ENOUGH"}
      // isOpen={true}
      handleClose={handleClose}
    >
      <p className="font-pretendard700 text-[20px] text-primary mt-[-8px] mb-[8px] leading-[30px]">
        {t("Insufficient Balance")}
      </p>
      <div className="mt-[8px]">
        <p className="text-gray1 text-[14px] leading-[20px] capitalize">
          {/* Your balance at {data?.brand_name} is{" "}
          {MoneyConvert(data?.amount_of_brand)} W<br />
          Please change your payment amount or method */}
          {t("Insufficient Balance sub")}
        </p>
        <div className="mt-[24px]">
          <Button onClick={handleClose} theme="main">
            {t("I understand")}
          </Button>
        </div>
      </div>
    </CommonModal>
  )
}

export default InsufficientBalance
