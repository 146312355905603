// import smallLogo from 'src/assets/imgs/moafriends_logo_small.png'
import useSelectLanguage from 'src/util/hook/useSelectLang'
import useWindowSize from 'src/util/hook/useWindowSize'
import Aos from 'aos'
import 'aos/dist/aos.css'
import React, { useEffect } from 'react'
import BackToTop from './components/BackToTop'
import Block1Kr from './components/Block1/Block1Kr'
import Block2Kr from './components/Block2/Block2Kr'
import Block3Kr from './components/Block3/Block3Kr'
import Block4Kr from './components/Block4/Block4Kr'
import Block5Kr from './components/Block5/Block5Kr'
import Block6Kr from './components/Block6/Block6Kr'
import FooterKr from './components/Footer/FooterKr'
import './style.scss'

interface HLangdingPageProps {}
const LangdingPage: React.FC<HLangdingPageProps> = () => {
  const { width }: any = useWindowSize()

  // done

  const lang = useSelectLanguage()

  useEffect(() => {
    Aos.init({ duration: 400 })
  }, [])

  return (
    <div>
      <Block1Kr />
      <Block2Kr />
      <Block3Kr />
      <Block4Kr />
      <Block5Kr />
      <Block6Kr />
      <FooterKr />
      
    </div>
  )
}

export default LangdingPage
