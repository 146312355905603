import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const createPost = async (data: {
  targetBrandId: number
  content: string
  medias: any
  onlyMembership: boolean
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_CREATE_POST,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const editPost = async (data: {
  id: number
  content: string
  medias: any
  onlyMembership: boolean
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_UPDATE_POST,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const getDataPost = async (params: {
  brandId?: number | null
  postOfBrand?: number
  page?: number
  size?: number
  id?: number
  ofMine?: number
  subscriberId?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_LIST_POST,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const reportFeed = async (data: {
  refId: number
  content: string
  type: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.FEED_REPORT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const reportPost = async (data: {
  refId: number
  content: string
  type: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_REPORT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const deletePost = async (data: {
  id: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_DELETE,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const commentListFeed = async (params: {
  feedId: number
  page: number
  size: number
  excludeId?: number | null
  id?: number
  sortFilter?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.FEED_LIST_COMMENT,
    method: "GET",
    tokenRequired: true,
    params: params,
  })
}

export const commentList = async (params: {
  postId: number
  page: number
  size: number
  excludeId?: number | null
  id?: number
  sortFilter?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_LIST_COMMENT,
    method: "GET",
    // tokenRequired: true,
    params: params,
  })
}

export const commentListMyMembership = async (params: {
  page: number
  size: number
  brandId?: number | null
  subscriberId?: string | null
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_LIST_COMMENT_MY_MEMBERSHIP,
    method: "GET",
    // tokenRequired: true,
    params: params,
  })
}

export const createCommentFeed = async (data: {
  feedId: number
  content: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.FEED_CREATE_COMMENT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const createComment = async (data: {
  postId: number
  content: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_CREATE_COMMENT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const deleteCommentFeed = async (data: {
  commentId: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.FEED_DELETE_COMMENT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const deleteComment = async (data: {
  commentId: number
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_DELETE_COMMENT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const editCommentFeedApi = async (data: {
  commentId: number
  content: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.FEED_EDIT_COMMENT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const editCommentApi = async (data: {
  commentId: number
  content: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_EDIT_COMMENT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}

export const listBrandCommunity = async (): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.COMMUNITY_LIST_BRAND,
    method: "GET",
    tokenRequired: true,
  })
}
