import logoBrandDefault from "src/assets/images/brand_detail/logo-brand-default.png"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import Button from "src/components/Button"

import CommonModal from "src/components/Modal"
import { completeTransaction } from "src/features/confirmTransaction/confirmTransactionSlice"
import { MoneyConvert } from "src/util/format"
import { useTranslation } from "react-i18next"
import { UrlInternal } from "src/constant/endpointRoute"

function PaymentSuccess() {
  const { status, data } = useAppSelector((state) => state.confirmModal)
  const { detailData } = useAppSelector((state) => state.brandData)
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleClose = () => {
    dispatch(completeTransaction())
  }
  return (
    <CommonModal
      isOpen={status === "SUCCESS"}
      // isOpen={true}
      handleClose={handleClose}
      imageTop={
        <img
          className="object-cover"
          src={detailData?.logoImage || logoBrandDefault}
          alt="brand-logo"
        />
      }
    >
      <p className="font-pretendard700 text-[20px] leading-normal text-primary">
        {t("Transaction completed")}
      </p>
      <div className="mt-[20px]">
        <p className="text-[18px] font-pretendard700 text-[#C70039] leading-normal">
          {MoneyConvert(data?.amount)} {t("Won")}
        </p>
        <div className="flex justify-between mt-2">
          <p className="text-[14px] leading-[24px] text-start text-black font-pretendard400">
            {t("Transaction hours")}
          </p>
          <p className="text-end text-[14px] leading-[24px] font-pretendard700">
            {dayjs(data?.time).format("HH:mm - YYYY/MM/DD")}
          </p>
        </div>
        <div className="flex justify-between mt-2">
          <p className="text-[14px] leading-[24px] text-start text-black font-pretendard400">
            {t("Location")}
          </p>
          <p className="text-end text-[14px] leading-[24px] font-pretendard700">
            {data?.brandName} {data?.receiver}
          </p>
        </div>
        <div className="flex justify-between mt-2">
          <p className="text-[14px] leading-[24px] text-start text-black font-pretendard400">
            {t("Address")}
          </p>
          <p className="text-end text-[14px] leading-[24px] font-pretendard700">
            {data?.branchInformation?.address}
          </p>
        </div>
        {/* <div className="flex justify-between text-textColor6">
          <p className="text-[14px] leading-[24px] text-start whitespace-nowrap font-pretendard400 text-black">
            {t("거래 아이디")}
          </p>
          <p className="text-end text-[14px] leading-[24px] font-pretendard600">
            {data?.trxUID}
          </p>
        </div> */}
        {/* <div className="p-4 bg-gray3 mt-4">
          <div className="flex justify-between text-primary">
            <p className="text-[12px] leading-[24px] text-start whitespace-nowrap">
              {t("보내는 사람")}
            </p>
            <p className="text-end text-[12px] leading-[24px] font-pretendard700">
              {data?.sender}
            </p>
          </div>
          <div className="flex justify-between text-primary">
            <p className="text-[12px] leading-[24px] text-start whitespace-nowrap">
              {t("받는 사람")}
            </p>
            <p className="text-end text-[12px] leading-[24px] font-pretendard700">
              {data?.receiver}
            </p>
          </div>
          <div className="flex justify-between text-primary">
            <p className="text-[12px] leading-[24px] text-start whitespace-nowrap">
              {t("위치")}
            </p>
            <p className="text-end text-[12px] font-pretendard700 leading-[24px]">
              {data?.branchInformation?.address}
            </p>
          </div>
          <div className="flex justify-between text-primary">
            <p className="text-[12px] text-start whitespace-nowrap">
              {t("메시지")}
            </p>
            <p className="text-end font-pretendard700">{data?.message}</p>
          </div>
        </div> */}
        <div className="mt-[12px]">
          <Button
            theme="main"
            labelSize={14}
            onClick={() => {
              handleClose()
              // navigate(`/my-page/order-detail/${data?.brandId}`)
              // navigate(`/my-membership/${data?.brandId}`)
              navigate(UrlInternal.HISTORY_USING_MEMBERSHIP)
            }}
          >
            {t("View transaction History")}
          </Button>
          <div className="mt-2 flex justify-between gap-2">
            <Button
              theme="main"
              labelSize={14}
              variant="outline"
              onClick={() => {
                handleClose()
                navigate("/")
              }}
            >
              {t("Home")}
            </Button>
          </div>
        </div>
      </div>
    </CommonModal>
  )
}

export default PaymentSuccess
