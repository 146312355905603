import CloseIcon from "@assets/icons/brand_page/close.svg"
import SearchIcon from "@assets/icons/brand_page/search-new-24.svg"
import { useEffect, useState } from "react"
import { getBrand, getMyMembership } from "src/api/homeApi"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import useIsMobile from "src/customHook/useIsMobile"
import {
  brandCategoriesSelect,
  getBrandCategoryList,
} from "src/features/brandCategories/brandCategoriesSlice"
import "swiper/css/pagination"
import { FreeMode } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import BrandRow from "./BrandRow"
import { useAuth } from "src/customHook/useAuth"
import { useTranslation } from "react-i18next"

interface Props {
  handleFilter?: any
  isFilterAllBrand?: boolean
}

const ListBrand: React.FC<Props> = ({ handleFilter, isFilterAllBrand }) => {
  const { isMobile } = useIsMobile(1023)
  const dispatch = useAppDispatch()
  const dataBrandCategory = useAppSelector(brandCategoriesSelect)
  const [selected, setSelected] = useState<string[]>([])
  const [openSearch, setOpenSearch] = useState<boolean>(false)
  const [dataMyBrand, setDataMyBrand] = useState<any[]>([])
  const [dataBrandRecommend, setDataBrandRecommend] = useState<any[]>([])
  const [querySearchChange, setQuerySearchChange] = useState<any>()
  const [querySearch, setQuerySearch] = useState<any>()
  const [isFilterBrand, setIsFilterBrand] = useState(false)
  const { isLogined } = useAuth()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (!isFilterAllBrand) {
      setIsFilterBrand(false)
      setSelected([])
      setQuerySearch("")
      setQuerySearchChange("")
      setOpenSearch(false)
    }
  }, [isFilterAllBrand])

  const handleSelected = (label: string) => {
    // setIsFilterBrand(true)

    if (label === "ALL") {
      setSelected([])
      handleFilter?.({
        selected: [],
        querySearch: querySearchChange,
      })
    } else {
      if (label !== "ALL" && selected.includes(label)) {
        setSelected((prev) => prev.filter((item: string) => item !== label))

        handleFilter?.({
          selected: selected.filter((item: string) => item !== label),
          querySearch: querySearchChange,
        })
      } else {
        setSelected((prev) => [...prev, label])

        handleFilter?.({
          selected: [...selected, label],
          querySearch: querySearchChange,
        })
      }
    }
  }

  // get all categories
  useEffect(() => {
    dispatch(getBrandCategoryList())
  }, [])

  useEffect(() => {
    ;(async function getData() {
      try {
        const res: any = await getMyMembership()

        if (res.result) {
          setDataMyBrand(res.data)
        }
      } catch (error) {
        setDataMyBrand([])
      }
    })()
  }, [])

  const getDataBrand = async (params?: any) => {
    try {
      const res: any = await getBrand({
        filter: "",
        page: 1,
        size: 12,
        ...params,
      })
      if (res.result) {
        setDataBrandRecommend(res.data.datas)
      }
    } catch (error) {}
  }

  useEffect(() => {
    getDataBrand()
  }, [])

  // useEffect(() => {
  //   if (querySearch !== undefined || selected.length > 0 || isFilterBrand) {
  //     handleFilter?.({ selected, querySearch })
  //   }
  // }, [selected, querySearch, isFilterBrand])

  return (
    <div className="container">
      <div className="brand-list-comp lg:mt-[85.5px] mt-[42px] lg:mb-[90px] mb-[50px]">
        <div className="sm:flex-row flex-col flex justify-between items-start overflow-hidden gap-[24px]">
          {/**mobile */}
          <div className="sm:hidden flex justify-between items-center border-b border-b-[#D4D4D4] pb-[8px] mb-[19px] w-[100%]">
            <input
              type="text"
              placeholder={t("Text words and hit enter ...")}
              className={`w-[100%] placeholder:font-pretendard500 placeholder:text-[#9E9E9E] focus-visible:outline-none`}
              style={{
                transition: "width 0.3s ease-in-out",
              }}
              onChange={(e) => setQuerySearchChange(e.target.value)}
              value={querySearchChange}
            />
            <div
              className="cursor-pointer flex items-center"
              onClick={() => {
                setQuerySearch(querySearchChange)
                if (querySearchChange) {
                  handleFilter?.({
                    selected,
                    querySearch: querySearchChange,
                  })
                }
              }}
            >
              <SearchIcon />
            </div>
          </div>
          {/**mobile */}
          {/**pc */}
          <div
            className={`${
              openSearch ? "sm:w-[calc(100%-330px)]" : "sm:w-[calc(100%-74px)]"
            } w-[100%]`}
          >
            <Swiper
              // ref={swiperRef}
              className="swiper-category-brand"
              freeMode={true}
              slidesPerView={"auto"}
              spaceBetween={0}
              // spaceBetween={8}
              pagination={{
                clickable: false,
              }}
              modules={[FreeMode]}
            >
              <SwiperSlide className="w-auto">
                <div
                  onClick={() => handleSelected("ALL")}
                  className={`cate-item flex items-center uppercase text-[18px] text-gray1 font-pretendard400 cursor-pointer pr-[13.5px]  ${
                    selected.length === 0
                      ? "text-primary font-pretendard700"
                      : ""
                  }`}
                >
                  {t("전체")}
                </div>
              </SwiperSlide>
              {dataBrandCategory?.map((item: any) => (
                <SwiperSlide className="w-auto" key={item.value}>
                  <div
                    onClick={() => handleSelected(item.label)}
                    className={`border-l border-[#BDBDBD] cate-item flex items-center uppercase text-[18px] text-gray1 font-pretendard400 cursor-pointer px-[13.5px]  ${
                      selected.includes(item.label)
                        ? "text-primary font-pretendard700"
                        : ""
                    }`}
                  >
                    {/* {item.label} */}
                    {i18n.language === "kor" ? item.korName : item.engName}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/**pc */}
          {/* <div>
            <div
              className={`sm:flex hidden gap-[19px] border-l border-[#4E4E4E] ${
                openSearch ? "pl-[14px]" : "pl-[24px]"
              }`}
            >
              <div className="flex justify-between items-center border-b border-b-[#D4D4D4] pb-[8px] ">
                <input
                  type="text"
                  placeholder={t("Text words and hit enter ...")}
                  className={`${
                    openSearch ? "w-[226px]" : "w-0"
                  }  placeholder:font-pretendard500 placeholder:text-[#9E9E9E] focus-visible:outline-none`}
                  style={{
                    transition: "width 0.3s ease-in-out",
                  }}
                  onChange={(e) => setQuerySearchChange(e.target.value)}
                  value={querySearchChange}
                />
                <div
                  className="cursor-pointer flex items-center"
                  onClick={() => {
                    setOpenSearch(true)
                    setQuerySearch(querySearchChange)

                    if (openSearch) {
                      handleFilter?.({
                        selected,
                        querySearch: querySearchChange,
                      })
                    }
                    // if (querySearchChange) {
                    //   handleFilter?.({
                    //     selected,
                    //     querySearch: querySearchChange,
                    //   })
                    // }
                  }}
                >
                  <SearchIcon />
                </div>
              </div>
              <div
                className={`${openSearch ? "block" : "hidden"} cursor-pointer`}
                onClick={() => {
                  setOpenSearch(false)
                  setQuerySearch("")
                  setQuerySearchChange("")
                }}
              >
                <CloseIcon />
              </div>
            </div>
          </div> */}
        </div>

        {!isFilterAllBrand && (
          <div>
            {isLogined && (
              <div className="lg:mt-[55px] mt-[40px]">
                <BrandRow title="MY 멤버십" data={dataMyBrand} />
              </div>
            )}
            <div className="lg:mt-[80px] mt-[47px]">
              <BrandRow
                isCarousel
                title="추천 브랜드"
                data={dataBrandRecommend}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ListBrand
