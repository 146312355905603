import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export interface SearchParams {
  querySearch?: string
  page?: number;
  size?: number;
}

export const searchApi = async (params: SearchParams) => {
  return api.request({
    url: ApiUrl.SEARCH,
    method: "GET",
    // tokenRequired: true,
    params: params,
  })
}

export const searchDetailApi = async (params: SearchParams) => {
  return api.request({
    url: ApiUrl.SEARCH_DETAIL,
    method: "GET",
    // tokenRequired: true,
    params: params,
  })
}