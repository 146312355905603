import { AxiosRequestConfig } from "axios"
import { ApiUrl } from "src/constant/endpoints"
import api from "src/services/axiosInstance"

export const confirmCodeGift = async (data: {
  code?: string
}): Promise<AxiosRequestConfig> => {
  return api.request({
    url: ApiUrl.CODE_GIFT,
    method: "POST",
    tokenRequired: true,
    data: data,
  })
}
