import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch } from "src/app/hooks"
import { open } from "src/features/drawer/drawerSlice"
import IconBack from "@assets/icons/header/icon-back.svg"
import IconMenu from "@assets/icons/header/icon-menu.svg"
import IconMypage from "@assets/icons/header/icon-mypage.svg"
import useCheckMatchPath from "src/customHook/useCheckMatchPath"
import { useTranslation } from "react-i18next"

import IconSearch from "@assets/images/home/icon_search_mobile.png"
import { UrlInternal } from "src/constant/endpointRoute"

export type NavType = "default" | "home"

interface NavBarMbProps {
  pageName: string
  navType?: NavType
  className?: string
  style?: React.CSSProperties
  leftButton?: React.ReactNode
  rightButton?: React.ReactNode
  csGoback?: () => void
}

const NavBarMb: React.FC<NavBarMbProps> = ({
  pageName,
  navType = "default",
  className,
  style,
  leftButton,
  rightButton,
  csGoback,
}) => {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const isHomePage = useCheckMatchPath("/")
  const isBrandDetail = useCheckMatchPath("/brand-detail/:id")
  const isGoupBuyingDetail = useCheckMatchPath("/group-buying/detail/:id")
  const { t } = useTranslation()

  console.log("state", state)

  const dispatch = useAppDispatch()

  const goBack = () => {
    const checkLinkBackMobile = localStorage.getItem("checkLinkBackMobile")
    if (checkLinkBackMobile) {
      navigate("/")
      localStorage.removeItem("checkLinkBackMobile")
    } else {
      if (state?.currentPage === "/" || state?.currentPage === "/brand") {
        navigate(state?.currentPage, {
          state: {
            currentPage: state?.currentPage,
          },
        })
      } else {
        navigate(-1)
      }
      // navigate(-1)
      // const segments = pathname.split("/")
      // if (segments.length > 3) {
      //   navigate(-1)

      // } else {
      //   navigate("../..")
      //   console.log('vao');
      // }
    }
  }

  const renderLeftButton = () => {
    if (leftButton) {
      return leftButton
    }
    if (navType === "home") {
      return (
        <div
          className="relative z-10"
          onClick={() => navigate(UrlInternal.SEARCH_BRAND)}
        >
          <img src={IconSearch} alt="IconSearch" className="max-w-[15px]" />
        </div>
      )
    }
    return (
      <div className="relative z-10" onClick={csGoback || goBack}>
        <IconBack />
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div
        className={`h-[60px] flex items-center justify-between px-[12px] ${
          className || ""
        }`}
        style={style}
      >
        {renderLeftButton()}
        <p className={`relative z-10 uppercase text-[20px] font-pretendard700`}>
          {t(pageName)}
        </p>
        {rightButton ? (
          rightButton
        ) : (
          <div className="relative z-10" onClick={() => dispatch(open())}>
            <IconMenu />
          </div>
        )}
      </div>
      {/* {(isHomePage || isBrandDetail || isGoupBuyingDetail) && (
        <div className="text-[14px] font-pretendard600 bg-primary text-primaryWhite h-[40px] flex items-center justify-center">
          MOAFRIENDS에서 프리미엄 브랜드 멤버십을 경험하세요.
        </div>
      )} */}
    </div>
  )
}

export default NavBarMb
