import { SECOND_PER_MINUTE } from "src/constant"
export const getStringTime = (time: number): string => {
  return time >= 10 || time <= -10 ? `${time}` : `0${time}`
}
export const secondToMinute = (num: number): string => {
  const minute = getStringTime(Math.floor(num / SECOND_PER_MINUTE))
  const second = getStringTime(num % SECOND_PER_MINUTE)

  return `${minute}:${second}`
}
export const secondToHours = (num: number, separator: string = ":"): string => {
  const days = Math.abs(Math.ceil(num / 86400))
  const hours = getStringTime(Math.abs(Math.floor(num / 3600)))
  const minutes = getStringTime(Math.abs(Math.floor((num % 3600) / 60)))
  const seconds = getStringTime(Math.abs(Math.floor((num % 3600) % 60)))
  const result =
    Number(days) > 1
      ? `${days} days`
      : `${hours}${separator}${minutes}${separator}${seconds}`
  return result
}

export const secondTo = (num: number, separator: string = ":"): string => {
  num = Math.abs(Math.floor(num))

  const hours = Math.floor(num / 3600)
  const minutes = Math.floor((num % 3600) / 60)
  const seconds = num % 60

  const formatNumber = (value: number): string => {
    return value.toString().padStart(2, "0")
  }

  const formattedHours = formatNumber(hours)
  const formattedMinutes = formatNumber(minutes)
  const formattedSeconds = formatNumber(seconds)

  let result = ""

  if (hours > 0) {
    result += `${formattedHours}${separator}`
  }

  if (minutes > 0 || result) {
    result += `${formattedMinutes}${separator}`
  }

  result += `${formattedSeconds}`

  return result
}

export function b64DecodeUnicode(str: string) {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(""),
  )
}
