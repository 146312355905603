import krFlag from 'src/assets/imgs/flag/kr.png'
import enFlag from 'src/assets/imgs/flag/en.png'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type LangId = 'en' | 'kr'

interface LangItemsType {
  id: LangId
  img: string
  label: string
}

const langs: LangItemsType[] = [
  {
    id: 'en',
    img: enFlag,
    label: 'ENG',
  },
  {
    id: 'kr',
    img: krFlag,
    label: 'KOR',
  },
]

interface SelectLanguageProps {}
const SelectLanguage: React.FC<SelectLanguageProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [active, setActive] = useState<LangId>('kr')

  const handleSelectLanguage = (lang: LangItemsType) => {
    setActive(lang.id)
    localStorage.setItem('lang', JSON.stringify(lang.id))
    searchParams.set('lang', lang.id)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    const langStorage = JSON.parse(localStorage.getItem('lang') || 'null')
    if (langStorage) {
      setActive(langStorage)
    }

    searchParams.set('lang', active)
    setSearchParams(searchParams)
  }, [active, searchParams, setSearchParams])

  return (
    <div className="shadow-custom2 w-[102px] h-[45px] rounded-[50px] flex items-center justify-center gap-[14.6px] bg-white py-[8px]">
      {langs.map((lang: LangItemsType) => (
        <div
          key={lang.id}
          // onClick={() => setActive(lang.id)}
          onClick={() => handleSelectLanguage(lang)}
          className="text-center flex flex-col gap-[3px] items-center justify-center relative cursor-pointer"
        >
          <img
            className={`${active === lang.id ? '' : 'transform scale-[0.845283]'} border border-solid border-[#F1F1F1]`}
            src={lang.img}
            alt="en"
          />
          {active === lang.id && (
            <span className="block text-[11px] text-[#5B5B5B] font-spoqa500 tracking-[-0.385px] uppercase leading-[8px]">
              {lang.label}
            </span>
          )}
          {active !== lang.id && <span className="block absolute w-[23px] h-[18px] bg-black bg-opacity-40" />}
        </div>
      ))}
    </div>
  )
}

export default SelectLanguage
