import BackSearchIcon from "@assets/icons/BackSearchIcon.svg"
import CloseIcon from "@assets/icons/CloseBlack14.svg"
import MbToggleMenuIcon from "@assets/icons/MbToggleMenuIcon.svg"
import SearchIcon from "@assets/icons/Search14.svg"
import { Input } from "antd"
import { ChangeEvent, memo, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { searchApi } from "src/api/search"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import { useDebounce } from "src/customHook/useDebounce"
import useIsMobile from "src/customHook/useIsMobile"
import { open } from "src/features/drawer/drawerSlice"
import { isOpenSearchSelect, onClose } from "../searchSlice"
import "../style.scss"
import { useTranslation } from "react-i18next"

type Props = {}

interface ItemSearch {
  id: string
  title: string
}
interface DataSearch {
  listBrand: ItemSearch[]
  listVoucher: ItemSearch[]
}

function SearchMobile({}: Props) {
  const { t } = useTranslation()
  const { isMobile } = useIsMobile(1023)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isOpenSearch = useAppSelector(isOpenSearchSelect)
  const [textSearch, setTextSearch] = useState<string>("")

  const [dataSearch, setDataSearch] = useState<DataSearch>({
    listBrand: [],
    listVoucher: [],
  })
  const debouncedInputValue = useDebounce<string>(textSearch, 500)

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value)
  }

  const handleSubmitSearch = () => {
    if (textSearch.trim() !== "") {
      setTextSearch("")
      dispatch(onClose())
      navigate(`/search-mobile?querySearch=${encodeURIComponent(textSearch)}`)
    }
  }

  useEffect(() => {
    if (isMobile) {
      const fetchData = async () => {
        try {
          const paramsSearch =
            textSearch.trim() !== "" ? { querySearch: textSearch } : {}
          const { data }: any = await searchApi(paramsSearch)

          if (data) {
            const listBrand: ItemSearch[] = data.brand.map((item: any) => ({
              id: item.id,
              title: item.brandName,
            }))
            const listVoucher: ItemSearch[] = data.voucher.map((item: any) => ({
              id: item.id,
              title: item.voucherName,
            }))

            setDataSearch({
              listBrand,
              listVoucher,
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
      if (isOpenSearch) {
        fetchData()
      }
    }
  }, [debouncedInputValue, isOpenSearch])

  return (
    <>
      <div className="search-header relative bg-primary h-[144px] justify-center flex items-center px-[15px]">
        <div
          className="absolute left-[15px] top-[53px]"
          onClick={() => {
            navigate("/")
            dispatch(onClose())
            setTextSearch("")
          }}
        >
          <BackSearchIcon />
        </div>
        <h2 className="uppercase text-[#B6F7F8] text-[14px] pb-[18px]">
          Search page
        </h2>
        <div
          className="absolute right-[15px] top-[53px]"
          onClick={() => dispatch(open())}
        >
          <MbToggleMenuIcon />
        </div>
      </div>
      <div className="search-body">
        <div className="h-[100vh]">
          <div className="search-form px-[20px] pt-[25px] relative">
            <Input
              value={textSearch}
              onChange={(e) => handleSearch(e)}
              placeholder={t("Type words and hit enter")}
              onPressEnter={handleSubmitSearch}
            />
            <div
              onClick={handleSubmitSearch}
              className="search-btn cursor-pointer"
            >
              <SearchIcon />
            </div>
            {textSearch.trim() !== "" && (
              <div
                className="absolute right-[45px] top-[30px] cursor-pointer"
                onClick={() => setTextSearch("")}
              >
                <CloseIcon />
              </div>
            )}
          </div>

          {(dataSearch.listBrand.length > 0 ||
            dataSearch.listVoucher.length > 0) && (
            <>
              <div className="h-[6px] bg-grayBg3 mt-[59px] mb-[28px]" />
              <div className="search-result search-content max-h-[360px] overflow-y-auto">
                {dataSearch.listBrand.length > 0 && (
                  <div className="mb-[20px]">
                    <h3 className="mb-[7px] text-[11px] text-[#999] font-pretendard600 uppercase leading-normal">
                      brand
                    </h3>
                    {dataSearch.listBrand.map((item: ItemSearch) => (
                      <p
                        className="text-[16px] text-primaryRed font-pretendard500 leading-normal"
                        key={item.id}
                        onClick={() => {
                          navigate(`/brand-detail/${item.id}`)
                          dispatch(onClose())
                          setTextSearch("")
                        }}
                      >
                        {item.title}
                      </p>
                    ))}
                  </div>
                )}
                {dataSearch.listVoucher.length > 0 && (
                  <div>
                    <h3 className="mb-[7px] text-[11px] text-[#999] font-pretendard600 uppercase leading-normal">
                      voucher
                    </h3>
                    {dataSearch.listVoucher.map((item: ItemSearch) => (
                      <p
                        className="text-[16px] text-primaryRed font-pretendard500 leading-normal"
                        key={item.id}
                        onClick={() => {
                          navigate(`/group-buying/detail/${item.id}`)
                          dispatch(onClose())
                          setTextSearch("")
                        }}
                      >
                        {item.title}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default memo(SearchMobile)
