import "swiper/css/pagination"
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import "./style.scss"

import imgSlide2 from "@assets/images/brand_page/slide-demo-center.jpg"
import imgSlide1 from "@assets/images/brand_page/slide-demo-left.jpg"
import imgSlide3 from "@assets/images/brand_page/slide-demo-right.jpg"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

export type Props = {
  data: any
}

const fakeData: any = [
  {
    id: "1",
    image: imgSlide1,
  },
  {
    id: "2",
    image: imgSlide2,
  },
  {
    id: "3",
    image: imgSlide3,
  },
  {
    id: "4",
    image: imgSlide1,
  },
]

const BrandSlide: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate()

  const dataSlide = useMemo(() => {
    return (
      <Swiper
        key={new Date().getTime()}
        speed={2000}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop
        // centeredSlides
        pagination={{
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
        }}
        initialSlide={0}
      >
        {data?.map((slide: any, index: number) => {
          return (
            <SwiperSlide key={slide?.id}>
              <div className="slide-item-custom flex items-center h-full lg:mx-[10.5px] relative">
                <img
                  src={slide?.image}
                  alt="slide image"
                  className="w-full max-h-[664px] object-cover"
                />

                <div
                  className="absolute bottom-[20px] right-[20px] bg-[#00000033] text-[#9E9E9E] px-[15px] py-[5px] cursor-pointer"
                  onClick={() => navigate(`/${slide.brandId}`)}
                >
                  브랜드 바로가기
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }, [data])

  return <div className="container brand-slide-comp">{dataSlide}</div>
}

export default BrandSlide
